import React, { useMemo } from 'react';
import LogoIcon from 'images/logo-white.svg';
import LogoIconSmall from 'images/logo-small.svg';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from 'configs/routes';
import { Hidden, Theme, useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'hooks';

interface HeaderLogoProps {
  title?: string;
}

export const HeaderLogo: React.FC<HeaderLogoProps> = ({ title }) => {
  const { t } = useTranslate();
  const isDesktopSize = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const icon = useMemo(() => {
    return isDesktopSize ? LogoIcon : LogoIconSmall;
  }, [isDesktopSize]);

  return (
    <div className={'logo-wrap'}>
      <Link to={APP_ROUTES.HOME.getConfig()()} className={'logo'}>
        <img alt={t('logo')} title={t('lin-q-bio')} src={icon} />
      </Link>
      {title && (
        <Hidden lgDown>
          <span className={'logo__title'}>{title}</span>
        </Hidden>
      )}
    </div>
  );
};
