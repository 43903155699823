import React, { memo } from 'react';
import { CssBaseline, jssPreset, StylesProvider, Theme, ThemeProvider } from '@material-ui/core';
import rtl from 'jss-rtl';
import { create } from 'jss';

const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
  insertionPoint: document.getElementById('jss-insertion-point') as HTMLElement,
});

export const AppThemeProvider = memo<{ theme: Theme; children: React.ReactNode }>(
  ({ theme, children }) => {
    return (
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StylesProvider>
    );
  },
);
