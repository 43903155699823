import { useTranslate } from './use-translate';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { parseErrorData } from 'utils';

export const useNotify = () => {
  const { t } = useTranslate();

  const onError = useCallback(
    (e: { message?: string }, defMessage: string = 'unexpected-error') => {
      const err = parseErrorData(e);
      return toast.error(t(err.message || defMessage));
    },
    [t],
  );
  const onSuccess = useCallback(() => {
    return toast.success(t('success'));
  }, [t]);
  return { onError, onSuccess };
};
export const useEffectNotifyError = (e: { message?: string } | undefined | null) => {
  const { onError } = useNotify();

  useEffect(() => {
    if (e) {
      onError(e);
    }
  }, [e, onError]);
};
