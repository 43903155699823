import axios, { AxiosRequestConfig } from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { axiosDateTransformer, parseErrorData } from 'utils';

export const baseURL = `${process.env.REACT_APP_API_SERVER || ''}/api/`;

export interface IUseFormRequestSaveParser {
  (data: object): { data: any; mixins: any[] };
}

export const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
  transformResponse: [axiosDateTransformer],
});

export enum SERVICE_ERROR_TYPE {
  ERROR = 0,
  WARNING = 1,
  INFO = 2,
  SUCCESS = 3,
}

export interface ServiceError<P = undefined> {
  responseType: SERVICE_ERROR_TYPE;
  messageLabel: string;
  additionalParams: P;
}

export const axiosBaseGetQuery =
  (): BaseQueryFn<Pick<AxiosRequestConfig, 'params' | 'url'>, unknown, Error> =>
  async ({ url, params }) => {
    try {
      const result = await api.get(url || '', { params });
      return { data: result.data };
    } catch (axiosError: any) {
      return {
        error: parseErrorData(axiosError),
      };
    }
  };
export const transformResponseSources = <T>(result: { value: T[] }) => {
  return result.value;
};
export const transformResponseItem = <T>(result: { value: T[] }) => {
  return result.value[0];
};
