import {
  API_NETWORKING_EVENT_USER_PROFILES,
  EditRepresentative,
  NetworkingEventRepresentativeNew,
  NetworkingEventRepresentativeExist,
  NetworkingEventRepresentative,
} from './models';
import { api } from 'services/utils';
import { BaseParams } from 'utils';

export * from './models';

class Service {
  async getAllDynamic(customerNetworkingEventID: string, params?: BaseParams) {
    const _params = {
      customerNetworkingEventID,
      ...params,
    };

    return await api.get<{ value: NetworkingEventRepresentative[] }>(
      API_NETWORKING_EVENT_USER_PROFILES.GET,
      {
        params: _params,
      },
    );
  }

  async inviteMember(data: NetworkingEventRepresentativeNew) {
    return api.post(API_NETWORKING_EVENT_USER_PROFILES.INVITE_MEMBER, data);
  }

  async invitePending(data: NetworkingEventRepresentativeExist) {
    return api.post(API_NETWORKING_EVENT_USER_PROFILES.INVITE_PENDING_MEMBER, data);
  }

  async delete(id: string) {
    return api.delete(API_NETWORKING_EVENT_USER_PROFILES.DELETE(id));
  }

  async put(data: EditRepresentative) {
    const { userCustomerNetworkingEventID, position } = data;
    return api.put(API_NETWORKING_EVENT_USER_PROFILES.PUT(userCustomerNetworkingEventID), {
      position,
      userCustomerNetworkingEventID,
    });
  }
}

export const ServiceNetworkingUserProfiles = new Service();
