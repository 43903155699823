import React, { useCallback } from 'react';

export const useOnEnter = <T extends Function = () => void>(handle: T) => {
  const onKeyPress = useCallback(
    (e: React.KeyboardEvent<any>) => {
      if (e.key === 'Enter') {
        handle(e);
      }
    },
    [handle],
  );
  return { onKeyPress };
};
