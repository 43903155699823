import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CustomerNetworkingEvent,
  CustomerNetworkingEventPatch,
} from 'services/customer-networking-event';
import { combineActions } from 'store/_utils';
import { PatchModel } from 'utils';
import {
  actionEventBuilderImagesCreate,
  actionEventBuilderImagesRemove,
  actionEventBuilderRepresentativesInvite,
  actionEventBuilderRepresentativesRemove,
  actionEventBuilderRepresentativesUpdate,
  actionEventBuilderResourcesCreate,
  actionEventBuilderResourcesRemove,
  actionEventBuilderVideosCreate,
  actionEventBuilderVideosRemove,
} from 'store/event-builder/actions';
import { actionAccountLogout } from 'store/auth';

type Patch = PatchModel<CustomerNetworkingEventPatch, 'customerNetworkingEventID'>;

export enum EVENT_BUILDER_STATUS {
  NOT_EXIST,
  EXIST,
}

interface ItemsState {
  error: Error | null;
  isLoading: boolean;
  isInit: boolean;
  data: CustomerNetworkingEvent | null;
  status: EVENT_BUILDER_STATUS;
}

export const EventBuilderItemState = (): ItemsState => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: null,
    status: EVENT_BUILDER_STATUS.NOT_EXIST,
  };
};

interface State {
  [customerNetworkingEventID: string]: ItemsState | undefined;
}

const initialState: State = {};

const slice = createSlice({
  name: 'EVENT_BUILDER',
  initialState,
  reducers: {
    getCustomerEventRequest(state, action: PayloadAction<{ customerNetworkingEventID: string }>) {
      const { customerNetworkingEventID } = action.payload;
      state[customerNetworkingEventID] = {
        ...EventBuilderItemState(),
        ...state[customerNetworkingEventID],
        isLoading: true,
        error: null,
      };
    },
    getCustomerEventSuccess(
      state,
      action: PayloadAction<{
        customerNetworkingEventID: string;
        data: CustomerNetworkingEvent | null;
      }>,
    ) {
      const { customerNetworkingEventID, data } = action.payload;
      state[customerNetworkingEventID] = {
        ...EventBuilderItemState(),
        ...state[customerNetworkingEventID],
        data,
        isLoading: false,
        isInit: true,
        status: data ? EVENT_BUILDER_STATUS.EXIST : EVENT_BUILDER_STATUS.NOT_EXIST,
      };
    },
    getCustomerEventFail(
      state,
      action: PayloadAction<{ customerNetworkingEventID: string; error: Error }>,
    ) {
      const { customerNetworkingEventID, error } = action.payload;
      state[customerNetworkingEventID] = {
        ...EventBuilderItemState(),
        ...state[customerNetworkingEventID],
        isLoading: false,
        isInit: true,
        error: error,
      };
    },

    autoSaveCustomerEventRequest(
      state,
      action: PayloadAction<{ customerNetworkingEventID: string; old: Patch; new: Patch }>,
    ) {
      const { customerNetworkingEventID } = action.payload;
      state[customerNetworkingEventID] = {
        ...EventBuilderItemState(),
        ...state[customerNetworkingEventID],
        isLoading: true,
      };
    },
    autoSaveCustomerEventSuccess(
      state,
      action: PayloadAction<{ customerNetworkingEventID: string }>,
    ) {
      const { customerNetworkingEventID } = action.payload;
      state[customerNetworkingEventID] = {
        ...EventBuilderItemState(),
        ...state[customerNetworkingEventID],
        isLoading: false,
      };
    },
    autoSaveCustomerEventFail(
      state,
      action: PayloadAction<{ customerNetworkingEventID: string; error: Error }>,
    ) {
      const { customerNetworkingEventID } = action.payload;
      state[customerNetworkingEventID] = {
        ...EventBuilderItemState(),
        ...state[customerNetworkingEventID],
        isLoading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionEventBuilderVideosRemove.fulfilled, (state, action) => {
      const { customerNetworkingEventID, videoID } = action.payload;
      const item = state[customerNetworkingEventID];
      if (item?.data) {
        item.data._videos = (item.data._videos || []).filter((item) => {
          return item.id !== videoID;
        });
      }
    });
    builder.addCase(actionEventBuilderVideosCreate.fulfilled, (state, action) => {
      const { customerNetworkingEventID, data } = action.payload;
      const item = state[customerNetworkingEventID];
      if (item?.data) {
        item.data._videos = data;
      }
    });

    builder.addCase(actionEventBuilderImagesRemove.fulfilled, (state, action) => {
      const { customerNetworkingEventID, imageID } = action.payload;
      const item = state[customerNetworkingEventID];
      if (item?.data) {
        item.data._images = (item.data._images || []).filter((item) => {
          return item.id !== imageID;
        });
      }
    });
    builder.addCase(actionEventBuilderImagesCreate.fulfilled, (state, action) => {
      const { customerNetworkingEventID, data } = action.payload;
      const item = state[customerNetworkingEventID];
      if (item?.data) {
        item.data._images = data;
      }
    });

    builder.addCase(actionEventBuilderResourcesRemove.fulfilled, (state, action) => {
      const { customerNetworkingEventID, data } = action.payload;
      const item = state[customerNetworkingEventID];
      if (item?.data) {
        item.data._resources = data;
      }
    });
    builder.addCase(actionEventBuilderResourcesCreate.fulfilled, (state, action) => {
      const { customerNetworkingEventID, data } = action.payload;
      const item = state[customerNetworkingEventID];
      if (item?.data) {
        item.data._resources = data;
      }
    });

    builder.addCase(actionEventBuilderRepresentativesInvite.fulfilled, (state, action) => {
      const { customerNetworkingEventID, data } = action.payload;
      const item = state[customerNetworkingEventID];
      if (item?.data) {
        item.data._representatives = data;
      }
    });
    builder.addCase(actionEventBuilderRepresentativesUpdate.fulfilled, (state, action) => {
      const { customerNetworkingEventID } = action.payload;
      const { userCustomerNetworkingEventID, position } = action.payload.data;
      const item = state[customerNetworkingEventID];
      if (item?.data) {
        item.data._representatives = (item.data._representatives || []).map((item) => {
          return item.userCustomerNetworkingEventID === userCustomerNetworkingEventID
            ? { ...item, position }
            : item;
        });
      }
    });
    builder.addCase(actionEventBuilderRepresentativesRemove.fulfilled, (state, action) => {
      const { customerNetworkingEventID, userCustomerNetworkingEventID } = action.payload;
      const item = state[customerNetworkingEventID];
      if (item?.data) {
        item.data._representatives = (item.data._representatives || []).filter((item) => {
          return item.userCustomerNetworkingEventID !== userCustomerNetworkingEventID;
        });
      }
    });
    builder.addCase(actionAccountLogout.success, (state, action) => {
      return initialState;
    });
  },
});
const actions = slice.actions;
export const actionEventBuilderGetEvent = combineActions(
  actions.getCustomerEventRequest,
  actions.getCustomerEventSuccess,
  actions.getCustomerEventFail,
);

export const actionEventBuilderAutoSave = combineActions(
  actions.autoSaveCustomerEventRequest,
  actions.autoSaveCustomerEventSuccess,
  actions.autoSaveCustomerEventFail,
);

export const reducerEventBuilder = slice.reducer;
