import React, { forwardRef, useCallback, useMemo } from 'react';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import { CircularProgress, Popper, TextField } from '@material-ui/core';
import { AppScrollbars } from 'components/app-scrollbar';
import { useTranslate } from 'hooks';

export const SelectPaperComponent = forwardRef<any, React.HTMLAttributes<HTMLElement>>(
  ({ children, ...rest }, ref) => {
    return (
      <div ref={ref} {...rest} role={'listbox'}>
        <AppScrollbars
          style={{ width: '100%' }}
          autoHeight
          className={'app-select__scroll'}
          renderView={(props) => <div {...props} style={{ ...props.style }} />}
        >
          {children}
        </AppScrollbars>
      </div>
    );
  },
);
export const SelectPopperComponent: React.FC = (props: any) => {
  return <Popper {...props} placement="bottom-start" />;
};

export interface CommonSelectProps
  extends Omit<
    AutocompleteProps<any, any, any, any>,
    'renderInput' | 'options' | 'getOptionLabel'
  > {
  value?: any | any[];
  required?: boolean;
  optionLabel?: string | AutocompleteProps<any, any, any, any>['getOptionLabel'];
  optionValue?: string;
  loading?: boolean;
  label?: React.ReactNode | string;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  error?: { message?: string } | boolean;
  source: any[];
  onChange?: (e: any, v: (string | number) | (string | number)[], option: any) => void;
  helperText?: string;
}

export const CommonSelect: React.FC<CommonSelectProps> = ({
  onChange,
  renderInput,
  value,
  optionLabel = 'title',
  optionValue = 'id',
  getOptionSelected,
  source,
  error,
  loading = false,
  label,
  multiple,
  required,
  helperText,
  ...rest
}) => {
  const { t } = useTranslate();
  const _renderInput = useCallback(
    (p: AutocompleteRenderInputParams) => {
      const helper =
        (typeof error === 'boolean' ? helperText : t(error?.message || '')) || helperText;
      return (
        <TextField
          {...p}
          required={required}
          label={label}
          error={Boolean(error)}
          helperText={helper}
          InputProps={{
            ...p.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={15} /> : null}
                {p.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          inputProps={{
            ...p.inputProps,
            autoComplete: 'nope',
          }}
        />
      );
    },
    [label, error, loading, t, required, helperText],
  );

  const _getOptionLabel = useCallback(
    (option) => {
      const value = typeof optionLabel === 'function' ? optionLabel(option) : option[optionLabel];
      return value || '';
    },
    [optionLabel],
  );

  const handleChange = useCallback(
    (e, option) => {
      let value_ = (option && option[optionValue]) || '';
      if (Array.isArray(option)) {
        value_ = option.map((optionItem) => optionItem[optionValue]).filter((v) => !!v);
      }
      return onChange && onChange({ ...e, target: { ...e.target, value: value_ } }, value_, option);
    },
    [onChange, optionValue],
  );

  const sourceMap = useMemo(() => {
    return source.reduce((acc, option) => {
      const id = option[optionValue];
      acc[id] = option;
      return acc;
    }, {});
  }, [source, optionValue]);

  const selectedValue = useMemo(() => {
    if (Array.isArray(value)) {
      const values = value || [];
      return values.map((id) => sourceMap[id]).filter((v) => !!v);
    } else if (value) {
      return sourceMap[value] || {};
    } else {
      return {};
    }
  }, [value, sourceMap]);

  return (
    <Autocomplete
      value={selectedValue}
      {...rest}
      multiple={multiple}
      options={source}
      getOptionLabel={_getOptionLabel}
      renderInput={renderInput || _renderInput}
      onChange={handleChange}
      PopperComponent={SelectPopperComponent}
      ListboxComponent={SelectPaperComponent}
    />
  );
};
