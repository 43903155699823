import React, {IframeHTMLAttributes} from "react";
import {Dialog} from "@material-ui/core";

interface Props {
  url: string
  onClose: () => void
  FrameProps?: IframeHTMLAttributes<HTMLIFrameElement>
}
export const DialogFrame: React.FC<Props> = ({ onClose, url, FrameProps }) => {
  return (
    <Dialog open={true} onClose={onClose}>
      <iframe title={"DialogFrame"} src={url} width={320} height={500} {...FrameProps} />
    </Dialog>
  )
}
