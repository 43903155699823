import { useMemo } from 'react';
import { format, isValid } from 'date-fns';
import { convertToDate } from '../utils';
import { formatInTimeZone } from 'date-fns-tz';

export const useDateFormatSplit = (date?: string | Date | null) => {
  const _date = useMemo(() => {
    if (!date) {
      return '';
    }
    return (isValid(date) ? date : new Date(date)) as Date;
  }, [date]);
  const locales = useMemo(() => {
    return 'en';
  }, []);

  const year = useMemo(() => {
    return _date ? _date.toLocaleDateString(locales, { year: 'numeric' }) : '';
  }, [locales, _date]);
  const month = useMemo(() => {
    return _date ? _date.toLocaleDateString(locales, { month: 'short' }) : '';
  }, [locales, _date]);
  const day = useMemo(() => {
    return _date ? _date.toLocaleDateString(locales, { day: '2-digit' }) : '';
  }, [locales, _date]);

  const hours = useMemo(() => {
    return _date ? format(_date, 'HH') : '';
  }, [_date]);
  const minutes = useMemo(() => {
    return _date ? format(_date, 'mm') : '';
  }, [_date]);

  return useMemo(() => {
    return { year, month, day, hours, minutes };
  }, [year, month, day, hours, minutes]);
};
export const useDateFormat = (date?: string | Date | null) => {
  const { year, day, month } = useDateFormatSplit(date);

  return useMemo(() => {
    return [day, month, year].filter((v) => !!v).join(' ');
  }, [day, month, year]);
};

export const useTimeZoneGmt = (
  date: string | undefined | null,
  timeZoneID: string | undefined | null,
) => {
  return useMemo(() => {
    if (!timeZoneID || !date) {
      return ' ';
    }
    return formatInTimeZone(convertToDate(date), timeZoneID || '', 'OOO');
  }, [date, timeZoneID]);
};

export const useTimeZonePlace = (timeZoneID: string | undefined | null) => {
  return useMemo(() => {
    if (!timeZoneID) {
      return ' ';
    }
    const arr = timeZoneID.split('/');
    const city = arr[1] || arr[0];
    let clearCity = city.replace(/_/g, ' ');
    if (clearCity.includes('GMT')) {
      return '';
    }
    return clearCity;
  }, [timeZoneID]);
};

export const useJoinDate = (fromDate: string | undefined, toDate: string | undefined) => {
  return useMemo(() => {
    return [fromDate, toDate].map((date) => format(convertToDate(date), 'dd MMM yyyy')).join(' - ');
  }, [fromDate, toDate]);
};
