import React, { CSSProperties, useCallback, useRef } from 'react';
import { IconButton, ListItemText, Menu, MenuItem, IconButtonProps } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MenuProps } from '@material-ui/core/Menu/Menu';

interface Item {
  title: string | React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
}

export interface ButtonMenuDotsProps {
  items: Item[];
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  sizeIcon?: 'inherit' | 'default' | 'small' | 'large';
  IconButtonProps?: Omit<IconButtonProps, 'onClick'>;
  MenuProps?: Partial<MenuProps>;
  closeOnClick?: boolean;
}

export const ButtonMenuDots: React.FC<ButtonMenuDotsProps> = ({
  items,
  isOpen,
  onClose,
  onOpen,
  sizeIcon,
  IconButtonProps,
  MenuProps,
  closeOnClick,
}) => {
  const ref = useRef<any>();
  const factoryClick = useCallback(
    (handle: Item['onClick']) => {
      return () => {
        handle && handle();
        if (closeOnClick) {
          onClose();
        }
      };
    },
    [closeOnClick, onClose],
  );
  return (
    <>
      <IconButton {...IconButtonProps} ref={ref} onClick={onOpen}>
        <MoreVertIcon fontSize={sizeIcon} />
      </IconButton>
      <Menu
        anchorEl={ref.current}
        open={isOpen}
        onClose={onClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        {...MenuProps}
      >
        {items.map(({ title, onClick, disabled, className, style }, i) => (
          <MenuItem
            key={i}
            onClick={factoryClick(onClick)}
            disabled={disabled}
            className={className}
            style={style}
          >
            <ListItemText>{title}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
