import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'configs/routes';

interface Props {
  defaultBack: () => void;
}

export const useBackButton = (p?: Props) => {
  const { defaultBack } = p || {};
  const { goBack: historyBack, push, length } = useHistory();

  const goBack = useCallback(() => {
    length ? historyBack() : defaultBack ? defaultBack() : push(APP_ROUTES.HOME.getConfig()());
  }, [historyBack, length, defaultBack, push]);

  return {
    goBack,
  };
};
