import React, { useCallback } from 'react';
import { Divider, LinearProgress } from '@material-ui/core';
import { APP_ROUTES, PREFERENCES_PAGE_TABS } from 'configs/routes';
import { eventProfileMy, useGaEvent } from '../google-analytics';
import { PERCENT_PROFILE_TO_COMPLETENESS } from 'contexts/provider-complete';
import { useCurrentUser, useTranslate } from 'hooks';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { ButtonClose } from '../app-buttons';

interface ProfileCompleteInfoProps {
  onClose(): void;
}

export const ProfileCompleteInfo: React.FC<ProfileCompleteInfoProps> = ({ onClose }) => {
  const { t } = useTranslate();
  const { profileCompleteness, currentAppUserProfileSummary } = useCurrentUser();
  const { push } = useHistory();
  const gaEvent = useGaEvent();
  const handleComplete = useCallback(() => {
    gaEvent(eventProfileMy());
    push(APP_ROUTES.PREFERENCES.getConfig()({ tab: PREFERENCES_PAGE_TABS.DETAILS }));
    onClose();
  }, [onClose, gaEvent, push]);

  const linearProgressClasses = clsx(
    profileCompleteness >= PERCENT_PROFILE_TO_COMPLETENESS && 'green',
  );

  return (
    <div className={'complete-info'}>
      <ButtonClose size={'small'} onClick={onClose} className={'complete-info__close'} />
      <div className={'complete-info__header'}>
        <h3 className={'complete-info__section complete-info__title'}>
          {t('profile-completeness')}
        </h3>
        <div className={'complete-info__section complete-info__progress'}>
          <LinearProgress
            className={linearProgressClasses}
            variant="determinate"
            value={profileCompleteness}
          />
          <span className={'complete-info__progress-percent'}>{profileCompleteness}%</span>
        </div>
        <div className={'complete-info__section'}>
          <span className={'complete-info__sub-title'}>{t('you-choose')}</span>
          <div className={'complete-info__network'}>
            <img
              className={'complete-info__network-icon'}
              src={currentAppUserProfileSummary.networkItemIcon}
              alt={'network-icon'}
              title={currentAppUserProfileSummary.networkItemTitle}
            />
            <span>{currentAppUserProfileSummary.networkItemTitle}</span>
          </div>
        </div>
      </div>
      <Divider />
      <div className={'complete-info__section complete-info__body'}>
        <span className={'complete-info__sub-title'}>{t('missing-parameters')}</span>
        <ul className={'app-list-style'}>
          {currentAppUserProfileSummary.missingInforamtionList.map((item: string, i: number) => {
            return <li key={i}>{t(item)}</li>;
          })}
        </ul>
        <div className={'complete-info__section complete-info__slogan'}>
          {t('profile-completeness-slogan')}
        </div>
      </div>
      <div className={'complete-info__footer'}>
        <span className={'complete-info__footer-btn'} onClick={handleComplete}>
          {t('complete-your-profile')}
        </span>
      </div>
    </div>
  );
};
