import { api, axiosBaseGetQuery, transformResponseItem } from 'services/utils';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { BaseParams, createFilterEquals } from 'utils';
import { ENDPOINTS_MEMBERS, Member } from './models';
import { UserStatusType } from 'configs';

export * from './models';

class Service {
  async getMember({ id, params }: { params?: BaseParams; id: Member['appUserID'] }) {
    const filter = `appUserID == "${id}"`;
    const _params = {
      ...params,
      filter: params?.filter ? `( ${params.filter} ) && ${filter}` : filter,
    };
    const {
      data: { value },
    } = await api.get<{ value: Member[] }>(ENDPOINTS_MEMBERS.GET_MEMBERS, { params: _params });

    if (value.length === 0) {
      throw new Error('record-not-found');
    }
    return { data: value[0] };
  }

  async getMembers(props?: { params?: BaseParams }) {
    const orderBy = props?.params?.orderBy || 'createdDate desc';
    const params = {
      ...props?.params,
      filter: [props?.params?.filter, `userStatusType == ${UserStatusType.ACTIVE}`]
        .filter((v) => !!v)
        .join('&&'),
    };
    return api.get<{ value: Member[]; count: number }>(ENDPOINTS_MEMBERS.GET_MEMBERS, {
      params: {
        ...params,
        orderBy,
      },
    });
  }

  async getMembersByNetworkItem({
    networkItemID,
    params = {},
  }: {
    params?: BaseParams;
    networkItemID: number | string;
  }) {
    const filter = `networkItemID == ${networkItemID}`;
    params.filter = params.filter ? `( ${params.filter} ) && ${filter}` : filter;
    return this.getMembers({ params });
  }
}
export const membersApi = createApi({
  reducerPath: 'membersApi',
  baseQuery: axiosBaseGetQuery(),
  tagTypes: ['Member'],
  endpoints: (builder) => ({
    getMember: builder.query<Member, string>({
      query: (appUserID) => ({
        url: ENDPOINTS_MEMBERS.GET_MEMBERS,
        params: {
          filter: createFilterEquals<Member>({ name: 'appUserID', value: appUserID }),
        },
      }),
      providesTags: (res, err, arg) => [{ type: 'Member', id: arg }],
      transformResponse: transformResponseItem,
    }),
  }),
});

export const ServiceMembers = new Service();
