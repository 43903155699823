import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetRequest } from 'hooks';
import { ServiceLabels } from 'services/labels';
import { getLabelsActions } from 'store/labels';

export const AppDictionaryProvider: React.FC = ({ children }) => {
  const { data, request } = useGetRequest({ request: ServiceLabels.getDefaultLanguageID });

  const dispatch = useDispatch();
  useEffect(() => {
    request();
  }, [request]);

  useEffect(() => {
    if (data) {
      dispatch(getLabelsActions.request({ languageID: data }));
    }
  }, [dispatch, data]);
  return <>{children}</>;
};
