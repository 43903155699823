import React, { useCallback } from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { useTranslate } from 'hooks';
import style from './index.module.scss';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'configs';

type TPrice = number | string;

interface Props {
  eventID: string;
  earlyStage: boolean;
  earlyStagePrice: TPrice;
  earlyStageDate: string;
  standardPrice: TPrice;
  className?: string;
}

export const TabExhibitorsBannerBecome: React.FC<Props> = ({ eventID, className }) => {
  const { t } = useTranslate();

  const { push } = useHistory();
  const onBecome = useCallback(() => {
    push(APP_ROUTES.EVENT_BUILDER_PAYMENT.getConfig({ networkingEventID: eventID })());
  }, [eventID, push]);
  return (
    <Paper
      classes={{
        root: style.root,
      }}
      elevation={0}
      className={className}
    >
      <Typography variant={'h4'} color={'primary'} className={style.title}>
        {t('become-an-exhibitor-info')}
      </Typography>
      <Button color={'secondary'} variant={'contained'} size={'large'} onClick={onBecome}>
        {t('start-now')}
      </Button>
    </Paper>
  );
};
