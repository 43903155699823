import React, { memo } from 'react';

interface Props {
  disabled?: boolean;
}

export const IconAwardFinish = memo<Props>(({ disabled }) => {
  return !disabled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="48" viewBox="0 0 35 48" fill="none">
      <g filter="url(#filter0_i_6638_20020)">
        <path
          d="M25.9526 36H9.05702C7.36939 36 6 37.3698 6 39.0482V47.836C6 47.9228 6.07715 48 6.16394 48H28.8361C28.9228 48 29 47.9325 29 47.836V39.0482C29 37.3698 27.6306 36 25.9526 36Z"
          fill="#5FB800"
        />
      </g>
      <g filter="url(#filter1_di_6638_20020)">
        <rect x="11" y="39" width="13" height="5" fill="#FFCE35" />
      </g>
      <g filter="url(#filter2_ii_6638_20020)">
        <path
          d="M30.9847 1.33948H27.3778V0H7.62222V1.33948H4.01528C1.79861 1.33948 0 3.09695 0 5.25342V7.77089C0 12.9768 4.34583 17.2137 9.69306 17.2137H10.3153C11.5208 18.5057 13.0764 19.5032 14.8458 20.0447C15.2347 20.1587 15.4875 20.5292 15.4583 20.9282L14.6903 29.5446C14.6611 29.9151 11.5403 32.024 11.5403 32.024C11.1514 32.3375 10.9278 32.7935 10.9278 33.2875V34H24.0722V33.2875C24.0722 32.7935 23.8486 32.3375 23.4597 32.024C23.4597 32.024 20.3389 29.9151 20.3097 29.5446L19.5417 20.9282C19.5028 20.5292 19.7556 20.1587 20.1542 20.0447C21.9333 19.5032 23.4792 18.5152 24.6847 17.2137H25.3069C30.6542 17.2137 35 12.9768 35 7.77089V5.25342C35 3.09695 33.2014 1.33948 30.9847 1.33948ZM2.41111 7.77089V5.25342C2.41111 4.38894 3.13056 3.68595 4.01528 3.68595H7.62222V10.4973C7.62222 12.0078 7.98194 13.4708 8.64306 14.7818C5.11389 14.2878 2.41111 11.3333 2.41111 7.77089ZM32.5889 7.77089C32.5889 11.3333 29.8764 14.2878 26.3667 14.7913C27.0181 13.4708 27.3875 12.0173 27.3875 10.5068V3.68595H30.9944C31.8792 3.68595 32.5986 4.38894 32.5986 5.25342V7.77089H32.5889Z"
          fill="#FFCE35"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_6638_20020"
          x="6"
          y="36"
          width="23"
          height="12"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.298039 0 0 0 0 0.576471 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6638_20020" />
        </filter>
        <filter
          id="filter1_di_6638_20020"
          x="11"
          y="39"
          width="14"
          height="6"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.298039 0 0 0 0 0.576471 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6638_20020" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6638_20020"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0 0.643137 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_6638_20020" />
        </filter>
        <filter
          id="filter2_ii_6638_20020"
          x="0"
          y="0"
          width="35"
          height="34"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.984314 0 0 0 0 0.741176 0 0 0 0 0.109804 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6638_20020" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0 0.643137 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_6638_20020"
            result="effect2_innerShadow_6638_20020"
          />
        </filter>
      </defs>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="48" viewBox="0 0 35 48" fill="none">
      <g opacity="0.2">
        <path
          d="M25.9526 36H9.05702C7.36939 36 6 37.3698 6 39.0482V47.836C6 47.9228 6.07715 48 6.16394 48H28.8361C28.9228 48 29 47.9325 29 47.836V39.0482C29 37.3698 27.6306 36 25.9526 36ZM24 44H11V39.0482L24 39V44Z"
          fill="#CFDCE7"
        />
        <path
          d="M30.9847 1.33948H27.3778V0H7.62222V1.33948H4.01528C1.79861 1.33948 0 3.09695 0 5.25342V7.77089C0 12.9768 4.34583 17.2137 9.69306 17.2137H10.3153C11.5208 18.5057 13.0764 19.5032 14.8458 20.0447C15.2347 20.1587 15.4875 20.5292 15.4583 20.9282L14.6903 29.5446C14.6611 29.9151 11.5403 32.024 11.5403 32.024C11.1514 32.3375 10.9278 32.7935 10.9278 33.2875V34H24.0722V33.2875C24.0722 32.7935 23.8486 32.3375 23.4597 32.024C23.4597 32.024 20.3389 29.9151 20.3097 29.5446L19.5417 20.9282C19.5028 20.5292 19.7556 20.1587 20.1542 20.0447C21.9333 19.5032 23.4792 18.5152 24.6847 17.2137H25.3069C30.6542 17.2137 35 12.9768 35 7.77089V5.25342C35 3.09695 33.2014 1.33948 30.9847 1.33948ZM2.41111 7.77089V5.25342C2.41111 4.38894 3.13056 3.68595 4.01528 3.68595H7.62222V10.4973C7.62222 12.0078 7.98194 13.4708 8.64306 14.7818C5.11389 14.2878 2.41111 11.3333 2.41111 7.77089ZM32.5889 7.77089C32.5889 11.3333 29.8764 14.2878 26.3667 14.7913C27.0181 13.4708 27.3875 12.0173 27.3875 10.5068V3.68595H30.9944C31.8792 3.68595 32.5986 4.38894 32.5986 5.25342V7.77089H32.5889Z"
          fill="#CFDCE7"
        />
      </g>
    </svg>
  );
});
