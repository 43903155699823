const API = 'MediaUploads';

export const API_MEDIA_UPLOADS = {
  UPLOAD_COMPANY_DESCRIPTION: 'MediaUploads/UploadCompanyDescription',
  UPLOAD_USER_PICTURE: 'MediaUploads/UploadUserProfilePicture',
  DELETE_USER_PICTURE: (data: { appUserID: string | number }) =>
    `MediaUploads/DeleteUserProfilePicture/${data.appUserID}`,
  UPLOAD_CV_FILE: 'MediaUploads/UploadCVFile',
  DELETE_CV_FILE: (data: { appUserID: string | number }) =>
    `MediaUploads/DeleteCVFile/${data.appUserID}`,
  UPLOAD_DESC_FILE: 'MediaUploads/UploadDeckFile',
  DELETE_DESC_FILE: (data: { appUserID: string | number }) =>
    `MediaUploads/DeleteDeckFile/${data.appUserID}`,
  UPLOAD_COMPANY_LOGO: 'MediaUploads/UploadCompanyLogo',
  DELETE_COMPANY_LOGO: (data: { companyID: string | number }) =>
    `MediaUploads/DeleteCompanyLogo/${data.companyID}`,
  UPLOAD_COMPANY_DESC_FILE: 'MediaUploads/UploadCompanyDeckFile',
  DELETE_COMPANY_DESC_FILE: (data: { companyID: string | number }) =>
    `MediaUploads/DeleteCompanyDeckFile/${data.companyID}`,

  UPLOAD_FILE: `${API}/UploadFileToCloud`,
  UPLOAD_HTML: `${API}/UploadHtmlToCloud`,
  UPLOAD_IMAGE: `${API}/UploadImageToCloud`,
  REMOVE_FILE: (filePath: string) =>
    `${API}/RemoveFileFromCloud?filePath=${encodeURIComponent(filePath)}`,
};

export interface UploadFilePayload {
  fileName: string;
  fileStreamString: string;
  filePath?: string;
}

export interface UploadHTMLPayload {
  fileName?: string;
  filePath?: string;
  htmlContent: string;
}

abstract class BaseUploadFileModel {
  fileName?: string = '';
  fileStreamString: string = '';
  filePath?: string = '';
}
export class UploadUserFileModel extends BaseUploadFileModel {
  appUserID!: string;
}

export class UploadCompanyFileModel extends BaseUploadFileModel {
  companyID!: string;
}
