import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Company, UserCompany } from 'services/companies';
import { combineActions } from '../_utils';
import { CurrentUserFullInfo } from 'services/accounts';

export class StoreCompanyItem {
  isLoading = false;
  isInit = false;
  error: null | Error = null;
  data: null | Company = null;
}

interface InitState {
  init: boolean;
  loading: boolean;
  loadingPatch: boolean;
  error: null | Error;
  user: null | CurrentUserFullInfo;
  code: boolean;

  loadingCompanies: boolean;
  companies: UserCompany[];
  companiesMap: Record<string, StoreCompanyItem>;
}

const initStateAccount = (): InitState => {
  return {
    init: false,
    loading: false,
    loadingPatch: false,
    error: null,
    user: null,
    code: false,

    loadingCompanies: false,
    companies: [],
    companiesMap: {},
  };
};

const slice = createSlice({
  name: 'ACCOUNT',
  initialState: initStateAccount(),
  reducers: {
    requestGetCurrentUser(state) {
      state.loading = true;
      state.error = null;
    },
    successGetCurrentUser(state, action: PayloadAction<{ user: CurrentUserFullInfo }>) {
      const { user } = action.payload;
      state.loading = false;
      state.init = true;
      state.user = user;
    },
    failGetCurrentUser(state, action: PayloadAction<{ error: Error }>) {
      state.loading = false;
      state.init = true;
    },

    generateCodeRequest(state, action: PayloadAction<{ email: string }>) {
      state.loading = true;
    },
    generateCodeSuccess(state) {
      state.loading = false;
      state.code = true;
    },
    generateCodeFail(state, action: PayloadAction<{ error: Error }>) {
      state.loading = false;
      state.code = false;
      state.error = action.payload.error;
    },

    loginWithCodeRequest(state, action: PayloadAction<{ email: string; code: string }>) {
      state.loading = true;
    },
    loginWithCodeSuccess(state) {
      state.loading = false;
    },
    loginWithCodeFail(state, action: PayloadAction<{ error: Error }>) {
      state.loading = false;
      state.error = action.payload.error;
    },

    requestGetCurrentUserCompanies(state) {
      state.loadingCompanies = true;
    },
    successGetCurrentUserCompanies(state, action: PayloadAction<{ companies: UserCompany[] }>) {
      state.companies = action.payload.companies;
      state.loadingCompanies = false;
    },
    failGetCurrentUserCompanies(state, action: PayloadAction<{ error: Error }>) {
      state.loadingCompanies = false;
    },

    selectCompanyRequest(state, action: PayloadAction<{ companyID: string }>) {
      state.loadingCompanies = true;
    },
    selectCompanySuccess(state) {
      state.loadingCompanies = false;
    },
    selectCompanyFail(state, action: PayloadAction<{ error: Error }>) {
      state.loadingCompanies = false;
    },

    actionAccountRefreshCurrentUser(state) {},

    logoutRequest(state) {
      state.loading = true;
    },
    logoutSuccess() {
      return initStateAccount();
    },
    logoutFail(state, action: PayloadAction<{ error: Error }>) {
      state.loading = false;
    },

    updateCurrentUserRequest(state, action: PayloadAction<Partial<CurrentUserFullInfo>>) {},
    updateCurrentUserSuccess(state, action: PayloadAction<Partial<CurrentUserFullInfo>>) {
      if (state.user) {
        state.user = { ...state.user, ...action.payload };
      }
    },
    updateCurrentUserFail(
      state,
      action: PayloadAction<{ data: Partial<CurrentUserFullInfo>; error: Error }>,
    ) {
      if (state.user) {
        state.user = { ...state.user, ...action.payload.data };
      }
    },

    getCurrentUserCompanyRequest(state, action: PayloadAction<{ companyID: string }>) {
      const { companyID } = action.payload;
      state.companiesMap[companyID] = {
        ...state.companiesMap[companyID],
        isLoading: true,
        isInit: false,
        error: null,
        data: null,
      };
    },
    getCurrentUserCompanySuccess(
      state,
      action: PayloadAction<{ companyID: string; data: Company }>,
    ) {
      const { companyID, data } = action.payload;
      state.companiesMap[companyID] = {
        ...state.companiesMap[companyID],
        data,
        isLoading: false,
        isInit: true,
      };
    },
    getCurrentUserCompanyFails(state, action: PayloadAction<{ companyID: string; error: Error }>) {
      const { companyID, error } = action.payload;
      state.companiesMap[companyID] = {
        ...state.companiesMap[companyID],
        error,
        isLoading: false,
        isInit: true,
      };
    },

    updateCurrentUserCompanyRequest(
      state,
      action: PayloadAction<{ companyID: string; data: Partial<Company> }>,
    ) {},
    updateCurrentUserCompanySuccess(
      state,
      action: PayloadAction<{ companyID: string; data: Company }>,
    ) {
      const { companyID, data } = action.payload;
      state.companiesMap[companyID] = {
        ...state.companiesMap[companyID],
        data,
        isLoading: false,
      };
    },
    updateCurrentUserCompanyFails(
      state,
      action: PayloadAction<{ companyID: string; error: Error; data: Company }>,
    ) {
      const { companyID, data } = action.payload;
      state.companiesMap[companyID] = {
        ...state.companiesMap[companyID],
        data,
        isLoading: false,
      };
    },
  },
});
const actions = slice.actions;

export const actionCurrentUserUpdate = combineActions(
  actions.updateCurrentUserRequest,
  actions.updateCurrentUserSuccess,
  actions.updateCurrentUserFail,
);

export const actionCurrentUserGetCompany = combineActions(
  actions.getCurrentUserCompanyRequest,
  actions.getCurrentUserCompanySuccess,
  actions.getCurrentUserCompanyFails,
);

export const actionCurrentUserUpdateCompany = combineActions(
  actions.updateCurrentUserCompanyRequest,
  actions.updateCurrentUserCompanySuccess,
  actions.updateCurrentUserCompanyFails,
);

export const actionGetCurrentUser = combineActions(
  actions.requestGetCurrentUser,
  actions.successGetCurrentUser,
  actions.failGetCurrentUser,
);

export const actionAccountLogout = combineActions(
  actions.logoutRequest,
  actions.logoutSuccess,
  actions.logoutFail,
);

export const actionAccountGenerateCode = combineActions(
  actions.generateCodeRequest,
  actions.generateCodeSuccess,
  actions.generateCodeFail,
);

export const actionAccountSelectCompany = combineActions(
  actions.selectCompanyRequest,
  actions.selectCompanySuccess,
  actions.selectCompanyFail,
);

export const actionAccountLoginWithCode = combineActions(
  actions.loginWithCodeRequest,
  actions.loginWithCodeSuccess,
  actions.loginWithCodeFail,
);
export const actionGetUserCompanies = combineActions(
  actions.requestGetCurrentUserCompanies,
  actions.successGetCurrentUserCompanies,
  actions.failGetCurrentUserCompanies,
);

export const { actionAccountRefreshCurrentUser } = actions;
export const reducerAccount = slice.reducer;
