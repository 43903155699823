import React, { useEffect, useState } from 'react';
import './_style.scss';
import { HeaderLogo } from './logo';
import { ProfileNav } from './profile-nav';
import { ProfileComplete } from './profile-complete';
import { IconMessages } from './icon-messages';
import { Divider, Hidden, Theme, useMediaQuery } from '@material-ui/core';
import { useSourcePageDashboard } from 'hooks';
import { HeaderIconEvents } from './icon-events';
import { HeaderDebugButton } from './debug-button';
import clsx from 'clsx';

interface Props {
  isTransparent: boolean;
}

export const AppHeader: React.FC<Props> = ({ isTransparent }) => {
  const isShowCompleteProfile = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [isScrolled, setIsScrolled] = useState(false);

  const { data: dashboardData } = useSourcePageDashboard();

  useEffect(() => {
    const onScroll = (e: any) => {
      setIsScrolled(!!window.scrollY);
    };
    window.document.addEventListener('scroll', onScroll);
    return () => {
      window.document.removeEventListener('scroll', onScroll);
    };
  }, []);

  const classNames = clsx(
    'app-header',
    isTransparent && 'app-header_dashboard',
    isScrolled && 'app-header_sticky',
    ' mui-fixed',
  );

  return (
    <header className={classNames}>
      <div className={'container'}>
        <div className={'app-header__inner'}>
          <HeaderLogo title={dashboardData?.title} />
          <HeaderDebugButton />
          <div className={'app-header__info'}>
            {isShowCompleteProfile && <ProfileComplete />}
            <Hidden smDown>
              <Divider orientation="vertical" className={'app-header__divider'} />
            </Hidden>
            <HeaderIconEvents />
            <IconMessages />
            <Hidden smDown>
              <Divider orientation="vertical" className={'app-header__divider'} />
            </Hidden>
            <ProfileNav />
          </div>
        </div>
      </div>
    </header>
  );
};
