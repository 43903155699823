import { useCallback, useMemo } from 'react';
import {
  IconAwardDescription,
  IconAwardFinish,
  IconAwardImage,
  IconAwardMaterials,
  IconAwardPeople,
  IconAwardStart,
  IconAwardVideo,
} from 'components/svg-icon';
import { APP_ROUTES, EVENT_BUILDER_STEP } from 'configs';
import { useTranslate } from 'hooks/use-translate';
import { useHistory } from 'react-router-dom';

export const useEventBuilderSteps = (customerNetworkingEventID: string) => {
  const { t } = useTranslate();
  const items = useMemo(() => {
    return [
      {
        title: t('builder-start'),
        IconComponent: IconAwardStart,
        value: EVENT_BUILDER_STEP.START,
      },
      {
        title: t('builder-videos'),
        IconComponent: IconAwardVideo,
        value: EVENT_BUILDER_STEP.VIDEOS,
      },
      {
        title: t('builder-images'),
        IconComponent: IconAwardImage,
        value: EVENT_BUILDER_STEP.IMAGES,
      },
      {
        title: t('builder-description'),
        IconComponent: IconAwardDescription,
        value: EVENT_BUILDER_STEP.DESCRIPTION,
      },
      {
        title: t('builder-materials'),
        IconComponent: IconAwardMaterials,
        value: EVENT_BUILDER_STEP.MATERIALS,
      },
      {
        title: t('builder-representatives'),
        IconComponent: IconAwardPeople,
        value: EVENT_BUILDER_STEP.REPRESENTATIVES,
      },
      {
        title: t('builder-finish'),
        IconComponent: IconAwardFinish,
        value: EVENT_BUILDER_STEP.FINISH,
      },
    ];
  }, [t]);
  const { push } = useHistory();
  const getCurrentIndex = useCallback(
    (currentStep: EVENT_BUILDER_STEP) => {
      return items.findIndex(({ value }) => value === currentStep);
    },
    [items],
  );
  const goToNext = useCallback(
    (currentStep: EVENT_BUILDER_STEP) => {
      const currentIndex = getCurrentIndex(currentStep);
      const nextItem = items[currentIndex + 1];

      if (nextItem) {
        push(
          APP_ROUTES.EVENT_BUILDER.getConfig({ customerNetworkingEventID })({
            step: nextItem.value,
          }),
        );
      }
    },
    [push, getCurrentIndex, items, customerNetworkingEventID],
  );
  const goToPrev = useCallback(
    (currentStep: EVENT_BUILDER_STEP) => {
      const currentIndex = getCurrentIndex(currentStep);
      const prevItem = items[currentIndex - 1];

      if (prevItem) {
        push(
          APP_ROUTES.EVENT_BUILDER.getConfig({ customerNetworkingEventID })({
            step: prevItem.value,
          }),
        );
      }
    },
    [push, getCurrentIndex, items, customerNetworkingEventID],
  );

  const goTo = useCallback(
    (currentStep: EVENT_BUILDER_STEP) => {
      const currentIndex = getCurrentIndex(currentStep);
      const currentItem = items[currentIndex];

      if (currentItem) {
        push(
          APP_ROUTES.EVENT_BUILDER.getConfig({ customerNetworkingEventID })({
            step: currentItem.value,
          }),
        );
      }
    },
    [push, getCurrentIndex, items, customerNetworkingEventID],
  );
  return { items, goToNext, goToPrev, goTo };
};
