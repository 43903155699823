import { calcRequestLabel, parseErrorData } from 'utils';
import { select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Action } from 'redux';
import { selectMapLabels } from 'store/labels';

interface ThunkAction<E extends { message: string }> extends Action<string> {
  error: E;
}
export function* workerErrorNotifyThunk<T extends ThunkAction<Error>>(data: T) {
  if (data.error) {
    yield* notifyErrorSaga(data.error);
  }
}

interface SagaAction<E extends { message: string }> extends Action<any> {
  payload: { error: E };
}
export function* workerErrorNotifySaga<T extends SagaAction<Error>>(action: T) {
  if (action.payload.error) {
    yield* notifyErrorSaga(action.payload.error);
  }
}

export function* notifyErrorSaga(e: { message: string }) {
  const error: Error = yield parseErrorData(e);
  const mapLabels: ReturnType<typeof selectMapLabels> = yield select(selectMapLabels);

  const title = calcRequestLabel(error.message, (key) => {
    return mapLabels[key]?.title || key;
  });

  yield toast.error(title);
}
export function* notifySuccessSaga(v: string = 'success') {
  const mapLabels: ReturnType<typeof selectMapLabels> = yield select(selectMapLabels);
  const title = calcRequestLabel(v, (key) => {
    return mapLabels[key]?.title || key;
  });

  yield toast.success(title);
}
