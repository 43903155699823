import React, { ChangeEvent, memo, useCallback, useState } from 'react';
import {
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import style from './index.module.scss';
import {
  useAppDispatch,
  useEffectNotifyError,
  useMountedRef,
  useSourceEventCategories,
  useTranslate,
} from 'hooks';
import { Loading } from 'components/loading';
import { ReactComponent as Logo } from 'images/shake-hands.svg';
import { actionEventJoin } from 'store/event/actions';
import { Theme } from '@material-ui/core/styles';

interface Props {
  networkingEventID: string;
  onClose: () => void;
  onSuccess: () => void;
}

export const DialogJoinToEvent = memo<Props>(({ networkingEventID, onSuccess, onClose }) => {
  const { isLoading, data, error } = useSourceEventCategories(networkingEventID);
  useEffectNotifyError(error);
  const { t } = useTranslate();

  const dispatch = useAppDispatch();

  const [value, setValue] = useState<string | null>(null);
  const mountedRef = useMountedRef();
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const onChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>, v: string) => {
      setValue(v);
      setIsLoadingRequest(true);
      const actionResult = await dispatch(
        actionEventJoin({ networkingEventID, networkingEventCategoryID: v }),
      );

      if (!mountedRef.current) return;
      setIsLoadingRequest(false);

      if (actionEventJoin.fulfilled.match(actionResult)) {
        onSuccess();
      }
    },
    [dispatch, networkingEventID, onSuccess, mountedRef],
  );

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog
      onClose={onClose}
      open={true}
      classes={{ paper: style.paper }}
      scroll={'body'}
      maxWidth={'lg'}
    >
      <IconButton autoFocus className={style.closeIcon} aria-label="close" onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <div className={style.container}>
        <Logo className={style.icon} />
        <Typography variant={'h1'} className={style.title}>
          {t('join-to-event-dialog-title')}
        </Typography>
        <FormControl className={style.fieldset} component={'fieldset'}>
          <FormLabel focused={false} component={'legend'} className={style.legend}>
            <Typography variant={'h4'}>{t('join-to-event-dialog-legend')}</Typography>
          </FormLabel>
          <RadioGroup className={style.group} value={value} onChange={onChange}>
            {data.map(({ title, id }) => {
              return (
                <FormControlLabel
                  key={id}
                  value={id}
                  control={<Radio className={style.radio} />}
                  label={
                    <Typography variant={isMobile ? 'body2' : 'subtitle1'}>{title}</Typography>
                  }
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
      <Loading active={isLoading || isLoadingRequest} />
    </Dialog>
  );
});
