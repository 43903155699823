import React, { memo } from 'react';
import ReactDOM from 'react-dom';
import { ChatContextProvider, CustomChantLayout } from 'components/custom-chat';
import { ProviderChatAuthUser } from 'components/custom-chat/provider/auth-user';
import { useAuth } from 'hooks';

const element = document.body;

const AppChat = () => {
  return ReactDOM.createPortal(
    <>
      <CustomChantLayout />
    </>,
    element,
  );
};

export const AppChatProvider = memo(({ children }) => {
  const { user } = useAuth();

  return (
    <ChatContextProvider>
      <ProviderChatAuthUser appUserID={user?.appUserID}>
        {user && user.appUserID && <AppChat />}
        {children}
      </ProviderChatAuthUser>
    </ChatContextProvider>
  );
});
