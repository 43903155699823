import { useContext } from 'react';
import { AuthContext } from 'contexts';

export const useAuth = () => {
  const res = useContext(AuthContext);

  if (!res) {
    throw new Error('You should use AuthContextProvider');
  }

  return res;
};
