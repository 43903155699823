import { useMemo } from 'react';
import { useMap, useOpen } from 'hooks';

export const useCollapseItems = <T extends { id: any }>(
  selected: string[],
  items: T[],
  visible: number = 5,
) => {
  const mapSelected = useMap(selected);
  const { isOpen, onOpen, onClose, onToggle } = useOpen();
  const [showedItems, hiddenItems] = useMemo(() => {
    return isOpen ? [items, []] : [items.slice(0, visible), items.slice(visible)];
  }, [isOpen, items, visible]);
  const countSelectedHidden = useMemo(() => {
    return hiddenItems.reduce((acc, item) => {
      if (mapSelected[item['id']]) {
        acc += 1;
      }
      return acc;
    }, 0);
  }, [hiddenItems, mapSelected]);

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
    countSelectedHidden,
    showedItems,
    hiddenItems,
  };
};
