import React, { useMemo } from 'react';
import { NetworkingVisitor } from 'services/networking-events';
import { AppAvatar } from 'components/app-avatar';
import { Typography } from '@material-ui/core';
import { useChatOnlineUsers } from 'components/custom-chat';
import { useGoToMember, useOpen, useStartChat, useTranslate } from 'hooks';
import style from './index.module.scss';
import { ChatTooltipDisabled } from 'components/chat-tooltip-disabled';
import { ButtonMeet } from '../button-meet';

interface Props {
  item: NetworkingVisitor;
  disableRequest: boolean;
}

export const VisitorItem: React.FC<Props> = ({ item, disableRequest }) => {
  const { t } = useTranslate();
  const userName = useMemo(() => {
    return [item.title, item.firstName, item.lastName].filter(Boolean).join(' ');
  }, [item.title, item.firstName, item.lastName]);

  const { goToMember } = useGoToMember({ appUserID: item.appUserID });
  const { checkIsOnline } = useChatOnlineUsers();
  const isOnline = useMemo(() => {
    return checkIsOnline(item.appUserID);
  }, [checkIsOnline, item.appUserID]);

  const { isVisible, onStart } = useStartChat(item);

  const workPlace = useMemo(() => {
    return [item.position, item.companyName].filter(Boolean).join(t(' at '));
  }, [item.position, item.companyName, t]);
  const { isOpen: isHover, onOpen: onMouseEnter, onClose: onMouseLeave } = useOpen();

  return (
    <div className={style.wrap} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <AppAvatar
        className={style.avatar}
        src={item.profilePicture}
        size={'md'}
        onClick={goToMember}
        isOnline={isOnline}
      />
      <div className={style.data}>
        <Typography
          component={'div'}
          variant={'h6'}
          color={'primary'}
          onClick={goToMember}
          style={{ cursor: 'pointer' }}
        >
          {userName}
        </Typography>
        <Typography component={'div'} variant={'body1'}>
          {workPlace}
        </Typography>
      </div>
      <div className={style.info}>
        <Typography component={'div'} variant={'body1'}>
          {item.networkingEventCategoryTitle}
        </Typography>
      </div>
      <div className={style.country}>
        <Typography
          component={'span'}
          variant={'subtitle2'}
          style={{ color: '#6D7278' }}
          className={style.countryName}
        >
          {item.countryTitle}
        </Typography>
        <img src={item.countryFlag} alt={''} />
      </div>
      <div className={style.control}>
        {isVisible && (
          <ChatTooltipDisabled disabled={!disableRequest}>
            <div>
              <ButtonMeet
                isActive={isHover}
                onClick={onStart}
                fullWidth
                disabled={!isVisible || disableRequest}
              />
            </div>
          </ChatTooltipDisabled>
        )}
      </div>
    </div>
  );
};
