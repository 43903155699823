import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineActions } from '../_utils';
import { Label, Language } from 'services/labels';

export interface StateLabels {
  loading: boolean;
  labels: Label[];
  languages: Language[];
}

const initState = (): StateLabels => {
  return {
    loading: false,
    labels: [],
    languages: [],
  };
};
const slice = createSlice({
  name: 'LABELS',
  initialState: initState(),
  reducers: {
    requestGetLabels(state, action: PayloadAction<{ languageID: number }>) {
      state.loading = true;
    },
    successGetLabels(state, action: PayloadAction<{ labels: Label[]; languages: Language[] }>) {
      state.loading = false;
      state.labels = action.payload.labels;
      state.languages = action.payload.languages;
    },
    failGetLabels(state, action: PayloadAction<Error>) {
      state.loading = false;
    },
  },
});

const { requestGetLabels, successGetLabels, failGetLabels } = slice.actions;

export const getLabelsActions = combineActions(requestGetLabels, successGetLabels, failGetLabels);

export const reducerLabels = slice.reducer;
