import React from 'react';
import { IconButton, ListItem } from '@material-ui/core';

import { AppAvatar } from 'components/app-avatar';
import { ButtonMenuDots } from 'components/button-menu-dots';
import { useChatOpen } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'configs/routes';
import CloseIcon from '@material-ui/icons/Close';
import { useOpen, useTranslate } from 'hooks';
import style from './index.module.scss';

const { MEMBER } = APP_ROUTES;

interface Props {
  appUserID: string;
  firstName: string;
  lastName: string;
  position?: string | null;
  picture?: string | null;
  isOnline: boolean;
  onDelete?: () => void;
  onClose?: () => void;
}

export const CustomChatHeader: React.FC<Props> = ({
  appUserID,
  firstName,
  lastName,
  position,
  picture,
  isOnline,
  onClose,
  onDelete,
}) => {
  const { t } = useTranslate();

  const { isOpen: isOpenDots, onOpen: onOpenDots, onClose: onCloseDots } = useOpen();

  const itemDots = [
    {
      title: t('delete-conversation'),
      onClick: onDelete,
    },
  ];

  const { push } = useHistory();
  const { onClose: onCloseChat } = useChatOpen();
  const goToUser = () => {
    onCloseChat();
    push(MEMBER.getConfig()({ member: appUserID }));
  };

  return (
    <div className={style.header}>
      <div>
        <ListItem button className={style.user} onClick={goToUser}>
          <AppAvatar firstName={firstName} lastName={lastName} src={picture} isOnline={isOnline} />
          <div className={style.cont}>
            <div className={style.name}>
              {firstName} {lastName}
            </div>
            {position}
          </div>
        </ListItem>
      </div>
      <div>
        {onDelete && (
          <ButtonMenuDots
            items={itemDots}
            isOpen={isOpenDots}
            onClose={onCloseDots}
            onOpen={onOpenDots}
          />
        )}
        {onClose && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};
