import { createApi } from '@reduxjs/toolkit/query/react';
import {
  API_NETWORKING_EVENT,
  EventSponsor,
  MyExhibitorEvent,
  MyVisitorEvent,
  NetworkingEvent,
  NetworkingEventCategory,
  NetworkingEventNormalized,
  NetworkingExhibitor,
  NetworkingVideo,
  NetworkingVisitor,
} from './models';
import { api, axiosBaseGetQuery, transformResponseSources } from 'services/utils';
import { BaseParams, createFilterEquals, DynamicResult, parseErrorData } from 'utils';
import { UserStatusType } from 'configs';
import { adapterEvenPageData } from 'utils/adapters';

class Service {
  async getNetworkingEvent(id: string, params?: BaseParams) {
    const params_ = {
      ...params,
      take: 1,
      filter: [params?.filter, createFilterEquals<NetworkingEvent>({ name: 'id', value: id })]
        .filter((v) => !!v)
        .join('&&'),
    };
    const result = await ServiceNetworkingEvents.getNetworkingEvents(params_);

    const data = result.data.value[0];

    if (!data) {
      throw new Error('record-not-found');
    }

    return { ...result, data };
  }

  async getNetworkingEventExhibitor(networkingEventID: string, id: string, params?: BaseParams) {
    const params_ = {
      ...params,
      take: 1,
      filter: [params?.filter, `id=="${id}"`].filter((v) => !!v).join('&&'),
    };
    const result = await ServiceNetworkingEvents.getNetworkingEventExhibitors(
      networkingEventID,
      params_,
    );

    const data = result.data.value[0];

    if (!data) {
      throw new Error('record-not-found');
    }

    return {
      ...result,
      data,
    };
  }

  async getNetworkingEvents(params?: BaseParams) {
    return api.get<{ value: NetworkingEvent[]; count: number }>(API_NETWORKING_EVENT.GET_DYNAMIC, {
      params,
    });
  }

  async getNetworkingEventVisitors(networkingEventID: string, params?: BaseParams) {
    return api.get<{ value: NetworkingVisitor[]; count: number }>(
      API_NETWORKING_EVENT.GET_VISITORS_DYNAMIC,
      {
        params: {
          ...params,
          networkingEventID,
        },
      },
    );
  }

  async getNetworkingEventExhibitors(networkingEventID: string, params?: BaseParams) {
    let result = await api.get<{ value: NetworkingExhibitor[]; count: number }>(
      API_NETWORKING_EVENT.GET_EXHIBITORS_DYNAMIC,
      {
        params: {
          ...params,
          networkingEventID,
        },
      },
    );
    result.data.value = result.data.value.map((item) => {
      return {
        ...item,
        representatives: item.representatives.filter(
          ({ userStatusID }) => userStatusID === UserStatusType.ACTIVE,
        ),
      };
    });
    return result;
  }

  async getNetworkingEventVideos(networkingEventID: string, params?: BaseParams) {
    return api.get<DynamicResult<NetworkingVideo, typeof params>>(
      API_NETWORKING_EVENT.GET_COMPANY_VIDEOS_DYNAMIC,
      {
        params: {
          ...params,
          networkingEventID,
        },
      },
    );
  }

  async addEventToCalendar(networkingEventID: string) {
    return api.get<string>(API_NETWORKING_EVENT.ADD_EVENT_TO_CALENDAR(networkingEventID));
  }

  async joinToEvent(networkingEventID: string, networkingEventCategoryID: string) {
    return api.post(
      API_NETWORKING_EVENT.JOIN_TO_EVENT({ networkingEventID, networkingEventCategoryID }),
    );
  }

  async unJoinToEvent(networkingEventID: string) {
    return api.post(API_NETWORKING_EVENT.UN_JOIN_TO_EVENT(networkingEventID));
  }

  async getMyExhibitorEvents(params?: BaseParams) {
    return api.get<{ value: MyExhibitorEvent[]; count: number }>(
      API_NETWORKING_EVENT.GET_MY_EXHIBITOR_EVENTS,
      { params },
    );
  }

  async getMyExhibitorEvent(networkingEventRepresentativeID: string) {
    const params = {
      take: 1,
      filter: `networkingEventRepresentativeID=="${networkingEventRepresentativeID}"`,
    };
    const result = await ServiceNetworkingEvents.getMyExhibitorEvents(params);

    if (result.data.value.length === 0) {
      throw new Error('record-not-found');
    }

    return { ...result, data: result.data.value[0] };
  }

  async getMyVisitorEvents(params?: BaseParams) {
    return api.get<{ value: MyVisitorEvent[]; count: number }>(
      API_NETWORKING_EVENT.GET_MY_VISITOR_EVENTS,
      { params },
    );
  }

  async acceptEventInvitation(networkingEventRepresentativeID: string) {
    return api.post(API_NETWORKING_EVENT.ACCEPT_EVENT_INVITATION(networkingEventRepresentativeID));
  }

  async declineEventInvitation(networkingEventRepresentativeID: string) {
    return api.post(API_NETWORKING_EVENT.DECLINE_EVENT_INVITATION(networkingEventRepresentativeID));
  }
}

export const networkingEventApi = createApi({
  reducerPath: 'networkingEventApi',
  baseQuery: axiosBaseGetQuery(),
  endpoints: (builder) => ({
    getSponsors: builder.query<EventSponsor[], string>({
      query: (networkingEventID) => ({
        url: API_NETWORKING_EVENT.GET_SPONSORS_DYNAMIC,
        params: {
          networkingEventID,
        },
      }),
      transformResponse: transformResponseSources,
    }),
    getCategories: builder.query<NetworkingEventCategory[], string>({
      query: (networkingEventID) => ({
        url: API_NETWORKING_EVENT.GET_CATEGORIES_DYNAMIC,
        params: {
          networkingEventID,
          filter: createFilterEquals<NetworkingEventCategory>({ name: 'isActive', value: true }),
          orderBy: 'title',
        },
      }),
      transformResponse: transformResponseSources,
    }),
    getNetworkingEvent: builder.query<NetworkingEventNormalized, string>({
      queryFn: async (id) => {
        const result = await ServiceNetworkingEvents.getNetworkingEvent(id);
        return { ...result, data: adapterEvenPageData(result.data) };
      },
    }),
    getCountExhibitorsRelativeToMe: builder.query<number, string>({
      query: (networkingEventID) => ({
        url: API_NETWORKING_EVENT.GET_EXHIBITORS_DYNAMIC,
        params: {
          networkingEventID,
          filter: createFilterEquals<NetworkingExhibitor>({ name: 'isOwner', value: true }),
          take: 0,
          count: true,
        },
      }),
      transformResponse: (result: DynamicResult<any, { count: true }>) => {
        return result.count;
      },
    }),
    getEventVideos: builder.query<NetworkingVideo[], { networkingEventID: string }>({
      async queryFn({ networkingEventID }) {
        try {
          const {
            data: { value },
          } = await ServiceNetworkingEvents.getNetworkingEventVideos(networkingEventID, {
            count: true,
          });
          return { data: value };
        } catch (e: any) {
          throw parseErrorData(e);
        }
      },
    }),
  }),
});

export const ServiceNetworkingEvents = new Service();
export * from './models';
