import React, { useCallback } from 'react';
import { AxiosInterceptorsToken } from './token';
import { AxiosInterceptorsRefreshToken } from './refresh-token';
import { ServiceAccounts } from 'services/accounts';
import { getAuthTokens, removeOldAuthTokens, updateAuthTokens } from 'utils';
import { actionAccountLogout } from 'store/auth';
import { useAppDispatch } from 'hooks';

export const AxiosInterceptors: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const onRefresh = useCallback(async () => {
    const { token, refreshToken, expires } = getAuthTokens();

    if (!token || !refreshToken || !expires) {
      throw new Error('tokens-are-not-exist');
    }

    const {
      data: { token: Token, refreshToken: RefreshToken },
    } = await ServiceAccounts.refreshToken({ token, refreshToken });

    return { token: Token, refreshToken: RefreshToken, expires };
  }, []);

  const onError = useCallback(
    (e) => {
      removeOldAuthTokens();
      dispatch(actionAccountLogout.success());
    },
    [dispatch],
  );

  const onSuccess = useCallback(
    ({
      token,
      refreshToken,
      expires,
    }: {
      token: string;
      refreshToken: string;
      expires: string;
    }) => {
      updateAuthTokens({ token, refreshToken, expires });
    },
    [],
  );

  return (
    <AxiosInterceptorsToken>
      <AxiosInterceptorsRefreshToken onRefresh={onRefresh} onSuccess={onSuccess} onError={onError}>
        {children}
      </AxiosInterceptorsRefreshToken>
    </AxiosInterceptorsToken>
  );
};
