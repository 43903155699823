import React, { forwardRef, useEffect, useState } from 'react';
import { AspectRatio } from '../aspect-ratio';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import style from './index.module.scss';
import { useTranslate } from 'hooks';
import { Loading } from 'components/loading';

export interface VideoPreviewProps extends ReactPlayerProps {
  value?: string;
  className?: string;
  LabelEmpty?: React.ReactNode | string;
  withLoading: boolean;
}

export const VideoPreview = forwardRef<ReactPlayer, VideoPreviewProps>(
  ({ value, withLoading = true, LabelEmpty, className, ...rest }, ref) => {
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
      withLoading && setLoading(true);
    }, [value, withLoading]);

    const { t } = useTranslate();

    return (
      <AspectRatio className={className}>
        <div className={style.inner}>
          {value ? (
            <ReactPlayer
              ref={ref}
              url={value}
              width={'100%'}
              height={'100%'}
              controls={true}
              {...rest}
              onReady={() => {
                setLoading(false);
              }}
            />
          ) : (
            <div className={style.noData}>{LabelEmpty || t('video-is-not-selected')}</div>
          )}
        </div>
        <Loading
          active={Boolean(value && isLoading)}
          background={`rgba(255, 255, 255, .5)`}
          size={30}
        />
      </AspectRatio>
    );
  },
);
