import { StoreState } from '../index';
import { createSelector } from 'reselect';
import { calcPaginationState } from 'utils';
import { adapterEvenPageData } from 'utils/adapters';

export const selectStateEvents = (state: StoreState) => state.events;

export const selectIsDebugMode = createSelector(selectStateEvents, ({ isDebugMode }) => {
  return isDebugMode;
});

export const selectEvents = createSelector(selectStateEvents, ({ events }) => {
  return events;
});
export const selectEventsList = createSelector(selectEvents, ({ data }) => {
  return data.map((item) => adapterEvenPageData(item));
});
export const selectEventsListVisitors = createSelector(selectEventsList, (list) => {
  return list.filter(({ isVisitor }) => isVisitor);
});
export const selectEventsListExhibitors = createSelector(selectEventsList, (list) => {
  return list.filter(({ isExhibitor }) => isExhibitor);
});
export const selectEventsListOther = createSelector(selectEventsList, (list) => {
  return list.filter(({ isExhibitor, isVisitor }) => isExhibitor === false && isVisitor === false);
});

export const selectEventsFilter = createSelector(selectEvents, ({ filter }) => {
  return filter;
});

export const selectEventsHasValue = createSelector(selectEvents, ({ filter }): boolean => {
  return Object.entries(filter).some((item) => {
    switch (item[0]) {
      case 'search':
        return Boolean(item[1]);
      case 'isUpcoming':
        return !item[1];
      default:
        return false;
    }
  });
});
export const selectEventsPagination = createSelector(selectEvents, ({ pagination }) => {
  return calcPaginationState(pagination);
});
