import React, { useEffect } from 'react';
import FullStory, { identify, setUserVars } from 'react-fullstory';
import { useAuth, useGetRequest } from 'hooks';
import { ServiceParameters } from 'services/parameters';

interface FullStoryViewProps {
  code: string;
}

export const FullStoryView: React.FC<FullStoryViewProps> = ({ code }) => {
  const data = useAuth();
  const { user } = data;

  const id = user?.appUserID;

  useEffect(() => {
    if (id) {
      identify(id);
    } else {
      identify(false);
    }
  }, [id]);

  const firstName = user?.firstName;
  const lastName = user?.lastName;

  useEffect(() => {
    if (firstName && lastName) {
      setUserVars({
        displayName: [firstName, lastName].filter((v) => !!v).join(' '),
        firstName,
        lastName,
      });
    }
  }, [firstName, lastName]);

  return <FullStory org={code} />;
};

export const FullStoryProvider: React.FC = ({ children }) => {
  const { data, request } = useGetRequest({ request: ServiceParameters.getFullStoryCode });

  useEffect(() => {
    request();
  }, [request]);

  return (
    <>
      {data && <FullStoryView code={data} />}
      {children}
    </>
  );
};
