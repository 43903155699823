import React, { useEffect } from 'react';
import style from './index.module.scss';
import { CustomChatHeader } from '../message-header';
import { CustomMessagesContent } from '../messages-content';
import { useChatConversation, useChatOnlineUsers, useChatRead } from '../../hooks';
import { useAppDispatch } from 'hooks';
import { actionChatGetMessages } from 'components/custom-chat/store';

interface Props {
  isShowClose?: boolean;
}

export const CustomChantMessages: React.FC<Props> = ({ isShowClose }) => {
  const dispatch = useAppDispatch();
  const { messages, conversation, conversationID, isNew, isLoading, isInit, onDelete, onClose } =
    useChatConversation();

  useEffect(() => {
    if (!isNew && !isInit && !isLoading && conversationID) {
      dispatch(actionChatGetMessages({ conversationID }));
    }
  }, [isNew, isInit, isLoading, conversationID, dispatch]);

  const { checkIsOnline } = useChatOnlineUsers();
  useChatRead();

  return (
    <div className={style.wrap}>
      {conversation && (
        <CustomChatHeader
          isOnline={checkIsOnline(conversation.appUserID)}
          appUserID={conversation.appUserID}
          firstName={conversation.appUserFirstName}
          lastName={conversation.appUserLastName}
          picture={conversation.appUserProfilePicture}
          position={conversation.appUserPosition}
          onClose={isShowClose ? onClose : undefined}
          onDelete={!isNew ? onDelete : undefined}
        />
      )}
      <CustomMessagesContent
        conversationID={conversationID}
        isNew={isNew}
        messages={messages}
        appUserID={conversation?.appUserID}
        isLoading={isLoading}
      />
    </div>
  );
};
