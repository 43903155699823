export const getRandomNumber = (min: number = 0, max: number = 1): number => {
  return min + Math.floor((max - min + 1) * Math.random());
};

export const getRandomString = (length = 10, chars = 'aA') => {
  let mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
  let result = '';
  for (let i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
  return result;
};

export type Await<T> = T extends PromiseLike<infer U> ? U : T;

export const throttle = <T extends Function>(cb: T, limit: number) => {
  let waiting = false;
  return (...args: any[]) => {
    if (!waiting) {
      cb(...args);
      waiting = true;
      setTimeout(function () {
        waiting = false;
      }, limit);
    }
  };
};
export const debounce = <T extends Function>(cb: T, delay: number) => {
  let timerID: NodeJS.Timeout | null = null;
  return (...args: any[]) => {
    if (timerID) {
      clearTimeout(timerID);
    }
    timerID = setTimeout(() => {
      cb(...args);
      timerID = null;
    }, delay);
  };
};

export const replaceNull = <T extends Record<string, any>>(data: T) => {
  return Object.keys(data).reduce((acc, key: keyof T) => {
    //@ts-ignore
    acc[key] = data[key] === null ? '' : data[key];
    return acc;
  }, {} as T);
};

export const getCroppedImg = (file: any, width: number, height: number, resultType: string) => {
  return new Promise((resolve, reject) => {
    const img_ = new Image();
    img_.src = URL.createObjectURL(file);
    img_.onload = function () {
      const imageWidth = img_.width;
      const imageHeight = img_.height;
      let ratio = 1;
      if (imageWidth > width) ratio = width / imageWidth;
      if (imageHeight > height) ratio = height / imageHeight;

      if (ratio === 1) {
        const reader_ = new FileReader();
        reader_.readAsDataURL(file);
        reader_.onload = () => resolve(reader_.result);
        reader_.onerror = reject;
      } else {
        const canvas = document.createElement('canvas');
        const width_ = imageWidth * ratio;
        const height_ = imageHeight * ratio;
        canvas.width = width_;
        canvas.height = height_;
        // @ts-ignore
        canvas
          .getContext('2d')
          .drawImage(img_, 0, 0, imageWidth, imageHeight, 0, 0, width_, height_);
        resolve(canvas.toDataURL(`image/${resultType}`));
      }
    };
    img_.onerror = reject;
    return;
  });
};
