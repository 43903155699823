import { call, debounce, put, select, takeEvery, takeLatest } from 'modules/typed-saga';
import { getEventActions, getEventExhibitorsActions, getEventVisitorsActions } from './slice';
import {
  NetworkingExhibitor,
  NetworkingVisitor,
  ServiceNetworkingEvents,
} from 'services/networking-events';
import {
  createFilterArrays,
  createFilterContains,
  createFilterEquals,
  createFilterSmartSearch,
} from 'utils';
import {
  selectEvent,
  selectEventExhibitorsFilters,
  selectEventExhibitorsPagination,
  selectEventVisitorsFilters,
  selectEventVisitorsPagination,
} from './selectors';

import { notifyErrorSaga, workerErrorNotifyThunk } from '../_utils';
import { actionEventAddToCalendar } from 'store/event/actions';

function* getEvent(action: ReturnType<typeof getEventActions.request>) {
  const { eventID } = action.payload;

  try {
    const { data: serverEvent } = yield* call(ServiceNetworkingEvents.getNetworkingEvent, eventID);
    yield* put(getEventActions.success(serverEvent));
  } catch (e: any) {
    yield* put(getEventActions.fail(e));
    yield* notifyErrorSaga(e);
  }
}

function* getEventVisitors() {
  try {
    const event = yield* select(selectEvent);

    if (!event) {
      throw new Error('Unexpected behaviour');
    }
    const { search, networkingEventCategoryIDs, countryID } = yield* select(
      selectEventVisitorsFilters,
    );
    const { take, skip } = yield* select(selectEventVisitorsPagination);

    const filter = [
      [
        createFilterSmartSearch<NetworkingVisitor>({
          name: ['firstName', 'lastName'],
          value: search,
        }),
        createFilterContains<NetworkingVisitor>({ name: 'position', value: search }),
      ]
        .filter(Boolean)
        .join('||'),
      createFilterArrays<NetworkingVisitor>({
        name: 'networkingEventCategoryID',
        value: networkingEventCategoryIDs,
      }),
      createFilterEquals<NetworkingVisitor>({ name: 'countryID', value: countryID }),
    ]
      .filter((v) => !!v)
      .join('&&');

    const {
      data: { value, count },
    } = yield* call(ServiceNetworkingEvents.getNetworkingEventVisitors, event.id, {
      filter,
      take,
      skip,
      count: true,
    });
    yield* put(getEventVisitorsActions.success({ data: value, count }));
  } catch (e: any) {
    yield* put(getEventVisitorsActions.fail(e));
    yield* notifyErrorSaga(e);
  }
}

function* getEventExhibitors() {
  try {
    const event = yield* select(selectEvent);

    if (!event) {
      throw new Error('Unexpected behaviour');
    }
    const {
      filters: { search, networkingEventCategoryIDs, countryID },
    } = yield* select(selectEventExhibitorsFilters);
    const { take, skip }: ReturnType<typeof selectEventExhibitorsPagination> = yield select(
      selectEventExhibitorsPagination,
    );

    const filter = [
      createFilterContains<NetworkingExhibitor>({
        name: ['companyName', 'packageTitle'],
        value: search,
      }),
      createFilterArrays<NetworkingExhibitor>({
        name: 'networkingEventCategoryID',
        value: networkingEventCategoryIDs,
      }),
      createFilterEquals<NetworkingExhibitor>({ name: 'countryID', value: countryID }),
      `((confirmContent==true) || (isOwner==true && confirmContent==false))`,
    ]
      .filter(Boolean)
      .join('&&');

    const {
      data: { value, count },
    } = yield* call(ServiceNetworkingEvents.getNetworkingEventExhibitors, event.id, {
      filter,
      take,
      skip,
      count: true,
      orderBy: 'isOwner desc',
    });
    yield* put(getEventExhibitorsActions.success({ data: value, count }));
  } catch (e: any) {
    yield* put(getEventExhibitorsActions.fail(e));
    yield* notifyErrorSaga(e);
  }
}

export const sagasEvent = [
  takeLatest(getEventActions.request.type, getEvent),
  debounce(500, getEventVisitorsActions.request.type, getEventVisitors),
  debounce(500, getEventExhibitorsActions.request.type, getEventExhibitors),
  takeEvery(actionEventAddToCalendar.rejected, workerErrorNotifyThunk),
];
