import React, { useEffect, useMemo, useRef } from 'react';
import style from './index.module.scss';
import { Message } from '../message';
import { MessageDate } from '../date';
import { splitBy } from '../../utils';
import { ConversationMessage } from '../../service';
import { useChatOnlineUsers } from '../../hooks';
import { MessageInput } from '../message-input';
import { CustomChatScroll, CustomChatScrollRef } from '../scroll';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { useCurrentUser } from 'hooks';
import clsx from 'clsx';
import { Loading } from 'components/loading';

interface Props {
  conversationID: string | null;
  isNew: boolean;
  isLoading: boolean;
  messages: ConversationMessage[];
  appUserID: string | undefined;
  className?: string;
}

export const CustomMessagesContent: React.FC<Props> = ({
  messages,
  className,
  conversationID,
  isNew,
  appUserID,
  isLoading,
}) => {
  const splitMessages = useMemo(() => {
    return splitBy(messages, (prev, next) => {
      return new Date(next.messageDate).getDay() !== new Date(prev.messageDate).getDay();
    });
  }, [messages]);
  const { appUserID: appIdentityUserID } = useCurrentUser();

  const ref = useRef<CustomChatScrollRef>(null);

  useEffect(() => {
    ref.current?.scrollToBottom();
  }, [messages.length]);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { checkIsOnline } = useChatOnlineUsers();

  return (
    <div className={clsx(style.wrap, className)}>
      {conversationID && appUserID && (
        <>
          <CustomChatScroll ref={ref}>
            <div className={clsx(style.content, isMobile && style.hidden)}>
              {splitMessages.map((items, i) => (
                <React.Fragment key={i}>
                  <MessageDate date={items[0].messageDate} />
                  {items.map((item, i) => {
                    return (
                      <Message
                        date={item.messageDate}
                        key={i}
                        personal={item.appUserID === appIdentityUserID}
                        message={item.message}
                        AvatarProps={{
                          firstName: item.appUserFirstName,
                          lastName: item.appUserLastName,
                          src: item.appUserProfilePicture,
                          isOnline: checkIsOnline(item.appUserID),
                        }}
                      />
                    );
                  })}
                </React.Fragment>
              ))}
            </div>
          </CustomChatScroll>
          <div className={style.footer}>
            <MessageInput isNew={isNew} conversationID={conversationID} userID={appUserID} />
          </div>
        </>
      )}
      <Loading active={isLoading} />
    </div>
  );
};
