import React from 'react';
import { SerializedError } from '@reduxjs/toolkit';

export interface LayoutErrorProps {
  error: Error | SerializedError | null;
}
export const LayoutError: React.FC<LayoutErrorProps> = ({ error }) => {
  return (
    <div className={'layout-page-error'}>
      <div className={'container'}>
        <div className={'layout-page-error__content'}>
          <h2>{error?.message}</h2>
        </div>
      </div>
    </div>
  );
};
