import React, { useMemo } from 'react';
import style from './index.module.scss';
import clsx from 'clsx';

interface Props {
  className?: string;
  bgUrl?: string;
}

export const TransparentHeader: React.FC<Props> = ({ bgUrl, className, children }) => {
  const headerStyles = useMemo<React.CSSProperties>(() => {
    return {
      backgroundImage: bgUrl ? `url(${bgUrl})` : undefined,
    };
  }, [bgUrl]);
  return (
    <div className={clsx(style.wrap, className)}>
      <div className={style.bg} style={headerStyles} />
      {children}
    </div>
  );
};
