import { call, put, select, takeLatest } from 'modules/typed-saga';
import { DEBUG_STORE_KEY, getEventsActions, setEventsDebugMode } from './slice';
import { selectEventsFilter, selectEventsPagination } from './selectors';
import { createFilterSmartSearch } from 'utils';
import { NetworkingEvent, ServiceNetworkingEvents } from 'services/networking-events';
import { notifyErrorSaga } from '../_utils';

const calcUpcomingFilter = (v: boolean) => {
  return v ? `(toDate.Date>=DateTime.Now.Date)` : null;
};

function* getEvents() {
  const { take, skip } = yield* select(selectEventsPagination);
  const { search, isUpcoming } = yield* select(selectEventsFilter);

  const filter = [
    createFilterSmartSearch<NetworkingEvent>({ name: ['titlePrefix', 'title'], value: search }),
    calcUpcomingFilter(isUpcoming),
  ]
    .filter((v) => !!v)
    .join('&&');

  try {
    const {
      data: { value, count },
    } = yield* call(ServiceNetworkingEvents.getNetworkingEvents, {
      take,
      skip,
      filter,
      orderBy: 'fromDate asc',
      count: true,
    });

    yield* put(getEventsActions.success({ data: value, count: count }));
  } catch (e: any) {
    yield* put(getEventsActions.fail(e as Error));
    yield* notifyErrorSaga(e);
  }
}

function watchDebugMode(action: ReturnType<typeof setEventsDebugMode>) {
  const { payload } = action;

  if (payload) {
    localStorage.setItem(DEBUG_STORE_KEY, 'TRUE');
  } else {
    localStorage.removeItem(DEBUG_STORE_KEY);
  }
}

export const sagasEvents = [
  takeLatest(getEventsActions.request.type, getEvents),
  takeLatest(setEventsDebugMode.type, watchDebugMode),
];
