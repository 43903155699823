import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { selectChatOnlineUsers } from '../store';

export const useChatOnlineUsers = () => {
  const onlineUsersMap = useSelector(selectChatOnlineUsers);

  const checkIsOnline = useCallback(
    (appUserID: string) => {
      return Boolean(onlineUsersMap[appUserID]);
    },
    [onlineUsersMap],
  );

  return { checkIsOnline };
};
