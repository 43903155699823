import messageAudio from 'sounds/coin.mp3';

type Tracks<T, K extends keyof T> = Record<K, HTMLAudioElement>;

class AudioController<T extends Record<string, any>> {
  private tracks: Tracks<T, keyof T>;

  constructor(tracks: T) {
    this.tracks = Object.entries(tracks).reduce((acc, [key, url]) => {
      const name = key as keyof T;
      acc[name] = new Audio(url);
      return acc;
    }, {} as Tracks<T, keyof T>);
  }

  play<K extends keyof T>(trackName: K) {
    try {
      this.tracks[trackName].play();
    } catch (e) {
      console.error('You should interact with window');
    }
  }
}

export const AppAudio = new AudioController({
  message: messageAudio,
});
