import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ContactMessage, ServiceContacts } from 'services/contacts';
import { useRequestNotify, useSourceSubject, useTranslate } from 'hooks';
import { DialogSubmit } from './components';
import { CommonSelect } from 'common/components/select';
import { DialogDivider } from './components/dialog-divider';
import { AppEditor } from '../app-editor';
import { FormController } from 'components/form-controller';
import { htmlEditorConfig } from 'configs';

export interface DialogContactUsProps {
  isOpen: boolean;
  defaultSubjectID?: number;
  commentPayload?: string;

  onClose(): void;
}

export const DialogContactUs: React.FC<DialogContactUsProps> = ({
  onClose,
  isOpen,
  defaultSubjectID,
  commentPayload,
}) => {
  const { t } = useTranslate();

  const { control, errors, handleSubmit } = useForm({
    defaultValues: new ContactMessage(defaultSubjectID),
  });
  const { data: sourceSubjects } = useSourceSubject();

  const request = useCallback(
    (data: ContactMessage) => {
      return ServiceContacts.contactUs({ ...data }, commentPayload);
    },
    [commentPayload],
  );

  const [isLoading, setLoading] = useState(false);
  const { requestWrap } = useRequestNotify({
    request: request,
    throwError: true,
    withSuccess: true,
    setLoading,
  });
  const onSubmit = useCallback(
    async (data: ContactMessage) => {
      await requestWrap(data);
      onClose();
    },
    [requestWrap, onClose],
  );

  return (
    <DialogSubmit
      className={'dialog-contact-us'}
      title={t('contact-us')}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
    >
      <FormController
        label={t('subject')}
        placeholder={t('select-subject')}
        name={'subjectID'}
        as={CommonSelect}
        control={control}
        errors={errors}
        source={sourceSubjects}
        disableClearable={true}
        rules={{
          required: true,
        }}
      />
      <DialogDivider />
      <FormController
        label={t('comment')}
        config={{
          ...htmlEditorConfig,
          placeholder: t('type-your-comment'),
          height: 280,
        }}
        name={'comment'}
        as={AppEditor}
        errors={errors}
        control={control}
        rules={{
          required: true,
        }}
      />
    </DialogSubmit>
  );
};
