import { useCallback, useEffect, useMemo, useState } from 'react';
import { ServiceUploads } from 'services/media-uploads';
import { useMountedRef } from 'hooks/use-mounted-ref';
import { isValidURL } from 'utils';

export const useLoadHtml = (link?: string) => {
  const [data, setData] = useState('');
  const mountedRef = useMountedRef();
  const load = useCallback(
    async (url: string) => {
      const { data } = await ServiceUploads.getHtml(url);
      if (!mountedRef.current) return;

      setData(data);
    },
    [mountedRef],
  );

  useEffect(() => {
    if (link && isValidURL(link)) {
      load(link);
    }
  }, [link, load]);

  return useMemo(() => {
    return { data };
  }, [data]);
};
