import React from 'react';
import { AppHeader } from 'components/app-header';
import { AppFooter } from 'components/app-footer';
import { useIsRoutes } from 'hooks';
import { APP_ROUTES } from 'configs';
import style from './index.module.scss';

export const LayoutContained: React.FC = ({ children }) => {
  const isTransparent = useIsRoutes(
    APP_ROUTES.HOME.path,
    APP_ROUTES.EVENT.path,
    APP_ROUTES.EXHIBITOR.path,
  );
  return (
    <>
      <AppHeader isTransparent={isTransparent} />
      <main className={style.layout}>{children}</main>
      <AppFooter alternativeStyle={true} />
    </>
  );
};
