import { CodeViaSMS, CurrentUser, CurrentUserFullInfo, ENDPOINTS_ACCOUNTS, JWT } from './models';
import { api, baseURL } from '../utils';
import { parseErrorData } from 'utils';
import { ServiceUserProfiles } from 'services/user-profiles';
import { AxiosResponse } from 'axios';

class Service {
  async refreshToken(p: { token?: string; refreshToken?: string }) {
    const result = await api.post<JWT>(ENDPOINTS_ACCOUNTS.REFRESH_TOKEN, p);

    const resultFetch = await fetch(`${baseURL}${ENDPOINTS_ACCOUNTS.GET_CURRENT_USER}`, {
      headers: {
        Authorization: 'Bearer ' + result.data.token,
      },
    });

    if (resultFetch.ok) {
      return result;
    }

    throw new Error('refresh-token');
  }

  async verifyReCaptcha(token: string) {
    return api
      .post(ENDPOINTS_ACCOUNTS.VERIFY_RECAPTCHA, { token })
      .then(() => {
        return true;
      })
      .catch((e: Error) => {
        throw parseErrorData(e);
      });
  }

  async generateCodeViaSMS(props: { data: CodeViaSMS }) {
    const { data } = props;
    return api.post<string>(`${ENDPOINTS_ACCOUNTS.GENERATE_CODE_VIA_SMS}`, data);
  }

  async logout() {
    return api.post(ENDPOINTS_ACCOUNTS.LOGOUT);
  }
  async getCurrentUser(): Promise<AxiosResponse<CurrentUserFullInfo>> {
    const response = await api.get<CurrentUser>(ENDPOINTS_ACCOUNTS.GET_CURRENT_USER);

    const _professionalFieldOfInterests = await ServiceUserProfiles.getListOfInterests(
      response.data.appUserID,
    );

    return { ...response, data: { ...response.data, _professionalFieldOfInterests } };
  }
  async generatePassword(data: { email: string }) {
    return api.post<CurrentUser>(ENDPOINTS_ACCOUNTS.GENERATE_PASSWORD, data);
  }
  async loginWithCode(data: { email: string; code: string }) {
    return api.post<{ jwt: JWT; expires: string }>(ENDPOINTS_ACCOUNTS.LOGIN, data);
  }
}

export const ServiceAccounts = new Service();
export * from './models';
