import { Badge, BadgeProps } from '@material-ui/core';
import React from 'react';
import './_style.scss';
import clsx from 'clsx';

interface AppBadgeProps extends BadgeProps {}
export const AppBadge: React.FC<AppBadgeProps> = ({ className, ...rest }) => {
  const classNames = clsx('app-badge', className);
  return <Badge {...rest} className={classNames} color={'primary'} />;
};
