import { CircularProgress, Theme, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';
import style from './index.module.scss';
import { AppScrollbars } from 'components/app-scrollbar';
import { NetworkingVideo } from 'services/networking-events';
import { useOpen, useTranslate } from 'hooks';
import { BannerPromoteCompany } from 'components/banners/promote-company';
import { MainStageItem } from '../main-stage-item';
import { BecomeSponsorDialogContactUs } from 'components/dialogs';

interface ItemsListProps {
  videoID: string | null | undefined;
  data: NetworkingVideo[];
  onPlay: (id: string) => void;
}

const ItemsList: React.FC<ItemsListProps> = ({ data, videoID, onPlay }) => {
  const { isOpen, onOpen: handleOpen, onClose: handleClose } = useOpen();

  return (
    <div className={style.item}>
      <div className={style.info}>
        <BannerPromoteCompany size={'small'} onClick={handleOpen} />
      </div>
      {data.map((item, i) => {
        return (
          <MainStageItem
            key={item.id}
            showLine={item.id !== videoID && i !== data.length}
            isSelected={item.id === videoID}
            item={item}
            onPlay={onPlay}
          />
        );
      })}
      {isOpen && <BecomeSponsorDialogContactUs onClose={handleClose} />}
    </div>
  );
};

interface Props {
  isLoading: boolean;
  videoID: string;
  list: NetworkingVideo[];
  onPlay: (v: this['videoID']) => void;
}

export const MainStageVideos: React.FC<Props> = ({ isLoading, videoID, list, onPlay }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { t } = useTranslate();

  return (
    <div className={style.wrap}>
      <Typography variant={'h5'} color={'primary'} style={{ marginBottom: '1.5rem' }}>
        {t('chose-from-list')}
      </Typography>
      <div>
        {isMobile ? (
          <ItemsList data={list} videoID={videoID} onPlay={onPlay} />
        ) : (
          <AppScrollbars style={{ height: '100%' }}>
            <ItemsList data={list} videoID={videoID} onPlay={onPlay} />
            {isLoading && (
              <div className={style.progress}>
                <CircularProgress disableShrink />
              </div>
            )}
          </AppScrollbars>
        )}
      </div>
    </div>
  );
};
