import { api, uploadFile } from 'services/utils';
import { API_NETWORKING_EVENT_IMAGES, NetworkingEventImage } from './models';
import { BaseParams, getFileInfo } from 'utils';

export * from './models';

class Service {
  async getLastRank(customerNetworkingEventID: string) {
    const response = await ServiceNetworkingEventImages.getAllDynamic(customerNetworkingEventID, {
      take: 1,
    });
    const rank = response.data.value[0]?.rank || 0;
    return { ...response, data: rank };
  }

  async getAllDynamic(customerNetworkingEventID: string, params?: BaseParams) {
    return api.get<{ value: NetworkingEventImage[] }>(API_NETWORKING_EVENT_IMAGES.GET_ALL_DYNAMIC, {
      params: {
        customerNetworkingEventID: customerNetworkingEventID,
        ...params,
      },
    });
  }

  async post(data: Pick<NetworkingEventImage, 'customerNetworkingEventID' | 'imageURL'>) {
    const { customerNetworkingEventID } = data;

    const { data: rank } = await ServiceNetworkingEventImages.getLastRank(
      customerNetworkingEventID,
    );
    const { fileName } = getFileInfo(data.imageURL);
    const { filePath: imageURL } = await uploadFile('', data.imageURL);
    return api.post(API_NETWORKING_EVENT_IMAGES.CREATE, {
      customerNetworkingEventID,
      imageURL,
      title: fileName,
      rank: rank + 1,
    });
  }

  async delete(id: string) {
    return api.delete(API_NETWORKING_EVENT_IMAGES.DELETE(id));
  }
}

export const ServiceNetworkingEventImages = new Service();
