import { useEffect } from 'react';
import { selectChatConversationsList, selectChatIsOpen } from '../store';
import { useAppSelector } from 'hooks';
import { useChatConversation } from './use-chat-conversation';

export const useChatAutoSelect = () => {
  const { conversationID, onSelect } = useChatConversation();
  const isOpenChat = useAppSelector(selectChatIsOpen);
  const conversationList = useAppSelector(selectChatConversationsList);

  const firstConversation = conversationList[0];
  const firstConversationID = firstConversation?.conversation.conversationID;

  useEffect(() => {
    if (isOpenChat && !conversationID && firstConversationID) {
      onSelect(firstConversationID);
    }
  }, [isOpenChat, conversationID, firstConversationID, onSelect]);
};
