import React from 'react';
import { Button, Fab, Paper, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { useEventBuilderSteps, useSourceCustomerEvent, useTranslate } from 'hooks';
import { APP_ROUTES, EVENT_BUILDER_STEP } from 'configs';
import { calcCustomerEventSteps } from 'store/event-builder';
import { useHistory } from 'react-router-dom';
import style from './index.module.scss';
import { CustomerNetworkingEvent } from 'services/customer-networking-event';
import { networkingEventApi } from 'services/networking-events';

interface ViewProps {
  className?: string;
  data: CustomerNetworkingEvent;
}

const View: React.FC<ViewProps> = ({ className, data }) => {
  const { t } = useTranslate();
  const mapSteps = calcCustomerEventSteps(data);
  const customerNetworkingEventID = data.customerNetworkingEventID;

  const { items, goTo } = useEventBuilderSteps(customerNetworkingEventID);

  const { push } = useHistory();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Paper
      classes={{
        root: style.wrap,
      }}
      elevation={0}
      className={className}
    >
      <div className={style.iconGrid}>
        {items.map(({ title, IconComponent, value }, i) => {
          const active = mapSteps[value];
          return (
            <div title={title} key={i}>
              <Fab
                color={'inherit'}
                disableRipple
                classes={{
                  root: style.iconItem,
                }}
                onClick={() => {
                  goTo(value);
                }}
              >
                <IconComponent disabled={!active} />
              </Fab>
            </div>
          );
        })}
      </div>
      <Typography variant={'body1'} color={'inherit'} className={style.title}>
        {t('builder-info')}
      </Typography>
      <Button
        color={'secondary'}
        variant={'contained'}
        size={isMobile ? 'medium' : 'large'}
        onClick={() => {
          push(
            APP_ROUTES.EVENT_BUILDER.getConfig({ customerNetworkingEventID })({
              step: EVENT_BUILDER_STEP.START,
            }),
          );
        }}
      >
        {t('builder-edit')}
      </Button>
    </Paper>
  );
};

interface Props {
  className?: string;
  networkingEventID: string;
}

export const TabExhibitorsBannerComplete: React.FC<Props> = ({ className, networkingEventID }) => {
  const { data } = useSourceCustomerEvent(networkingEventID, {
    refetchOnMountOrArgChange: true,
  });
  const { data: count } =
    networkingEventApi.endpoints.getCountExhibitorsRelativeToMe.useQuery(networkingEventID);

  return data && count === 1 ? <View data={data} className={className} /> : null;
};
