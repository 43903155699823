import React, { useMemo } from 'react';
import style from './index.module.scss';
import { AppAvatar, AppAvatarProps } from 'components/app-avatar';
import { useDateFormatSplit } from 'hooks';
import clsx from 'clsx';
import { sanitizeChatMessage } from 'components/custom-chat/utils';

interface MessageProps {
  personal?: boolean;
  message: string;
  date: string;
  AvatarProps: AppAvatarProps;
}

export const Message: React.FC<MessageProps> = ({ personal, message, date, AvatarProps }) => {
  const { hours, minutes } = useDateFormatSplit(date);

  const cleanMessage = useMemo(() => {
    return sanitizeChatMessage(message);
  }, [message]);

  return (
    <div className={clsx(style.item, personal && style.reitem)}>
      <div className={style.box}>
        {!personal && <AppAvatar size={'xs'} className={style.avatar} {...AvatarProps} />}
        <div className={style.cont}>
          <div dangerouslySetInnerHTML={{ __html: cleanMessage }} />
          <div className={style.time}>
            {hours}:{minutes}
          </div>
        </div>
      </div>
    </div>
  );
};
