const API = 'CardcomServices';

export const API_CARDCOM = {
  CREATE_PAGE: `${API}/CreatePaymentPage`,
};

export interface CreatePagePayload {
  customerNetworkingEventID: string;
  successURL: string;
  errorURL: string;
}
