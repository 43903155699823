import React, { useCallback, useMemo } from 'react';
import style from './index.module.scss';
import { AppAvatar } from 'components/app-avatar';
import { ListItem } from '@material-ui/core';
import { ChatTyping } from '../typing';
import clsx from 'clsx';
import { sanitizeChatMessage } from 'components/custom-chat/utils';

interface Props {
  id: string;
  photo?: string | null;
  firstName: string;
  lastName: string;
  lastMessage: string;
  isOnline: boolean;
  isNotRead: boolean;
  hasNewMessage?: boolean;
  selected?: boolean;
  onClick: (id: string) => void;
  conversationID: string;
  isTyping: boolean;
}

export const CustomChatDialogItem: React.FC<Props> = ({
  id,
  isOnline,
  isNotRead,
  lastName,
  firstName,
  photo,
  lastMessage,
  selected,
  onClick,
  isTyping,
}) => {
  const handleClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  const cleanMessage = useMemo(() => {
    return sanitizeChatMessage(lastMessage);
  }, [lastMessage]);

  return (
    <ListItem
      className={style.wrap}
      disableGutters
      selected={selected}
      button
      onClick={handleClick}
    >
      <div className={clsx(style.user, isNotRead && style.notRead)}>
        <div>
          <AppAvatar
            firstName={firstName}
            lastName={lastName}
            src={photo}
            size={'xs'}
            isOnline={isOnline}
          />
        </div>
        <div className={style.cont}>
          <div className={style.name}>{`${firstName} ${lastName}`}</div>
          <div className={style.message}>
            {isTyping ? (
              <ChatTyping />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: cleanMessage }} />
            )}
          </div>
        </div>
      </div>
    </ListItem>
  );
};
