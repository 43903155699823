import React, { useMemo } from 'react';
import { AppFooterTransparent } from 'components/app-footer-transparent';
import { useSourcePageNetwork } from 'hooks';
import style from './index.module.scss';
import clsx from 'clsx';

interface Props {
  Header?: React.ReactNode;
  className?: string;
}

export const LayoutTransparent: React.FC<Props> = ({ children, className, Header }) => {
  const { data: dataPackageNetwork } = useSourcePageNetwork();

  const bannerUrl = useMemo(() => {
    return dataPackageNetwork?.banner;
  }, [dataPackageNetwork]);
  return (
    <div
      className={clsx(style.layout, Header && style.withHeader, className)}
      style={{ backgroundImage: bannerUrl ? `url(${bannerUrl})` : undefined }}
    >
      {Header}
      <main>{children}</main>
      <AppFooterTransparent />
    </div>
  );
};
