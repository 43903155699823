import { useDispatch, useSelector } from 'react-redux';
import { selectAppUserID } from 'store/auth';
import { useCallback, useMemo } from 'react';
import { eventChatStar, useGaEvent } from 'components/google-analytics';
import { actionChatStartChat } from 'components/custom-chat';

export interface ChatUserData {
  appUserID: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
}

export const useStartChat = (member: ChatUserData) => {
  const currentAppUserID = useSelector(selectAppUserID);
  const isVisible = useMemo(() => {
    return member.appUserID !== currentAppUserID;
  }, [currentAppUserID, member.appUserID]);

  const dispatch = useDispatch();
  const gaEvent = useGaEvent();

  const onStart = useCallback(() => {
    dispatch(
      actionChatStartChat({
        appUserID: member.appUserID,
        firstName: member.firstName,
        lastName: member.lastName,
        profilePicture: member.profilePicture,
      }),
    );
    gaEvent(eventChatStar());
  }, [dispatch, member, gaEvent]);
  return {
    isVisible,
    onStart,
  };
};
