import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';

interface Props {
  className?: string;
  Tools?: React.ReactNode;
  Aside?: React.ReactNode;
}

export const LayoutSearch: React.FC<Props> = ({ className, Tools, Aside, children }) => {
  return (
    <div className={clsx(style.layout, !Aside && style.layoutFull, className)}>
      {Tools && <div className={style.tools}>{Tools}</div>}
      {Aside && <aside className={style.aside}>{Aside}</aside>}
      <div className={style.content}>{children}</div>
    </div>
  );
};
