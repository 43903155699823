import { call, put, select, takeLatest } from 'modules/typed-saga';
import {
  getMyExhibitorEventsActions,
  getMyVisitorEventsActions,
  invitationMyExhibitorEventsActions,
  leaveMyVisitorEventsActions,
  loadMoreMyExhibitorEvents,
  loadMoreMyVisitorEvents,
  setMyExhibitorEventsPagination,
  setMyVisitorEventsPagination,
} from './slice';
import { selectMyExhibitorEventsPagination, selectMyVisitorEventsPagination } from './selectors';
import { ServiceNetworkingEvents } from 'services/networking-events';
import { notifyErrorSaga } from '../_utils';

function* getVisitorEvents(action: ReturnType<typeof getMyVisitorEventsActions.request>) {
  const { isMore } = action.payload;
  const { take, skip } = yield* select(selectMyVisitorEventsPagination);
  try {
    const {
      data: { value, count },
    } = yield* call(ServiceNetworkingEvents.getMyVisitorEvents, {
      take,
      skip,
      count: true,
    });
    yield* put(getMyVisitorEventsActions.success({ data: value, count, isMore }));
  } catch (e: any) {
    yield* put(getMyVisitorEventsActions.fail(e as Error));
    yield notifyErrorSaga(e);
  }
}

function* getExhibitorEvents(action: ReturnType<typeof getMyExhibitorEventsActions.request>) {
  const { isMore } = action.payload;
  const { take, skip } = yield* select(selectMyExhibitorEventsPagination);
  try {
    const {
      data: { value, count },
    } = yield* call(ServiceNetworkingEvents.getMyExhibitorEvents, {
      take,
      skip,
      count: true,
    });
    yield* put(getMyExhibitorEventsActions.success({ data: value, count, isMore }));
  } catch (e: any) {
    yield* put(getMyExhibitorEventsActions.fail(e as Error));
    yield* notifyErrorSaga(e);
  }
}

function* invitationExhibitorEvents(
  action: ReturnType<typeof invitationMyExhibitorEventsActions.request>,
) {
  const { accept, networkingEventRepresentativeID } = action.payload;

  try {
    if (accept) {
      yield call(ServiceNetworkingEvents.acceptEventInvitation, networkingEventRepresentativeID);
    } else {
      yield call(ServiceNetworkingEvents.declineEventInvitation, networkingEventRepresentativeID);
    }
    yield put(invitationMyExhibitorEventsActions.success({ networkingEventRepresentativeID }));
  } catch (e: any) {
    yield put(invitationMyExhibitorEventsActions.fail({ networkingEventRepresentativeID }));
    yield notifyErrorSaga(e);
  }
}

function* loadMoreVisitorEvents() {
  const { page } = yield* select(selectMyVisitorEventsPagination);
  yield put(setMyVisitorEventsPagination({ page: page + 1 }));
  yield put(getMyVisitorEventsActions.request({ isMore: true }));
}

function* leaveVisitorEvents(action: ReturnType<typeof leaveMyVisitorEventsActions.request>) {
  const { networkingEventID } = action.payload;
  try {
    yield call(ServiceNetworkingEvents.unJoinToEvent, networkingEventID);
    yield put(leaveMyVisitorEventsActions.success({ networkingEventID }));
  } catch (e: any) {
    yield put(leaveMyVisitorEventsActions.fail({ networkingEventID }));
    yield notifyErrorSaga(e);
  }
}

function* loadMoreExhibitorEvents() {
  const { page } = yield* select(selectMyExhibitorEventsPagination);
  yield put(setMyExhibitorEventsPagination({ page: page + 1 }));
  yield put(getMyExhibitorEventsActions.request({ isMore: true }));
}

export const sagasMyEvents = [
  takeLatest(loadMoreMyVisitorEvents, loadMoreVisitorEvents),
  takeLatest(getMyVisitorEventsActions.request.type, getVisitorEvents),
  takeLatest(leaveMyVisitorEventsActions.request.type, leaveVisitorEvents),

  takeLatest(loadMoreMyExhibitorEvents, loadMoreExhibitorEvents),
  takeLatest(
    [
      getMyExhibitorEventsActions.request.type,
      invitationMyExhibitorEventsActions.success,
      invitationMyExhibitorEventsActions.fail,
    ],
    getExhibitorEvents,
  ),
  takeLatest(invitationMyExhibitorEventsActions.request.type, invitationExhibitorEvents),
];
