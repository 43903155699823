import { createAsyncThunk } from '@reduxjs/toolkit';
import { StoreState } from '../index';
import { parseErrorData, PatchModel, ServiceError, ValueFileUploader } from 'utils';
import {
  customerNetworkingEventApi,
  CustomerNetworkingEventPatch,
  CustomerNetworkingEventPost,
  ServiceCustomerNetworkingEvents,
} from 'services/customer-networking-event';
import {
  NetworkingEventResource,
  ServiceNetworkingEventResources,
} from 'services/networking-event-resources';
import {
  NetworkingEventRepresentative,
  NetworkingEventRepresentativeNew,
  ServiceNetworkingUserProfiles,
} from 'services/networking-event-user-profiles';
import {
  NetworkingEventImage,
  ServiceNetworkingEventImages,
} from 'services/networking-event-images';
import {
  NetworkingEventVideo,
  ServiceNetworkingEventVideos,
} from 'services/networking-event-videos';
import { ServiceCardcom } from 'services/cardcom-services';

type Patch = PatchModel<CustomerNetworkingEventPatch, 'customerNetworkingEventID'>;

export const actionEventBuilderRepayEvent = createAsyncThunk<
  void,
  {
    customerNetworkingEventID: string;
    data: CustomerNetworkingEventPost;
  },
  { state: StoreState; serializedErrorType: Error }
>(`EVENT_BUILDER/repay`, async ({ data, customerNetworkingEventID }) => {
  try {
    await ServiceCustomerNetworkingEvents.patch(
      { customerNetworkingEventID },
      { ...data, customerNetworkingEventID },
    );

    const { data: url } = await ServiceCardcom.createPaymentPage(customerNetworkingEventID);
    window.open(url, '_self');
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionEventBuilderCreateEvent = createAsyncThunk<
  { networkingEventID: string; customerNetworkingEventID: string },
  { networkingEventID: string; data: CustomerNetworkingEventPost },
  { state: StoreState; serializedErrorType: Error }
>(`EVENT_BUILDER/create`, async ({ networkingEventID, data }) => {
  try {
    const { data: customerNetworkingEventID } = await ServiceCustomerNetworkingEvents.create(data);
    return { networkingEventID, customerNetworkingEventID };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionEventBuilderUpdate = createAsyncThunk<
  void,
  { old: Patch; new: Patch },
  { state: StoreState; serializedErrorType: Error }
>(`EVENT_BUILDER/actionEventBuilderUpdate`, async ({ old: oldValue, new: newValue }) => {
  try {
    await ServiceCustomerNetworkingEvents.patch(oldValue, newValue);
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionEventBuilderResourcesCreate = createAsyncThunk<
  { customerNetworkingEventID: string; data: NetworkingEventResource[] },
  { networkingEventID: string; customerNetworkingEventID: string; data: NetworkingEventResource },
  { state: StoreState; serializedErrorType: Error }
>(
  `EVENT_BUILDER/actionEventBuilderResourcesCreate`,
  async ({ networkingEventID, customerNetworkingEventID, data: newData }, { dispatch }) => {
    try {
      await ServiceNetworkingEventResources.post({ ...newData, customerNetworkingEventID });
      const { data } = await ServiceCustomerNetworkingEvents.getResources(
        customerNetworkingEventID,
      );
      dispatch(
        customerNetworkingEventApi.endpoints.getMyCustomerEventRepresentatives.initiate(
          networkingEventID,
        ),
      );
      return { customerNetworkingEventID, data };
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
export const actionEventBuilderResourcesRemove = createAsyncThunk<
  { customerNetworkingEventID: string; data: NetworkingEventResource[] },
  { networkingEventID: string; customerNetworkingEventID: string; id: string },
  { state: StoreState; serializedErrorType: Error }
>(
  `EVENT_BUILDER/actionEventBuilderResourcesRemove`,
  async ({ networkingEventID, customerNetworkingEventID, id }, { dispatch }) => {
    try {
      await ServiceNetworkingEventResources.delete(id);
      const { data } = await ServiceCustomerNetworkingEvents.getResources(
        customerNetworkingEventID,
      );
      dispatch(
        customerNetworkingEventApi.endpoints.getMyCustomerEventRepresentatives.initiate(
          networkingEventID,
        ),
      );
      return { customerNetworkingEventID, data };
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

// REPRESENTATIVES
export type ErrorNetworkingEventRepresentative = ServiceError<{ appUserID: string; name: string }>;
export const actionEventBuilderRepresentativesInvite = createAsyncThunk<
  {
    customerNetworkingEventID: string;
    data: NetworkingEventRepresentative[];
  },
  {
    customerNetworkingEventID: string;
    data: NetworkingEventRepresentativeNew;
  },
  {
    state: StoreState;
    serializedErrorType: Error;
  }
>(
  `EVENT_BUILDER/actionEventBuilderRepresentativesInvite`,
  async ({ customerNetworkingEventID, data: newData }) => {
    try {
      await ServiceNetworkingUserProfiles.inviteMember({ ...newData, customerNetworkingEventID });
      const { data } = await ServiceCustomerNetworkingEvents.getRepresentatives(
        customerNetworkingEventID,
      );
      return { customerNetworkingEventID, data };
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
export const actionEventBuilderRepresentativesUpdate = createAsyncThunk<
  {
    customerNetworkingEventID: string;
    data: { position: string; userCustomerNetworkingEventID: string };
  },
  {
    customerNetworkingEventID: string;
    data: { position: string; userCustomerNetworkingEventID: string };
  },
  { state: StoreState; serializedErrorType: Error }
>(
  `EVENT_BUILDER/actionEventBuilderRepresentativesUpdate`,
  async ({ customerNetworkingEventID: string, data }) => {
    try {
      await ServiceNetworkingUserProfiles.put(data);
      return { customerNetworkingEventID: string, data };
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionEventBuilderRepresentativesRemove = createAsyncThunk<
  {
    customerNetworkingEventID: string;
    userCustomerNetworkingEventID: string;
  },
  {
    customerNetworkingEventID: string;
    userCustomerNetworkingEventID: string;
  },
  { state: StoreState; serializedErrorType: Error }
>(
  `EVENT_BUILDER/actionEventBuilderRepresentativesRemove`,
  async ({ userCustomerNetworkingEventID, customerNetworkingEventID }) => {
    try {
      await ServiceNetworkingUserProfiles.delete(userCustomerNetworkingEventID);
      return { customerNetworkingEventID, userCustomerNetworkingEventID };
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionEventBuilderImagesCreate = createAsyncThunk<
  { customerNetworkingEventID: string; data: NetworkingEventImage[] },
  { customerNetworkingEventID: string; data: ValueFileUploader },
  { state: StoreState; serializedErrorType: Error }
>(
  `EVENT_BUILDER/actionEventBuilderImagesCreate`,
  async ({ customerNetworkingEventID, data: newData }) => {
    try {
      await ServiceNetworkingEventImages.post({ imageURL: newData, customerNetworkingEventID });
      const { data } = await ServiceCustomerNetworkingEvents.getImages(customerNetworkingEventID);
      return { data, customerNetworkingEventID };
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
export const actionEventBuilderImagesRemove = createAsyncThunk<
  { customerNetworkingEventID: string; imageID: string },
  { customerNetworkingEventID: string; imageID: string },
  { state: StoreState; serializedErrorType: Error }
>(
  `EVENT_BUILDER/actionEventBuilderImagesRemove`,
  async ({ customerNetworkingEventID, imageID }) => {
    try {
      await ServiceNetworkingEventImages.delete(imageID);
      return { customerNetworkingEventID, imageID };
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionEventBuilderVideosCreate = createAsyncThunk<
  { customerNetworkingEventID: string; data: NetworkingEventVideo[] },
  { customerNetworkingEventID: string; data: ValueFileUploader },
  { state: StoreState; serializedErrorType: Error }
>(
  `EVENT_BUILDER/actionEventBuilderVideosCreate`,
  async ({ customerNetworkingEventID, data: newData }) => {
    try {
      await ServiceNetworkingEventVideos.post({ videoURL: newData, customerNetworkingEventID });
      const { data } = await ServiceCustomerNetworkingEvents.getVideos(customerNetworkingEventID);
      return { customerNetworkingEventID, data };
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
export const actionEventBuilderVideosRemove = createAsyncThunk<
  { customerNetworkingEventID: string; videoID: string },
  { customerNetworkingEventID: string; videoID: string },
  { state: StoreState; serializedErrorType: Error }
>(
  `EVENT_BUILDER/actionEventBuilderVideosRemove`,
  async ({ customerNetworkingEventID, videoID }) => {
    try {
      await ServiceNetworkingEventVideos.delete(videoID);
      return { customerNetworkingEventID, videoID };
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
