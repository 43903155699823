export enum ENDPOINTS_CONTACTS {
  POST_CONTACT_COMMENT = 'Contacts/PostContactComment',
  INVITE_BALK_MEMBERS = 'Contacts/InviteBalkMembers',
}

export class ContactMessage {
  subjectID: number;
  comment: string;
  constructor(subjectID = 1) {
    this.subjectID = subjectID;
    this.comment = '';
  }
}
