import { isFileLike, ValueFileUploader } from 'utils';
import { ServiceUploads } from 'services/media-uploads';

export const uploadHtml = async (
  oldValue: string | undefined,
  newValue: string | undefined,
  fileName?: string,
) => {
  let result: { filePath: string | undefined } = { filePath: undefined };

  if (oldValue === newValue) {
    return Promise.resolve({ filePath: oldValue });
  }

  if (oldValue && (newValue || newValue === '')) {
    await ServiceUploads.removeFile(oldValue);
    result.filePath = '';
  }

  if (newValue) {
    const { data } = await ServiceUploads.uploadHtml({
      fileName: fileName,
      htmlContent: newValue,
    });
    result.filePath = data.filePath;
  }
  return Promise.resolve(result);
};
export const uploadImage = async (
  oldValue: string | undefined,
  newValue: ValueFileUploader | undefined,
) => {
  let result: { filePath: string | undefined } = { filePath: undefined };

  if (oldValue === newValue) {
    return Promise.resolve({ filePath: oldValue });
  }

  if (oldValue && (newValue || newValue === '')) {
    await ServiceUploads.removeFile(oldValue);
    result.filePath = '';
  }

  if (isFileLike(newValue)) {
    const { data } = await ServiceUploads.uploadImage({
      fileName: newValue.name,
      fileStreamString: newValue.value,
    });
    result.filePath = data.filePath;
  }
  return Promise.resolve(result);
};
export const uploadFile = async (
  oldValue: string | undefined,
  newValue: ValueFileUploader | undefined,
) => {
  let result: { filePath: string | undefined } = { filePath: undefined };

  if (oldValue === newValue) {
    return Promise.resolve({ filePath: oldValue });
  }

  if (oldValue && (newValue || newValue === '')) {
    await ServiceUploads.removeFile(oldValue);
    result.filePath = '';
  }

  if (typeof newValue === 'string') {
    result.filePath = newValue;
  }

  if (isFileLike(newValue)) {
    const { data } = await ServiceUploads.uploadFile({
      fileName: newValue.name,
      fileStreamString: newValue.value,
    });
    result.filePath = data.filePath;
  }

  return Promise.resolve(result);
};
