import React from 'react';
import style from './index.module.scss';
import { useTranslate } from 'hooks';

interface Props {}

export const CustomChatNoConversations: React.FC<Props> = ({ children }) => {
  const { t } = useTranslate();
  return <div className={style.wrap}>{t('no-conversations')}</div>;
};
