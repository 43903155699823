import React, { useContext } from 'react';
import { SerializedError } from '@reduxjs/toolkit';

export class ModelLayoutPageContext {
  isLoading: boolean = false;
  error: Error | SerializedError | null = null;
  setError(e: this['error']): void {}
  setLoading(v: boolean): void {}
}
export const LayoutPageContext = React.createContext<ModelLayoutPageContext>(
  new ModelLayoutPageContext(),
);

export const usePageContext = () => {
  return useContext(LayoutPageContext);
};
