import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AppAvatar } from '../app-avatar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import {
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItem,
  Paper,
  Popper,
  useMediaQuery,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { DialogContactUs } from '../dialogs/contact-us';
import { CompanyItem } from './compan-item';
import { APP_ROUTES, PREFERENCES_PAGE_TABS } from 'configs/routes';
import {
  actionGetUserCompanies,
  selectCurrentUserCompaniesActive,
  selectCurrentUserCompaniesPending,
} from 'store/auth';
import Scrollbars from 'react-custom-scrollbars';
import { eventProfileMy, useGaEvent } from '../google-analytics';
import { useAppSelector, useCurrentUser, useOpen, useTranslate } from 'hooks';
import { useHistory } from 'react-router-dom';
import { Loading } from 'components/loading';
import { actionAccountLogout } from 'store/auth/slice';
import { selectChatOnlineUsers } from 'components/custom-chat';

export const ProfileNav: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const user = useCurrentUser();
  const { push } = useHistory();
  const ref = useRef<HTMLUListElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLUListElement | null>(null);
  const isShowName = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
  const { isOpen: isOpenContactUs, onClose: onCloseContactUs, onOpen: onOpenContactUS } = useOpen();

  const { isLoading: isLoadingActive, data: companiesActive } = useSelector(
    selectCurrentUserCompaniesActive,
  );
  const { isLoading: isLoadingPending, data: companiesPending } = useSelector(
    selectCurrentUserCompaniesPending,
  );
  const isLoading = isLoadingActive || isLoadingPending;

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleToggle = useCallback(
    (e: any) => {
      if (!anchorEl) {
        setAnchorEl(e.currentTarget);
      } else {
        setAnchorEl(null);
      }
    },
    [anchorEl],
  );
  const handleLogout = useCallback(() => {
    dispatch(actionAccountLogout.request());
    handleClose();
  }, [dispatch, handleClose]);
  const gaEvent = useGaEvent();
  const handleEditProfile = useCallback(() => {
    gaEvent(eventProfileMy());
    push(APP_ROUTES.PREFERENCES.getConfig()({ tab: PREFERENCES_PAGE_TABS.DETAILS }));
    handleClose();
  }, [handleClose, push, gaEvent]);
  const handleOpenContactUs = useCallback(() => {
    onOpenContactUS();
    handleClose();
  }, [onOpenContactUS, handleClose]);

  useEffect(() => {
    dispatch(actionGetUserCompanies.request());
  }, [dispatch]);

  const onAddCompany = useCallback(() => {
    push(APP_ROUTES.COMPANY_EDITOR.getConfig()());
  }, [push]);

  const map = useAppSelector(selectChatOnlineUsers);

  return (
    <div className={'profile-nav'}>
      <List ref={ref}>
        <ListItem onClick={handleToggle} button className={'profile-nav__list-item'}>
          {isShowName && (
            <div className={'profile-nav__info'}>
              <span className={'profile-nav__name'}>{user.fullName}</span>
              <span className={'profile-nav__company'}>
                {user.currentCompanyName || user.currentCompanyPosition}
              </span>
            </div>
          )}
          <IconButton size={'small'}>
            <AppAvatar isOnline={map[user.appUserID]} src={user.profilePicture} />
          </IconButton>
          {!!anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </ListItem>
      </List>
      <Popper open={!!anchorEl} anchorEl={anchorEl} style={{ zIndex: 99 }}>
        <Paper className={'profile-nav__menu'}>
          <ClickAwayListener onClickAway={handleClose}>
            <div className={'profile-nav-menu'}>
              <div className={'profile-nav-menu__avatar'}>
                <AppAvatar src={user.profilePicture} size={'md'} title={user.fullName} />
              </div>
              <div className={'profile-nav-menu__name'}>
                <p title={user.fullName}>{user.fullName}</p>
              </div>
              <div className={'profile-nav-menu__controls profile-nav-menu__controls-top'}>
                <Button
                  variant={'text'}
                  color={'primary'}
                  startIcon={<MailOutlineIcon />}
                  onClick={handleOpenContactUs}
                >
                  {t('contact-us')}
                </Button>
                <Button
                  variant={'text'}
                  color={'primary'}
                  startIcon={<EditIcon />}
                  onClick={handleEditProfile}
                >
                  {t('edit-preferences')}
                </Button>
              </div>
              {(companiesActive.length > 0 || companiesPending.length > 0) && (
                <>
                  <Divider className={'profile-nav-menu__divider'} />
                  <div className={'profile-nav-menu__companies'}>
                    <Scrollbars autoHeight autoHeightMax={'29.5rem'}>
                      {companiesActive.map((company, i) => {
                        return (
                          <CompanyItem
                            isPending={false}
                            key={company.companyID}
                            company={company}
                            isSelected={company.selected}
                            onClose={handleClose}
                          />
                        );
                      })}
                      {companiesPending.map((company, i) => {
                        return (
                          <CompanyItem
                            isPending={true}
                            key={company.companyID}
                            company={company}
                            isSelected={company.selected}
                            onClose={handleClose}
                          />
                        );
                      })}
                      <Loading active={isLoading} />
                    </Scrollbars>
                  </div>
                </>
              )}
              <Divider className={'profile-nav-menu__divider'} />
              <div className={'profile-nav-menu__controls'}>
                <Button
                  variant={'text'}
                  color={'primary'}
                  startIcon={<AddIcon />}
                  onClick={onAddCompany}
                >
                  {t('add-company')}
                </Button>
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  onClick={handleLogout}
                  style={{
                    minWidth: '10rem',
                  }}
                >
                  {t('logout')}
                </Button>
              </div>
            </div>
          </ClickAwayListener>
        </Paper>
      </Popper>
      <DialogContactUs isOpen={isOpenContactUs} onClose={onCloseContactUs} />
    </div>
  );
};
