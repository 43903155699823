import React from 'react';

interface Options {
  shift?: boolean;
}
export const getHandlerKayPress = (key: string, cb: () => void, options: Options = {}) => {
  const { shift } = options;
  return (e: React.KeyboardEvent) => {
    const shouldExecute = [e.key === key, shift ? shift === e.shiftKey : true].every(Boolean);
    if (shouldExecute) {
      cb();
    }
  };
};
