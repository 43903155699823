import DOMPurify from 'dompurify';

export const splitBy = <T extends { [x: string]: any }>(
  arr: T[],
  calculator: (prevItem: T, nextItem: T) => boolean,
) => {
  if (arr.length < 1) {
    return [];
  }

  const result = [[arr[0]]];

  for (let i = 1; i < arr.length; i++) {
    const isNext = calculator(arr[i - 1], arr[i]);
    if (isNext) {
      result.push([arr[i]]);
    } else {
      result[result.length - 1].push(arr[i]);
    }
  }

  return result;
};
export const sanitizeChatMessage = (message?: string) => {
  const value = String(message || '').trim();

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
    }
  });

  const result = DOMPurify.sanitize(value, {
    ALLOWED_TAGS: ['p', 'strong', 'a', 'div', 'span', 'em', 'u', 'br'],
    ALLOWED_ATTR: ['href', 'target', 'rel'],
  });

  DOMPurify.removeHook('afterSanitizeAttributes');

  return result;
};
