export const ENDPOINTS_PAGES = {
  GET_PACKAGE_NETWORK_STEPS: 'Pages/GetNetworkOnboardingStepPackages',
  GET_DASHBOARD: 'Pages/GetDashboard',
  GET_TERMS_AND_CONDITIONS: 'Pages/GetTermsAndConditions',
  GET_PRIVACY_POLICE: 'Pages/GetPrivacy',
  GET_HTML: (p: { fileName: string }) => `Pages/GetHtml/${p.fileName}`,
};

interface BasePageItemModel {
  id: number;
  title: string;
  description: string;
  picture: string;
  rank: number;
}
export interface Page {
  id: number;
  languageID: number;
  title: string;
  description: string;
  banner: string;
  htmlURL: string;
  metaTitle: string;
  metaDescription: string;
  metaKeyWords: string;
  items: BasePageItemModel[] | null;
}
export interface PageWithHtml extends Page {
  html: string;
}
