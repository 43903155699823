import React from 'react';
import { useTranslate } from 'hooks';
import { Tooltip } from '@material-ui/core';
import { useApiGetEventSponsors } from 'hooks/use-api';

import style from './index.module.scss';

interface Props {
  eventID: string;
}

export const SponsorsList: React.FC<Props> = ({ eventID }) => {
  const { t } = useTranslate();
  const { data = [] } = useApiGetEventSponsors(eventID);

  if (data.length === 0) {
    return null;
  }

  return (
    <div className={style.section}>
      <h2>{t('sponsors')}</h2>
      <div className={style.grid}>
        {data.map(({ title, logo, linkURL }, i) => {
          const Component = (
            <Tooltip title={title}>
              <img src={logo} alt={title} />
            </Tooltip>
          );
          return <div key={i}>{linkURL ? <a href={linkURL}>{Component}</a> : Component}</div>;
        })}
      </div>
    </div>
  );
};
