import { API_CARDCOM, CreatePagePayload } from './models';
import { api } from 'services/utils';
import { AxiosResponse } from 'axios';
import { APP_ROUTES } from 'configs';

export * from './models';

class Service {
  async createPaymentPage(customerNetworkingEventID: string) {
    const payload: CreatePagePayload = {
      customerNetworkingEventID,
      successURL: APP_ROUTES.EVENT_BUILDER_PAYMENT_SUCCESS.getAbsoluteUrl({
        customerNetworkingEventID,
      })(),
      errorURL: APP_ROUTES.EVENT_BUILDER_PAYMENT_ERROR.getAbsoluteUrl({
        customerNetworkingEventID,
      })(),
    };
    return api.post<CreatePagePayload, AxiosResponse<string>>(API_CARDCOM.CREATE_PAGE, payload);
  }
}

export const ServiceCardcom = new Service();
