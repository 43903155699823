import {
  api,
  axiosBaseGetQuery,
  transformResponseSources,
  uploadFile,
  uploadHtml,
  uploadImage,
} from '../utils';
import {
  API_COMPANIES,
  Company,
  CompanyBusinessActivity,
  InviteAppModel,
  InviteNewModel,
  UserCompany,
} from './models';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { BaseParams, DynamicResult, PatchModel, prepareRecords } from 'utils';

interface CompanyUserModel {
  isOwner: true;
  appUserID: string;
  companyID: string;
  position: string;
}

class Service {
  async patch(oldData: PatchModel<Company, 'companyID'>, newData: Partial<Company>) {
    const { companyID } = oldData;
    const { companyName, logo, companyDescription, deckFile, _businessActivities, ...rest } =
      newData;

    if (companyName) {
      await ServiceCompanies.checkCompanyName({ companyName });
    }

    const [{ filePath: pathLogo }, { filePath: pathHtml }, { filePath: pathDeckFile }] =
      await Promise.all([
        uploadImage(oldData.logo, logo),
        uploadHtml(oldData.companyDescription, companyDescription),
        uploadFile(oldData.deckFile, deckFile),
      ]);

    if (_businessActivities) {
      const { postItems, deleteItems } = prepareRecords(
        oldData._businessActivities || [],
        _businessActivities,
        'businessActivityID',
      );

      await Promise.all([
        ...deleteItems.map(({ uid }) => ServiceCompanies.deleteBusinessActivity(uid)),
        ...postItems.map(({ businessActivityID }) =>
          ServiceCompanies.createBusinessActivity({ companyID, businessActivityID }),
        ),
      ]);
    }

    await api.patch(API_COMPANIES.PATCH_COMPANY({ companyID: oldData.companyID }), {
      ...rest,
      companyName,
      logo: pathLogo,
      companyDescription: pathHtml,
      deckFile: pathDeckFile,
    });
  }

  async getCompany(companyID: string) {
    const [
      response,
      {
        data: { value },
      },
    ] = await Promise.all([
      api.get<Company>(API_COMPANIES.GET_COMPANY({ companyID })),
      ServiceCompanies.getBusinessActivities(companyID),
    ]);

    return { ...response, data: { ...response.data, _businessActivities: value } };
  }

  async createCompany(p: { data: Company; appUserID: string; position?: string }) {
    const {
      data: { logo, _businessActivities, deckFile, companyDescription, ...rest },
    } = p;

    await ServiceCompanies.checkCompanyName({ companyName: rest.companyName });

    const { data: company } = await api.post<Company>(API_COMPANIES.CREATE_COMPANY, rest);

    const { companyID } = company;

    const { position, appUserID } = p;

    await ServiceCompanies._createCompanyUser({ position, appUserID, companyID });

    await ServiceCompanies.patch(company, {
      logo,
      _businessActivities,
      deckFile,
      companyDescription,
    });

    return { data: { companyID } };
  }

  async getCompanyFullInfo(p: { companyID: string }) {
    const params = { filter: `companyID == "${p.companyID}"` };
    const {
      data: { value },
    } = await api.get<{ value: Company[] }>(API_COMPANIES.GET_COMPANIES, { params });

    if (!value.length) {
      throw new Error('company not found');
    }

    return { data: value[0] };
  }

  async getCompanies(props?: { params?: BaseParams }) {
    const orderBy = props?.params?.orderBy || 'createdDate desc';
    const params = props?.params || {};
    return api.get<{ value: Company[]; count: number }>(API_COMPANIES.GET_COMPANIES, {
      params: {
        ...params,
        orderBy,
      },
    });
  }

  async getBusinessActivities(companyID: string) {
    return api.get<DynamicResult<CompanyBusinessActivity>>(API_COMPANIES.GET_BUSINESS_ACTIVITIES, {
      params: { companyID },
    });
  }

  async createBusinessActivity(p: { companyID: string; businessActivityID: string | number }) {
    const { companyID, businessActivityID } = p;
    return api.post(API_COMPANIES.POST_BUSINESS_ACTIVITIES, { companyID, businessActivityID });
  }

  async deleteBusinessActivity(uid: string) {
    return api.delete(API_COMPANIES.DELETE_BUSINESS_ACTIVITIES({ uid }));
  }

  async getUserCompanies(p: { companyID: string; appUserID: string }) {
    const url = API_COMPANIES.GET_USER_COMPANIES_DYNAMIC;
    const params = {
      companyID: p.companyID,
      filter: `appUserID != "${p.appUserID}"`,
    };
    return api.get<{ value: UserCompany[] }>(url, { params });
  }

  async getCompaniesUser(p: { appUserID: string }) {
    const url = API_COMPANIES.GET_USER_COMPANIES_DYNAMIC;
    const { appUserID } = p;
    const params = {
      ActiveUsersOnly: false,
      appUserID,
    };
    return api.get<{ value: UserCompany[] }>(url, { params });
  }

  async checkAllowToEditCompany(p: { appUserID: string; companyID: string }) {
    const url = API_COMPANIES.GET_USER_COMPANIES_DYNAMIC;
    const params = {
      companyID: p.companyID,
      appUserID: p.appUserID,
    };
    const {
      data: { value },
    } = await api.get<{ value: UserCompany[] }>(url, { params });

    const company = value.find((company) => company.appUserID === p.appUserID);

    if (!company) {
      throw new Error('You are not a part of this company');
    }
    if (!company.isOwner) {
      throw new Error('You are not an owner of this company');
    }
    return company;
  }

  async updateUserCompany(p: { userCompanyID: string; position?: string }) {
    const { position } = p;
    return api.patch(API_COMPANIES.PATCH_USER_COMPANY(p), { position });
  }

  async checkExistUserInTheCompany(p: { appUserID: string; companyID: string }) {
    const url = API_COMPANIES.GET_USER_COMPANIES_DYNAMIC;
    const params = {
      companyID: p.companyID,
      filter: `appUserID == "${p.appUserID}"`,
    };
    const {
      data: { value },
    } = await api.get<{ value: UserCompany[] }>(url, { params });
    if (value.length) {
      throw new Error('user already exist');
    }
    return false;
  }

  async inviteAppMember(p: InviteAppModel) {
    await ServiceCompanies.checkExistUserInTheCompany(p);
    return api.post<InviteAppModel>(API_COMPANIES.INVITE_APP_MEMBER, p);
  }

  async inviteNewMember(p: InviteNewModel) {
    return api.post<InviteNewModel>(API_COMPANIES.INVITE_NEW_MEMBER, p);
  }

  async invitePendingMember(p: InviteAppModel) {
    return api.post<InviteNewModel>(API_COMPANIES.INVITE_PENDING_MEMBER, p);
  }

  async confirmInvite(companyID: string) {
    return api.put(API_COMPANIES.INVITE_CONFIRM(companyID));
  }

  async rejectInvite(companyID: string) {
    return api.put(API_COMPANIES.INVITE_REJECT(companyID));
  }

  async leaveCompany(userCompanyID: string) {
    return api.delete(API_COMPANIES.DELETE_USER_COMPANY({ userCompanyID }));
  }

  async makeOwner(p: { userCompanyID: string }) {
    const { userCompanyID } = p;
    return api.patch(API_COMPANIES.PATCH_USER_COMPANY({ userCompanyID }), { isOwner: true });
  }

  async deleteOwner(p: { userCompanyID: string }) {
    const { userCompanyID } = p;
    return api.patch(API_COMPANIES.PATCH_USER_COMPANY({ userCompanyID }), { isOwner: false });
  }

  async removeInvite(p: { userCompanyID: string }) {
    return api.delete(API_COMPANIES.DELETE_USER_COMPANY(p));
  }

  private async checkCompanyName(p: { companyName: string }) {
    let { companyName } = p;

    companyName = companyName.replace(/ /g, '').toUpperCase();
    const params = {
      filter: `companyName.replace(" ", String.Empty).ToUpper() == "${companyName}"`,
      take: 1,
    };

    const {
      data: { value },
    } = await api.get<DynamicResult<Company>>(API_COMPANIES.GET_COMPANIES, { params });

    if (value.length) {
      throw new Error('company-exist');
    }

    return { data: { companyName } };
  }

  private async _createCompanyUser(p: { position?: string; companyID: string; appUserID: string }) {
    const url = API_COMPANIES.CREATE_COMPANY_USER;
    return api.post<CompanyUserModel>(url, { ...p, isOwner: true });
  }
}

export const companiesApi = createApi({
  reducerPath: 'companiesApi',
  baseQuery: axiosBaseGetQuery(),
  endpoints: (builder) => ({
    getCompany: builder.query<Company, string>({
      query: (companyID) => ({
        url: API_COMPANIES.GET_COMPANY({ companyID }),
      }),
    }),
    getMemberCompanies: builder.query<UserCompany[], string>({
      query: (appUserID) => ({
        url: API_COMPANIES.GET_USER_COMPANIES_DYNAMIC,
        params: {
          appUserID,
          activeUsersOnly: false,
        },
      }),
      transformResponse: transformResponseSources,
    }),
  }),
});

export const ServiceCompanies = new Service();
export * from './models';
