import { StoreState } from '../index';
import { createSelector } from 'reselect';
import { calcPaginationState } from 'utils';
import { adapterEvenPageData } from 'utils/adapters';

export const selectStateEvent = (state: StoreState) => state.event;

export const selectEvent = createSelector(selectStateEvent, ({ event }) => {
  return event ? adapterEvenPageData(event) : event;
});

export const selectEventVisitors = createSelector(selectStateEvent, ({ visitors }) => {
  return visitors;
});
export const selectEventVisitorsLoading = createSelector(selectEventVisitors, ({ loading }) => {
  return loading;
});
export const selectEventVisitorsList = createSelector(selectEventVisitors, ({ data }) => {
  return data;
});
export const selectEventVisitorsFilters = createSelector(selectEventVisitors, ({ filter }) => {
  return filter;
});
export const selectEventVisitorsHasFilters = createSelector(
  selectEventVisitorsFilters,
  (filters) => {
    return Object.values(filters).some((v) => {
      if (Array.isArray(v)) {
        return v.length;
      } else {
        return Boolean(v);
      }
    });
  },
);
export const selectEventVisitorsPagination = createSelector(
  selectEventVisitors,
  ({ pagination }) => {
    return calcPaginationState(pagination);
  },
);

export const selectEventExhibitors = createSelector(selectStateEvent, ({ exhibitors }) => {
  return exhibitors;
});
export const selectEventExhibitorsData = createSelector(
  selectEventExhibitors,
  ({ data, loading, error }) => {
    return { data, loading, error };
  },
);
export const selectEventExhibitorsFilters = createSelector(selectEventExhibitors, ({ filter }) => {
  return {
    filters: filter,
    hasValue: Object.values(filter).some((v) => {
      if (Array.isArray(v)) {
        return v.length;
      } else {
        return Boolean(v);
      }
    }),
  };
});

export const selectEventExhibitorsPagination = createSelector(
  selectEventExhibitors,
  ({ pagination }) => {
    return calcPaginationState(pagination);
  },
);

export const selectEventTargetAudiences = createSelector(selectEvent, (event) => {
  return event?.targetAudiences || [];
});
export const selectEventBenefits = createSelector(selectEvent, (event) => {
  return event?.benefits || [];
});
export const selectEventFeatures = createSelector(selectEvent, (event) => {
  return event?.services || [];
});
