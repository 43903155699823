import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'configs/routes';
import { useAuth, useCurrentUser, useSearchParams } from 'hooks';

const SESSION_KEY = 'LINQBIO_COMPLETE_INIT';
export const PERCENT_PROFILE_TO_COMPLETENESS = 70;
export const KEY_DO_NOT_SHOW_COMPLETENESS_PAGE = 'LINQBIO_DO_NOT_SHOW_COMPLETENESS_PAGE';

const Component: React.FC = () => {
  const user = useCurrentUser();
  const { replace } = useHistory();
  const { pathname, search } = useLocation();
  const { query } = useSearchParams();
  const redirect = query['redirect'] || `${pathname}${search}`;

  useEffect(() => {
    const session = sessionStorage.getItem(SESSION_KEY);
    const doNotShow = Boolean(localStorage.getItem(KEY_DO_NOT_SHOW_COMPLETENESS_PAGE));

    if (user.profileCompleteness <= PERCENT_PROFILE_TO_COMPLETENESS && !session && !doNotShow) {
      sessionStorage.setItem(SESSION_KEY, 'true');
      replace(APP_ROUTES.FULL_EDIT.getConfig()({ redirect }));
    }
    // eslint-disable-next-line
  }, []);

  return null;
};

export const ProviderComplete: React.FC = ({ children }) => {
  const { user } = useAuth();
  return (
    <>
      {user && <Component />}
      {children}
    </>
  );
};
