import { api, axiosBaseGetQuery, uploadHtml, uploadImage } from 'services/utils';
import {
  API_CUSTOMER_NETWORKING_EVENTS,
  CustomerNetworkingEvent,
  CustomerNetworkingEventPatch,
  CustomerNetworkingEventPost,
} from './models';
import { BaseParams, PatchModel } from 'utils';
import { AxiosResponse } from 'axios';
import { ServiceNetworkingEventVideos } from 'services/networking-event-videos';
import { ServiceNetworkingEventImages } from 'services/networking-event-images';
import { ServiceNetworkingEventResources } from 'services/networking-event-resources';
import {
  NetworkingEventRepresentative,
  ServiceNetworkingUserProfiles,
} from 'services/networking-event-user-profiles';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

class Service {
  async create(data: CustomerNetworkingEventPost) {
    return api.post<CustomerNetworkingEventPost, AxiosResponse<string>>(
      API_CUSTOMER_NETWORKING_EVENTS.CREATE,
      data,
    );
  }

  async getByID(customerNetworkingEventID: string) {
    const [
      result,
      { data: videos },
      { data: images },
      { data: resources },
      { data: representatives },
    ] = await Promise.all([
      api.get<CustomerNetworkingEvent>(
        API_CUSTOMER_NETWORKING_EVENTS.GET_BY_ID(customerNetworkingEventID),
      ),
      ServiceCustomerNetworkingEvents.getVideos(customerNetworkingEventID),
      ServiceCustomerNetworkingEvents.getImages(customerNetworkingEventID),
      ServiceCustomerNetworkingEvents.getResources(customerNetworkingEventID),
      ServiceCustomerNetworkingEvents.getRepresentatives(customerNetworkingEventID),
    ]);

    return {
      ...result,
      data: {
        ...result.data,
        _videos: videos,
        _images: images,
        _resources: resources,
        _representatives: representatives,
      },
    };
  }
  async getByEventID(networkingEventID: string, params?: BaseParams) {
    return api.get<CustomerNetworkingEvent>(
      API_CUSTOMER_NETWORKING_EVENTS.GET_BY_EVENT_ID(networkingEventID),
      { params },
    );
  }

  async patch(
    oldData: PatchModel<CustomerNetworkingEventPatch, 'customerNetworkingEventID'>,
    newData: PatchModel<CustomerNetworkingEventPatch, 'customerNetworkingEventID'>,
  ) {
    const { customerNetworkingEventID } = oldData;
    const { logo, _videos, _images, contentHtml1, ...rest } = newData;

    const { filePath: logoURL } = await uploadImage(oldData.logo, newData.logo);

    const { filePath: contentHtml1URL } = await uploadHtml(
      oldData.contentHtml1,
      newData.contentHtml1,
      customerNetworkingEventID,
    );

    return api.patch<CustomerNetworkingEvent>(
      API_CUSTOMER_NETWORKING_EVENTS.PATCH(oldData.customerNetworkingEventID),
      {
        ...rest,
        logo: logoURL,
        contentHtml1: contentHtml1URL,
      },
    );
  }

  async getRelativeData() {}
  async getResources(customerNetworkingEventID: string) {
    const result = await ServiceNetworkingEventResources.getAllDynamic(customerNetworkingEventID, {
      orderBy: 'rank asc',
    });
    return { ...result, data: result.data.value };
  }

  async getRepresentatives(customerNetworkingEventID: string) {
    const result = await ServiceNetworkingUserProfiles.getAllDynamic(customerNetworkingEventID);
    return { ...result, data: result.data.value };
  }

  async getImages(customerNetworkingEventID: string) {
    const result = await ServiceNetworkingEventImages.getAllDynamic(customerNetworkingEventID, {
      orderBy: 'rank asc',
    });
    return { ...result, data: result.data.value };
  }

  async getVideos(customerNetworkingEventID: string) {
    const result = await ServiceNetworkingEventVideos.getAllDynamic(customerNetworkingEventID, {
      orderBy: 'rank asc',
    });
    return { ...result, data: result.data.value };
  }
}
export const ServiceCustomerNetworkingEvents = new Service();

export const customerNetworkingEventApi = createApi({
  reducerPath: 'customerNetworkingEventApi',
  baseQuery: axiosBaseGetQuery(),
  tagTypes: ['MyCustomerEvent'],
  endpoints: (builder) => ({
    getMyCustomerEvent: builder.query<CustomerNetworkingEvent, string>({
      query: (networkingEventID) => ({
        url: API_CUSTOMER_NETWORKING_EVENTS.GET_BY_EVENT_ID(networkingEventID),
      }),
    }),
    getMyCustomerEventRepresentatives: builder.query<NetworkingEventRepresentative[], string>({
      queryFn: async (networkingEventID) => {
        try {
          const result = await ServiceCustomerNetworkingEvents.getByEventID(networkingEventID, {
            select: 'customerNetworkingEventID',
          });
          const { data } = await ServiceCustomerNetworkingEvents.getRepresentatives(
            result.data.customerNetworkingEventID,
          );
          return { data, error: undefined };
        } catch (e) {
          return { error: e as Error };
        }
      },
      providesTags: (res, err, networkingEventID) => [
        { type: 'MyCustomerEvent', networkingEventID },
      ],
    }),
  }),
});

export * from './models';
