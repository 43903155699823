enum CATEGORIES {
  MESSAGE = 'message',
  SEARCH = 'search',
  INVITATION = 'invitation',
  PROFILE = 'profile',
  COMPANIES = 'companies',
  PREFERENCES = 'preferences',
  CHAT = 'chat',
}

type TLabel<P extends any = void> = string | ((p: P) => string);
const createGaEvent = <P extends any = void>(
  category: CATEGORIES,
  action: string,
  label: TLabel<P>,
) => {
  return (payload: P) => {
    return {
      category,
      action,
      label: typeof label === 'function' ? label(payload) : label,
    };
  };
};

export const eventMessageNew = createGaEvent(
  CATEGORIES.MESSAGE,
  'click',
  'new message button click',
);
export const eventMessageSent = createGaEvent(CATEGORIES.MESSAGE, 'click', 'sent message click');
export const eventMessageSentChat = createGaEvent(
  CATEGORIES.MESSAGE,
  'click',
  'sent chat message button click',
);

export const eventSearchNetworkItem = createGaEvent<string>(
  CATEGORIES.SEARCH,
  'click',
  (p) => `searched ${p}`,
);
export const eventSearchLatestUpdate = createGaEvent(
  CATEGORIES.SEARCH,
  'click',
  'searched latest updates',
);
export const eventSearchCategory = createGaEvent(
  CATEGORIES.SEARCH,
  'click',
  'searched in category',
);
export const eventSearchCompanies = createGaEvent(
  CATEGORIES.SEARCH,
  'click',
  'searched in companies',
);

export const eventInvitationShareBar = createGaEvent(CATEGORIES.INVITATION, 'click', 'share bar');
export const eventInvitationSent = createGaEvent(CATEGORIES.INVITATION, 'click', 'sent invitation');

export const eventProfileMember = createGaEvent(CATEGORIES.PROFILE, 'click', 'view member profile');
export const eventProfileMy = createGaEvent(CATEGORIES.PROFILE, 'click', 'view my profile');
export const eventProfileCompany = createGaEvent(
  CATEGORIES.PROFILE,
  'click',
  'member profile click company',
);

export const eventCompanyView = createGaEvent(CATEGORIES.COMPANIES, 'click', 'view company');
export const eventCompanyMember = createGaEvent(
  CATEGORIES.COMPANIES,
  'click',
  'company page click member',
);
export const eventCompanyAddExistingMember = createGaEvent(
  CATEGORIES.COMPANIES,
  'click',
  'edit company - add existing member',
);
export const eventCompanyAddNewMember = createGaEvent(
  CATEGORIES.COMPANIES,
  'click',
  'edit company - add new member',
);
export const eventCompanyDeleteMember = createGaEvent(
  CATEGORIES.COMPANIES,
  'click',
  'edit company - delete member',
);
export const eventCompanyMakeAdmin = createGaEvent(
  CATEGORIES.COMPANIES,
  'click',
  'edit company - make admin',
);
export const eventCompanyRemoveAdmin = createGaEvent(
  CATEGORIES.COMPANIES,
  'click',
  'edit company - remove admin',
);
export const eventCompanyResendInvite = createGaEvent(
  CATEGORIES.COMPANIES,
  'click',
  'edit company - resend invite',
);

export const eventPreferencesViewCompanies = createGaEvent(
  CATEGORIES.PREFERENCES,
  'click',
  'preferences - view my companies',
);
export const eventPreferencesSetPosition = createGaEvent(
  CATEGORIES.PREFERENCES,
  'click',
  'preference - view my companies - set position',
);
export const eventPreferencesEditCompany = createGaEvent(
  CATEGORIES.PREFERENCES,
  'click',
  'preference - view my companies - edit company',
);
export const eventPreferencesLeaveCompany = createGaEvent(
  CATEGORIES.PREFERENCES,
  'click',
  'preference - view my companies - leave company',
);

export const eventChatStar = createGaEvent(CATEGORIES.CHAT, 'click', 'start chat');
