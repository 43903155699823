import { API_LABELS, Label, Language } from './models';
import { api } from '../utils';

class Service {
  async getLanguages() {
    return api.get<Language[]>(API_LABELS.GET_LANGUAGES);
  }

  async getLabels(languageID: number) {
    return api.get<{ value: Label[] }>(API_LABELS.GET_LABELS, {
      params: { filter: `languageID="${languageID}"` },
    });
  }

  async getDefaultLanguageID() {
    return api.get<number>(API_LABELS.GET_DEFAULT_LANGUAGE);
  }
}

export const ServiceLabels = new Service();
export * from './models';
