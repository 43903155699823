import { createAsyncThunk } from '@reduxjs/toolkit';
import { StoreState } from '../index';
import { parseErrorData } from 'utils';
import { ServiceNetworkingEvents } from 'services/networking-events';
import { selectEvent } from 'store/event/selectors';
import { getEventActions } from 'store/event/slice';

export const actionEventJoin = createAsyncThunk<
  { networkingEventID: string },
  { networkingEventID: string; networkingEventCategoryID: string },
  { state: StoreState; serializedErrorType: Error }
>(
  `EVENT/actionEventJoin`,
  async ({ networkingEventID, networkingEventCategoryID }, { dispatch, getState }) => {
    try {
      await ServiceNetworkingEvents.joinToEvent(networkingEventID, networkingEventCategoryID);
      const { data } = await ServiceNetworkingEvents.getNetworkingEvent(networkingEventID);
      const event = selectEvent(getState());

      if (data.id === event?.id) {
        await dispatch(getEventActions.success(data));
      }
      return { networkingEventID };
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionEventAddToCalendar = createAsyncThunk<
  void,
  { networkingEventID: string },
  { state: StoreState; serializedErrorType: Error }
>(`EVENT/actionEventAddToCalendar`, async ({ networkingEventID }) => {
  try {
    const { data } = await ServiceNetworkingEvents.addEventToCalendar(networkingEventID);

    window.open(data, '_blank');
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
