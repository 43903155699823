import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import ReorderIcon from '@material-ui/icons/Reorder';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import { useTranslate } from 'hooks';
import style from './index.module.scss';
import clsx from 'clsx';

interface Props {
  value: 'list' | 'cards';
  onChange: (v: this['value']) => void;
  count: number;
  className?: string;
}

export const TabExhibitorsTools: React.FC<Props> = ({ count, value, onChange, className }) => {
  const { tp } = useTranslate();
  return (
    <div className={clsx(style.wrap, className)}>
      <Typography component={'div'} style={{ color: 'rgba(0,0,0,0.4)' }}>
        {tp('event-exhibitors-results', { count })}
      </Typography>
      <div className={style.control}>
        <IconButton
          onClick={() => onChange('list')}
          className={clsx(style.item, value === 'list' && style.selected)}
          size={'small'}
        >
          <ReorderIcon />
        </IconButton>
        <IconButton
          onClick={() => onChange('cards')}
          className={clsx(style.item, value === 'cards' && style.selected)}
          size={'small'}
        >
          <ViewModuleIcon />
        </IconButton>
      </div>
    </div>
  );
};
