import React, { useCallback } from 'react';
import ImgLeft from 'images/bg-companies.png';
import ImgRight from 'images/logo-small.svg';

import styleMain from '../index.module.scss';
import styleCustom from './index.module.scss';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'configs/routes';
import { useTranslate } from 'hooks';
import clsx from 'clsx';

export const BannerAddCompany: React.FC = () => {
  const { t } = useTranslate();
  const { push } = useHistory();
  const goToCompany = useCallback(() => {
    push(APP_ROUTES.COMPANY_EDITOR.getConfig()());
  }, [push]);
  return (
    <div className={clsx(styleMain.wrap, styleCustom.wrap)} onClick={goToCompany}>
      <div className={styleMain.banner}>
        <div className={clsx(styleMain.left, styleCustom.left)}>
          <img src={ImgLeft} alt={''} />
        </div>
        <div>
          <h2 className={styleCustom.title}>{t('banner-add-company-title')}</h2>
        </div>
        <div className={clsx(styleMain['right-wrap'], 'right-wrap')}>
          <div className={clsx(styleMain.right, styleMain.ripple, styleCustom.right)}>
            <img src={ImgRight} alt={''} />
          </div>
        </div>
      </div>
    </div>
  );
};
