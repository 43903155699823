import { Badge, Button, Collapse, Grid } from '@material-ui/core';
import { AppInput } from 'components/app-input';
import { CommonSelect } from 'common';
import React, { useCallback, useEffect } from 'react';
import styles from './index.module.scss';
import {
  useCollapseItems,
  useDecorator,
  useFormController,
  useIsMobile,
  useOpen,
  useSourceCountries,
  useSourceEventCategories,
  useTranslate,
} from 'hooks';
import { CheckboxGroup } from 'components/checkbox-group';
import { AnimationText } from 'components/animation-text';
import { DialogHelpExhibitor } from 'components/dialogs/help-exhibitor';
import { AppButtonFilter } from 'components/app-buttons/button-filter';
import { useForm } from 'react-hook-form';
import { getHandlerKayPress } from 'utils/handlers';

interface InitData {
  search: string;
  networkingEventCategoryIDs: string[];
  countryID: string;
}

interface Props {
  eventID: string;
  isShowClear?: boolean;
  initData?: InitData;
  onSetFilters?: (v: Partial<InitData>) => void;
  onReset?: () => void;
}

const CATEGORIES_TO_SHOW_DESK = 8;
const CATEGORIES_TO_SHOW_MOBILE = 10;

export const AsideFilters: React.FC<Props> = ({
  eventID,
  initData,
  onSetFilters,
  isShowClear,
  onReset,
}) => {
  const { t } = useTranslate();
  const { control, getValues, reset } = useForm({
    defaultValues: initData,
  });
  useEffect(() => {
    reset(initData);
  }, [reset, initData]);

  const updateFiltersAndRefresh = useCallback(() => {
    if (JSON.stringify(initData) !== JSON.stringify(getValues())) {
      onSetFilters && onSetFilters(getValues());
    }
  }, [getValues, initData, onSetFilters]);

  const { field: fieldSearch } = useFormController({
    control,
    name: 'search',
  });

  const { field: fieldCategories } = useFormController({
    control,
    name: 'networkingEventCategoryIDs',
  });

  const { data: sourceCategories } = useSourceEventCategories(eventID);
  const isMobile = useIsMobile();
  const showedCategories = isMobile ? CATEGORIES_TO_SHOW_MOBILE : CATEGORIES_TO_SHOW_DESK;
  const { isOpen, onToggle, showedItems, countSelectedHidden } = useCollapseItems(
    fieldCategories.value,
    sourceCategories,
    showedCategories,
  );

  const { field: fieldCountryID } = useFormController({
    control,
    name: 'countryID',
  });
  const sourceCountries = useSourceCountries();

  const { isOpen: isOpenHelp, onClose: handleCloseHelp, onOpen: handleOpenHelp } = useOpen();
  const { isOpen: isOpenFilters, onToggle: onToggleFilters } = useOpen();

  return (
    <>
      <AppInput
        style={{ marginBottom: isMobile ? 0 : '2rem' }}
        InputLabelProps={{ shrink: true }}
        label={t('search')}
        {...fieldSearch}
        onKeyPress={getHandlerKayPress('Enter', updateFiltersAndRefresh)}
        placeholder={t('search-by-keywords')}
      />
      {isMobile && (
        <div className={styles.filterBtn}>
          <AppButtonFilter isOpen={isOpenFilters} onToggle={onToggleFilters} />
        </div>
      )}
      <Collapse in={!isMobile || isOpenFilters}>
        <AnimationText>
          <CheckboxGroup
            label={t('search-event-category')}
            {...fieldCategories}
            options={showedItems}
            onChange={useDecorator(fieldCategories.onChange, updateFiltersAndRefresh)}
          />
        </AnimationText>
        <div style={{ margin: '1rem 0 -0.6rem 1rem' }}>
          {sourceCategories.length > showedCategories && (
            <Badge badgeContent={countSelectedHidden} color={'secondary'}>
              <Button size={'small'} variant={'outlined'} color={'primary'} onClick={onToggle}>
                {t(isOpen ? 'view-less' : 'view-more')}
              </Button>
            </Badge>
          )}
        </div>
        <div
          className={styles.countryFilter}
          style={{ textTransform: 'lowercase', margin: '1.5rem 0' }}
        >
          <CommonSelect
            label={t('search-country')}
            placeholder={t('all')}
            source={sourceCountries.data}
            loading={sourceCountries.isLoading}
            {...fieldCountryID}
            onChange={useDecorator(fieldCountryID.onChange, updateFiltersAndRefresh)}
          />
        </div>
      </Collapse>

      <Grid container spacing={1} alignItems={'center'}>
        <Grid item xs={6}>
          <Button fullWidth variant={'outlined'} color={'primary'} onClick={handleOpenHelp}>
            {t('need-help')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant={'outlined'}
            color={'primary'}
            fullWidth={true}
            disabled={!isShowClear}
            onClick={onReset}
          >
            {t('reset')}
          </Button>
        </Grid>
      </Grid>

      {isOpenHelp && <DialogHelpExhibitor onClose={handleCloseHelp} />}
    </>
  );
};
