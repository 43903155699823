import React, { useRef } from 'react';
import { Divider, List, ListItem, MenuItem, MenuList, Popover } from '@material-ui/core';
import EventNoteIcon from '@material-ui/icons/EventNote';
import style from './index.module.scss';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES, PREFERENCES_PAGE_TABS } from 'configs/routes';
import { useOpen, useTranslate } from 'hooks';

const { PREFERENCES } = APP_ROUTES;
export const HeaderIconEvents: React.FC = () => {
  const { t } = useTranslate();
  const { isOpen, onOpen, onClose } = useOpen();
  const ref = useRef<HTMLUListElement>(null);
  const { push } = useHistory();
  return (
    <>
      <List ref={ref} disablePadding={true}>
        <ListItem button onClick={onOpen}>
          <EventNoteIcon className={style.icon} />
          <span className={style.title}>{t('my-events')}</span>
        </ListItem>
      </List>
      <Popover
        open={isOpen}
        anchorEl={ref.current}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList className={style.menu}>
          <MenuItem
            onClick={() => {
              onClose();
              push(
                PREFERENCES.getConfig()({
                  tab: PREFERENCES_PAGE_TABS.EVENTS,
                  'tab-events': 'exhibitor',
                }),
              );
            }}
          >
            {t('i-am-exhibitor')}
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              onClose();
              push(
                PREFERENCES.getConfig()({
                  tab: PREFERENCES_PAGE_TABS.EVENTS,
                  'tab-events': 'visitor',
                }),
              );
            }}
          >
            {t('i-am-visitor')}
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};
