import React, { CSSProperties, memo } from 'react';
import { CircularProgress } from '@material-ui/core';

interface Props {
  active?: boolean;
  zIndex?: number;
  background?: string;
  size?: number;
  style?: CSSProperties;
}

export const Loading = memo<Props>(
  ({ active = true, style, zIndex = 1000, background = 'rgba(255,255,255,.5)', size = 50 }) => {
    if (!active) return null;
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: zIndex,
          background: background,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...style,
        }}
      >
        <CircularProgress size={size} />
      </div>
    );
  },
);
