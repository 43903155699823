import { api, uploadFile } from 'services/utils';
import { API_NETWORKING_EVENT_RESOURCES, NetworkingEventResource } from './models';
import { BaseParams } from 'utils';

export * from './models';

class Service {
  async getLastRank(customerNetworkingEventID: string) {
    const response = await ServiceNetworkingEventResources.getAllDynamic(
      customerNetworkingEventID,
      {
        take: 1,
      },
    );
    const rank = response.data.value[0]?.rank || 0;
    return { ...response, data: rank };
  }

  async getAllDynamic(customerNetworkingEventID: string, params?: BaseParams) {
    return api.get<{ value: NetworkingEventResource[] }>(
      API_NETWORKING_EVENT_RESOURCES.GET_ALL_DYNAMIC,
      {
        params: {
          customerNetworkingEventID: customerNetworkingEventID,
          ...params,
        },
      },
    );
  }

  async post(data: NetworkingEventResource) {
    const { customerNetworkingEventID } = data;

    const { data: rank } = await ServiceNetworkingEventResources.getLastRank(
      customerNetworkingEventID,
    );
    const { filePath: linkURL } = await uploadFile('', data.linkURL);
    return api.post(API_NETWORKING_EVENT_RESOURCES.CREATE, {
      ...data,
      linkURL,
      rank: rank + 1,
      id: undefined,
    });
  }

  async patch() {}

  async delete(id: string) {
    return api.delete(API_NETWORKING_EVENT_RESOURCES.DELETE(id));
  }
}

export const ServiceNetworkingEventResources = new Service();
