import { NetworkingEventVideo } from 'services/networking-event-videos';
import { NetworkingEventImage } from 'services/networking-event-images';
import { NetworkingEventResource } from 'services/networking-event-resources';
import { NetworkingEventRepresentative } from 'services/networking-event-user-profiles';

const API = 'CustomerNetworkingEvents';

export const API_CUSTOMER_NETWORKING_EVENTS = {
  CREATE: `${API}/CreateCustomer`,
  GET_BY_EVENT_ID: (networkingEventID: string) =>
    `${API}/GetCustomerNetworkingEvent/${networkingEventID}`,
  GET_BY_ID: (customerNetworkingEventID: string) =>
    `${API}/GetCustomerNetworkingEventByID/${customerNetworkingEventID}`,
  PATCH: (customerNetworkingEventID: string) =>
    `${API}/PatchCustomerNetworkingEvent/${customerNetworkingEventID}`,
};

export class CustomerNetworkingEventPost {
  customerCompanyName = '';
  customerCountryID?: number;
  networkingEventCategoryID = '';
  networkingEventID = '';
  numberOfPresenters = 0;
  extraPresenterPrice = 0;
  totalPrice = 0;
}

export class CustomerNetworkingEventPatch {
  customerNetworkingEventID!: string;
  networkingEventID!: string;
  customerCompanyWebsite = '';
  linkURL = '';
  logo = '';
  displayVideos = false;
  videosTitle = '';
  displayImages = false;
  imagesTitle = '';
  displayResources = false;
  resourcesTitle = '';
  displayContent1 = false;
  contentTitle1 = '';
  contentHtml1 = '';
  // displayContent2 = false;
  // contentTitle2 = '';
  // contentHtml2 = '';
  confirmContent = false;
  customerCountryID!: number;
  networkingEventCategoryID = '';
  _videos?: NetworkingEventVideo[];
  _images?: NetworkingEventImage[];
  _resources?: NetworkingEventResource[];
  _representatives?: NetworkingEventRepresentative[];
}

export interface CustomerNetworkingEvent extends CustomerNetworkingEventPatch {
  customerNetworkingEventID: string;
  customerCountryTitle: string;
  customerCountryFlag: string;
  paid: boolean;
  paymentDate: string;
  customerID: string;
  customerCompanyWebsite: string;
  linkURL: string;
  logo: string;
  displayVideos: boolean;
  videosTitle: string;
  displayImages: boolean;
  imagesTitle: string;
  displayResources: boolean;
  resourcesTitle: string;
  displayContent1: boolean;
  contentTitle1: string;
  contentHtml1: string;
  displayContent2: boolean;
  contentTitle2: string;
  contentHtml2: string;
  confirmContent: boolean;
  customerCompanyName: string;
  customerCountryID: number;
  networkingEventCategoryID: string;
  networkingEventID: string;
  numberOfPresenters: number;
  extraPresenterPrice: number;
  totalPrice: number;
}
