import React from 'react';
import styleMain from '../index.module.scss';
import ImgPerson from 'images/bg-personal.png';
import ImgCompany from 'images/bg-companies.png';
import styleCustom from './index.module.scss';
import { useTranslate } from 'hooks';
import clsx from 'clsx';

export const BannerAddMember: React.FC = () => {
  const { t } = useTranslate();
  return (
    <div className={styleMain.wrap}>
      <div className={styleMain.banner}>
        <div className={styleMain['left']}>
          <img src={ImgPerson} alt={''} />
        </div>
        <div>
          <h2 className={styleCustom.title}>{t('banner-add-member-title')}</h2>
          <p className={styleCustom.sub}>{t('banner-add-member-title-sub')}</p>
        </div>
        <div className={styleMain['right-wrap']}>
          <div className={clsx(styleMain.right, styleMain.ripple, styleCustom.right)}>
            <img src={ImgCompany} alt={''} />
          </div>
        </div>
      </div>
    </div>
  );
};
