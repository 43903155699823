export const calcFilterFullName = ({ value }: { value: string }) => {
  return `(firstName.replace(" ", String.Empty) + lastName.replace(" ", String.Empty)).contains("${value.replace(
    / /gi,
    '',
  )}")`;
};
export const calcFilterCompanyName = ({ value }: { value: string }) => {
  return `(companyName != null && companyName.trim().contains("${value.trim()}"))`;
};
export const calcFilterSelect = <Model>({ name, value }: { name: keyof Model; value: string }) => {
  return value ? `${name} == ${value}` : undefined;
};
export const calcSearchMember = <Model>({ name, value }: { name: keyof Model; value: string }) => {
  const fields = ['fullName', 'position'] as const;

  return value
    ? `(${fields
        .map((field) => {
          switch (field) {
            case 'fullName':
              return calcFilterFullName({ value });
            default:
              return `${field}.contains("${value}")`;
          }
        })
        .join(' || ')})`
    : undefined;
};
export const calcSearchCompany = <Model>({ name, value }: { name: keyof Model; value: string }) => {
  const fields = ['companyName', 'companyWebsite'] as const;
  return value
    ? `(${fields
        .map((field) => {
          switch (field) {
            case 'companyName':
              return calcFilterCompanyName({ value });
            default:
              return `${field}.contains("${value}")`;
          }
        })
        .join(' || ')})`
    : undefined;
};
export const defaultQueryConverter = (v: string | undefined) => {
  if (v === 'true') {
    return true;
  }
  if (v === 'false') {
    return false;
  }
  if (!isNaN(Number(v))) {
    return Number(v);
  }
  if (v) {
    return String(v);
  }
  return null;
};
export const searchInclude = (value: string | null | undefined | number, include: string) => {
  const _value = String(value || '').toLocaleLowerCase();
  const _include = String(include).toLocaleLowerCase();
  return _value.includes(_include);
};

export const templateValue = (value: string, templates: string[]) => {
  let _templates = Array.from(templates);
  const patterns = {
    '#': /\d/,
  };
  const _value = Array.from(String(value || ''));
  return _value.reduce((result, char, i) => {
    _templates = _templates.filter((template) => {
      // @ts-ignore
      const pattern = patterns[template[i]];
      return pattern ? pattern.test(char) : template[i] === char;
    });
    const isMatch = !!_templates.length;
    result += isMatch ? char : '';
    return result;
  }, '');
};
