import React, { useMemo, useState } from 'react';
import { useChatOnlineUsers } from '../../hooks';
import { AppScrollbars } from 'components/app-scrollbar';
import { CustomChatDialogItem } from '../dialog-item';
import style from './index.module.scss';
import SearchIcon from '@material-ui/icons/Search';
import { AppInput } from 'components/app-input';
import { filterSmartMultiFields } from 'utils/front-filters';
import { CustomChatNoConversations } from '../no-conversatins';
import { useAppSelector, useTranslate } from 'hooks';
import { Loading } from 'components/loading';
import { selectChatConversationsList } from 'components/custom-chat/store';

interface Props {
  conversationID: string | null;
  onSelectConversation: (v: string) => void;
  isLoading: boolean;
}

export const CustomChatConversations: React.FC<Props> = ({
  conversationID,
  isLoading,
  onSelectConversation,
}) => {
  const [search, setSearch] = useState('');

  const conversationsList = useAppSelector(selectChatConversationsList);

  const { checkIsOnline } = useChatOnlineUsers();

  const filteredList = useMemo(() => {
    return conversationsList.filter(({ conversation: { appUserFirstName, appUserLastName } }) => {
      return filterSmartMultiFields([appUserFirstName, appUserLastName], search);
    });
  }, [conversationsList, search]);

  const { t } = useTranslate();

  return (
    <div className={style.wrap}>
      <div className={style.panel}>
        <AppInput
          placeholder={t('search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          fullWidth
          InputProps={{
            endAdornment: <SearchIcon color={'primary'} />,
          }}
          disabled={isLoading}
        />
      </div>
      <AppScrollbars autoHeightMin="100%" autoHeightMax="100%">
        <div className={style.list}>
          {!isLoading && filteredList.length === 0 && <CustomChatNoConversations />}
          {filteredList.map(({ conversation: item, isTyping }) => {
            return (
              <CustomChatDialogItem
                key={item.appUserID}
                conversationID={item.conversationID}
                isOnline={checkIsOnline(item.appUserID)}
                id={item.conversationID}
                selected={item.conversationID === conversationID}
                firstName={item.appUserFirstName}
                lastName={item.appUserLastName}
                lastMessage={item.lastMessage}
                isNotRead={item.notRead}
                photo={item.appUserProfilePicture}
                onClick={onSelectConversation}
                isTyping={isTyping}
              />
            );
          })}
          <Loading active={isLoading} background={'transparent'} />
        </div>
      </AppScrollbars>
    </div>
  );
};
