import { ButtonClose } from 'components/app-buttons';
import { DialogTitle } from '@material-ui/core';
import React from 'react';
import './_style.scss';
import clsx from 'clsx';

interface HeaderSubmitProps {
  className?: string;
  title: string;

  onClose(): void;
}

export const HeaderSubmit: React.FC<HeaderSubmitProps> = ({ className, title, onClose }) => {
  const classNames = clsx('header-submit', className);
  return (
    <DialogTitle className={classNames}>
      <span>{title}</span>
      <ButtonClose className={'header-submit-close'} onClick={onClose} size={'small'} />
    </DialogTitle>
  );
};
