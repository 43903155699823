import React from 'react';
import style from './index.module.scss';
import clsx from 'clsx';
import { DateBox } from './components/date-box';
import { Divider, Fab } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';

interface EventDatesProps {
  fromDate: string | null | undefined;
  toDate: string | null | undefined;
  className?: string;
  variant?: 'light' | 'dark';
}

interface EventDateTimezoneProps {
  toDate: string | null | undefined;
  timeZoneID: string | null | undefined;
  fromDate: string | null | undefined;
}

export const EventDates: React.FC<EventDatesProps> = ({
  fromDate,
  variant = 'dark',
  toDate,
  className,
}) => {
  return (
    <div className={clsx(style.wrap, style[variant], className)}>
      <DateBox label={'time-start'} date={fromDate} variant={variant} />
      <Divider orientation={'vertical'} className={clsx(style.divider)} />
      <DateBox label={'time-end'} date={toDate} variant={variant} />
    </div>
  );
};

export const EventDateWithTimezone: React.FC<EventDateTimezoneProps> = ({
  fromDate,
  toDate,
  timeZoneID,
}) => {
  return (
    <div className={clsx(style.wrap, style.light, style.wrapTimeZone)}>
      <DateBox label={'time-start'} date={fromDate} />
      <DateBox label={'time-end'} date={toDate} />
      <Fab className={style.fab}>
        <EventIcon />
      </Fab>
    </div>
  );
};
