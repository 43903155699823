import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useGaPageView} from "../../hooks";

export const GARouteWrap: React.FC = ({children}) => {
  const { pathname } = useLocation()
  const registerPage = useGaPageView()

  useEffect(() => {
    registerPage(pathname)
  }, [pathname, registerPage])

  return (
    <>
      {children}
    </>
  )
}
