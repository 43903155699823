import React from 'react';
import styleMain from '../index.module.scss';
import ImgCompany from 'images/bg-companies.png';
import ImgGroup from 'images/bg-groups.svg';
import styleCustom from './index.module.scss';
import { useTranslate } from 'hooks';
import clsx from 'clsx';

interface Props {
  onClick: () => void;
}

export const BannerBecameSponsor: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslate();
  return (
    <div className={styleCustom.wrap} onClick={onClick}>
      <div className={styleMain.banner}>
        <div className={styleMain['left']}>
          <img src={ImgCompany} alt={''} />
        </div>
        <div>
          <h2 className={styleCustom.title}>{t('banner-became-sponsor-title')}</h2>
        </div>
        <div className={styleMain['right-wrap']}>
          <div className={clsx(styleMain.right, styleMain.ripple, styleCustom.right)}>
            <img src={ImgGroup} alt={''} />
          </div>
        </div>
      </div>
    </div>
  );
};
