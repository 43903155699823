import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const _ScrollTop: React.FC<RouteComponentProps> = ({ children, history, location }) => {
  const prevPath = location.pathname;
  useEffect(() => {
    const subscribe = (nextLocation: RouteComponentProps['location']) => {
      if (prevPath !== nextLocation.pathname) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };
    const unregister = history.listen(subscribe);
    return () => {
      unregister();
    };
  }, [history, prevPath]);
  return <>{children}</>;
};
export const ScrollPageTop = withRouter(_ScrollTop);
