import React, { useMemo, useState } from 'react';
import { Loading } from 'components/loading';
import { LayoutPageContext, ModelLayoutPageContext } from './context';
import { LayoutError } from './layout-error';
import { GARouteWrap } from '../google-analytics';
import clsx from 'clsx';
import './_style.scss';
import { SerializedError } from '@reduxjs/toolkit';

interface LayoutPageProps {
  isLoading?: boolean;
  className?: string;
}

export const LayoutPage: React.FC<LayoutPageProps> = ({
  isLoading = false,
  className,
  children,
}) => {
  const [error, setError] = useState<Error | SerializedError | null>(null);
  const [_loading, setLoading] = useState(false);

  const valueContext = useMemo<ModelLayoutPageContext>(() => {
    return {
      setLoading,
      isLoading: isLoading || _loading,
      error,
      setError,
    };
  }, [error, isLoading, _loading]);

  const classNames = clsx('layout-page', className);
  return (
    <GARouteWrap>
      <LayoutPageContext.Provider value={valueContext}>
        <div className={classNames}>
          {error && <LayoutError error={error} />}
          {!error && (
            <>
              {children}
              <Loading active={isLoading || _loading} />
            </>
          )}
        </div>
      </LayoutPageContext.Provider>
    </GARouteWrap>
  );
};

export * from './context';
