import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useTranslate } from 'hooks';
import clsx from 'clsx';
import style from './index.module.scss';

interface Props extends ButtonProps {
  className?: string;
}

export const BackButton: React.FC<Props> = ({ className, ...rest }) => {
  const { t } = useTranslate();
  return (
    <Button
      color={'secondary'}
      className={clsx(className, style.root)}
      startIcon={<ChevronLeftIcon />}
      {...rest}
    >
      {t('back')}
    </Button>
  );
};
