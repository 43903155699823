import React from 'react';
import CheckIcon from '@material-ui/icons/Check';

import style from './index.module.scss';

interface Props {
  title: string;
}

export const GeneralTarget: React.FC<Props> = ({ title }) => {
  return (
    <div className={style.wrap}>
      <div className={style.icon}>
        <CheckIcon />
      </div>
      <div className={style.cont}>{title}</div>
    </div>
  );
};
