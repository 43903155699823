import {
  actionChannel,
  call,
  debounce,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'modules/typed-saga';
import { actionEventBuilderAutoSave, actionEventBuilderGetEvent } from 'store/event-builder/slice';
import { ServiceCustomerNetworkingEvents } from 'services/customer-networking-event';
import { parseErrorData } from 'utils';
import { workerErrorNotifyThunk } from 'store/_utils';
import {
  actionEventBuilderCreateEvent,
  actionEventBuilderImagesCreate,
  actionEventBuilderImagesRemove,
  actionEventBuilderRepayEvent,
  actionEventBuilderRepresentativesInvite,
  actionEventBuilderVideosCreate,
  actionEventBuilderVideosRemove,
} from 'store/event-builder/actions';

function* workerGetEvent(action: ReturnType<typeof actionEventBuilderGetEvent.request>) {
  const {
    payload: { customerNetworkingEventID },
  } = action;
  try {
    const { data } = yield* call(
      ServiceCustomerNetworkingEvents.getByID,
      customerNetworkingEventID,
    );

    yield* put(actionEventBuilderGetEvent.success({ customerNetworkingEventID, data }));
  } catch (e: any) {
    const error = parseErrorData(e);
    yield* put(actionEventBuilderGetEvent.fail({ customerNetworkingEventID, error }));
  }
}

function* sagaAutoSave() {
  const requestChanel = yield* actionChannel(actionEventBuilderAutoSave.request);

  while (true) {
    const action = yield* take(requestChanel);
    yield* call(workerAutoSave, action);
  }
}

function* workerAutoSave(action: ReturnType<typeof actionEventBuilderAutoSave.request>) {
  const {
    payload: { customerNetworkingEventID, old: oldData, new: newData },
  } = action;
  try {
    yield* call(ServiceCustomerNetworkingEvents.patch, oldData, newData);
    yield* put(actionEventBuilderAutoSave.success({ customerNetworkingEventID }));
  } catch (e: any) {
    yield* put(
      actionEventBuilderAutoSave.fail({ customerNetworkingEventID, error: parseErrorData(e) }),
    );
  }
}

function* workerAutoSuccess(action: ReturnType<typeof actionEventBuilderAutoSave.success>) {
  const {
    payload: { customerNetworkingEventID },
  } = action;

  try {
    const { data } = yield* call(
      ServiceCustomerNetworkingEvents.getByID,
      customerNetworkingEventID,
    );
    yield* put(actionEventBuilderGetEvent.success({ customerNetworkingEventID, data }));
  } catch (e) {}
}

function* workerChangeImages(
  action: ReturnType<
    | typeof actionEventBuilderImagesCreate.fulfilled
    | typeof actionEventBuilderImagesRemove.fulfilled
  >,
) {
  const {
    payload: { customerNetworkingEventID },
  } = action;

  try {
    yield* put(
      actionEventBuilderAutoSave.request({
        customerNetworkingEventID,
        old: { customerNetworkingEventID },
        new: { customerNetworkingEventID, displayImages: true },
      }),
    );
  } catch (e) {}
}

function* workerChangeVideos(
  action: ReturnType<
    | typeof actionEventBuilderVideosCreate.fulfilled
    | typeof actionEventBuilderVideosRemove.fulfilled
  >,
) {
  const {
    payload: { customerNetworkingEventID },
  } = action;

  try {
    yield* put(
      actionEventBuilderAutoSave.request({
        customerNetworkingEventID,
        old: { customerNetworkingEventID },
        new: { customerNetworkingEventID, displayVideos: true },
      }),
    );
  } catch (e) {}
}

export const sagasEventBuilder = [
  takeLatest(actionEventBuilderGetEvent.request, workerGetEvent),
  takeLatest(
    [actionEventBuilderImagesCreate.fulfilled, actionEventBuilderImagesRemove.fulfilled],
    workerChangeImages,
  ),
  takeLatest(
    [actionEventBuilderVideosCreate.fulfilled, actionEventBuilderVideosRemove.fulfilled],
    workerChangeVideos,
  ),
  debounce(2000, [actionEventBuilderAutoSave.success], workerAutoSuccess),
  // alert errors thunks
  takeEvery(
    [
      actionEventBuilderRepayEvent.rejected,
      actionEventBuilderCreateEvent.rejected,
      actionEventBuilderImagesCreate.rejected,
      actionEventBuilderImagesRemove.rejected,
      actionEventBuilderVideosCreate.rejected,
      actionEventBuilderVideosRemove.rejected,
      actionEventBuilderRepresentativesInvite.rejected,
    ],
    workerErrorNotifyThunk,
  ),
  sagaAutoSave(),
];
