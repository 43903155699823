import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getAuthTokens } from 'utils';
import { api } from 'services';

interface AxiosInterceptorsTokenProps {}

export const AxiosInterceptorsToken: React.FC<AxiosInterceptorsTokenProps> = ({ children }) => {
  const [isInit, setIsInit] = useState(false);
  useEffect(() => {
    const ids = [api, axios].map((instance) => {
      const id = instance.interceptors.request.use((config) => {
        const { token } = getAuthTokens();
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
      });
      return { id, instance };
    });
    setIsInit(true);

    return () => {
      ids.forEach(({ instance, id }) => {
        instance.interceptors.request.eject(id);
      });
    };
  }, []);

  return <>{isInit && children}</>;
};
