import React from 'react';
import styleMain from '../index.module.scss';
import ImgCompany from 'images/bg-handshake.png';
import ImgGroup from 'images/icon-team-mates.svg';
import styleCustom from './index.module.scss';
import clsx from 'clsx';

interface Props {
  onClick: () => void;
  children: string;
}

export const BannerGeneralSponsor: React.FC<Props> = ({ onClick, children }) => {
  return (
    <div className={styleCustom.wrap} onClick={onClick}>
      <div className={styleMain.banner}>
        <div className={clsx(styleMain['left'], styleCustom.left)}>
          <img src={ImgCompany} alt={''} />
        </div>
        <div>
          <h2 className={styleCustom.title} dangerouslySetInnerHTML={{ __html: children }} />
        </div>
        <div className={styleMain['right-wrap']}>
          <div className={clsx(styleMain.right, styleMain.ripple, styleCustom.right)}>
            <img src={ImgGroup} alt={''} />
          </div>
        </div>
      </div>
    </div>
  );
};
