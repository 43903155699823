import { useCallback, useMemo } from 'react';
import { saveAs } from 'file-saver';

export const useDownload = () => {
  const download = useCallback(
    ({ src, fileName = 'download' }: { src: string; fileName?: string }) => {
      saveAs(src, fileName);
    },
    [],
  );

  return useMemo(() => {
    return {
      download,
    };
  }, [download]);
};
