import React, { useMemo } from 'react';
import { Avatar, AvatarProps, Badge } from '@material-ui/core';
import ImgCompanyDef from 'images/default-company.svg';
import style from './index.module.scss';
import clsx from 'clsx';

export interface AppAvatarProps extends Omit<AvatarProps, 'src'> {
  fullName?: string;
  isOnline?: boolean;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'xm' | 'lg' | 'xl' | 'xxl';
  firstName?: string;
  lastName?: string;
  isCompany?: boolean;
  src: string | null | undefined;
}

export const AppAvatar: React.FC<AppAvatarProps> = ({
  size = 'sm',
  fullName,
  className,
  firstName,
  lastName,
  isOnline = false,
  isCompany,
  src,
  children,
  ...rest
}) => {
  const _fullName = useMemo(() => {
    return [firstName, lastName].filter((v) => v).join(' ');
  }, [firstName, lastName]);
  const shortName = useMemo(() => {
    return String(fullName || _fullName)
      .split(' ')
      .map((word) => {
        return word[0] || '';
      })
      .join('');
  }, [fullName, _fullName]);

  const _src = useMemo(() => {
    if (src) {
      return src;
    } else if (isCompany) {
      return ImgCompanyDef;
    } else {
      return undefined;
    }
  }, [src, isCompany]);
  const variant = useMemo(() => {
    switch (size) {
      case 'xs':
      case 'sm':
      case 'md':
        return 'dot';
      default:
        return 'standard';
    }
  }, [size]);

  return (
    <Badge
      variant={variant}
      overlap={'circular'}
      invisible={!isOnline}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      className={clsx(style.badge, className)}
      color={'secondary'}
      badgeContent={' '}
    >
      <Avatar
        {...rest}
        src={_src}
        className={clsx(style.avatar, style[`size-${size}`], rest.onClick && style.clickable)}
        {...(children || shortName ? { children: children || shortName } : {})}
      />
    </Badge>
  );
};
