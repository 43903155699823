import { api, uploadFile, uploadHtml, uploadImage } from '../utils';
import { ENDPOINTS_USER_PROFILES, UserProfessionalField } from './models';
import { ServiceCompanies } from 'services/companies';
import { BaseParams, createFilterEquals, DynamicResult, PatchModel, prepareRecords } from 'utils';
import { ServiceParameters } from '../parameters';
import { CurrentUser, CurrentUserFullInfo } from 'services/accounts';

class Service {
  private async _patch(data: PatchModel<CurrentUser, 'appUserID'>) {
    let moreData: Partial<CurrentUser> = {};

    if (data.networkItemID) {
      moreData = await this.withNetworkItem({ networkItemID: data.networkItemID });
    }

    return api.patch(ENDPOINTS_USER_PROFILES.PATCH_MEMBER({ appUserID: data.appUserID }), {
      ...data,
      ...moreData,
    });
  }

  async patch(
    oldData: PatchModel<CurrentUserFullInfo, 'appUserID'>,
    newData: Partial<CurrentUserFullInfo>,
  ) {
    const {
      profilePicture,
      description,
      deckFile,
      cvFile,
      _professionalFieldOfInterests,
      ...restData
    } = newData;

    const appUserID = oldData.appUserID;

    const [
      { filePath: pathProfilePicture },
      { filePath: pathDescription },
      { filePath: pathDeckFile },
      { filePath: pathCvFile },
    ] = await Promise.all([
      uploadImage(oldData.profilePicture, profilePicture),
      uploadHtml(oldData.description, description),
      uploadFile(oldData.deckFile, deckFile),
      uploadFile(oldData.cvFile, cvFile),
    ]);

    if (_professionalFieldOfInterests) {
      const { postItems, deleteItems } = prepareRecords(
        oldData._professionalFieldOfInterests || [],
        _professionalFieldOfInterests || [],
        'uid',
      );

      await Promise.all([
        ...deleteItems.map(({ uid }) => ServiceUserProfiles.deleteInterest(uid)),
        ...postItems.map(({ professionalFieldOfInterestID }) =>
          ServiceUserProfiles.createInterest({ appUserID, professionalFieldOfInterestID }),
        ),
      ]);
    }

    await ServiceUserProfiles._patch({
      ...restData,
      appUserID: oldData.appUserID,
      profilePicture: pathProfilePicture,
      description: pathDescription,
      deckFile: pathDeckFile,
      cvFile: pathCvFile,
    });
  }

  async getListOfInterests(appUserID: string) {
    const {
      data: { value },
    } = await api.get<DynamicResult<UserProfessionalField>>(
      ENDPOINTS_USER_PROFILES.GET_USER_PROFESSIONAL_FIELD,
      {
        params: {
          filter: createFilterEquals<UserProfessionalField>({
            name: 'appUserID',
            value: appUserID,
          }),
        },
      },
    );

    return value;
  }
  async createInterest({
    appUserID,
    professionalFieldOfInterestID,
  }: {
    appUserID: string;
    professionalFieldOfInterestID: string | number;
  }) {
    return api.post(ENDPOINTS_USER_PROFILES.POST_USER_PROFESSIONAL_FIELD, {
      appUserID,
      professionalFieldOfInterestID,
    });
  }
  async deleteInterest(uid: string) {
    return api.delete(ENDPOINTS_USER_PROFILES.DELETE_USER_PROFESSIONAL_FIELD({ uid }));
  }

  async getMemberInterests({
    appUserID,
    params = {},
  }: {
    params?: BaseParams;
    appUserID: number | string;
  }) {
    const filter = `appUserID == "${appUserID}"`;
    params.filter = params.filter ? `( ${params.filter} ) && ${filter}` : filter;

    return api.get<{ value: [any] }>(ENDPOINTS_USER_PROFILES.GET_USER_PROFESSIONAL_FIELD, {
      params,
    });
  }

  async createMemberInterest({
    appUserID,
    professionalFieldOfInterestID,
  }: {
    appUserID: string;
    professionalFieldOfInterestID: string | number;
  }) {
    return api.post(ENDPOINTS_USER_PROFILES.POST_USER_PROFESSIONAL_FIELD, {
      appUserID,
      professionalFieldOfInterestID,
    });
  }

  async deleteMemberInterest({ id }: { id: string | number }) {
    return api.delete(ENDPOINTS_USER_PROFILES.DELETE_USER_PROFESSIONAL_FIELD({ uid: id }));
  }

  async getCurrentUserCompanies({ appUserID }: { appUserID: string }) {
    return ServiceCompanies.getCompaniesUser({ appUserID });
  }

  async selectCurrentCompany(p: { appUserID: string; companyID: string }) {
    const { appUserID, companyID } = p;
    return api.patch(ENDPOINTS_USER_PROFILES.PATCH_MEMBER({ appUserID }), {
      currentCompanyID: companyID,
    });
  }

  private async withNetworkItem({ networkItemID }: { networkItemID: number }) {
    const { data: network } = await ServiceParameters.getNetworkItem({ id: networkItemID });

    if (network.defaultHealthCareCategoryID) {
      return { healthCareCategoryID: network.defaultHealthCareCategoryID };
    }
    return {};
  }
}

export const ServiceUserProfiles = new Service();

export { ENDPOINTS_USER_PROFILES };
