import { useCallback, useMemo, useRef } from 'react';
import { useAppDispatch } from 'hooks';
import { actionChatTyping } from 'components/custom-chat/store';
import { debounce } from 'utils';

interface Props {
  isNew: boolean;
  conversationID: string;
  appUserID: string;
}

const DEBOUNCE_DELAY = 3000;

export const useChatMessageTyping = ({ conversationID, appUserID, isNew }: Props) => {
  const dispatch = useAppDispatch();

  const refTyping = useRef(false);

  const debounceStopTyping = useMemo(() => {
    return debounce(() => {
      if (isNew) return;
      refTyping.current = false;
      dispatch(actionChatTyping({ conversationID, appUserID, value: false }));
    }, DEBOUNCE_DELAY);
  }, [conversationID, appUserID, dispatch, isNew]);

  const onTyping = useCallback(() => {
    if (isNew) return;

    if (refTyping.current) {
      debounceStopTyping();
    } else {
      refTyping.current = true;
      dispatch(actionChatTyping({ conversationID, appUserID, value: true }));
      debounceStopTyping();
    }
  }, [conversationID, appUserID, dispatch, debounceStopTyping, isNew]);

  return {
    onTyping,
  };
};
