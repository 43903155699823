import React, { memo } from 'react';

interface Props {
  disabled?: boolean;
}

export const IconAwardMaterials = memo<Props>(({ disabled }) => {
  return !disabled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="48" viewBox="0 0 38 48" fill="none">
      <path
        d="M6.31173 33.9789C6.1328 33.425 5.81748 32.9251 5.39477 32.525C5.24672 32.3818 5.0864 32.2519 4.91564 32.1367L0.107814 41.3724C0.0461642 41.4938 0.0152283 41.6284 0.0177173 41.7645C0.0202063 41.9006 0.0560438 42.0341 0.12209 42.1531C0.188136 42.2722 0.282374 42.3732 0.396543 42.4473C0.510713 42.5215 0.641329 42.5665 0.776944 42.5785L5.76651 42.9502L8.89738 37.0106C7.6728 36.3763 6.74483 35.2882 6.31173 33.9789Z"
        fill="#5FB800"
      />
      <path
        d="M15.505 38.3315C15.4967 38.3149 15.4802 38.3067 15.4637 38.2902C14.8087 37.7199 13.9695 37.406 13.1011 37.4063C12.8515 37.4076 12.6026 37.4325 12.3576 37.4806C11.7412 37.615 11.105 37.6319 10.4824 37.5302L7.24414 43.6762L10.4741 47.6084C10.5498 47.7045 10.6465 47.782 10.7568 47.835C10.867 47.8879 10.9879 47.915 11.1102 47.9141C11.138 47.9154 11.1659 47.9126 11.1928 47.9058C11.3296 47.8935 11.4611 47.8469 11.5752 47.7704C11.6892 47.6939 11.7822 47.5899 11.8454 47.468L16.2981 38.9097C16.0189 38.7421 15.7561 38.5485 15.5133 38.3315H15.505Z"
        fill="#5FB800"
      />
      <path
        d="M15.505 38.3315C15.4967 38.3149 15.4802 38.3067 15.4637 38.2902C14.8087 37.7199 13.9695 37.406 13.1011 37.4063C12.8515 37.4076 12.6026 37.4325 12.3576 37.4806C11.7412 37.615 11.105 37.6319 10.4824 37.5302L7.24414 43.6762L10.4741 47.6084C10.5498 47.7045 10.6465 47.782 10.7568 47.835C10.867 47.8879 10.9879 47.915 11.1102 47.9141C11.138 47.9154 11.1659 47.9126 11.1928 47.9058C11.3296 47.8935 11.4611 47.8469 11.5752 47.7704C11.6892 47.6939 11.7822 47.5899 11.8454 47.468L16.2981 38.9097C16.0189 38.7421 15.7561 38.5485 15.5133 38.3315H15.505Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M26.699 37.5963C26.3437 37.5902 25.9898 37.5515 25.6416 37.4806C25.3967 37.4325 25.1477 37.4077 24.8981 37.4063C24.0136 37.4047 23.1599 37.7315 22.5025 38.3232C22.2556 38.5449 21.9872 38.7414 21.7012 38.9097L26.1538 47.468C26.217 47.5899 26.31 47.6939 26.424 47.7704C26.5381 47.847 26.6696 47.8935 26.8064 47.9058C26.8334 47.9127 26.8612 47.9154 26.889 47.9141C27.0113 47.915 27.1322 47.8879 27.2425 47.835C27.3527 47.782 27.4494 47.7045 27.5251 47.6084L30.7055 43.7341L27.4673 37.5384C27.2132 37.5788 26.9562 37.5982 26.699 37.5963Z"
        fill="#5FB800"
      />
      <path
        d="M33.0846 32.1289C32.4206 32.5672 31.9276 33.2206 31.6885 33.9793C31.249 35.3021 30.3049 36.3977 29.0615 37.0276L32.1676 42.9589L37.215 42.5789C37.3514 42.5684 37.4831 42.5244 37.5985 42.4508C37.7138 42.3772 37.8092 42.2763 37.8762 42.157C37.9432 42.0377 37.9797 41.9037 37.9825 41.7669C37.9854 41.6301 37.9544 41.4948 37.8924 41.3728L33.0846 32.1289Z"
        fill="#5FB800"
      />
      <path
        d="M33.0846 32.1289C32.4206 32.5672 31.9276 33.2206 31.6885 33.9793C31.249 35.3021 30.3049 36.3977 29.0615 37.0276L32.1676 42.9589L37.215 42.5789C37.3514 42.5684 37.4831 42.5244 37.5985 42.4508C37.7138 42.3772 37.8092 42.2763 37.8762 42.157C37.9432 42.0377 37.9797 41.9037 37.9825 41.7669C37.9854 41.6301 37.9544 41.4948 37.8924 41.3728L33.0846 32.1289Z"
        fill="black"
        fillOpacity="0.2"
      />
      <g filter="url(#filter0_ii_6638_13452)">
        <path
          d="M16.2509 1.15958C17.7576 -0.386527 20.2424 -0.386527 21.7491 1.15958C22.7309 2.16717 24.1853 2.55688 25.5395 2.17521C27.6173 1.58956 29.7692 2.83199 30.301 4.92428C30.6475 6.28781 31.7122 7.35251 33.0757 7.69903C35.168 8.23076 36.4104 10.3827 35.8248 12.4605C35.4431 13.8147 35.8328 15.2691 36.8404 16.2509C38.3865 17.7576 38.3865 20.2424 36.8404 21.7491C35.8328 22.7309 35.4431 24.1853 35.8248 25.5395C36.4104 27.6173 35.168 29.7692 33.0757 30.301C31.7122 30.6475 30.6475 31.7122 30.301 33.0757C29.7692 35.168 27.6173 36.4104 25.5395 35.8248C24.1853 35.4431 22.7309 35.8328 21.7491 36.8404C20.2424 38.3865 17.7576 38.3865 16.2509 36.8404C15.2691 35.8328 13.8147 35.4431 12.4605 35.8248C10.3827 36.4104 8.23076 35.168 7.69903 33.0757C7.35251 31.7122 6.28781 30.6475 4.92428 30.301C2.83199 29.7692 1.58956 27.6173 2.17521 25.5395C2.55688 24.1853 2.16717 22.7309 1.15958 21.7491C-0.386527 20.2424 -0.386527 17.7576 1.15958 16.2509C2.16717 15.2691 2.55688 13.8147 2.17521 12.4605C1.58956 10.3827 2.83199 8.23076 4.92428 7.69903C6.28781 7.35251 7.35251 6.28781 7.69903 4.92428C8.23076 2.83199 10.3827 1.58956 12.4605 2.17521C13.8147 2.55688 15.2691 2.16717 16.2509 1.15958Z"
          fill="#FFCE35"
        />
      </g>
      <g filter="url(#filter1_i_6638_13452)">
        <circle cx="19.0004" cy="18.9985" r="14.8696" fill="#EFAB08" />
      </g>
      <g filter="url(#filter2_di_6638_13452)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 10.2998C18.1264 10.1536 18.2964 10.0424 18.4972 9.98857L23.3268 8.69448C23.8603 8.55154 24.4086 8.86812 24.5515 9.40159L29.2103 26.7882C29.3532 27.3217 29.0367 27.8701 28.5032 28.013L23.6736 29.3071C23.1401 29.45 22.5918 29.1334 22.4488 28.6L18 11.9968V28.0013C18 28.5536 17.5523 29.0013 17 29.0013H12C11.4477 29.0013 11 28.5536 11 28.0013V10.0013C11 9.44901 11.4477 9.0013 12 9.0013H17C17.5523 9.0013 18 9.44901 18 10.0013V10.2998ZM23.7336 25.6675C23.5192 24.8673 23.9941 24.0448 24.7943 23.8304C25.5945 23.616 26.417 24.0909 26.6314 24.8911C26.8458 25.6913 26.3709 26.5138 25.5707 26.7282C24.7705 26.9426 23.948 26.4677 23.7336 25.6675ZM22.8785 10.8851L19.9807 11.6616L22.8277 22.2868L25.7255 21.5103L22.8785 10.8851ZM21.2055 12.3687L22.1714 12.1099L24.5008 20.8032L23.5349 21.062L21.2055 12.3687ZM13 25.5013C13 24.6729 13.6716 24.0013 14.5 24.0013C15.3284 24.0013 16 24.6729 16 25.5013C16 26.3297 15.3284 27.0013 14.5 27.0013C13.6716 27.0013 13 26.3297 13 25.5013ZM16 11.0013H13V22.0013H16V11.0013ZM14 12.0013H15V21.0013H14V12.0013Z"
          fill="#FFCE35"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii_6638_13452"
          x="0"
          y="0"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.984314 0 0 0 0 0.741176 0 0 0 0 0.109804 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6638_13452" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0 0.643137 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_6638_13452"
            result="effect2_innerShadow_6638_13452"
          />
        </filter>
        <filter
          id="filter1_i_6638_13452"
          x="4.13086"
          y="4.12891"
          width="29.7393"
          height="29.7383"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0 0.00392157 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6638_13452" />
        </filter>
        <filter
          id="filter2_di_6638_13452"
          x="11"
          y="8.66016"
          width="19.2441"
          height="21.6797"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0 0.00392157 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6638_13452" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6638_13452"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0 0.643137 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_6638_13452" />
        </filter>
      </defs>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="48" viewBox="0 0 38 48" fill="none">
      <g opacity="0.2">
        <path
          d="M6.31173 33.9789C6.1328 33.425 5.81748 32.9251 5.39477 32.525C5.24672 32.3818 5.0864 32.2519 4.91564 32.1367L0.107814 41.3724C0.0461642 41.4938 0.0152283 41.6284 0.0177173 41.7645C0.0202063 41.9006 0.0560438 42.0341 0.12209 42.1531C0.188136 42.2722 0.282374 42.3732 0.396543 42.4473C0.510713 42.5215 0.641329 42.5665 0.776944 42.5785L5.76651 42.9502L8.89738 37.0106C7.6728 36.3763 6.74483 35.2882 6.31173 33.9789Z"
          fill="#CFDCE7"
        />
        <path
          d="M15.505 38.3315C15.4967 38.3149 15.4802 38.3067 15.4637 38.2902C14.8087 37.7199 13.9695 37.406 13.1011 37.4063C12.8515 37.4076 12.6026 37.4325 12.3576 37.4806C11.7412 37.615 11.105 37.6319 10.4824 37.5302L7.24414 43.6762L10.4741 47.6084C10.5498 47.7045 10.6465 47.782 10.7568 47.835C10.867 47.8879 10.9879 47.915 11.1102 47.9141C11.138 47.9154 11.1659 47.9126 11.1928 47.9058C11.3296 47.8935 11.4611 47.8469 11.5752 47.7704C11.6892 47.6939 11.7822 47.5899 11.8454 47.468L16.2981 38.9097C16.0189 38.7421 15.7561 38.5485 15.5133 38.3315H15.505Z"
          fill="#CFDCE7"
        />
        <path
          d="M26.699 37.5963C26.3437 37.5902 25.9898 37.5515 25.6416 37.4806C25.3967 37.4325 25.1477 37.4077 24.8981 37.4063C24.0136 37.4047 23.1599 37.7315 22.5025 38.3232C22.2556 38.5449 21.9872 38.7414 21.7012 38.9097L26.1538 47.468C26.217 47.5899 26.31 47.6939 26.424 47.7704C26.5381 47.847 26.6696 47.8935 26.8064 47.9058C26.8334 47.9127 26.8612 47.9154 26.889 47.9141C27.0113 47.915 27.1322 47.8879 27.2425 47.835C27.3527 47.782 27.4494 47.7045 27.5251 47.6084L30.7055 43.7341L27.4673 37.5384C27.2132 37.5788 26.9562 37.5982 26.699 37.5963Z"
          fill="#CFDCE7"
        />
        <path
          d="M33.0846 32.1289C32.4206 32.5672 31.9276 33.2206 31.6885 33.9793C31.249 35.3021 30.3049 36.3977 29.0615 37.0276L32.1676 42.9589L37.215 42.5789C37.3514 42.5684 37.4831 42.5244 37.5985 42.4508C37.7138 42.3772 37.8092 42.2763 37.8762 42.157C37.9432 42.0377 37.9797 41.9037 37.9825 41.7669C37.9854 41.6301 37.9544 41.4948 37.8924 41.3728L33.0846 32.1289Z"
          fill="#CFDCE7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7491 1.15958C20.2424 -0.386527 17.7576 -0.386527 16.2509 1.15958C15.2691 2.16717 13.8147 2.55688 12.4605 2.17521C10.3827 1.58956 8.23076 2.83199 7.69903 4.92428C7.35251 6.28781 6.28781 7.35251 4.92428 7.69903C2.83199 8.23076 1.58956 10.3827 2.17521 12.4605C2.55688 13.8147 2.16717 15.2691 1.15958 16.2509C-0.386527 17.7576 -0.386527 20.2424 1.15958 21.7491C2.16717 22.7309 2.55688 24.1853 2.17521 25.5395C1.58956 27.6173 2.83199 29.7692 4.92428 30.301C6.28781 30.6475 7.35251 31.7122 7.69903 33.0757C8.23076 35.168 10.3827 36.4104 12.4605 35.8248C13.8147 35.4431 15.2691 35.8328 16.2509 36.8404C17.7576 38.3865 20.2424 38.3865 21.7491 36.8404C22.7309 35.8328 24.1853 35.4431 25.5395 35.8248C27.6173 36.4104 29.7692 35.168 30.301 33.0757C30.6475 31.7122 31.7122 30.6475 33.0757 30.301C35.168 29.7692 36.4104 27.6173 35.8248 25.5395C35.4431 24.1853 35.8328 22.7309 36.8404 21.7491C38.3865 20.2424 38.3865 17.7576 36.8404 16.2509C35.8328 15.2691 35.4431 13.8147 35.8248 12.4605C36.4104 10.3827 35.168 8.23076 33.0757 7.69903C31.7122 7.35251 30.6475 6.28781 30.301 4.92428C29.7692 2.83199 27.6173 1.58956 25.5395 2.17521C24.1853 2.55688 22.7309 2.16717 21.7491 1.15958ZM19 34C27.2843 34 34 27.2843 34 19C34 10.7157 27.2843 4 19 4C10.7157 4 4 10.7157 4 19C4 27.2843 10.7157 34 19 34Z"
          fill="#CFDCE7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 10.2998C18.1264 10.1536 18.2964 10.0424 18.4972 9.98857L23.3268 8.69448C23.8603 8.55154 24.4086 8.86812 24.5515 9.40159L29.2103 26.7882C29.3532 27.3217 29.0367 27.8701 28.5032 28.013L23.6736 29.3071C23.1401 29.45 22.5918 29.1334 22.4488 28.6L18 11.9968V28.0013C18 28.5536 17.5523 29.0013 17 29.0013H12C11.4477 29.0013 11 28.5536 11 28.0013V10.0013C11 9.44901 11.4477 9.0013 12 9.0013H17C17.5523 9.0013 18 9.44901 18 10.0013V10.2998ZM23.7336 25.6675C23.5192 24.8673 23.9941 24.0448 24.7943 23.8304C25.5945 23.616 26.417 24.0909 26.6314 24.8911C26.8458 25.6913 26.3709 26.5138 25.5707 26.7282C24.7705 26.9426 23.948 26.4677 23.7336 25.6675ZM22.8785 10.8851L19.9807 11.6616L22.8277 22.2868L25.7255 21.5103L22.8785 10.8851ZM21.2055 12.3687L22.1714 12.1099L24.5008 20.8032L23.5349 21.062L21.2055 12.3687ZM13 25.5013C13 24.6729 13.6716 24.0013 14.5 24.0013C15.3284 24.0013 16 24.6729 16 25.5013C16 26.3297 15.3284 27.0013 14.5 27.0013C13.6716 27.0013 13 26.3297 13 25.5013ZM16 11.0013H13V22.0013H16V11.0013ZM14 12.0013H15V21.0013H14V12.0013Z"
          fill="#CFDCE7"
        />
      </g>
    </svg>
  );
});
