import React, { useMemo } from 'react';
import style from './index.module.scss';

interface AppAvatarGroupProps {
  max?: number;
}

export const AppAvatarGroup: React.FC<AppAvatarGroupProps> = ({ children, max = 3 }) => {
  const arrayChildren = useMemo(() => {
    return React.Children.toArray(children).filter((child) => React.isValidElement(child));
  }, [children]);
  return (
    <div className={style.wrap}>
      {arrayChildren.slice(0, max).map((child, i) => (
        <div key={i} className={style.item}>
          {child}
        </div>
      ))}
      {arrayChildren.length > max && (
        <div className={`${style.item} ${style.left}`}>+ {arrayChildren.length - max}</div>
      )}
    </div>
  );
};
