import React, { useEffect } from 'react';
import { useChatContext } from '../hooks';
import { useAppDispatch } from 'hooks';

interface Props {
  appUserID: string | undefined;
}

export const ProviderChatAuthUser: React.FC<Props> = ({ children, appUserID }) => {
  const { socket } = useChatContext();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (appUserID) {
      socket.auth = { appUserID };
      socket.connect();
    }
  }, [appUserID, socket, dispatch]);

  return <>{children}</>;
};
