import { UserStatusType } from 'configs';

export enum ENDPOINTS_MEMBERS {
  GET_MEMBERS = 'Members/GetMembersDynamic',
}

export class Member {
  appUserID: string = '';
  firstName: string = '';
  lastName: string = '';
  alternativeEmail: string = '';
  profilePicture: string = '';
  regionID: number = 0;
  regionTitle: string = '';
  titleOfNameID: number = 0;
  title: string = '';
  fieldOfOccupationID: number = 0;
  fieldOfOccupationTitle: string = '';
  fieldOfServiceID: number = 0;
  developmentStageID!: number;
  developmentStageTitle: string = '';
  fieldOfServiceTitle: string = '';
  countryID: number = 0;
  countryTitle: string = '';
  countryFlag: string = '';
  networkItemID: number = 0;
  networkItemTitle: string = '';
  networkItemImage: string = '';
  medicalFieldID: number = 0;
  medicalFieldTitle: string = '';
  healthCareCategoryID: number = 0;
  healthCareCategoryTitle: string = '';
  companyName: string = '';
  companyWebsite: string = '';
  position: string = '';
  cvFile: string = '';
  deckFile: string = '';
  description: string = '';
  isSendMailApproval: boolean = false;
  isAllowToGetJobOffer: boolean = false;
  networkItemDescriptionLabel: string = '';
  networkItemCompanyNameLabel: string = '';
  networkItemFileLabel: string = '';
  networkItemMedicalFieldLabel: string = '';
  professionalFieldOfInterestList: string[] = [];
  professionalFieldOfInterests: string = '';
  phoneNumber: string = '';
  userStatusType: UserStatusType = UserStatusType.ONBOARD_NETWORK;
}
