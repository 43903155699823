import { UserStatusType } from 'configs';

const API = 'CustomerNetworkingEventUserProfiles';

export const API_NETWORKING_EVENT_USER_PROFILES = {
  GET: `${API}/GetCustomerNetworkingEventUserRepresentativesDynamic`,
  INVITE_PENDING_MEMBER: `${API}/InvitePendingMemberToCustomerNetworkingEvent`,
  INVITE_MEMBER: `${API}/InviteMemberToCustomerNetworkingEvent`,
  DELETE: (userCustomerNetworkingEventID: string) =>
    `${API}/DeleteUserCustomerNetworkingEvent/${userCustomerNetworkingEventID}`,
  PUT: (userCustomerNetworkingEventID: string) =>
    `${API}/PutUserCustomerNetworkingEvent/${userCustomerNetworkingEventID}`,
};

export interface NetworkingEventRepresentative {
  userCustomerNetworkingEventID: string;
  customerNetworkingEventID: string;
  isAdmin: boolean;
  appUserID: string;
  userStatusID: UserStatusType;
  firstName: string;
  lastName: string;
  email: string;
  profilePicture: string;
  userStatusTitle: string;
  userStatusType: UserStatusType;
  position: string;
}

export interface NetworkingEventRepresentativeExist {
  appUserID: string;
  customerNetworkingEventID: string;
  position: string;
}

export class NetworkingEventRepresentativeNew {
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  customerNetworkingEventID: string = '';
  position: string = '';
}

export interface EditRepresentative {
  userCustomerNetworkingEventID: string;
  position: string;
}
