import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineActions } from '../_utils';
import {
  NetworkingEvent,
  NetworkingExhibitor,
  NetworkingVisitor,
} from 'services/networking-events';

export interface StateEvent {
  error: Error | null;
  loading: boolean;
  event: NetworkingEvent | null;
  visitors: {
    error: Error | null;
    loading: boolean;
    data: NetworkingVisitor[];
    filter: {
      search: string;
      networkingEventCategoryIDs: string[];
      countryID: string;
    };
    pagination: {
      take: number;
      count: number;
      page: number;
    };
  };
  exhibitors: {
    error: Error | null;
    loading: boolean;
    data: NetworkingExhibitor[];
    filter: {
      search: string;
      networkingEventCategoryIDs: string[];
      countryID: string;
    };
    pagination: {
      take: number;
      count: number;
      page: number;
    };
  };
}

const initState = (): StateEvent => {
  return {
    error: null,
    loading: false,
    event: null,
    visitors: {
      error: null,
      loading: false,
      data: [],
      filter: {
        search: '',
        networkingEventCategoryIDs: [],
        countryID: '',
      },
      pagination: {
        take: 5,
        count: 0,
        page: 1,
      },
    },
    exhibitors: {
      error: null,
      loading: false,
      data: [],
      filter: {
        search: '',
        networkingEventCategoryIDs: [],
        countryID: '',
      },
      pagination: {
        take: 6,
        count: 0,
        page: 1,
      },
    },
  };
};

const slice = createSlice({
  name: 'EVENT',
  initialState: initState(),
  reducers: {
    requestGetEvent(state, action: PayloadAction<{ eventID: string }>) {
      let newState = { error: null, loading: true };
      if (state.event?.id !== action.payload.eventID) {
        newState = { ...initState(), ...newState };
      }
      return { ...state, ...newState };
    },
    successGetEvent(state, action: PayloadAction<NetworkingEvent>) {
      state.loading = false;
      state.event = action.payload;
    },
    failGetEvent(state, action: PayloadAction<Error>) {
      state.error = action.payload;
      state.loading = false;
    },

    requestJoinToEvent(state, action: PayloadAction<{ join: boolean }>) {
      state.loading = true;
    },
    successJoinToEvent(state, action: PayloadAction<{ join: boolean; eventID: string }>) {
      const { join, eventID } = action.payload;
      state.loading = false;
      if (state.event && eventID === state.event.id) {
        state.event.isVisitor = join;
      }
    },
    failJoinToEvent(state, action: PayloadAction<Error>) {
      state.loading = false;
    },

    // Visitors
    requestGetEventVisitors(state) {
      state.visitors.loading = true;
    },
    successGetEventVisitors(
      state,
      action: PayloadAction<{ data: NetworkingVisitor[]; count: number }>,
    ) {
      const { data, count } = action.payload;
      state.visitors.loading = false;
      state.visitors.data = data;
      state.visitors.pagination.count = count;
    },
    failGetEventVisitors(state, action: PayloadAction<Error>) {
      state.visitors.loading = false;
      state.visitors.error = action.payload;
    },
    setEventVisitorsFilters(
      state,
      action: PayloadAction<Partial<StateEvent['visitors']['filter']>>,
    ) {
      state.visitors.filter = { ...state.visitors.filter, ...action.payload };
    },
    setEventVisitorsPagination(
      state,
      action: PayloadAction<Partial<StateEvent['visitors']['pagination']>>,
    ) {
      state.visitors.pagination = { ...state.visitors.pagination, ...action.payload };
    },
    resetEventVisitorsFilters(state) {
      state.visitors.filter = initState().visitors.filter;
    },

    // Exhibitors
    requestGetEventExhibitors(state) {
      state.exhibitors.loading = true;
    },
    successGetEventExhibitors(
      state,
      action: PayloadAction<{ data: NetworkingExhibitor[]; count: number }>,
    ) {
      const { data, count } = action.payload;
      state.exhibitors.loading = false;
      state.exhibitors.data = data;
      state.exhibitors.pagination.count = count;
    },
    failGetEventExhibitors(state, action: PayloadAction<Error>) {
      state.exhibitors.loading = false;
      state.exhibitors.error = action.payload;
    },
    setEventExhibitorsFilters(
      state,
      action: PayloadAction<Partial<StateEvent['exhibitors']['filter']>>,
    ) {
      state.exhibitors.filter = { ...state.exhibitors.filter, ...action.payload };
    },
    setEventExhibitorsPagination(
      state,
      action: PayloadAction<Partial<StateEvent['exhibitors']['pagination']>>,
    ) {
      state.exhibitors.pagination = { ...state.exhibitors.pagination, ...action.payload };
    },
    resetEventExhibitorsFilters(state) {
      state.exhibitors.filter = initState().exhibitors.filter;
    },

    updateEventPartial(state, action: PayloadAction<{ id: string } & Partial<NetworkingEvent>>) {
      if (state.event?.id === action.payload.id) {
        state.event = { ...state.event, ...action.payload };
      }
    },
  },
});

const {
  requestGetEvent,
  successGetEvent,
  failGetEvent,

  requestJoinToEvent,
  successJoinToEvent,
  failJoinToEvent,

  requestGetEventVisitors,
  successGetEventVisitors,
  failGetEventVisitors,

  requestGetEventExhibitors,
  successGetEventExhibitors,
  failGetEventExhibitors,
} = slice.actions;

export const getEventActions = combineActions(requestGetEvent, successGetEvent, failGetEvent);
export const joinToEventActions = combineActions(
  requestJoinToEvent,
  successJoinToEvent,
  failJoinToEvent,
);
export const getEventVisitorsActions = combineActions(
  requestGetEventVisitors,
  successGetEventVisitors,
  failGetEventVisitors,
);
export const getEventExhibitorsActions = combineActions(
  requestGetEventExhibitors,
  successGetEventExhibitors,
  failGetEventExhibitors,
);

export const {
  setEventVisitorsFilters,
  resetEventVisitorsFilters,
  setEventVisitorsPagination,
  setEventExhibitorsFilters,
  resetEventExhibitorsFilters,
  setEventExhibitorsPagination,
  updateEventPartial,
} = slice.actions;

export const reducerEvent = slice.reducer;
