import React from 'react';
import { Fab, IconButton, IconButtonProps, FabProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import style from './index.module.scss';
import clsx from 'clsx';

export const ButtonClose: React.FC<Omit<FabProps, 'children'>> = ({ className, ...rest }) => {
  return (
    <Fab size={'small'} {...rest} className={clsx(style.root, className)}>
      <CloseIcon />
    </Fab>
  );
};

interface AppButtonCloseProps extends IconButtonProps {}

export const AppButtonClose: React.FC<AppButtonCloseProps> = (props) => {
  return (
    <IconButton {...props}>
      <CloseIcon />
    </IconButton>
  );
};
