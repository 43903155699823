import React from 'react';
import { Button, Dialog, DialogActions, IconButton } from '@material-ui/core';
import style from './index.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import { useOpen, useTranslate } from 'hooks';
import { BecomeSponsorDialogContactUs } from '../become-sponsor-contact-us';

interface Props {
  eventName?: string;
  onClose: () => void;
}

export const DialogSponsorBanner: React.FC<Props> = ({ onClose, eventName }) => {
  const { t } = useTranslate();
  const { isOpen, onOpen, onClose: handleClose } = useOpen();

  return (
    <>
      <Dialog
        open={true}
        PaperProps={{
          className: style.paper,
        }}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={onClose}
        scroll={'body'}
      >
        <div className={style.title}>{t('sponsors-title')}</div>

        <IconButton className={style.close} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <div className={style.subTitle}>{t('sponsors-subtitle1')}</div>
        <p>{t('sponsors-subtitle2')}</p>
        <p>{t('sponsors-info')}</p>
        <DialogActions className={style.actions} disableSpacing>
          <div className={style.buttonClose}>
            <Button variant={'text'} color={'inherit'} size={'large'} onClick={onClose}>
              {t('close')}
            </Button>
          </div>
          <div>
            <Button variant={'contained'} color={'secondary'} size={'large'} onClick={onOpen}>
              {t('contact-us')}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {isOpen && <BecomeSponsorDialogContactUs onClose={handleClose} />}
    </>
  );
};
