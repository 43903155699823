import { useCallback, useMemo } from 'react';
import { APP_ROUTES } from 'configs/routes';
import { useTranslate } from './use-translate';
import { useHistory } from 'react-router-dom';
import { UserStatusType } from 'configs';

export const useGoToMember = ({ appUserID }: { appUserID: string }) => {
  const { push } = useHistory();
  const memberPath = useMemo(() => {
    return APP_ROUTES.MEMBER.getUrl()({ member: appUserID });
  }, [appUserID]);
  const goToMember = useCallback(() => {
    push(memberPath);
  }, [push, memberPath]);

  return useMemo(() => {
    return {
      memberPath,
      goToMember,
    };
  }, [memberPath, goToMember]);
};
export const useGoToCompany = ({ companyID }: { companyID: string }) => {
  const { push } = useHistory();
  const companyPath = useMemo(() => {
    return APP_ROUTES.COMPANY.getUrl()({ company: companyID });
  }, [companyID]);

  const goToCompany = useCallback(() => {
    push(companyPath);
  }, [push, companyPath]);
  const onEditCompany = useCallback(() => {
    push(APP_ROUTES.COMPANY_EDITOR.getConfig()({ companyID }));
  }, [push, companyID]);

  return useMemo(() => {
    return {
      companyPath,
      goToCompany,
      onEditCompany,
    };
  }, [companyPath, goToCompany, onEditCompany]);
};
export const useMemberStatusTitle = (status?: UserStatusType) => {
  const { t } = useTranslate();
  return useMemo(() => {
    switch (status) {
      case UserStatusType.ACTIVE:
        return t('member-status-active');
      case UserStatusType.PENDING_INVITATION:
        return t('member-status-pending');
      case UserStatusType.REJECT:
        return t('member-status-reject');
      case UserStatusType.ONBOARD_NETWORK:
        return t('member-status-onboard-network');
      case UserStatusType.ONBOARD_EXPO:
        return t('member-status-onboard-expo');
      default:
        return t('--');
    }
  }, [t, status]);
};
export const useCompanyStatusTitle = (status?: UserStatusType) => {
  const { t } = useTranslate();
  return useMemo(() => {
    switch (status) {
      case UserStatusType.ACTIVE:
        return t('company-status-active');
      case UserStatusType.PENDING_INVITATION:
        return t('company-status-pending');
      case UserStatusType.REJECT:
        return t('company-status-reject');
      case UserStatusType.ONBOARD_NETWORK:
        return t('company-status-onboard-network');
      case UserStatusType.ONBOARD_EXPO:
        return t('company-status-onboard-expo');
      default:
        return t('--');
    }
  }, [t, status]);
};
