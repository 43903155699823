import { DateValue, getCurrentDate } from 'utils';

const API = 'NetworkingEventCalendars';

export const API_NETWORKING_EVENT_CALENDARS = {
  GET_SLOT_STATUSES_DYNAMIC: `${API}/GetCalendarSlotStatusesDynamic`,
  GET_TIME_ZONES_DYNAMIC: `${API}/GetCalendarTimeZonesDynamic`,

  CREATE_CALENDAR: `${API}/CreateCalendar`,
  GET_CURRENT_USER_CALENDAR: `${API}/GetCurrentUserCalendar`,
  GET_USER_CALENDAR: `${API}/GetUserCalendar`,
  UPDATE_USER_CALENDAR: (networkingEventID: string) => `${API}/UpdateCalendar/${networkingEventID}`,

  GET_AVAILABLE_SLOTS_DYNAMIC: `${API}/GetCalendarAvailableSlotsDynamic`,
  GET_AVAILABLE_SLOT: `${API}/GetCalendarAvailableSlot`,
  CREATE_AVAILABLE_SLOT: `${API}/CreateCalendarAvailableSlot`,
  UPDATE_AVAILABLE_SLOT: `${API}/UpdateCalendarAvailableSlot`,
  DELETE_AVAILABLE_SLOT: (calendarAvailableSlotID: string) =>
    `${API}/DeleteCalendarAvailableSlot?calendarAvailableSlotID=${calendarAvailableSlotID}`,

  GET_MEETINGS_DYNAMIC: `${API}/GetCalendarMeetingsDynamic`,
  CREATE_MEETING: `${API}/CreateCalendarMeeting`,
  DELETE_DECLINE_MEETING: (calendarMeetingID: string) =>
    `${API}/DeleteOrDeclineCalendarMeeting?calendarMeetingID=${calendarMeetingID}`,
  UPDATE_MEETING: `${API}/UpdateCalendarMeeting`,

  // DEV only
  REMOVE_CALENDAR: (networkingEventID: string) => `${API}/DeleteCalendars/${networkingEventID}`,
};

export const EVENT_CALENDAR_MINUTES_STEP = 30;

export const EVENT_CALENDAR_HOURS_LIMIT = 11;

export enum CALENDAR_STATUS_ID {
  AVAILABLE = 1,
  PENDING = 2,
  NOT_AVAILABLE = 3,
  TAKEN = 4,
  MY_PENDING = 5,
  ARRANGE = 6,
}

export const getDefaultEventTimeSlotStart = () => {
  return getCurrentDate(8);
};
export const getDefaultEventTimeSlotEnd = () => {
  return getCurrentDate(8 + EVENT_CALENDAR_HOURS_LIMIT);
};

export const currentUserTimeZone = (() => {
  return {
    id: '',
    displayName: '',
    timeZoneID: Intl.DateTimeFormat().resolvedOptions().timeZone,
    utcDistance: '',
  };
})();

export interface TimeZone {
  id: string;
  displayName: string;
  timeZoneID: string;
  utcDistance: string;
}

export interface CalendarSlotStatus {
  id: string;
  title: string;
}

export class NewCalendar {
  networkingEventID = '';
  calendarTimeZoneID = '';
  introMessage?: string = '';
  calendarTimeSlots: {
    start: DateValue;
    end: DateValue;
    slotID: string | undefined | null;
  }[] = [];
}

export interface ServerNewCalendar {
  networkingEventID: string;
  calendarTimeZoneID: string;
  introMessage?: string;
  calendarTimeSlots: {
    networkingEventSlotFromDateTime: string;
    networkingEventSlotToDateTime: string;
  }[];
}

export interface CalendarAvailableSlot {
  calendarAvailableSlotID: string;
  slotFromDateTime: string;
  slotToDateTime: string;
}

export interface CalendarAvailableSlotPatch
  extends Omit<CalendarAvailableSlot, 'calendarAvailableSlotID'> {
  calendarAvailableSlotID: string | null | undefined;
}

export interface CalendarMeeting {
  calendarMeetingID: string;
  calendarSlotStatusID: CALENDAR_STATUS_ID;
  calendarSlotStatusTitle: string;
  slotFromDateTime: string;
  slotToDateTime: string;
  displayDetails: boolean;
  fromUserAppID: string;
  fromUserFirstName: string;
  fromUserLastName: string;
  fromUserProfilePicture: string;
  fromUserCompanyName: string;
  fromUserPosition: string;
  fromUserCountryTitle: string;
  fromUserCountryFlag: string;
  fromUserMessage: string;
  toUserAppID: string;
  toUserFirstName: string;
  toUserLastName: string;
  toUserProfilePicture: string;
  toUserCompanyName: string;
  toUserPosition: string;
  toUserCountryTitle: string;
  toUserCountryFlag: string;
  toUserMessage: string;
  videoChatRoomCode: string;
}

export interface CalendarCurrentUser {
  networkingEventID: string;
  networkingEventFromDate: string;
  networkingEventToDate: string;
  calendarTimeZoneID: string;
  introMessage: string;
  calendarAvailableSlots: CalendarAvailableSlot[];
  calendarMeetingsByCurrentUserList: CalendarMeeting[];
  calendarMeetingsByOtherUsersList: CalendarMeeting[];
}

export type MeetingPayload = {
  networkingEventID: string;
  slotFromDateTime: string;
  slotToDateTime: string;
  fromUserMessage: string;
  toUserAppID: string;
  videoChatRoomCode: string;
};
