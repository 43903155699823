import { all, call, put, takeLatest } from 'modules/typed-saga';
import { getLabelsActions } from './slice';
import { ServiceLabels } from 'services/labels';

function* getLabels(action: ReturnType<typeof getLabelsActions.request>) {
  const { languageID } = action.payload;
  try {
    const {
      labels: {
        data: { value: labels },
      },
      languages: { data: languages },
    } = yield* all({
      labels: call(ServiceLabels.getLabels, languageID),
      languages: call(ServiceLabels.getLanguages),
    });
    yield* put(getLabelsActions.success({ labels, languages }));
  } catch (e: any) {
    yield* put(getLabelsActions.fail(e));
  }
}

export const sagasLabels = [takeLatest(getLabelsActions.request.type, getLabels)];
