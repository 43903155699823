import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { LayoutPage, usePageContext } from 'components/layout-page';
import { useDispatch, useSelector } from 'react-redux';
import { SubHeaderTabs } from 'components/sub-header-tabs';
import { Button, Typography } from '@material-ui/core';
import { getEventActions, selectEvent, selectStateEvent } from 'store/event';
import { NetworkingEvent } from 'services/networking-events';
import {
  ButtonEventVideo,
  DialogJoinToEvent,
  General,
  MainStageDialog,
  TabExhibitors,
} from './components';
import { APP_ROUTES, EVENT_PAGE_TABS } from 'configs/routes';
import { DialogFrame } from 'components/dialogs';
import { useIsMobile, useOpen, useSearchParams, useSourcePageDashboard, useTranslate } from 'hooks';
import { EventHeaderDates } from 'pages/Event/components/header-dates';
import style from './index.module.scss';
import { useHistory } from 'react-router-dom';
import { BackButton } from 'components/back-button';

export const SUBJECT_SPONSOR_ID = 8;
export const SUBJECT_SPONSOR_LOGO_ID = 11;

enum ACTION_BEFORE_JOIN {
  DEFAULT,
  TAB_PARTICIPANTS,
  TAB_GENERAL,
  BUTTON_VIDEO,
}

interface ViewProps {
  loading: boolean;
  event: NetworkingEvent & { fullEventTitle: string };
}

export const View: React.FC<ViewProps> = ({ event, loading }) => {
  const { data: dashboardData } = useSourcePageDashboard();

  const [actionBeforeJoin, setActionBeforeJoin] = useState<ACTION_BEFORE_JOIN>(
    ACTION_BEFORE_JOIN.DEFAULT,
  );

  const { query, replace } = useSearchParams();
  const queryTab = query['tab'] || EVENT_PAGE_TABS.GENERAL;
  const [tabValue, _setTabValue] = useState<EVENT_PAGE_TABS>(queryTab);

  const isAllowToViewAll = event.isExhibitor || event.isVisitor;

  const setTabValue = useCallback(
    (val: EVENT_PAGE_TABS) => {
      if (isAllowToViewAll) {
        return _setTabValue(val);
      }
      if (val === EVENT_PAGE_TABS.GENERAL) {
        setActionBeforeJoin(ACTION_BEFORE_JOIN.TAB_GENERAL);
      }
      if (val === EVENT_PAGE_TABS.EXHIBITORS) {
        setActionBeforeJoin(ACTION_BEFORE_JOIN.TAB_PARTICIPANTS);
      }
      return _setTabValue(EVENT_PAGE_TABS.GENERAL);
    },
    [isAllowToViewAll],
  );

  useEffect(() => {
    replace({ tab: tabValue });
  }, [tabValue, replace]);
  useEffect(() => {
    setTabValue(queryTab);
  }, [queryTab, setTabValue]);

  const { t } = useTranslate();

  const tabs = useMemo(() => {
    return [
      {
        value: EVENT_PAGE_TABS.GENERAL,
        title: t('general'),
        component: <General htmlURL={event.welcomeHtml} />,
      },
      {
        value: EVENT_PAGE_TABS.EXHIBITORS,
        title: t('participants'),
        component: <TabExhibitors isVisible={tabValue === EVENT_PAGE_TABS.EXHIBITORS} />,
      },
    ];
  }, [t, tabValue, event.welcomeHtml]);

  const isMobile = useIsMobile();

  const [iframeUrl, setIframeUrl] = useState('');
  const { isOpen, onOpen, onClose } = useOpen();

  const onSuccessJoin = useCallback(() => {
    if (actionBeforeJoin === ACTION_BEFORE_JOIN.TAB_GENERAL) {
      _setTabValue(EVENT_PAGE_TABS.GENERAL);
    }
    if (actionBeforeJoin === ACTION_BEFORE_JOIN.TAB_PARTICIPANTS) {
      _setTabValue(EVENT_PAGE_TABS.EXHIBITORS);
    }
    if (actionBeforeJoin === ACTION_BEFORE_JOIN.BUTTON_VIDEO) {
      onOpen();
    }

    setActionBeforeJoin(ACTION_BEFORE_JOIN.DEFAULT);
  }, [actionBeforeJoin, onOpen]);

  const showDialogJoin = actionBeforeJoin !== ACTION_BEFORE_JOIN.DEFAULT;

  const onClickVideoButton = useCallback(() => {
    if (!isAllowToViewAll) {
      return setActionBeforeJoin(ACTION_BEFORE_JOIN.BUTTON_VIDEO);
    }
    return onOpen();
  }, [isAllowToViewAll, onOpen]);

  const isShowBecomeExhibitor = !event.isExhibitor && tabValue === EVENT_PAGE_TABS.GENERAL;

  const { push } = useHistory();
  const onBecomeExhibitor = useCallback(() => {
    push(APP_ROUTES.EVENT_BUILDER_PAYMENT.getConfig({ networkingEventID: event.id })());
  }, [push, event.id]);

  const onBack = useCallback(() => {
    push(APP_ROUTES.HOME.getConfig()());
  }, [push]);

  return (
    <>
      <SubHeaderTabs
        tabValue={tabValue}
        onChangeTabValue={setTabValue}
        bgUrl={dashboardData?.banner}
        tabs={tabs}
        TabsProps={{
          variant: isMobile ? 'scrollable' : 'fullWidth',
          scrollButtons: 'off',
        }}
      >
        <BackButton className={style.back} onClick={onBack} />
        <div className={style.header}>
          <Typography component={'h1'} variant={'h2'} color={'inherit'}>
            {event.titlePrefix}
            {event.titlePrefix && <br />}
            {event.title}
          </Typography>
          <EventHeaderDates
            networkingEventID={event.id}
            fromDate={event.fromDate}
            toDate={event.toDate}
          />
          <div>
            {isShowBecomeExhibitor && (
              <Button variant={'contained'} color={'secondary'} onClick={onBecomeExhibitor}>
                {t('become-an-exhibitor')}
              </Button>
            )}
          </div>
          <div>
            <ButtonEventVideo onClick={onClickVideoButton} />
          </div>
        </div>
      </SubHeaderTabs>
      {tabs.map(({ component, value }) => {
        return (
          <div
            key={value}
            style={{ display: value === tabValue ? undefined : 'none' }}
            className={style.main}
          >
            {component}
          </div>
        );
      })}
      {iframeUrl && <DialogFrame url={iframeUrl} onClose={() => setIframeUrl('')} />}
      {isOpen && <MainStageDialog networkingEventID={event.id} onClose={onClose} />}
      {showDialogJoin && (
        <DialogJoinToEvent
          networkingEventID={event.id}
          onSuccess={onSuccessJoin}
          onClose={() => setActionBeforeJoin(ACTION_BEFORE_JOIN.DEFAULT)}
        />
      )}
    </>
  );
};

const DataProvider: React.FC = () => {
  const { query } = useSearchParams();
  const { setError } = usePageContext();
  const eventID = query['id'];

  const dispatch = useDispatch();
  useEffect(() => {
    if (eventID) {
      dispatch(getEventActions.request({ eventID }));
    } else {
      setError(new Error('Unexpected behaviour'));
    }
  }, [dispatch, eventID, setError]);

  const { error, loading } = useSelector(selectStateEvent);
  const event = useSelector(selectEvent);

  useEffect(() => {
    if (error) {
      setError(new Error('Unexpected behaviour'));
    }
  }, [dispatch, error, setError]);

  return <>{event && eventID === event.id && <View event={event} loading={loading} />}</>;
};

export default memo(() => {
  return (
    <LayoutPage>
      <DataProvider />
    </LayoutPage>
  );
});
