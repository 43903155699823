import React from 'react';
import { useSelector } from 'react-redux';
import { selectEvent, selectEventBenefits, selectEventTargetAudiences } from 'store/event';
import { AspectRatio } from 'components/aspect-ratio';
import { SponsorsList } from '../sponsors-list';
import { BannerGeneralSponsor } from 'components/banners/general-sponsor';
import { BackgroundPlayer } from 'components/backgronud-player';
import { DialogSponsorBanner } from 'components/dialogs/become-sponsor-banner';
import { useLoadHtml, useOpen, useTranslate } from 'hooks';
import { Grid } from '@material-ui/core';
import { GeneralBenefit } from '../general-benefit';
import { GeneralTarget } from '../general-target';
import { GeneralTitle } from '../general-title';

import clsx from 'clsx';
import style from './index.module.scss';
import { BecomeSponsorDialogContactUs } from 'components/dialogs';
import { SUBJECT_SPONSOR_LOGO_ID } from 'pages/Event/index';

interface Props {
  htmlURL: string;
}

export const General: React.FC<Props> = ({ htmlURL }) => {
  const event = useSelector(selectEvent);
  const targetAudiences = useSelector(selectEventTargetAudiences);
  const benefits = useSelector(selectEventBenefits);
  const { isOpen: isOpenVideo, onOpen: onOpenVideo, onClose: onCloseVideo } = useOpen();
  const { isOpen: isOpenLogo, onOpen: onOpenLogo, onClose: onCloseLogo } = useOpen();
  const { t, tp } = useTranslate();
  const { data: content } = useLoadHtml(htmlURL);
  return (
    <>
      <div className={clsx('container', style.wrap)}>
        <div className={style.top}>
          <AspectRatio ratioWidth={10} ratioHeight={4}>
            <BackgroundPlayer url={event?.video} previewPicture={event?.picture} />
          </AspectRatio>
        </div>

        <GeneralTitle>{t('what-for-me')}</GeneralTitle>

        <div className={style.grid}>
          {benefits.slice(0, 3).map(({ title, description, picture }, i) => (
            <div key={i}>
              <GeneralBenefit picture={picture} title={title} description={description} />
            </div>
          ))}
        </div>

        <div className={style.description} dangerouslySetInnerHTML={{ __html: content || '' }} />

        <GeneralTitle>{t('target-audience')}</GeneralTitle>

        <Grid container justifyContent={'center'} spacing={4} className={style.marginBottom}>
          {targetAudiences.map(({ title }, i) => {
            return (
              <Grid item xs={6} sm={4} md={'auto'} key={i}>
                <GeneralTarget title={title} />
              </Grid>
            );
          })}
        </Grid>

        <Grid container>
          <Grid item lg={6} xs={12}>
            <BannerGeneralSponsor onClick={onOpenVideo}>
              {tp('banner-general-sponsor-title-1')}
            </BannerGeneralSponsor>
            {isOpenVideo && <DialogSponsorBanner onClose={onCloseVideo} />}
          </Grid>
          <Grid item lg={6} xs={12}>
            <BannerGeneralSponsor onClick={onOpenLogo}>
              {tp('banner-general-sponsor-title-2')}
            </BannerGeneralSponsor>
            {isOpenLogo && (
              <BecomeSponsorDialogContactUs
                defaultSubjectID={SUBJECT_SPONSOR_LOGO_ID}
                onClose={onCloseLogo}
              />
            )}
          </Grid>
        </Grid>
        {event && <SponsorsList eventID={event.id} />}
      </div>
    </>
  );
};
