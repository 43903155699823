import React from "react";


interface AspectRatioProps {
  ratioWidth?: number
  ratioHeight?: number
  className?: string
}

export const AspectRatio: React.FC<AspectRatioProps> = ({
                                                          children,
                                                          ratioWidth = 16,
                                                          ratioHeight = 9,
                                                          className
                                                        }) => {
  const ratio = (ratioHeight / ratioWidth) * 100 + "%"
  return (
    <div style={{paddingBottom: ratio, position: 'relative', width: '100%' }} className={className}>
      <div style={{position: 'absolute', top: '0', left: '0', height: '100%', width: '100%'}}>
        {children}
      </div>
    </div>
  )
}
