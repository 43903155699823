const API = 'Labels';

export const API_LABELS = {
  GET_LABELS: `${API}/GetLabelsDynamic`,
  GET_LANGUAGES: `${API}/GetLanguages`,
  GET_DEFAULT_LANGUAGE: `${API}/GetDefaultLanguage`,
};

export interface Language {
  id: number;
  culture: string;
  title: string;
}

export interface Label {
  id: number;
  languageID: number;
  labelKey: string;
  title: string;
}
