import React, { useEffect, useState } from 'react';
import { useTranslate } from 'hooks';
import { setInterval } from 'timers';

interface Props {
  title?: string;
}

export const ChatTyping: React.FC<Props> = ({ title }) => {
  const { t } = useTranslate();

  const [dots, setDots] = useState('.');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((v) => {
        return v.length >= 3 ? '.' : `${v}.`;
      });
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <em>
      {title ? title : t('typing')}
      {dots}
    </em>
  );
};
