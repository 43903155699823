import { useLocation, matchPath } from 'react-router-dom';

export const useIsRoutes = (...args: string[]) => {
  const { pathname } = useLocation();
  return args.some((routePath) => {
    const result = matchPath(pathname, {
      path: routePath,
      exact: true,
    });
    return result !== null;
  });
};
