import { useCallback, useMemo } from 'react';
import { useNotify } from 'hooks';

interface IRequest {
  (...args: any[]): Promise<any>;
}
interface UseRequestNotifyProps<R extends IRequest> {
  withSuccess?: boolean;
  setLoading?: (v: boolean) => void;
  request: R;
  throwError?: boolean;
}

const defaultSetLoading = () => ({});
export const useRequestNotify = <R extends IRequest>({
  request,
  setLoading = defaultSetLoading,
  withSuccess = true,
  throwError = false,
}: UseRequestNotifyProps<R>) => {
  const { onError, onSuccess } = useNotify();
  const requestWrap = useCallback(
    async (...args: any[]) => {
      setLoading(true);
      try {
        const result = await request(...args);
        withSuccess && onSuccess();
        setLoading(false);
        return result;
      } catch (e: any) {
        setLoading(false);
        onError(e);
        if (throwError) {
          throw e;
        }
      }
    },
    // eslint-disable-next-line
    [throwError, request, setLoading, withSuccess],
  ) as R;

  return useMemo(() => {
    return {
      requestWrap,
    };
  }, [requestWrap]);
};
