import React from 'react';
import { Divider, Theme, useMediaQuery } from '@material-ui/core';
import { DialogPrivacy, DialogTermsAndCondition } from '../../dialogs';
import Link from '@material-ui/core/Link';
import { useTranslate, useOpen } from 'hooks';
import { DialogContactUs } from '../../dialogs/contact-us';

interface AppFooterControlsProps {}

export const AppFooterControls: React.FC<AppFooterControlsProps> = () => {
  const { t } = useTranslate();
  const isShowDivider = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    isOpen: isOpenConditions,
    onClose: onCloseConditions,
    onOpen: onOpenConditions,
  } = useOpen();
  const { isOpen: isOpenPrivacy, onClose: onClosePrivacy, onOpen: onOpenPrivacy } = useOpen();
  const { isOpen: isOpenContactUS, onClose: onCloseContactUS, onOpen: onOpenContactUS } = useOpen();
  return (
    <div className={'app-footer__controls'}>
      <Link className={'app-footer__link'} onClick={onOpenPrivacy}>
        {t('privacy')}
      </Link>
      {isShowDivider && <Divider className={'app-footer__divider'} orientation={'vertical'} />}
      <Link className={'app-footer__link'} onClick={onOpenConditions}>
        {t('terms')}
      </Link>
      {isShowDivider && <Divider className={'app-footer__divider'} orientation={'vertical'} />}
      <Link className={'app-footer__link'} onClick={onOpenContactUS}>
        {t('contact-us')}
      </Link>
      <DialogContactUs isOpen={isOpenContactUS} onClose={onCloseContactUS} />
      {isOpenPrivacy && <DialogPrivacy onClose={onClosePrivacy} />}
      {isOpenConditions && <DialogTermsAndCondition onClose={onCloseConditions} />}
    </div>
  );
};
