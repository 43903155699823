export const replaceLabel = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/#.+?#/gi, (v: string) => {
    const key = v.substring(1, v.length - 1);
    return replacer(key);
  });
};

export const calcRequestLabel = (value: string, replacer: (v: string) => string) => {
  const _value = String(value);
  if (RegExp(/^@.+@$/gi).test(_value)) {
    return replaceLabel(_value.substring(1, _value.length - 1), replacer);
  } else {
    return replacer(_value);
  }
};

const replaceTranslate = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/{{.+?}}/gi, (v: string) => {
    const key = v.substring(2, v.length - 2);
    return replacer(key);
  });
};
export const calcTranslate = (value: string, payload: { [x: string]: any } = {}) => {
  return replaceTranslate(value, (key) => {
    if (payload.hasOwnProperty(key)) {
      return payload[key];
    }
    return '-- --';
  });
};
