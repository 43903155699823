const createSearch = (query: Record<string, any> | string | void | undefined) => {
  if (query && typeof query === 'object') {
    const _query = Object.entries(query).reduce((acc, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {} as typeof query);
    return new URLSearchParams(_query).toString();
  } else if (query) {
    return query;
  } else {
    return undefined;
  }
};
const makePathname = (path: string, params: RouteParams = {}) => {
  return path
    .split('/')
    .map((item) => {
      if (item.startsWith(':')) {
        const key = item.substring(1);
        if (!params.hasOwnProperty(key)) {
          throw new Error(`TypedRoute/${path}: param ${key} is not exist`);
        }

        return params[key];
      }
      return item;
    })
    .join('/');
};

export interface TypedRoute<P, Q> {
  path: string;
  getConfig: (params: P) => (query: Q) => { pathname: string; search?: string };
  getUrl: (params: P) => (query: Q) => string;
  getAbsoluteUrl: (params: P) => (query: Q) => string;
}

export type ExtractParamsTypedRoute<T> = T extends TypedRoute<infer P, any> ? P : never;
export type ExtractQueryTypedRoute<T> = T extends TypedRoute<any, infer Q> ? Q : never;
export type ExtractTypedRoute<T> = {
  params: ExtractParamsTypedRoute<T>;
  query: ExtractQueryTypedRoute<T>;
};

type RouteParams = { [paramName: string]: string | number | boolean | undefined };

export const createRoute = <
  Params extends RouteParams | void = void,
  Query extends string | Record<string, any> | void = void,
>(
  path: string,
): TypedRoute<Params, Query> => {
  const getUrl = (params: Params) => {
    return (query: Query) => {
      let _params = (params || undefined) as RouteParams | undefined;
      return [makePathname(path, _params), createSearch(query)].filter((v) => v).join('?');
    };
  };
  return {
    path,
    getConfig: (params) => {
      return (query) => {
        let _params = (params || undefined) as RouteParams | undefined;
        return {
          pathname: makePathname(path, _params),
          search: createSearch(query),
        };
      };
    },
    getUrl,
    getAbsoluteUrl: (params) => {
      return (query) => {
        return `${window.location.origin}${getUrl(params)(query)}`;
      };
    },
  };
};
