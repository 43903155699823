import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectChatIsOpen, actionChatSwitchChat } from '../store';
import { useAppDispatch } from 'hooks';

export const useChatOpen = () => {
  const dispatch = useAppDispatch();
  const isOpen = useSelector(selectChatIsOpen);
  const onOpen = useCallback(() => {
    dispatch(actionChatSwitchChat(true));
  }, [dispatch]);
  const onClose = useCallback(() => {
    dispatch(actionChatSwitchChat(false));
  }, [dispatch]);
  const onToggle = useCallback(() => {
    dispatch(actionChatSwitchChat(!isOpen));
  }, [dispatch, isOpen]);

  return { isOpen, onOpen, onClose, onToggle };
};
