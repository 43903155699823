import {
  actionChannel,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
  throttle,
} from 'modules/typed-saga';
import { selectAppUserID, selectCurrentUser, selectStoreCurrentUserCompany } from './selectors';
import { parseErrorData, updateAuthTokens } from 'utils';
import {
  actionAccountGenerateCode,
  actionAccountLoginWithCode,
  actionAccountLogout,
  actionAccountRefreshCurrentUser,
  actionAccountSelectCompany,
  actionCurrentUserGetCompany,
  actionCurrentUserUpdate,
  actionCurrentUserUpdateCompany,
  actionGetCurrentUser,
  actionGetUserCompanies,
} from './slice';
import { ServiceAccounts } from 'services/accounts';
import { notifyErrorSaga, workerErrorNotifySaga, workerErrorNotifyThunk } from '../_utils';
import { ServiceUserProfiles } from 'services/user-profiles';
import { ServiceCompanies } from 'services/companies';
import { actionAccountCreateCompany } from 'store/auth/actions';
import { membersApi } from 'services/members';

export const ERROR_AS_SUCCESS_GENERATE = 'already-generated';

function* getUser() {
  try {
    let { data: user } = yield* call(ServiceAccounts.getCurrentUser);
    yield* put(actionGetCurrentUser.success({ user }));
  } catch (error: any) {
    yield* put(actionGetCurrentUser.fail({ error: error }));
  }
}
function* updateCurrentUser(action: ReturnType<typeof actionCurrentUserUpdate.request>) {
  const currentData = yield* select(selectCurrentUser);
  const { payload } = action;
  try {
    if (!currentData) {
      throw new Error('Unexpected behaviour: updateCurrentUser');
    }
    yield* call(ServiceUserProfiles.patch, currentData, payload);
    let { data: user } = yield* call(ServiceAccounts.getCurrentUser);
    yield* put(actionCurrentUserUpdate.success(user));

    // invalidate current user data
    yield* put(membersApi.util.invalidateTags([{ type: 'Member', id: currentData.appUserID }]));
  } catch (e: any) {
    let { data: user } = yield* call(ServiceAccounts.getCurrentUser);
    yield* put(actionCurrentUserUpdate.fail({ data: user, error: parseErrorData(e) }));
  }
}
function* sagaAutoUpdateUser() {
  const channel = yield* actionChannel(actionCurrentUserUpdate.request);

  while (true) {
    const action = yield* take(channel);
    yield* call(updateCurrentUser, action);
  }
}

function* getCurrentUserCompany(action: ReturnType<typeof actionCurrentUserGetCompany.request>) {
  const { companyID } = action.payload;
  try {
    const appUserID = yield* select(selectAppUserID);
    if (!appUserID) {
      throw new Error('Unexpected behaviour');
    }
    yield* call(ServiceCompanies.checkAllowToEditCompany, { appUserID, companyID });
    const { data } = yield* call(ServiceCompanies.getCompany, companyID);
    yield* put(actionCurrentUserGetCompany.success({ companyID, data }));
  } catch (e: any) {
    yield* put(actionCurrentUserGetCompany.fail({ companyID, error: parseErrorData(e) }));
  }
}
function* updateCurrentUserCompany(
  action: ReturnType<typeof actionCurrentUserUpdateCompany.request>,
) {
  const { companyID } = action.payload;
  const companyStore = yield* select((state) => selectStoreCurrentUserCompany(state, companyID));
  const { payload } = action;

  try {
    const oldData = companyStore.data;
    if (!oldData) {
      throw new Error('Unexpected behaviour: updateCurrentUserCompany');
    }

    yield call(ServiceCompanies.patch, oldData, payload.data);
    let { data: company } = yield* call(ServiceCompanies.getCompany, companyID);
    yield put(actionCurrentUserUpdateCompany.success({ companyID, data: company }));
  } catch (e: any) {
    let { data: company } = yield* call(ServiceCompanies.getCompany, companyID);
    yield* put(
      actionCurrentUserUpdateCompany.fail({ companyID, data: company, error: parseErrorData(e) }),
    );
  }
}
function* sagaAutoUpdateCompany() {
  const channel = yield* actionChannel(actionCurrentUserUpdateCompany.request);

  while (true) {
    const action = yield* take(channel);
    yield* call(updateCurrentUserCompany, action);
  }
}

function* loginWithCode(action: ReturnType<typeof actionAccountLoginWithCode.request>) {
  try {
    const response = yield* call(ServiceAccounts.loginWithCode, action.payload);

    const {
      jwt: { token, refreshToken },
      expires,
    } = response.data;

    yield* call(updateAuthTokens, { token, refreshToken, expires });
    const { data: user } = yield* call(ServiceAccounts.getCurrentUser);
    yield* put(actionGetCurrentUser.success({ user }));
    yield* put(actionAccountLoginWithCode.success());
  } catch (e: any) {
    yield* put(actionAccountLoginWithCode.fail({ error: parseErrorData(e) }));
  }
}

function* generateCode(action: ReturnType<typeof actionAccountGenerateCode.request>) {
  try {
    yield* call(ServiceAccounts.generatePassword, action.payload);
    yield* put(actionAccountGenerateCode.success());
  } catch (e: any) {
    const error = parseErrorData(e);

    if (e.message !== ERROR_AS_SUCCESS_GENERATE) {
      yield* call(notifyErrorSaga, error);
    }

    yield* put(actionAccountGenerateCode.fail({ error }));
  }
}

function* logout() {
  try {
    yield* call(ServiceAccounts.logout);
    yield* put(actionAccountLogout.success());
    updateAuthTokens();
  } catch (e: any) {
    yield* put(actionAccountLogout.fail({ error: parseErrorData(e) }));
  }
}

function* loadCompaniesUser() {
  const appUserID = yield* select(selectAppUserID);

  try {
    if (!appUserID) {
      throw new Error('unexpected-behaviour');
    }
    const {
      data: { value },
    } = yield* call(ServiceUserProfiles.getCurrentUserCompanies, { appUserID });
    yield* put(actionGetUserCompanies.success({ companies: value }));
  } catch (e: any) {
    yield* put(actionGetUserCompanies.fail({ error: parseErrorData(e) }));
  }
}

function* selectCurrentUserCompany(action: ReturnType<typeof actionAccountSelectCompany.request>) {
  const {
    payload: { companyID },
  } = action;
  const appUserID = yield* select(selectAppUserID);

  try {
    if (!appUserID) {
      throw new Error('unexpected-behaviour');
    }
    yield* call(ServiceUserProfiles.selectCurrentCompany, { appUserID, companyID });
    yield* put(actionAccountSelectCompany.success());
  } catch (e: any) {
    yield* put(actionAccountSelectCompany.fail({ error: parseErrorData(e) }));
  }
}

export const customAuthSagas = [
  sagaAutoUpdateUser(),
  sagaAutoUpdateCompany(),

  takeLeading(actionAccountLogout.request, logout),

  takeLatest(actionCurrentUserGetCompany.request, getCurrentUserCompany),

  throttle(
    5000,
    [actionCurrentUserUpdateCompany.success, actionAccountCreateCompany.fulfilled],
    loadCompaniesUser,
  ),

  takeLatest(
    [
      actionAccountSelectCompany.success,
      actionGetCurrentUser.request,
      actionAccountRefreshCurrentUser,
    ],
    getUser,
  ),
  takeLatest(actionAccountGenerateCode.request, generateCode),
  takeLatest(actionAccountSelectCompany.request, selectCurrentUserCompany),
  takeLatest(actionAccountLoginWithCode.request, loginWithCode),
  takeLatest(actionGetUserCompanies.request, loadCompaniesUser),

  takeEvery([actionAccountCreateCompany.rejected], workerErrorNotifyThunk),
  takeEvery(
    [
      actionAccountLogout.fail,
      actionGetUserCompanies.fail,
      actionAccountSelectCompany.fail,
      actionCurrentUserUpdateCompany.fail,
      actionCurrentUserUpdate.fail,
    ],
    workerErrorNotifySaga,
  ),
];
