import React from 'react';
import { Button, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContactUs } from '../contact-us';
import { useOpen, useTranslate } from 'hooks';

import style from './index.module.scss';

interface Props {
  onClose: () => void;
}

export const DialogHelpExhibitor: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslate();
  const { isOpen: isOpenContactUs, onClose: onCloseContactUs, onOpen: onOpenContactUS } = useOpen();

  return (
    <Dialog
      open={true}
      PaperProps={{
        className: style.paper,
      }}
      fullWidth={true}
      maxWidth={'xs'}
      onClose={onClose}
      scroll={'body'}
    >
      <IconButton className={style.close} onClick={onClose} disableRipple>
        <CloseIcon />
      </IconButton>
      <div className={style.title}>{t('how-we-can-help-title')}</div>
      <div className={style.subTitle}>{t('contact-us-info')}</div>
      <Button variant={'contained'} color={'secondary'} onClick={onOpenContactUS}>
        {t('contact-us')}
      </Button>
      <DialogContactUs isOpen={isOpenContactUs} onClose={onCloseContactUs} />
    </Dialog>
  );
};
