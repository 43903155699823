export enum UserStatusType {
  ACTIVE = 1,
  REJECT = 2,
  PENDING_INVITATION = 3,
  ONBOARD_NETWORK = 4,
  ONBOARD_EXPO = 5,
}
export const createUserStatusLabels = (prefix?: string, suffix?: string) => {
  prefix = prefix || '';
  suffix = suffix || '';
  return {
    [UserStatusType.ACTIVE]: `${prefix}active${suffix}`,
    [UserStatusType.REJECT]: `${prefix}reject${suffix}`,
    [UserStatusType.PENDING_INVITATION]: `${prefix}pending${suffix}`,
    [UserStatusType.ONBOARD_EXPO]: `${prefix}onboard-expo${suffix}`,
    [UserStatusType.ONBOARD_NETWORK]: `${prefix}onboard-network${suffix}`,
  };
};

export const htmlEditorConfig = {
  buttons: 'bold,italic,underline,|,ul,ol,outdent,indent,|,align,|,undo,redo',
  height: '23rem',
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  cleanHTML: {
    allowTags: {
      p: true,
      ul: true,
      ol: true,
      li: true,
      strong: true,
      br: true,
      span: true,
      em: true,
      u: true,
      div: true,
    },
  },
};
