import { ValueFileUploader } from 'utils';

const API = 'CustomerNetworkingEventImages';

export const API_NETWORKING_EVENT_IMAGES = {
  GET_ALL_DYNAMIC: `${API}/GetCustomerNetworkingEventImagesDynamic`,
  CREATE: `${API}/AddCustomerNetworkingEventImage`,
  DELETE: (id: string) => `${API}/RemoveCustomerNetworkingEventImage/${id}`,
  PATCH: (id: string) => `${API}/PatchCustomerNetworkingEventImage/${id}`,
};

export interface NetworkingEventImage {
  id: string;
  customerNetworkingEventID: string;
  title: string;
  imageURL: ValueFileUploader;
  rank: number;
}
