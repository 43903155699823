import * as React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { AppButtonSizes, AppButtonVariants, useAppButtonClasses } from './useButtonClasses';
import clsx from 'clsx';

const BUTTON_NAME = 'app-button-secondary';

interface AppButtonSecondaryProps extends Omit<ButtonProps, 'size'> {
  variant?: AppButtonVariants;
  size?: AppButtonSizes;
}

export const AppButtonSecondary: React.FC<AppButtonSecondaryProps> = ({
  children,
  size = 'large',
  className,
  variant = 'contained',
  ...rest
}) => {
  const appButtonClasses = useAppButtonClasses({ baseName: BUTTON_NAME, variant, size });
  const classNames = clsx('app-button', appButtonClasses, className);
  return (
    <Button {...rest} variant={variant} color={'primary'} className={classNames}>
      {children}
    </Button>
  );
};
