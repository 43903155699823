import { StoreState } from '../index';
import { createSelector } from '@reduxjs/toolkit';
import { calcPaginationState } from 'utils/services';

export const selectStateMyEvents = (state: StoreState) => state.myEvents;

export const selectMyVisitorEvents = createSelector(selectStateMyEvents, ({ visitorEvents }) => {
  return visitorEvents;
});

export const selectMyVisitorEventsPagination = createSelector(
  selectMyVisitorEvents,
  ({ pagination }) => {
    return calcPaginationState(pagination);
  },
);

export const selectMyExhibitorEvents = createSelector(
  selectStateMyEvents,
  ({ exhibitorEvents }) => {
    return exhibitorEvents;
  },
);
export const selectMyExhibitorEventsPagination = createSelector(
  selectMyExhibitorEvents,
  ({ pagination }) => {
    return calcPaginationState(pagination);
  },
);
