import { selectEvent } from '../store/event';
import { useSelector } from 'react-redux';

export const usePageEvent = () => {
  const event = useSelector(selectEvent);

  if (!event) {
    throw new Error('usePageEvent => Unexpected behaviour');
  }

  return event;
};
