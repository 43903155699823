import React from 'react';
import style from './index.module.scss';
import { Tab, Tabs, TabsProps } from '@material-ui/core';
import { TransparentHeader } from '../transparent-header';
import clsx from 'clsx';

interface TabItem {
  value: number | string;
  title: string;
}

interface Classes {
  root: string;
  content: string;
}

interface Props {
  className?: string;
  tabValue: any;
  onChangeTabValue: (v: any) => void;
  tabs: TabItem[];
  TabsProps?: Partial<TabsProps>;
  bgUrl?: string;
  classes?: Partial<Classes>;
}

export const SubHeaderTabs: React.FC<Props> = ({
  bgUrl,
  className,
  tabs,
  tabValue,
  onChangeTabValue,
  TabsProps,
  children,
  classes,
}) => {
  return (
    <TransparentHeader className={clsx(className, classes?.root)} bgUrl={bgUrl}>
      <div className={'container'}>
        <div className={clsx(style.content, classes?.content)}>{children}</div>
        <Tabs
          value={tabValue}
          indicatorColor={'secondary'}
          textColor={'primary'}
          onChange={(e, v) => onChangeTabValue(v)}
          className={style.tabs}
          variant={'fullWidth'}
          {...TabsProps}
        >
          {tabs.map(({ title, value }) => {
            return <Tab value={value} key={value} label={title} className={style.tabItem} />;
          })}
        </Tabs>
      </div>
    </TransparentHeader>
  );
};
