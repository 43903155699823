import React, { useState } from 'react';
import { useTranslate } from 'hooks';
import { TabButtons } from 'components/tab-buttons';
import { TabExhibitorsList } from '../tab-exhibitors-list';
import style from './index.module.scss';
import { TabVisitors } from '../tab-visitors';

export enum INNER_TABS {
  EXHIBITORS = 'exhibitors',
  VISITORS = 'visitors',
}

interface Props {
  isVisible: boolean;
}

export const TabExhibitors: React.FC<Props> = ({ isVisible }) => {
  const { t } = useTranslate();
  const [innerTab, setInnerTab] = useState(INNER_TABS.EXHIBITORS);
  const [view, setView] = useState<'list' | 'cards'>('list');
  const tabItems = [
    {
      value: INNER_TABS.EXHIBITORS,
      label: t('exhibitors'),
      Component: <TabExhibitorsList view={view} onChangeView={setView} />,
    },
    {
      value: INNER_TABS.VISITORS,
      label: t('visitors'),
      Component: <TabVisitors isVisible={innerTab === INNER_TABS.VISITORS} />,
    },
  ];

  return (
    <div className={'container'}>
      <div className={style.tabs}>
        <TabButtons value={innerTab} onChange={setInnerTab} items={tabItems} />
      </div>
      {tabItems.map(({ Component, value }) => {
        return (
          <div key={value} hidden={innerTab !== value}>
            {Component}
          </div>
        );
      })}
    </div>
  );
};
