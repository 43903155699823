import React from "react";
import Scrollbars from "react-custom-scrollbars";

interface DialogInfoContentProps {
  content: string
}
export const DialogInfoContent: React.FC<DialogInfoContentProps> = ({ content }) => {
  return (
    <Scrollbars>
      <div className={'info-content'} dangerouslySetInnerHTML={{ __html: content }} />
    </Scrollbars>
  )
}
