import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { reducerLabels, sagasLabels } from './labels';
import { reducerEvents, sagasEvents } from './events';
import { reducerEvent, sagasEvent } from './event';
import { reducerMyEvents, sagasMyEvents } from './my-events';
import { reducerCustomChat, chatSagas } from 'components/custom-chat';
import { reducerAccount, customAuthSagas } from './auth';
import { reducerEventBuilder, sagasEventBuilder } from './event-builder';

import { companiesApi } from 'services/companies';
import { networkingEventApi } from 'services/networking-events';
import { networkingEventCalendarApi } from 'services/networking-event-calendars';
import { customerNetworkingEventApi } from 'services/customer-networking-event';
import { membersApi } from 'services/members';
import { pagesApi } from 'services/pages';
import { parametersApi } from 'services/parameters';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  auth: reducerAccount,
  events: reducerEvents,
  event: reducerEvent,
  labels: reducerLabels,
  myEvents: reducerMyEvents,
  eventBuilder: reducerEventBuilder,
  customChat: reducerCustomChat,
  [networkingEventApi.reducerPath]: networkingEventApi.reducer,
  [networkingEventCalendarApi.reducerPath]: networkingEventCalendarApi.reducer,
  [customerNetworkingEventApi.reducerPath]: customerNetworkingEventApi.reducer,
  [parametersApi.reducerPath]: parametersApi.reducer,
  [pagesApi.reducerPath]: pagesApi.reducer,
  [membersApi.reducerPath]: membersApi.reducer,
  [companiesApi.reducerPath]: companiesApi.reducer,
});

export const appStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ thunk: true, serializableCheck: false, immutableCheck: false })
      .concat(sagaMiddleware)
      .concat(networkingEventApi.middleware)
      .concat(parametersApi.middleware)
      .concat(pagesApi.middleware)
      .concat(networkingEventCalendarApi.middleware)
      .concat(membersApi.middleware)
      .concat(companiesApi.middleware)
      .concat(customerNetworkingEventApi.middleware);
  },
});

export type StoreState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;

function* rootSaga() {
  yield all([
    ...chatSagas,
    ...customAuthSagas,
    ...sagasEvents,
    ...sagasEvent,
    ...sagasLabels,
    ...sagasMyEvents,
    ...sagasEventBuilder,
  ]);
}

sagaMiddleware.run(rootSaga);
