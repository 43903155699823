import React, { memo } from 'react';

interface Props {
  disabled?: boolean;
}

export const IconAwardStart = memo<Props>(({ disabled }) => {
  return !disabled ? (
    <svg width="38" height="30" viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_6638_17490)">
        <path
          d="M5.63901 19.3753C5.63901 19.3753 5.13118 19.2582 7.24931 18.6615C9.36565 18.0635 17.2768 18.0158 22.1789 18.6615C19.8393 14.7936 20.953 5.94264 19.7285 4.76157C17.0535 3.01608 7.5829 3.68585 3.41211 4.65577L5.63901 19.3753Z"
          fill="#5FB800"
        />
      </g>
      <g filter="url(#filter1_i_6638_17490)">
        <path
          d="M26.5179 21.5796C24.2061 22.3725 20.9518 22.6113 20.1945 21.9711C19.4363 21.3299 18.5395 19.9422 18.6772 19.3769C18.8148 18.8134 21.2262 19.1712 21.8858 19.474C22.5467 19.7792 22.5574 21.0969 21.2953 21.2486C23.0785 21.5111 23.6914 20.3177 23.0175 18.8134C22.3436 17.3081 21.3325 13.852 21.4244 12.0893C21.515 10.3288 21.4513 8.19326 23.1058 8.63236C24.7599 9.07146 29.3714 11.6806 31.8146 11.647C34.3739 11.6125 37.3897 10.7098 38 9.5428C37.8493 10.4868 37.2301 14.0659 34.1143 16.9285C30.9986 19.791 28.8742 20.7736 26.5179 21.5796Z"
          fill="#5FB800"
        />
      </g>
      <g filter="url(#filter2_ii_6638_17490)">
        <path
          d="M0.966383 2.78294C0.966383 2.78294 4.45923 28.9903 4.70674 29.5439C4.95334 30.0988 6.85625 30.2382 6.93292 29.4444C7.01004 28.6498 6.00523 21.0035 5.81198 19.1535C5.61963 17.3045 3.37551 2.86195 3.36699 2.67487L0.966383 2.78294ZM2.11871 0.0212097C0.198763 0.282762 -1.57994 4.64196 2.329 4.78454C6.2357 4.9303 5.02822 -0.375659 2.11871 0.0212097Z"
          fill="#FFCE35"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_6638_17490"
          x="3.41211"
          y="3.67969"
          width="18.7666"
          height="15.6953"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.298039 0 0 0 0 0.576471 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6638_17490" />
        </filter>
        <filter
          id="filter1_i_6638_17490"
          x="18.6631"
          y="8.57422"
          width="19.3369"
          height="13.7656"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.298039 0 0 0 0 0.576471 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6638_17490" />
        </filter>
        <filter
          id="filter2_ii_6638_17490"
          x="0"
          y="0"
          width="6.9375"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.984314 0 0 0 0 0.741176 0 0 0 0 0.109804 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6638_17490" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0 0.643137 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_6638_17490"
            result="effect2_innerShadow_6638_17490"
          />
        </filter>
      </defs>
    </svg>
  ) : (
    <svg width="38" height="30" viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.2">
        <path
          d="M26.518 21.577C24.2062 22.3698 20.9519 22.6087 20.1945 21.9684C19.4363 21.3272 18.5396 19.9396 18.6772 19.3742C18.8149 18.8107 21.2263 19.1685 21.8858 19.4714C22.5467 19.7765 22.5575 21.0943 21.2953 21.246C23.0785 21.5084 23.6914 20.3151 23.0175 18.8107C22.3436 17.3054 21.3325 13.8494 21.4244 12.0867C21.515 10.3262 21.4513 8.19062 23.1058 8.62972C24.7599 9.06882 29.3715 11.678 31.8147 11.6444C34.374 11.6099 37.3898 10.7071 38 9.54015C37.8493 10.4842 37.2301 14.0633 34.1144 16.9258C30.9986 19.7884 28.8742 20.771 26.518 21.577ZM0.966383 2.78294C0.966383 2.78294 4.45923 28.9903 4.70674 29.5439C4.95334 30.0988 6.85625 30.2382 6.93292 29.4444C7.01004 28.6498 6.00523 21.0035 5.81198 19.1535C5.61963 17.3045 5.36585 14.0755 5.35733 13.8885C5.34971 13.7023 3.36699 2.67487 3.36699 2.67487L0.966383 2.78294ZM2.11871 0.0212097C0.198763 0.282762 -1.57994 4.64196 2.329 4.78454C6.2357 4.9303 5.02822 -0.375659 2.11871 0.0212097ZM4.35028 20.4976C4.35028 20.4976 5.1309 19.2571 7.24903 18.6604C9.36536 18.0624 17.2765 18.0147 22.1786 18.6604C19.839 14.7925 20.9527 5.94155 19.7282 4.76048C17.0532 3.01498 7.58262 3.68475 3.41183 4.65467L4.35028 20.4976Z"
          fill="#CFDCE7"
        />
      </g>
    </svg>
  );
});
