import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectLabels } from 'store/labels';
import { Loading } from 'components/loading';
import { selectStateAuth } from 'store/auth';

export const AppLoadingProvider: FC = ({ children }) => {
  const auth = useSelector(selectStateAuth);
  const { loading: labelsLoading } = useSelector(selectLabels);
  return (
    <>
      {auth.init && children}
      <Loading active={(!auth.init && auth.loading) || labelsLoading} />
    </>
  );
};
