import React from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from 'configs/routes';
import LogoFooter from 'images/logo_footer.png';
import { useTranslate } from 'hooks';

interface AppFooterLogoProps {}

export const AppFooterLogo: React.FC<AppFooterLogoProps> = () => {
  const { t } = useTranslate();

  return (
    <div className={'footer-logo'}>
      <Link to={APP_ROUTES.HOME.getConfig()()} className={'footer-logo__link'}>
        <img
          className={'footer-logo__img'}
          alt={t('logo')}
          title={t('lin-q-bio')}
          src={LogoFooter}
        />
      </Link>
      <div className={'footer-logo__title'}>
        <span>{t('stay-connected')}</span>
        <span>{t('in-the-health-tech-world')}</span>
      </div>
    </div>
  );
};
