import {
  API_MEDIA_UPLOADS,
  UploadCompanyFileModel,
  UploadFilePayload,
  UploadHTMLPayload,
  UploadUserFileModel,
} from './models';
import { api } from 'services';
import { base64ToFileStream, getRandomString } from 'utils';
import { AxiosResponse } from 'axios';

export const MEDIA_PREFIX = '@@CLOUD@@';

class Service {
  async uploadCompanyLogo(data: UploadCompanyFileModel) {
    const url = API_MEDIA_UPLOADS.UPLOAD_COMPANY_LOGO;
    return api.post<string>(url, data);
  }

  async uploadCompanyDescription(data: UploadCompanyFileModel) {
    const url = API_MEDIA_UPLOADS.UPLOAD_COMPANY_DESCRIPTION;
    return api.post<string>(url, { ...data, fileName: data.fileName || `${data.companyID}.html` });
  }

  async uploadUserProfilePicture({ data }: { data: UploadUserFileModel }) {
    const url = API_MEDIA_UPLOADS.UPLOAD_USER_PICTURE;
    return api.post<string>(url, { ...data, name: undefined });
  }

  async uploadCvFile({ data }: { data: UploadUserFileModel }) {
    const url = API_MEDIA_UPLOADS.UPLOAD_CV_FILE;
    return api.post<string>(url, { ...data });
  }

  async uploadDescFile({ data }: { data: UploadUserFileModel }) {
    const url = API_MEDIA_UPLOADS.UPLOAD_DESC_FILE;
    return api.post<string>(url, { ...data });
  }

  async deleteUserProfilePicture({ appUserID }: { appUserID: string }) {
    const url = API_MEDIA_UPLOADS.DELETE_USER_PICTURE({ appUserID });
    return api.delete(url);
  }

  async deleteCvFile({ appUserID }: { appUserID: string }) {
    const url = API_MEDIA_UPLOADS.DELETE_CV_FILE({ appUserID });
    return api.delete(url);
  }

  async deleteDescFile({ appUserID }: { appUserID: string }) {
    const url = API_MEDIA_UPLOADS.DELETE_DESC_FILE({ appUserID });
    return api.delete(url);
  }

  async deleteCompanyLogo(p: { companyID: string }) {
    const url = API_MEDIA_UPLOADS.DELETE_COMPANY_LOGO(p);
    return api.delete(url);
  }

  async uploadCompanyDescFile({ data }: { data: UploadCompanyFileModel }) {
    const url = API_MEDIA_UPLOADS.UPLOAD_COMPANY_DESC_FILE;
    return api.post<UploadCompanyFileModel, string>(url, data);
  }

  async deleteCompanyDescFile(p: { companyID: string }) {
    const url = API_MEDIA_UPLOADS.DELETE_COMPANY_DESC_FILE(p);
    return api.delete(url);
  }

  async getHtml(url?: string) {
    if (url) {
      const response = await fetch(url);
      const text = await response.text();
      if (response.ok) {
        return { data: text };
      }
    }
    return { data: '' };
  }

  async uploadFile(data: UploadFilePayload) {
    return api.post(API_MEDIA_UPLOADS.UPLOAD_FILE, {
      ...data,
      fileName: `${MEDIA_PREFIX}__${data.fileName ? data.fileName : getRandomString(20)}`,
      fileStreamString: base64ToFileStream(data.fileStreamString),
    });
  }
  async uploadImage(data: UploadFilePayload) {
    return api.post<UploadFilePayload, AxiosResponse<Required<UploadFilePayload>>>(
      API_MEDIA_UPLOADS.UPLOAD_IMAGE,
      {
        ...data,
        fileName: `${MEDIA_PREFIX}__${data.fileName ? data.fileName : getRandomString(20)}`,
        fileStreamString: base64ToFileStream(data.fileStreamString),
      },
    );
  }
  async uploadHtml(data: UploadHTMLPayload) {
    return api.post(API_MEDIA_UPLOADS.UPLOAD_HTML, {
      ...data,
      fileName: `${MEDIA_PREFIX}__${data.fileName ? data.fileName : getRandomString(20)}`,
    });
  }
  async removeFile(filePath: string) {
    try {
      return api.delete(API_MEDIA_UPLOADS.REMOVE_FILE(filePath));
    } catch (e) {}
  }
}

export const ServiceUploads = new Service();
export * from './models';
