import { Controller, ControllerProps, FieldErrors, FieldValues } from 'react-hook-form';
import React, { useMemo } from 'react';
import { debounce } from 'utils';
import { useTranslate } from 'hooks';

type Component = React.ReactElement | React.ComponentType<any> | 'input' | 'select' | 'textarea';

type Props<C extends Component, F extends FieldValues = FieldValues> = {
  onWatchChange?: (name: keyof F) => void;
  watchDelay?: number;
  as: C;
  errors: FieldErrors;
} & Omit<ControllerProps<C, F>, 'as' | 'render'>;

export const FormController = <
  TAs extends Component,
  TFieldValues extends FieldValues = FieldValues,
>(
  props: Props<TAs, TFieldValues>,
) => {
  const {
    name,
    control,
    errors,
    onWatchChange,
    watchDelay = 1000,
    rules,
    as: Element,
    defaultValue,
    ...rest
  } = props;
  const error = errors[name];
  const debounceChangeWatch = useMemo(() => {
    return debounce(() => onWatchChange && onWatchChange(name), watchDelay);
  }, [name, onWatchChange, watchDelay]);
  const { t } = useTranslate();
  const helperText = error?.message ? t(error.message) : rest.helperText;
  console.log(helperText);
  return (
    <Controller<TAs, TFieldValues>
      name={name}
      control={control}
      render={(props) => {
        return (
          //@ts-ignore
          <Element
            {...props}
            {...rest}
            onChange={(...event: any[]) => {
              props.onChange(...event);
              debounceChangeWatch();
            }}
            error={Boolean(error)}
            helperText={helperText}
          />
        );
      }}
      rules={rules}
      defaultValue={defaultValue}
    />
  );
};
