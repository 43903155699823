import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { AppButtonPrimary } from '../app-buttons';
import { useTranslate } from 'hooks';

interface DialogInfoFooterProps {
  onClose: () => void;
}

export const DialogInfoFooter: React.FC<DialogInfoFooterProps> = ({ onClose }) => {
  const { t } = useTranslate();
  return (
    <div className={'info-footer'}>
      <AppButtonPrimary onClick={onClose} startIcon={<CheckIcon />}>
        {t('agree')}
      </AppButtonPrimary>
    </div>
  );
};
