import { createAsyncThunk } from '@reduxjs/toolkit';
import { StoreState } from '../index';
import { parseErrorData } from 'utils';
import { Company, ServiceCompanies } from 'services/companies';
import { selectCurrentUser } from 'store/auth/selectors';

export const actionAccountCreateCompany = createAsyncThunk<
  { companyID: string },
  Company,
  { state: StoreState; serializedErrorType: Error }
>(`ACCOUNT/actionAccountCreateCompany`, async (data, { getState }) => {
  try {
    const user = selectCurrentUser(getState());
    if (!user) {
      throw new Error('Unexpected behaviour');
    }
    const {
      data: { companyID },
    } = await ServiceCompanies.createCompany({
      data,
      appUserID: user.appUserID,
      position: user.position,
    });
    return { companyID };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
