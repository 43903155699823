import { ContactMessage, ENDPOINTS_CONTACTS } from './models';
import { api } from 'services/utils';

class Service {
  async contactUs(props: ContactMessage, payload?: string) {
    const comment = props.comment + (payload ? `<p>[[ ${payload} ]]</p>` : '');
    return api.post(ENDPOINTS_CONTACTS.POST_CONTACT_COMMENT, { ...props, comment });
  }

  async inviteBalkMembers({ emails }: { emails: string[] }) {
    const data = emails.map((email) => ({ email }));
    await api.post(ENDPOINTS_CONTACTS.INVITE_BALK_MEMBERS, data);

    return { data: emails };
  }
}

export const ServiceContacts = new Service();
export { ENDPOINTS_CONTACTS, ContactMessage };
