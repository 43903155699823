import React, { createContext, FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetCurrentUser, selectStateAuth } from 'store/auth';
import { StoreState } from 'store';

export const AuthContext = createContext<StoreState['auth'] | null>(null);

export const AuthContextProvider: FC = ({ children }) => {
  const auth = useSelector(selectStateAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.init) {
      dispatch(actionGetCurrentUser.request());
    }
  }, [dispatch, auth.init]);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
