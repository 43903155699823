import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineActions } from '../_utils';
import { MyExhibitorEvent, MyVisitorEvent } from 'services/networking-events';
import { actionAccountLogout } from 'store/auth';

interface StoreMyExhibitorEvent extends MyExhibitorEvent {
  isLoading: boolean;
}

export interface StateMyEvents {
  exhibitorEvents: {
    error: null | Error;
    loading: boolean;
    data: StoreMyExhibitorEvent[];
    pagination: {
      take: number;
      count: number;
      page: number;
    };
  };
  visitorEvents: {
    error: null | Error;
    loading: boolean;
    data: MyVisitorEvent[];
    pagination: {
      take: number;
      count: number;
      page: number;
    };
  };
}

const initState: StateMyEvents = {
  exhibitorEvents: {
    error: null,
    loading: false,
    data: [],
    pagination: {
      take: 5,
      count: 0,
      page: 1,
    },
  },
  visitorEvents: {
    error: null,
    loading: false,
    data: [],
    pagination: {
      take: 5,
      count: 0,
      page: 1,
    },
  },
};

const slice = createSlice({
  name: 'MY_EVENTS',
  initialState: initState,
  reducers: {
    loadMoreVisitorEvents() {},
    requestGetVisitorEvents(state, action: PayloadAction<{ isMore: boolean }>) {
      state.visitorEvents.loading = true;
      state.visitorEvents.error = null;
    },
    successGetVisitorEvents(
      state,
      action: PayloadAction<{ data: MyVisitorEvent[]; count: number; isMore: boolean }>,
    ) {
      const {
        payload: { data, count, isMore },
      } = action;
      state.visitorEvents.loading = false;
      state.visitorEvents.data = isMore ? [...state.visitorEvents.data, ...data] : data;
      state.visitorEvents.pagination.count = count;
    },
    failGetVisitorEvents(state, action: PayloadAction<Error>) {
      state.visitorEvents.loading = false;
      state.visitorEvents.error = action.payload;
    },
    setVisitorEventsPagination(
      state,
      action: PayloadAction<Partial<StateMyEvents['visitorEvents']['pagination']>>,
    ) {
      state.visitorEvents.pagination = { ...state.visitorEvents.pagination, ...action.payload };
    },

    requestLeaveVisitorEvents(state, action: PayloadAction<{ networkingEventID: string }>) {
      const { networkingEventID } = action.payload;
      state.visitorEvents.data = state.visitorEvents.data.map((item) => {
        return item.networkingEventID === networkingEventID ? { ...item, isLoading: true } : item;
      });
    },
    successLeaveVisitorEvents(state, action: PayloadAction<{ networkingEventID: string }>) {
      const { networkingEventID } = action.payload;
      state.visitorEvents.data = state.visitorEvents.data.filter((item) => {
        return item.networkingEventID !== networkingEventID;
      });
    },
    failLeaveVisitorEvents(state, action: PayloadAction<{ networkingEventID: string }>) {
      const { networkingEventID } = action.payload;
      state.visitorEvents.data = state.visitorEvents.data.map((item) => {
        return item.networkingEventID === networkingEventID ? { ...item, isLoading: false } : item;
      });
    },

    loadMoreExhibitorEvents() {},
    requestGetExhibitorEvents(state, action: PayloadAction<{ isMore: boolean }>) {
      state.exhibitorEvents.loading = true;
      state.exhibitorEvents.error = null;
    },
    successGetExhibitorEvents(
      state,
      action: PayloadAction<{ data: MyExhibitorEvent[]; count: number; isMore: boolean }>,
    ) {
      const {
        payload: { data, count, isMore },
      } = action;
      state.exhibitorEvents.loading = false;

      const newData = isMore ? [...state.exhibitorEvents.data, ...data] : data;

      state.exhibitorEvents.data = newData.map((item) => ({ ...item, isLoading: false }));
      state.exhibitorEvents.pagination.count = count;
    },
    failGetExhibitorEvents(state, action: PayloadAction<Error>) {
      state.exhibitorEvents.loading = false;
      state.exhibitorEvents.error = action.payload;
    },

    requestInvitationExhibitorEvents(
      state,
      action: PayloadAction<{ networkingEventRepresentativeID: string; accept: boolean }>,
    ) {
      const { networkingEventRepresentativeID } = action.payload;
      state.exhibitorEvents.data = state.exhibitorEvents.data.map((item) => {
        return item.networkingEventRepresentativeID === networkingEventRepresentativeID
          ? { ...item, isLoading: true }
          : item;
      });
    },
    successInvitationExhibitorEvents(
      state,
      action: PayloadAction<{ networkingEventRepresentativeID: string }>,
    ) {
      const { networkingEventRepresentativeID } = action.payload;
      state.exhibitorEvents.data = state.exhibitorEvents.data.map((item) => {
        return item.networkingEventRepresentativeID === networkingEventRepresentativeID
          ? { ...item, isLoading: false }
          : item;
      });
    },
    failInvitationExhibitorEvents(
      state,
      action: PayloadAction<{ networkingEventRepresentativeID: string }>,
    ) {
      const { networkingEventRepresentativeID } = action.payload;
      state.exhibitorEvents.data = state.exhibitorEvents.data.map((item) => {
        return item.networkingEventRepresentativeID === networkingEventRepresentativeID
          ? { ...item, isLoading: false }
          : item;
      });
    },

    setExhibitorEventsPagination(
      state,
      action: PayloadAction<Partial<StateMyEvents['exhibitorEvents']['pagination']>>,
    ) {
      state.exhibitorEvents.pagination = { ...state.exhibitorEvents.pagination, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionAccountLogout.success, (state) => {
      return initState;
    });
  },
});

const {
  requestGetVisitorEvents,
  successGetVisitorEvents,
  failGetVisitorEvents,
  requestLeaveVisitorEvents,
  successLeaveVisitorEvents,
  failLeaveVisitorEvents,

  requestGetExhibitorEvents,
  successGetExhibitorEvents,
  failGetExhibitorEvents,
  requestInvitationExhibitorEvents,
  successInvitationExhibitorEvents,
  failInvitationExhibitorEvents,
} = slice.actions;

export const getMyVisitorEventsActions = combineActions(
  requestGetVisitorEvents,
  successGetVisitorEvents,
  failGetVisitorEvents,
);
export const leaveMyVisitorEventsActions = combineActions(
  requestLeaveVisitorEvents,
  successLeaveVisitorEvents,
  failLeaveVisitorEvents,
);

export const getMyExhibitorEventsActions = combineActions(
  requestGetExhibitorEvents,
  successGetExhibitorEvents,
  failGetExhibitorEvents,
);
export const invitationMyExhibitorEventsActions = combineActions(
  requestInvitationExhibitorEvents,
  successInvitationExhibitorEvents,
  failInvitationExhibitorEvents,
);

export const {
  loadMoreVisitorEvents: loadMoreMyVisitorEvents,
  setVisitorEventsPagination: setMyVisitorEventsPagination,
  loadMoreExhibitorEvents: loadMoreMyExhibitorEvents,
  setExhibitorEventsPagination: setMyExhibitorEventsPagination,
} = slice.actions;

export const reducerMyEvents = slice.reducer;
