import React from 'react';
import style from './index.module.scss';
import { Button, Theme, Tooltip, Typography, useMediaQuery } from '@material-ui/core';

import { AppAvatar } from 'components/app-avatar';
import { AppAvatarGroup } from 'components/app-avatar-group';
import { useOpen, useTranslate } from 'hooks';
import clsx from 'clsx';
import { ChatTooltipDisabled } from 'components/chat-tooltip-disabled';
import { ButtonMeet } from 'pages/Event/components/button-meet';

interface ExhibitorCardProps {
  eventID: string;
  id: string;
  title: string;
  picture: string;
  countryTitle: string;
  countryFlag: string;
  categoryTitle: string;
  users: { firstName: string; lastName: string; profilePicture?: string }[];
  isOwn: boolean;
  onClick: () => void;
  isDisabledMeeting: boolean;
}

export const CardLine: React.FC<ExhibitorCardProps> = ({
  title,
  picture,
  countryTitle,
  categoryTitle,
  countryFlag,
  users = [],
  isOwn,
  onClick,
  isDisabledMeeting,
}) => {
  const { t } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { isOpen: isHover, onOpen: onMouseEnter, onClose: onMouseLeave } = useOpen();

  return (
    <div
      className={clsx(style.item, isOwn && style.active)}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={style.avatar}>
        <AppAvatar isCompany={true} src={picture} />
      </div>
      <div className={style.data}>
        <Typography component={'div'}>{title}</Typography>
        <Typography component={'div'} variant={'body2'} style={{ color: 'rgba(0, 0, 0, 0.54) ' }}>
          {categoryTitle}
        </Typography>
      </div>
      <div className={style.location}>
        {(countryTitle || countryFlag) && (
          <div className={style.country}>
            {countryFlag && <img src={countryFlag} alt={''} />}
            <Typography component={'div'} variant={'body2'} className={style.countryName}>
              {countryTitle || ''}
            </Typography>
          </div>
        )}
      </div>
      <div className={style.users}>
        {users.length > 0 && (
          <AppAvatarGroup max={5}>
            {users.map((item, i) => {
              return (
                <Tooltip key={i} title={`${item.firstName} ${item.lastName}`}>
                  <div>
                    <AppAvatar
                      src={item.profilePicture}
                      firstName={item.firstName}
                      lastName={item.lastName}
                      size={'xs'}
                    />
                  </div>
                </Tooltip>
              );
            })}
          </AppAvatarGroup>
        )}
      </div>
      <div className={style.control}>
        <div className={style.controlBox}>
          {isOwn ? (
            <Button
              className={style.buttonSecondary}
              color={'secondary'}
              variant={'contained'}
              size={isMobile ? 'small' : 'medium'}
              fullWidth
            >
              {t('edit-my-booth')}
            </Button>
          ) : (
            <ChatTooltipDisabled disabled={!isDisabledMeeting}>
              <div>
                <ButtonMeet
                  isActive={isHover}
                  size={isMobile ? 'small' : 'medium'}
                  disabled={isDisabledMeeting}
                />
              </div>
            </ChatTooltipDisabled>
          )}
        </div>
      </div>
    </div>
  );
};
