import React, { PropsWithChildren, useCallback } from 'react';
import { Checkbox, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { useMap } from 'hooks';
import style from './index.module.scss';

interface Option<T> {
  id: T;
  title: string;
}

interface Props<T> {
  label?: string;
  options: Option<T>[];
  value: Option<T>['id'][];
  onChange: (v: this['value']) => void;
}

export const CheckboxGroup = <T extends string | number>({
  value,
  label,
  options,
  onChange,
}: PropsWithChildren<Props<T>>) => {
  const mapSelectedIDs = useMap(value);

  const onChangeItem = useCallback(
    (id: Option<T>['id'], checked: boolean) => {
      if (checked) {
        onChange([...value, id]);
      } else {
        onChange([...value].filter((idItem) => idItem !== id));
      }
    },
    [value, onChange],
  );
  return (
    <>
      <FormLabel
        classes={{
          root: style.title,
        }}
      >
        {label}
      </FormLabel>
      <FormGroup
        classes={{
          root: style.group,
        }}
      >
        {options.map(({ id, title }) => {
          const isChecked = Boolean(mapSelectedIDs[String(id)]);
          return (
            <FormControlLabel
              label={title}
              key={id}
              className={isChecked ? style.checked : ''}
              classes={{
                label: style.label,
                root: style.control,
              }}
              control={
                <Checkbox
                  checked={isChecked}
                  className={isChecked ? style.checked : ''}
                  checkedIcon={<DoneIcon color={'secondary'} />}
                  onChange={(e, checked) => onChangeItem(id, checked)}
                  classes={{
                    root: style.checkbox,
                  }}
                />
              }
            />
          );
        })}
      </FormGroup>
    </>
  );
};
