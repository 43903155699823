import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslate } from 'hooks';
import { Typography } from '@material-ui/core';

import style from './index.module.scss';

interface Classes {
  root: string;
  icon: string;
  title: string;
  text1: string;
  text2: string;
  text3: string;
}

interface LayoutNoDataProps {
  isNoData: boolean;
  classes?: Partial<Classes>;
}

export const LayoutNoData: React.FC<LayoutNoDataProps> = ({ isNoData, children, classes }) => {
  const { t } = useTranslate();
  return (
    <>
      {isNoData && (
        <div className={style.wrap}>
          <div className={style.icon}>
            <SearchIcon fontSize={'inherit'} />
          </div>
          <Typography component={'h2'} variant={'h2'} color={'primary'} className={style.title}>
            {t('no-results-title')}
          </Typography>
          <Typography variant={'h4'} className={style.subtitle}>
            {t('no-results-subtitle-line1')}
          </Typography>
          <Typography>{t('no-results-subtitle-line2')}</Typography>
          <Typography>{t('no-results-subtitle-line3')}</Typography>
        </div>
      )}
      {!isNoData && children}
    </>
  );
};
