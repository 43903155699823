import React, { memo } from 'react';

interface Props {
  disabled?: boolean;
}

export const IconAwardDescription = memo<Props>(({ disabled }) => {
  return !disabled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="48" viewBox="0 0 38 48" fill="none">
      <path
        d="M6.31173 33.9789C6.1328 33.425 5.81748 32.9251 5.39477 32.525C5.24672 32.3818 5.0864 32.2519 4.91564 32.1367L0.107814 41.3724C0.0461642 41.4938 0.0152283 41.6284 0.0177173 41.7645C0.0202063 41.9006 0.0560438 42.0341 0.12209 42.1531C0.188136 42.2722 0.282374 42.3732 0.396543 42.4473C0.510713 42.5215 0.641329 42.5665 0.776944 42.5785L5.76651 42.9502L8.89738 37.0106C7.6728 36.3763 6.74483 35.2882 6.31173 33.9789Z"
        fill="#5FB800"
      />
      <path
        d="M15.505 38.3315C15.4967 38.3149 15.4802 38.3067 15.4637 38.2902C14.8087 37.7199 13.9695 37.406 13.1011 37.4063C12.8515 37.4076 12.6026 37.4325 12.3576 37.4806C11.7412 37.615 11.105 37.6319 10.4824 37.5302L7.24414 43.6762L10.4741 47.6084C10.5498 47.7045 10.6465 47.782 10.7568 47.835C10.867 47.8879 10.9879 47.915 11.1102 47.9141C11.138 47.9154 11.1659 47.9126 11.1928 47.9058C11.3296 47.8935 11.4611 47.8469 11.5752 47.7704C11.6892 47.6939 11.7822 47.5899 11.8454 47.468L16.2981 38.9097C16.0189 38.7421 15.7561 38.5485 15.5133 38.3315H15.505Z"
        fill="#5FB800"
      />
      <path
        d="M15.505 38.3315C15.4967 38.3149 15.4802 38.3067 15.4637 38.2902C14.8087 37.7199 13.9695 37.406 13.1011 37.4063C12.8515 37.4076 12.6026 37.4325 12.3576 37.4806C11.7412 37.615 11.105 37.6319 10.4824 37.5302L7.24414 43.6762L10.4741 47.6084C10.5498 47.7045 10.6465 47.782 10.7568 47.835C10.867 47.8879 10.9879 47.915 11.1102 47.9141C11.138 47.9154 11.1659 47.9126 11.1928 47.9058C11.3296 47.8935 11.4611 47.8469 11.5752 47.7704C11.6892 47.6939 11.7822 47.5899 11.8454 47.468L16.2981 38.9097C16.0189 38.7421 15.7561 38.5485 15.5133 38.3315H15.505Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M26.699 37.5963C26.3437 37.5902 25.9898 37.5515 25.6416 37.4806C25.3967 37.4325 25.1477 37.4077 24.8981 37.4063C24.0136 37.4047 23.1599 37.7315 22.5025 38.3232C22.2556 38.5449 21.9872 38.7414 21.7012 38.9097L26.1538 47.468C26.217 47.5899 26.31 47.6939 26.424 47.7704C26.5381 47.847 26.6696 47.8935 26.8064 47.9058C26.8334 47.9127 26.8612 47.9154 26.889 47.9141C27.0113 47.915 27.1322 47.8879 27.2425 47.835C27.3527 47.782 27.4494 47.7045 27.5251 47.6084L30.7055 43.7341L27.4673 37.5384C27.2132 37.5788 26.9562 37.5982 26.699 37.5963Z"
        fill="#5FB800"
      />
      <path
        d="M33.0846 32.1289C32.4206 32.5672 31.9276 33.2206 31.6885 33.9793C31.249 35.3021 30.3049 36.3977 29.0615 37.0276L32.1676 42.9589L37.215 42.5789C37.3514 42.5684 37.4831 42.5244 37.5985 42.4508C37.7138 42.3772 37.8092 42.2763 37.8762 42.157C37.9432 42.0377 37.9797 41.9037 37.9825 41.7669C37.9854 41.6301 37.9544 41.4948 37.8924 41.3728L33.0846 32.1289Z"
        fill="#5FB800"
      />
      <path
        d="M33.0846 32.1289C32.4206 32.5672 31.9276 33.2206 31.6885 33.9793C31.249 35.3021 30.3049 36.3977 29.0615 37.0276L32.1676 42.9589L37.215 42.5789C37.3514 42.5684 37.4831 42.5244 37.5985 42.4508C37.7138 42.3772 37.8092 42.2763 37.8762 42.157C37.9432 42.0377 37.9797 41.9037 37.9825 41.7669C37.9854 41.6301 37.9544 41.4948 37.8924 41.3728L33.0846 32.1289Z"
        fill="black"
        fillOpacity="0.2"
      />
      <g filter="url(#filter0_ii_6280_58493)">
        <path
          d="M16.2509 1.15958C17.7576 -0.386527 20.2424 -0.386527 21.7491 1.15958C22.7309 2.16717 24.1853 2.55688 25.5395 2.17521C27.6173 1.58956 29.7692 2.83199 30.301 4.92428C30.6475 6.28781 31.7122 7.35251 33.0757 7.69903C35.168 8.23076 36.4104 10.3827 35.8248 12.4605C35.4431 13.8147 35.8328 15.2691 36.8404 16.2509C38.3865 17.7576 38.3865 20.2424 36.8404 21.7491C35.8328 22.7309 35.4431 24.1853 35.8248 25.5395C36.4104 27.6173 35.168 29.7692 33.0757 30.301C31.7122 30.6475 30.6475 31.7122 30.301 33.0757C29.7692 35.168 27.6173 36.4104 25.5395 35.8248C24.1853 35.4431 22.7309 35.8328 21.7491 36.8404C20.2424 38.3865 17.7576 38.3865 16.2509 36.8404C15.2691 35.8328 13.8147 35.4431 12.4605 35.8248C10.3827 36.4104 8.23076 35.168 7.69903 33.0757C7.35251 31.7122 6.28781 30.6475 4.92428 30.301C2.83199 29.7692 1.58956 27.6173 2.17521 25.5395C2.55688 24.1853 2.16717 22.7309 1.15958 21.7491C-0.386527 20.2424 -0.386527 17.7576 1.15958 16.2509C2.16717 15.2691 2.55688 13.8147 2.17521 12.4605C1.58956 10.3827 2.83199 8.23076 4.92428 7.69903C6.28781 7.35251 7.35251 6.28781 7.69903 4.92428C8.23076 2.83199 10.3827 1.58956 12.4605 2.17521C13.8147 2.55688 15.2691 2.16717 16.2509 1.15958Z"
          fill="#FFCE35"
        />
      </g>
      <g filter="url(#filter1_i_6280_58493)">
        <circle cx="19.0004" cy="18.9985" r="14.8696" fill="#EFAB08" />
      </g>
      <g filter="url(#filter2_di_6280_58493)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.7182 12.9815C26.9614 10.5558 29.1784 8.80823 29.5697 9.01689C29.8566 9.17339 29.8566 10.8166 29.5436 13.0076C29.5436 13.0337 29.5175 13.0598 29.4914 13.0858L26.4136 15.6941L28.9437 15.1986C29.048 15.1986 29.1263 15.2768 29.1002 15.3551C28.8654 16.4766 28.5524 17.6504 28.1873 18.7719L28.1351 18.8241L24.5878 21.615L26.9875 21.2759C27.0918 21.2498 27.17 21.3541 27.1179 21.4585C25.7616 24.3276 23.9097 26.3099 21.5361 25.397C21.1188 26.3882 20.858 27.4315 20.7536 27.8749C20.7275 28.0053 20.5971 28.1096 20.4406 28.1096H18.98C18.7452 28.1096 18.5887 27.8227 18.7192 27.6141L20.4667 24.7449C19.1626 23.6494 19.1626 21.928 19.8929 20.0239C19.9451 19.9457 20.0755 19.9196 20.1276 19.9978L21.6144 21.9019L21.0666 17.7547C21.0666 17.7286 21.0666 17.7025 21.0927 17.6764C21.6665 16.7375 22.3186 15.7985 23.0489 14.9116C23.1272 14.8334 23.2576 14.8595 23.2837 14.9638L23.9097 17.1026L24.6922 13.0598C24.6922 13.0337 24.6922 13.0076 24.7182 12.9815ZM10.0433 13.0866H22.0322C22.4307 12.5932 22.8292 12.155 23.1775 11.7721L23.1776 11.772L23.1777 11.7719C23.308 11.6286 23.4313 11.493 23.545 11.3652C23.5972 11.3 23.6558 11.2413 23.7145 11.1826C23.7732 11.1239 23.8319 11.0652 23.8841 11H10.0433C9.46949 11 9 11.4695 9 12.0433C9 12.6171 9.46949 13.0866 10.0433 13.0866ZM6 16.9984C6 16.4246 6.46949 15.9551 7.04332 15.9551V15.9812H19.8673C19.4239 16.6332 19.0066 17.3375 18.6675 18.0417H7.04332C6.46949 18.0417 6 17.5722 6 16.9984ZM6 21.9554C6 21.3816 6.46949 20.9121 7.04332 20.9121V20.9382H17.6502C17.4937 21.6946 17.4937 22.3988 17.572 22.9987H7.04332C6.46949 22.9987 6 22.5293 6 21.9554ZM9 26.9105C9 27.4843 9.46949 27.9538 10.0433 27.9538H16.8156C16.7634 27.4843 16.8678 26.9888 17.1286 26.5453L17.5459 25.8672H10.0694C9.46949 25.8672 9 26.3367 9 26.9105Z"
          fill="#FFCE35"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii_6280_58493"
          x="0"
          y="0"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.984314 0 0 0 0 0.741176 0 0 0 0 0.109804 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6280_58493" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0 0.643137 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_6280_58493"
            result="effect2_innerShadow_6280_58493"
          />
        </filter>
        <filter
          id="filter1_i_6280_58493"
          x="4.13086"
          y="4.12891"
          width="29.7393"
          height="29.7383"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0 0.00392157 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6280_58493" />
        </filter>
        <filter
          id="filter2_di_6280_58493"
          x="6"
          y="9"
          width="24.7812"
          height="20.1094"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0 0.00392157 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6280_58493" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6280_58493"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0 0.643137 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_6280_58493" />
        </filter>
      </defs>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="48" viewBox="0 0 38 48" fill="none">
      <g opacity="0.2">
        <path
          d="M6.31173 33.9789C6.1328 33.425 5.81748 32.9251 5.39477 32.525C5.24672 32.3818 5.0864 32.2519 4.91564 32.1367L0.107814 41.3724C0.0461642 41.4938 0.0152283 41.6284 0.0177173 41.7645C0.0202063 41.9006 0.0560438 42.0341 0.12209 42.1531C0.188136 42.2722 0.282374 42.3732 0.396543 42.4473C0.510713 42.5215 0.641329 42.5665 0.776944 42.5785L5.76651 42.9502L8.89738 37.0106C7.6728 36.3763 6.74483 35.2882 6.31173 33.9789Z"
          fill="#CFDCE7"
        />
        <path
          d="M15.505 38.3315C15.4967 38.3149 15.4802 38.3067 15.4637 38.2902C14.8087 37.7199 13.9695 37.406 13.1011 37.4063C12.8515 37.4076 12.6026 37.4325 12.3576 37.4806C11.7412 37.615 11.105 37.6319 10.4824 37.5302L7.24414 43.6762L10.4741 47.6084C10.5498 47.7045 10.6465 47.782 10.7568 47.835C10.867 47.8879 10.9879 47.915 11.1102 47.9141C11.138 47.9154 11.1659 47.9126 11.1928 47.9058C11.3296 47.8935 11.4611 47.8469 11.5752 47.7704C11.6892 47.6939 11.7822 47.5899 11.8454 47.468L16.2981 38.9097C16.0189 38.7421 15.7561 38.5485 15.5133 38.3315H15.505Z"
          fill="#CFDCE7"
        />
        <path
          d="M26.699 37.5963C26.3437 37.5902 25.9898 37.5515 25.6416 37.4806C25.3967 37.4325 25.1477 37.4077 24.8981 37.4063C24.0136 37.4047 23.1599 37.7315 22.5025 38.3232C22.2556 38.5449 21.9872 38.7414 21.7012 38.9097L26.1538 47.468C26.217 47.5899 26.31 47.6939 26.424 47.7704C26.5381 47.847 26.6696 47.8935 26.8064 47.9058C26.8334 47.9127 26.8612 47.9154 26.889 47.9141C27.0113 47.915 27.1322 47.8879 27.2425 47.835C27.3527 47.782 27.4494 47.7045 27.5251 47.6084L30.7055 43.7341L27.4673 37.5384C27.2132 37.5788 26.9562 37.5982 26.699 37.5963Z"
          fill="#CFDCE7"
        />
        <path
          d="M33.0846 32.1289C32.4206 32.5672 31.9276 33.2206 31.6885 33.9793C31.249 35.3021 30.3049 36.3977 29.0615 37.0276L32.1676 42.9589L37.215 42.5789C37.3514 42.5684 37.4831 42.5244 37.5985 42.4508C37.7138 42.3772 37.8092 42.2763 37.8762 42.157C37.9432 42.0377 37.9797 41.9037 37.9825 41.7669C37.9854 41.6301 37.9544 41.4948 37.8924 41.3728L33.0846 32.1289Z"
          fill="#CFDCE7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7491 1.15958C20.2424 -0.386527 17.7576 -0.386527 16.2509 1.15958C15.2691 2.16717 13.8147 2.55688 12.4605 2.17521C10.3827 1.58956 8.23076 2.83199 7.69903 4.92428C7.35251 6.28781 6.28781 7.35251 4.92428 7.69903C2.83199 8.23076 1.58956 10.3827 2.17521 12.4605C2.55688 13.8147 2.16717 15.2691 1.15958 16.2509C-0.386527 17.7576 -0.386527 20.2424 1.15958 21.7491C2.16717 22.7309 2.55688 24.1853 2.17521 25.5395C1.58956 27.6173 2.83199 29.7692 4.92428 30.301C6.28781 30.6475 7.35251 31.7122 7.69903 33.0757C8.23076 35.168 10.3827 36.4104 12.4605 35.8248C13.8147 35.4431 15.2691 35.8328 16.2509 36.8404C17.7576 38.3865 20.2424 38.3865 21.7491 36.8404C22.7309 35.8328 24.1853 35.4431 25.5395 35.8248C27.6173 36.4104 29.7692 35.168 30.301 33.0757C30.6475 31.7122 31.7122 30.6475 33.0757 30.301C35.168 29.7692 36.4104 27.6173 35.8248 25.5395C35.4431 24.1853 35.8328 22.7309 36.8404 21.7491C38.3865 20.2424 38.3865 17.7576 36.8404 16.2509C35.8328 15.2691 35.4431 13.8147 35.8248 12.4605C36.4104 10.3827 35.168 8.23076 33.0757 7.69903C31.7122 7.35251 30.6475 6.28781 30.301 4.92428C29.7692 2.83199 27.6173 1.58956 25.5395 2.17521C24.1853 2.55688 22.7309 2.16717 21.7491 1.15958ZM19 34C27.2843 34 34 27.2843 34 19C34 10.7157 27.2843 4 19 4C10.7157 4 4 10.7157 4 19C4 27.2843 10.7157 34 19 34Z"
          fill="#CFDCE7"
        />
        <path
          d="M22.0322 13.0866H10.0433C9.46949 13.0866 9 12.6171 9 12.0433C9 11.4695 9.46949 11 10.0433 11H23.8841C23.8319 11.0652 23.7732 11.1239 23.7145 11.1826C23.6558 11.2413 23.5972 11.3 23.545 11.3652C23.4313 11.493 23.308 11.6287 23.1776 11.772C22.8293 12.155 22.4307 12.5932 22.0322 13.0866Z"
          fill="#CFDCE7"
        />
        <path
          d="M7.04332 15.9551C6.46949 15.9551 6 16.4246 6 16.9984C6 17.5722 6.46949 18.0417 7.04332 18.0417H18.6675C19.0066 17.3375 19.4239 16.6332 19.8673 15.9812H7.04332V15.9551Z"
          fill="#CFDCE7"
        />
        <path
          d="M6 21.9554C6 21.3816 6.46949 20.9121 7.04332 20.9121V20.9382H17.6502C17.4937 21.6946 17.4937 22.3988 17.572 22.9987H7.04332C6.46949 22.9987 6 22.5293 6 21.9554Z"
          fill="#CFDCE7"
        />
        <path
          d="M10.0433 27.9538C9.46949 27.9538 9 27.4843 9 26.9105C9 26.3367 9.46949 25.8672 10.0694 25.8672H17.5459L17.1286 26.5453C16.8678 26.9888 16.7634 27.4843 16.8156 27.9538H10.0433Z"
          fill="#CFDCE7"
        />
        <path
          d="M29.5697 9.01689C29.1784 8.80823 26.9614 10.5558 24.7182 12.9815C24.6922 13.0076 24.6922 13.0337 24.6922 13.0598L23.9097 17.1026L23.2837 14.9638C23.2576 14.8595 23.1272 14.8334 23.0489 14.9116C22.3186 15.7985 21.6665 16.7375 21.0927 17.6764C21.0666 17.7025 21.0666 17.7286 21.0666 17.7547L21.6144 21.9019L20.1276 19.9978C20.0755 19.9196 19.9451 19.9457 19.8929 20.0239C19.1626 21.928 19.1626 23.6494 20.4667 24.7449L18.7192 27.6141C18.5887 27.8227 18.7452 28.1096 18.98 28.1096H20.4406C20.5971 28.1096 20.7275 28.0053 20.7536 27.8749C20.858 27.4315 21.1188 26.3882 21.5361 25.397C23.9097 26.3099 25.7616 24.3276 27.1179 21.4585C27.17 21.3541 27.0918 21.2498 26.9875 21.2759L24.5878 21.615L28.1351 18.8241C28.1612 18.798 28.1612 18.798 28.1873 18.7719C28.5524 17.6504 28.8654 16.4766 29.1002 15.3551C29.1263 15.2768 29.048 15.1986 28.9437 15.1986L26.4136 15.6941L29.4914 13.0858C29.5175 13.0598 29.5436 13.0337 29.5436 13.0076C29.8566 10.8166 29.8566 9.17339 29.5697 9.01689Z"
          fill="#CFDCE7"
        />
      </g>
    </svg>
  );
});
