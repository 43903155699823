import { useMemo } from 'react';
import { isValidURL } from 'utils';

const PREFIXES = ['http://', 'https://'];

export const useUrl = (link: string | null | undefined) => {
  const link_ = useMemo(() => {
    const l_ = String(link);
    if (PREFIXES.some((prefix) => l_.startsWith(prefix))) {
      return link || '';
    } else {
      return `http://${link}`;
    }
  }, [link]);

  const isValid = useMemo(() => {
    return isValidURL(link_);
  }, [link_]);

  return useMemo(() => {
    return {
      link: link_,
      isValid,
    };
  }, [link_, isValid]);
};
