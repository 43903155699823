import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useTranslate } from 'hooks';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import styles from './index.module.scss';

interface AppButtonFilterProps {
  isOpen: boolean;
  onToggle: () => void;
  className?: string;
}

export const AppButtonFilter: React.FC<AppButtonFilterProps> = ({
  isOpen,
  onToggle,
  className,
}) => {
  const { t } = useTranslate();
  return (
    <Button
      onClick={onToggle}
      color={'primary'}
      className={clsx(styles.filterBtn, className)}
      size={'small'}
      variant={isOpen ? 'outlined' : 'contained'}
      startIcon={<FilterListIcon />}
    >
      {t(isOpen ? 'hide' : 'advanced-filter')}
    </Button>
  );
};
