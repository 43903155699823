import React, { useCallback } from 'react';
import { Tab, TabProps, Tabs } from '@material-ui/core';
import style from './index.module.scss';

interface Item extends TabProps {}

interface Props<T> {
  value?: T;
  onChange?: (v: T) => void;
  items: Item[];
}

export const TabButtons = <T,>({ value, onChange, items }: Props<T>) => {
  const _onChange = useCallback(
    (event: React.ChangeEvent<{}>, v: any) => {
      onChange && onChange(v);
    },
    [onChange],
  );
  return (
    <Tabs
      classes={{
        root: style.tabs,
        flexContainer: style.container,
        indicator: style.indicator,
      }}
      className={style.tabs}
      indicatorColor={'primary'}
      value={value}
      onChange={_onChange}
    >
      {items.map((item, i) => {
        return (
          <Tab
            key={i}
            {...item}
            classes={{
              root: style.item,
              selected: style.itemSelected,
            }}
          />
        );
      })}
    </Tabs>
  );
};
