import React from 'react';
import { DialogPrivacy, DialogTermsAndCondition } from 'components/dialogs';
import style from './index.module.scss';
import clsx from 'clsx';
import { useOpen, useTranslate } from 'hooks';

interface Props {
  className?: string;
}

export const AppFooterTransparent: React.FC<Props> = ({ className }) => {
  const { t } = useTranslate();

  const { isOpen: isOpenPrivacy, onToggle: onTogglePrivacy, onClose: onCLosePrivacy } = useOpen();
  const { isOpen: isOpenTerms, onToggle: onToggleTerms, onClose: onCloseTerms } = useOpen();

  return (
    <footer className={clsx(className, style.footer)}>
      <div className={clsx('container', style.inner)}>
        <ul className={style.nav}>
          <li>
            <a
              className={style.link}
              href={process.env.REACT_APP_URL_WEBSITE}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              {t('about')}
            </a>
          </li>
          <li>
            <span className={style.link} onClick={onTogglePrivacy}>
              {t('privacy')}
            </span>
          </li>
          <li>
            <span className={style.link} onClick={onToggleTerms}>
              {t('terms')}
            </span>
          </li>
        </ul>
        <div className={style.copyrights}>{t('copyrights')}</div>
        {isOpenPrivacy && <DialogPrivacy onClose={onCLosePrivacy} />}
        {isOpenTerms && <DialogTermsAndCondition onClose={onCloseTerms} />}
      </div>
    </footer>
  );
};
