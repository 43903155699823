export enum ENDPOINTS_PARAMETERS {
  GET_NETWORK_ITEMS = 'Parameters/GetNetworkItemsDynamic',
  GET_FIELD_OF_OCCUPATIONS = 'Parameters/GetFieldOfOccupationsDynamic',
  GET_REGIONS = 'Parameters/GetRegionsDynamic',
  GET_MEDICAL_FIELDS = 'Parameters/GetMedicalFieldsDynamic',
  GET_HEALTH_CARE_CATEGORIES = 'Parameters/GetHealthCareCategoriesDynamic',
  GET_COUNTRIES = 'Parameters/GetCountriesDynamic',
  GET_FIELD_OF_SERVICES = 'Parameters/GetFieldOfServicesDynamic',
  GET_PROFESSIONAL_FIELD_OF_INTERESTS = 'Parameters/GetProfessionalFieldOfInterestsDynamic',
  GET_BUSINESS_FIELD_OF_INTERESTS = 'Parameters/GetBusinessFieldOfInterestsDynamic',
  GET_DEVELOPMENT_STAGES = 'Parameters/GetDevelopmentStagesDynamic',
  GET_TITLE_OG_NAMES = 'Parameters/GetTitleOfNamesDynamic',
  GET_SUBJECTS = 'Parameters/GetEmailSubjectsDynamic',
  GET_PRODUCT_TYPES = 'Parameters/GetProductTypesDynamic',
  GET_INGREDIENT_TYPES = 'Parameters/GetIngredientTypesDynamic',
  GET_ACTIVITIES = 'Parameters/GetBusinessActivitiesDynamic',
  GET_FULL_STORY_CODE = 'Parameters/GetFullStoryCode',
}

export abstract class BaseParameter {
  id: number = 0;
  title: string = '';
}

export class NetworkItem extends BaseParameter {
  icon: string = '';
  companyNameLabel: string = '';
  descriptionLabel: string = '';
  fileLabel: string = '';
  medicalFieldLabel: string = '';
  defaultHealthCareCategoryID: number = 0;
  displayDevelopmentStage: boolean = true;
  displayFieldOfOccupation: boolean = true;
  displayHealthCareCategory: boolean = true;
  displayFieldOfService: boolean = true;
  displayMedicalField: boolean = true;
  displayProfessionalFieldsOfInterest: boolean = true;

  displayCompanyBusinessActivity: boolean = false;
  displayCompanyBusinessFieldOfInterest: boolean = false;
  displayCompanyDevelopmentStage: boolean = true;
  displayCompanyFieldOfService: boolean = true;
  displayCompanyHealthCareCategory: boolean = true;
  displayCompanyIngredientType: boolean = false;
  displayCompanyMedicalField: boolean = false;
  displayCompanyNumberOfMonitoredStudies: boolean = false;
  displayCompanyProductType: boolean = false;
  displayCompanySalesTurnover: boolean = false;

  displayCompanyNumberOfPhysicians: boolean = false;
  displayCompanyMedicalTourism: boolean = false;
  displayCompanyMainResearchField: boolean = false;
  displayCompanyNumberOfResearchers: boolean = false;
  displayCompanyTTO: boolean = false;
  displayCompanyNumberOfEmployees: boolean = false;
}

export class FieldOfOccupation extends BaseParameter {}

export class Region extends BaseParameter {}

export class MedicalField extends BaseParameter {}

export class HealthCareCategory extends BaseParameter {}

export class ProductType extends BaseParameter {}

export class IngredientType extends BaseParameter {}

export class FieldOfService extends BaseParameter {}

export class ProfessionalFieldOfInterest extends BaseParameter {}

export class DevelopmentStage extends BaseParameter {}

export class TitleOfName extends BaseParameter {}

export class Country extends BaseParameter {
  countryCode: string = '';
  internationalDailingCode: string = '';
  flag: string = '';
  isAllowToSendSMS: boolean = false;
}

export class Subject extends BaseParameter {}

export class BusinessFieldOfInterest extends BaseParameter {}

export class BusinessActivity extends BaseParameter {}
