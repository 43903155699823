import React from 'react';
import style from './index.module.scss';
import { useDateFormatSplit, useTranslate } from 'hooks';
import clsx from 'clsx';

interface Props {
  date: string | null | undefined;
  label: string;
  variant?: 'light' | 'dark';
}

export const DateBox: React.FC<Props> = ({ date, label, variant = 'dark' }) => {
  const dividedDate = useDateFormatSplit(date);
  const { t } = useTranslate();
  return (
    <div className={clsx(style.box, style[variant])}>
      <div className={style.heading}>{t(label)}</div>
      <div className={style.date}>
        <div className={style.number}>{dividedDate.day}</div>
        <div>{dividedDate.month}</div>
        <div>{dividedDate.year}</div>
      </div>
    </div>
  );
};
