import React from 'react';
import { APP_ROUTES } from 'configs/routes';
import { Loading } from 'components/loading';
import { UserStatusType } from 'configs';

interface Props {
  userStatusType: UserStatusType;
}

export const RouteUserStatusWrap: React.FC<Props> = ({ children, userStatusType }) => {
  if (userStatusType === UserStatusType.ACTIVE) {
    return <>{children}</>;
  } else {
    window.location.replace(APP_ROUTES.LANDING_LOGIN.getUrl()({ redirect: window.location.href }));
    return <Loading active={true} />;
  }
};
