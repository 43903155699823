import React, {useEffect} from "react";
import ReactGA from "react-ga"

interface GAProviderProps {
  trackingID: string
}

export const GAProvider: React.FC<GAProviderProps> = ({trackingID, children}) => {
  useEffect(() => {
    ReactGA
      .initialize(trackingID, {
        debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      })
  }, [trackingID])

  return (
    <>
      {children}
    </>
  )
}
