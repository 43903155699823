import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'configs/routes';
import { DEBUG_PASSWORD } from 'pages/__debug';
import { Fab } from '@material-ui/core';
import AdbIcon from '@material-ui/icons/Adb';
import { useDebugMode } from 'hooks';

export const HeaderDebugButton: React.FC = () => {
  const { isDebug } = useDebugMode();

  const { push } = useHistory();
  const onDebug = useCallback(() => {
    push(APP_ROUTES.DEBUG.getConfig()({ pass: DEBUG_PASSWORD }));
  }, [push]);

  return isDebug ? (
    <div>
      <Fab size={'small'} color={'secondary'} onClick={onDebug}>
        <AdbIcon />
      </Fab>
    </div>
  ) : null;
};
