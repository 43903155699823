import React from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { AppButtonPrimary, AppButtonSecondary } from '../../../app-buttons';
import { useTranslate } from 'hooks';
import clsx from 'clsx';
import './_style.scss';

interface FooterSubmitProps {
  className?: string;
  titleClose?: string;
  titleSubmit?: string;

  onClose(): void;

  onSubmit(): void;
}

export const FooterSubmit: React.FC<FooterSubmitProps> = ({
  onClose,
  onSubmit,
  titleClose,
  titleSubmit,
  className,
}) => {
  const { t } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classNames = clsx('footer-submit', className);
  return (
    <div className={classNames}>
      {!isMobile && (
        <AppButtonSecondary size={'small'} variant={'text'} onClick={onClose}>
          {titleClose || t('close')}
        </AppButtonSecondary>
      )}
      <AppButtonPrimary size={'small'} onClick={onSubmit} fullWidth={isMobile}>
        {titleSubmit || t('submit')}
      </AppButtonPrimary>
    </div>
  );
};
