import React, { useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss';
import clsx from 'clsx';

interface AnimationTextProps {
  className?: string;
}

export const AnimationText: React.FC<AnimationTextProps> = ({ children, className }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const res = useMemo(() => {
    return new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect) {
          setHeight(entry.contentRect.height);
        }
      }
    });
  }, []);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      res.observe(element);
    }
    return () => {
      if (element) {
        res.unobserve(element);
      }
    };
  }, [res]);

  return (
    <div className={clsx(className, style.wrap)}>
      <div className={style.collapse} style={{ height: `${height}px` }}>
        <div className={style.content} ref={ref}>
          {children}
        </div>
      </div>
    </div>
  );
};
