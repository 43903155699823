import { call, put, select, take, takeEvery, takeLatest } from 'modules/typed-saga';
import { selectChatConversationsList, selectChatStatuses } from './selectors';
import { Conversation, ServiceChat } from '../service';
import { parseErrorData } from 'utils';
import io, { Socket } from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import {
  actionChatConversationWasDeleted,
  actionChatGetConversations,
  actionChatStartChat,
  actionCustomChatUsersListSet,
  actionChatConversationOpen,
  actionChatStartChatNew,
  actionChatUserConnected,
  actionChatUserDisconnected,
  actionChatCameMessage,
  actionChatTyping,
  actionChatTypingCame,
} from 'components/custom-chat/store/slice';
import { Action } from 'redux';
import { workerErrorNotifySaga, workerErrorNotifyThunk } from 'store/_utils';
import {
  actionChatConversationDelete,
  actionChatConversationGet,
  actionChatMessageGet,
  actionChatSendMessage,
  actionChatStartConversation,
} from 'components/custom-chat/store/actions';
import { AppAudio } from 'modules/audio';
import { ClientToServerEvents, ServerToClientEvents } from '@shared/types';

export const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(
  process.env.REACT_APP_CHAT_SERVER as string,
  {
    transports: ['websocket'],
    autoConnect: false,
  },
);

function getSocketChannel() {
  return eventChannel((emitter) => {
    socket.on('connect', () => {
      emitter(actionChatGetConversations.request());
    });
    socket.on('users', (userIDs) => {
      emitter(actionCustomChatUsersListSet(userIDs));
    });
    socket.on('user_connected', (userID) => {
      emitter(actionChatUserConnected(userID));
    });
    socket.on('user_disconnected', (userID) => {
      emitter(actionChatUserDisconnected(userID));
    });

    socket.on('delete', (payload: { conversationID: string }) => {
      emitter(actionChatConversationWasDeleted(payload));
    });
    socket.on('message', (payload: { conversationID: string; messageID: string }) => {
      emitter(actionChatCameMessage(payload));
    });

    socket.on('typing', (payload) => {
      emitter(actionChatTypingCame(payload));
    });

    socket.on('connect_error', (error) => {
      console.error(error);
    });
    socket.onAny((event, ...args) => {
      console.log(event, args);
    });

    return () => {
      socket.removeListener('connect');
      socket.removeListener('disconnect');
      socket.removeListener('users');
      socket.removeListener('user_connected');
      socket.removeListener('user_disconnected');
      socket.removeListener('delete');
      socket.removeListener('message');
      socket.removeListener('typing');
    };
  });
}

function* chatChannelSagas() {
  const channel = yield* call(getSocketChannel);

  while (true) {
    const action = yield* take(channel);
    yield* put(action as Action);
  }
}

function* fetchConversations() {
  try {
    const {
      data: { value },
    } = yield* call(ServiceChat.getConversations);
    yield* put(actionChatGetConversations.success({ data: value }));
  } catch (e: any) {
    yield* put(actionChatGetConversations.fail({ error: parseErrorData(e) }));
  }
}

function watchSuccessDeleteConversation(
  action: ReturnType<typeof actionChatConversationDelete.fulfilled>,
) {
  const { conversationID, conversation } = action.payload;

  if (conversation) {
    socket.emit('delete', { toAppUserID: conversation.appUserID, conversationID });
  }
}

function watchSuccessSendMessage(
  action: ReturnType<
    typeof actionChatStartConversation.fulfilled | typeof actionChatSendMessage.fulfilled
  >,
) {
  const { appUserID, message, conversationID } = action.payload;

  socket.emit('message', {
    toAppUserID: appUserID,
    conversationID,
    messageID: message.conversationMessageID,
  });
}

function watchType(action: ReturnType<typeof actionChatTyping>) {
  const { appUserID, conversationID, value } = action.payload;
  socket.emit('typing', { toAppUserID: appUserID, conversationID, typing: value });
}

function* watchCameMessage(action: ReturnType<typeof actionChatCameMessage>) {
  const { conversationID, messageID } = action.payload;

  const stateItemList = yield* select(selectChatConversationsList);
  const stateItem = stateItemList.find(
    ({ conversation }) => conversation.conversationID === conversationID,
  );

  if (!stateItem) {
    yield* put(actionChatConversationGet({ conversationID }));
  } else if (stateItem.isInit) {
    yield* put(actionChatMessageGet({ conversationID, messageID }));
  }

  AppAudio.play('message');
}

function* watchStartChat(action: ReturnType<typeof actionChatStartChat>) {
  const { appUserID } = action.payload;

  let conversation: Conversation | null = null;

  const { isInit } = yield* select(selectChatStatuses);

  // wait for conversations
  if (!isInit) {
    yield* take(actionChatGetConversations.success);
  }

  const listStateItems = yield* select(selectChatConversationsList);

  const stateItem = listStateItems.find(({ conversation }) => conversation.appUserID === appUserID);

  conversation = stateItem ? stateItem.conversation : conversation;

  if (conversation) {
    yield* put(actionChatConversationOpen({ conversationID: conversation.conversationID }));
  } else {
    yield* put(actionChatStartChatNew(action.payload));
  }
}

export const chatSagas = [
  chatChannelSagas(),
  takeLatest(actionChatGetConversations.request, fetchConversations),

  takeLatest(actionChatConversationDelete.fulfilled, watchSuccessDeleteConversation),

  takeLatest(
    [actionChatSendMessage.fulfilled, actionChatStartConversation.fulfilled],
    watchSuccessSendMessage,
  ),

  takeLatest(actionChatCameMessage, watchCameMessage),
  takeLatest(actionChatTyping, watchType),
  takeLatest(actionChatStartChat, watchStartChat),

  takeEvery([actionChatGetConversations.fail], workerErrorNotifySaga),

  takeEvery(
    [
      actionChatConversationGet.rejected,
      actionChatMessageGet.rejected,
      actionChatConversationDelete.rejected,
      actionChatSendMessage.rejected,
      actionChatStartConversation.rejected,
    ],
    workerErrorNotifyThunk,
  ),
];
