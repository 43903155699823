import React from 'react';
import { APP_ROUTES } from 'configs/routes';
import { useAuth } from 'hooks';
import { Loading } from 'components/loading';
import { RouteUserStatusWrap } from 'router/components/user-status-wrap';

export const RoutePrivateWrap: React.FC<{ withPrivate: boolean }> = ({ children, withPrivate }) => {
  const { user } = useAuth();

  if (!withPrivate) {
    return <>{children}</>;
  } else if (!user) {
    window.location.replace(APP_ROUTES.LANDING_LOGIN.getUrl()({ redirect: window.location.href }));
    return <Loading active={true} />;
  } else {
    return <RouteUserStatusWrap userStatusType={user.userStatusID}>{children}</RouteUserStatusWrap>;
  }
};
