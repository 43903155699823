import React, { useMemo } from 'react';
import { socket } from 'components/custom-chat/store';
import { Socket } from 'socket.io-client';

interface Context {
  socket: Socket;
}

export const ChatContext = React.createContext<Context | null>(null);

interface Props {
  socketUrl?: string;
}

export const ChatContextProvider: React.FC<Props> = ({ children }) => {
  const value = useMemo(() => {
    return { socket };
  }, []);

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
