import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { DialogInfoHeader } from './info-header';
import { DialogInfoContent } from './info-content';
import { DialogInfoFooter } from './info-footer';
import { Loading } from 'components/loading';
import { useSourcePagePrivacy } from 'hooks';

interface DialogPrivacyProps {
  onClose: () => void;
}

export const DialogPrivacy: React.FC<DialogPrivacyProps> = ({ onClose }) => {
  const { data, isLoading } = useSourcePagePrivacy();

  return (
    <Dialog
      onClose={onClose}
      open={true}
      fullWidth={true}
      maxWidth={'lg'}
      PaperProps={{ className: 'app-dialog app-dialog_size_big' }}
    >
      <DialogInfoHeader text={data?.title || ''} onClose={onClose} />
      <DialogContent style={{ flex: '1 1' }}>
        <DialogInfoContent content={data?.html || ''} />
      </DialogContent>
      <DialogInfoFooter onClose={onClose} />
      <Loading active={isLoading} />
    </Dialog>
  );
};
