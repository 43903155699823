import React from 'react';
import style from './index.module.scss';
import { NetworkingVideo } from 'services/networking-events';
import { AppAvatar } from 'components/app-avatar';
import { Divider } from '@material-ui/core';
import { VideoPreview } from 'components/video-preview';
import clsx from 'clsx';

interface Props {
  showLine: boolean;
  isSelected: boolean;
  item: NetworkingVideo;
  onPlay: (id: NetworkingVideo['id']) => void;
}

export const MainStageItem: React.FC<Props> = ({ item, isSelected, showLine, onPlay }) => {
  return (
    <div className={clsx(style.wrap, isSelected && style.selectedWrap)}>
      <div
        className={clsx(style.item, isSelected && style.selectedItem)}
        onClick={() => onPlay(item.id)}
      >
        <div className={style.title}>{item.title}</div>
        <div className={style.company}>
          <AppAvatar className={style.avatar} isCompany={true} size={'xs'} src={item.imageURL} />
          {item.companyName}
        </div>
        <div className={style.videoWrap}>
          <div className={style.video}>
            <VideoPreview
              withLoading={false}
              value={item.videoURL}
              light={true}
              controls={false}
              playIcon={<></>}
            />
          </div>
        </div>
      </div>
      {showLine && <Divider className={style.line} />}
    </div>
  );
};
