const API_ENDPOINT = 'UserProfiles';

export const ENDPOINTS_USER_PROFILES = {
  API_ENDPOINT,
  PATCH_MEMBER: (data: { appUserID: string | number }) => `${API_ENDPOINT}/Patch/${data.appUserID}`,

  GET_USER_SUB_CATEGORIES: `${API_ENDPOINT}/GetUserHealthCareSubCategoriesDynamic`,
  POST_USER_SUB_CATEGORIES: `${API_ENDPOINT}/CreateUserHealthCareSubCategory`,
  DELETE_USER_SUB_CATEGORIES: (data: { uid: string | number }) =>
    `${API_ENDPOINT}/DeleteUserHealthCareSubCategory/${data.uid}`,

  GET_USER_PROFESSIONAL_FIELD: `${API_ENDPOINT}/GetUserProfessionalFieldOfInterestsDynamic`,
  POST_USER_PROFESSIONAL_FIELD: `${API_ENDPOINT}/CreateUserProfessionalFieldOfInterest`,
  DELETE_USER_PROFESSIONAL_FIELD: (data: { uid: string | number }) =>
    `${API_ENDPOINT}/DeleteUserProfessionalFieldOfInterest/${data.uid}`,

  GET_USER_DEVELOPMENT_STAGES: `${API_ENDPOINT}/GetUserDevelopmentStagesDynamic`,
  POST_USER_DEVELOPMENT_STAG: `${API_ENDPOINT}/CreateUserDevelopmentStage`,
  DELETE_USER_DEVELOPMENT_STAGES: (data: { uid: string | number }) =>
    `${API_ENDPOINT}/DeleteUserDevelopmentStage/${data.uid}`,
};

export interface UserProfessionalField {
  professionalFieldOfInterestID: number;
  uid: string;
  appUserID: string;
}
