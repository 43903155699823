import mime from 'mime-types';
import { fromBlob } from 'file-type/browser';

export type ValueFileUploaderFile = { value: string; name: string; size: number; type: string };
export type ValueFileUploader = string | ValueFileUploaderFile;

export const isFileLike = (file?: ValueFileUploader): file is ValueFileUploaderFile => {
  return Boolean(file && typeof file === 'object');
};
export const getFileAccept = (type?: string | string[]) => {
  const types = Array.isArray(type) ? type : String(type || '').split(',');
  const contentTypes = types.map((t) => mime.lookup(t));

  return {
    inputType: types.length ? types.join(', ') : 'application/octet-stream',
    contentTypes: contentTypes.filter((v) => v !== false) as string[],
  };
};
export const getFileInfo = (v?: ValueFileUploader) => {
  const isFile = isFileLike(v);
  const value = isFileLike(v) ? v.value : v || '';
  const fileName = isFileLike(v) ? v.name : getFileNameFromUrl(v || '');
  const fileUrl = value || '';

  return {
    isFile,
    fileUrl,
    fileName,
    value,
  };
};

export const fileToBase64 = (file: Blob) => {
  return new Promise<string>((res, rej) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (!e.target) {
        return rej(new Error('error-load'));
      }
      return res(String(e.target.result));
    };
    fileReader.onerror = rej;
    fileReader.readAsDataURL(file);
  });
};
export const base64ToFileStream = (base64: string) => {
  return base64.split(',')[1];
};
export const cropImg = (file: File, width: number, height: number) => {
  return new Promise<string>((resolve, reject) => {
    const img_ = new Image();
    img_.src = URL.createObjectURL(file);
    img_.onload = () => {
      const imageWidth = img_.width;
      const imageHeight = img_.height;

      let ratio = 1;

      if (imageWidth > width) ratio = width / imageWidth;
      if (imageHeight > height) ratio = height / imageHeight;

      const canvas = document.createElement('canvas');
      const width_ = imageWidth * ratio;
      const height_ = imageHeight * ratio;
      canvas.width = width_;
      canvas.height = height_;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img_, 0, 0, imageWidth, imageHeight, 0, 0, width_, height_);
      resolve(canvas.toDataURL());
    };
    img_.onerror = reject;
  });
};

export const getFileNameWithoutExt = (fileName: string) => {
  return fileName.split('.')[0];
};
export const getFileNameFromUrl = (fileName: string) => {
  //eslint-disable-next-line
  return fileName.replace(/^.*[\\\/]/, '');
};

export const validateFileSize = async (file: File, maxSize: number) => {
  const isValid = file.size < maxSize * 1000000;
  if (!isValid) {
    throw new Error('max-file-size');
  }
  return isValid;
};
export const validateFileType = async (file: Blob, contentTypes: string[]) => {
  const { type } = file;
  const validType = contentTypes.length && contentTypes.some((t) => t === type);

  if (!validType) {
    throw new Error('incorrect-file-type');
  }

  const result = await fromBlob(file);

  if (!result) {
    throw new Error('incorrect-file-type');
  }

  const validTypeBlob = contentTypes.length && contentTypes.some((t) => t === result.mime);

  if (!validTypeBlob) {
    throw new Error('incorrect-file-type');
  }

  return true;
};
