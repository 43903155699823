import React from "react";
import style from './index.module.scss'

interface Props {
  percent: number
  width?: number
  height?: number
  value?: string
  strokeWidth?: number
  colorLine?: string
}

const CXY = 16.91549431

export const CirclePercent: React.FC<Props> = ({
                                                 width = 300,
                                                 colorLine,
                                                 strokeWidth = 4,
                                                 percent,
                                                 height = 200,
                                                 value
                                               }) => {
  return (
    <svg
      className={style.chart}
      viewBox={`0 0 ${CXY * 2}
      ${CXY * 2}`}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className={style.background}
        strokeWidth={strokeWidth}
        fill="none"
        cx={CXY}
        cy={CXY}
        r={CXY - strokeWidth / 2}
      />
      <circle
        className={style.circle}
        strokeWidth={strokeWidth}
        strokeDasharray={`${percent},100`}
        strokeLinecap="round"
        stroke={colorLine}
        fill="none"
        cx={CXY}
        cy={CXY}
        r={CXY - strokeWidth / 2}
      />
      <g className={style.info}>
        {
          value &&
          <text
            className={style.percent}
            x={CXY} y={CXY}
            alignmentBaseline="central"
            textAnchor="middle"
          >
            {value}
          </text>
        }
      </g>
    </svg>
  )
}
