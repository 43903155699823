import { useMemo } from 'react';
import { validateEmail, validateURL } from 'utils';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

export const validatePhoneNumber = (value: string) => {
  let [countryCode, phone] = String(value).split('-');
  if (!countryCode) {
    return 'invalid-country-code';
  } else if (!phone || !(phone.length >= 9 && phone.length <= 11)) {
    return 'invalid-phone-number';
  } else {
    return undefined;
  }
};

type ValidateRules = 'required' | 'phone' | 'minLength' | 'email' | 'url';
export const useValidateRules = (...args: ValidateRules[]) => {
  return useMemo(() => {
    return args.reduce(
      (acc: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>, rule) => {
        switch (rule) {
          case 'required':
            acc['required'] = true;
            return acc;
          case 'email':
            acc['validate'] = {
              ...acc['validate'],
              email: validateEmail,
            };
            return acc;
          case 'url':
            acc['validate'] = {
              ...acc['validate'],
              url: validateURL,
            };
            return acc;
          case 'phone':
            acc['validate'] = {
              ...acc['validate'],
              phone: validatePhoneNumber,
            };
            return acc;
          case 'minLength':
            acc['minLength'] = {
              value: 4,
              message: 'min-length',
            };
            return acc;
          default:
            return acc;
        }
      },
      {},
    );

    // eslint-disable-next-line
  }, [...args]);
};
