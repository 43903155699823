const APP_TOKEN_FIELD = 'linqbio_network_auth_token';
const APP_TOKEN_REFRESH_FIELD = 'linqbio_network_auth_token_refresh';
const APP_TOKEN_EXPIRE_FIELD = 'linqbio_network_auth_token_date';

const APP_DOMAIN = (() => {
  if (process.env.NODE_ENV === 'development') {
    return window.location.hostname;
  } else {
    return `.linqbio.com`;
  }
})();

const getCookies = (name: string) => {
  if (!name) return undefined;
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        // eslint-disable-next-line
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
const setCookies = (name: string, data = '', expires = '', domain: string = APP_DOMAIN) => {
  const value = data
    ? `${name}=${encodeURIComponent(data)};${
        expires ? ` expires=${new Date(expires).toUTCString()};` : ''
      } secure; domain=${domain}; samesite=lax; path=/;`
    : `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; domain=${domain}; samesite=lax; path=/;`;
  document.cookie = value;
};

interface IToken {
  token: string;
  refreshToken: string;
  expires: string;
}

export const updateAuthTokens = (data: Partial<IToken> = {}) => {
  const { token, expires, refreshToken } = data;
  removeOldAuthTokens();

  setCookies(APP_TOKEN_FIELD, token, expires);
  setCookies(APP_TOKEN_REFRESH_FIELD, refreshToken, expires);
  setCookies(APP_TOKEN_EXPIRE_FIELD, expires, expires);
};

export const removeOldAuthTokens = () => {
  setCookies(APP_TOKEN_FIELD, '', '', '.app.linqbio.com');
  setCookies(APP_TOKEN_REFRESH_FIELD, '', '', '.app.linqbio.com');
  setCookies(APP_TOKEN_EXPIRE_FIELD, '', '', '.app.linqbio.com');
};

export const getAuthTokens = () => {
  return {
    token: getCookies(APP_TOKEN_FIELD),
    refreshToken: getCookies(APP_TOKEN_REFRESH_FIELD),
    expires: getCookies(APP_TOKEN_EXPIRE_FIELD),
  };
};
