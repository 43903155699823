import {
  BusinessActivity,
  BusinessFieldOfInterest,
  Country,
  DevelopmentStage,
  ENDPOINTS_PARAMETERS,
  FieldOfOccupation,
  FieldOfService,
  HealthCareCategory,
  IngredientType,
  MedicalField,
  NetworkItem,
  ProductType,
  ProfessionalFieldOfInterest,
  Region,
  Subject,
  TitleOfName,
} from './models';
import { api, axiosBaseGetQuery, transformResponseSources } from '../utils';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

export * from './models';

class Service {
  async getNetworkItem({ id }: { id: string | number }) {
    const params = { filter: `id == "${id}"` };
    const {
      data: { value },
    } = await api.get<{ value: NetworkItem[] }>(ENDPOINTS_PARAMETERS.GET_NETWORK_ITEMS, { params });

    if (!value.length) {
      throw new Error('record-not-found');
    }
    return { data: value[0] };
  }

  async getFullStoryCode() {
    return api.get<string>(ENDPOINTS_PARAMETERS.GET_FULL_STORY_CODE);
  }
}

const DefaultParams = { orderBy: 'title' };

export const parametersApi = createApi({
  reducerPath: 'parametersApi',
  baseQuery: axiosBaseGetQuery(),
  endpoints: (builder) => ({
    getFieldOfOccupation: builder.query<FieldOfOccupation[], void>({
      query: () => ({
        url: ENDPOINTS_PARAMETERS.GET_FIELD_OF_OCCUPATIONS,
        params: DefaultParams,
      }),
      transformResponse: transformResponseSources,
    }),
    getRegions: builder.query<Region[], void>({
      query: () => ({
        url: ENDPOINTS_PARAMETERS.GET_REGIONS,
        params: DefaultParams,
      }),
      transformResponse: transformResponseSources,
    }),
    getMedicalFields: builder.query<MedicalField[], void>({
      query: () => ({
        url: ENDPOINTS_PARAMETERS.GET_MEDICAL_FIELDS,
        params: DefaultParams,
      }),
      transformResponse: transformResponseSources,
    }),
    getSubjects: builder.query<Subject[], void>({
      query: () => ({
        url: ENDPOINTS_PARAMETERS.GET_SUBJECTS,
        params: DefaultParams,
      }),
      transformResponse: transformResponseSources,
    }),
    getHealthCareCategories: builder.query<HealthCareCategory[], void>({
      query: () => ({
        url: ENDPOINTS_PARAMETERS.GET_HEALTH_CARE_CATEGORIES,
        params: DefaultParams,
      }),
      transformResponse: transformResponseSources,
    }),
    getNetworkItems: builder.query<NetworkItem[], { forMember?: boolean; forCompany?: boolean }>({
      query: ({ forCompany, forMember }) => {
        const filters = [
          forMember && `forMember==${forMember}`,
          forCompany && `forCompany==${forCompany}`,
        ]
          .filter((v) => !!v)
          .join('&&');
        return {
          url: ENDPOINTS_PARAMETERS.GET_NETWORK_ITEMS,
          params: {
            filters: filters || undefined,
            orderBy: 'title',
          },
        };
      },
      transformResponse: transformResponseSources,
    }),
    getFieldOfServices: builder.query<FieldOfService[], void>({
      query: () => {
        return {
          url: ENDPOINTS_PARAMETERS.GET_FIELD_OF_SERVICES,
          params: DefaultParams,
        };
      },
      transformResponse: transformResponseSources,
    }),
    getCountries: builder.query<Country[], void>({
      query: () => {
        return {
          url: ENDPOINTS_PARAMETERS.GET_COUNTRIES,
          params: DefaultParams,
        };
      },
      transformResponse: transformResponseSources,
    }),
    getProfInterests: builder.query<ProfessionalFieldOfInterest[], void>({
      query: () => {
        return {
          url: ENDPOINTS_PARAMETERS.GET_PROFESSIONAL_FIELD_OF_INTERESTS,
          params: DefaultParams,
        };
      },
      transformResponse: transformResponseSources,
    }),
    getDevelopmentStages: builder.query<DevelopmentStage[], void>({
      query: () => {
        return {
          url: ENDPOINTS_PARAMETERS.GET_DEVELOPMENT_STAGES,
          params: DefaultParams,
        };
      },
      transformResponse: transformResponseSources,
    }),
    getTitleOfNames: builder.query<TitleOfName[], void>({
      query: () => {
        return {
          url: ENDPOINTS_PARAMETERS.GET_TITLE_OG_NAMES,
          params: DefaultParams,
        };
      },
      transformResponse: transformResponseSources,
    }),
    getProductTypes: builder.query<ProductType[], void>({
      query: () => {
        return {
          url: ENDPOINTS_PARAMETERS.GET_PRODUCT_TYPES,
          params: DefaultParams,
        };
      },
      transformResponse: transformResponseSources,
    }),
    getBusInterest: builder.query<BusinessFieldOfInterest[], void>({
      query: () => {
        return {
          url: ENDPOINTS_PARAMETERS.GET_BUSINESS_FIELD_OF_INTERESTS,
          params: DefaultParams,
        };
      },
      transformResponse: transformResponseSources,
    }),
    getIngredientTypes: builder.query<IngredientType[], void>({
      query: () => {
        return {
          url: ENDPOINTS_PARAMETERS.GET_INGREDIENT_TYPES,
          params: DefaultParams,
        };
      },
      transformResponse: transformResponseSources,
    }),
    getBusActivities: builder.query<BusinessActivity[], void>({
      query: () => {
        return {
          url: ENDPOINTS_PARAMETERS.GET_ACTIVITIES,
          params: DefaultParams,
        };
      },
      transformResponse: transformResponseSources,
    }),
  }),
});

export const ServiceParameters = new Service();
