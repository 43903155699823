import React, { forwardRef, PropsWithChildren, useImperativeHandle, useRef } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import Scrollbars from 'react-custom-scrollbars';
import style from './index.module.scss';
import { AppScrollbars } from 'components/app-scrollbar';

export interface CustomChatScrollRef {
  scrollToBottom: () => void;
}

export const CustomChatScroll = forwardRef<CustomChatScrollRef, PropsWithChildren<any>>(
  ({ children }, ref) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const mobileRef = useRef<HTMLDivElement>(null);
    const desktopRef = useRef<Scrollbars>(null);

    useImperativeHandle(
      ref,
      () => ({
        scrollToBottom: () => {
          requestAnimationFrame(() => {
            mobileRef.current?.scrollTo(
              0,
              mobileRef.current.firstElementChild?.getBoundingClientRect().height || 0,
            );
            desktopRef.current?.scrollToBottom();
          });
        },
      }),
      [],
    );

    const Content = <div className={style.content}>{children}</div>;

    return isMobile ? (
      <div ref={mobileRef} className={style.scrollMobile}>
        {Content}
      </div>
    ) : (
      <AppScrollbars ref={desktopRef} className={style.scrollDesktop}>
        {Content}
      </AppScrollbars>
    );
  },
);
