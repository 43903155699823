import { FieldErrors, FieldValues, useController, UseControllerOptions } from 'react-hook-form';
import { useTranslate } from './use-translate';
import { useMemo } from 'react';
import { debounce } from 'utils';

interface UseFormControllerProps<TFieldValues extends FieldValues = FieldValues>
  extends UseControllerOptions<TFieldValues> {
  errors?: FieldErrors;
  watchDelay?: number;
  onWatchChange?: (name: keyof TFieldValues) => void;
}

const getPropByName = (o: any, s: string) => {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  let a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (o && typeof o === 'object' && k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const useFormController = <TFieldValues extends FieldValues = FieldValues>(
  props: UseFormControllerProps<TFieldValues>,
) => {
  const { t } = useTranslate();
  const { errors, onWatchChange, watchDelay = 1000, ...rest } = props;
  const name = rest.name;
  const res = useController(props);
  const error = useMemo(() => {
    return getPropByName(errors, name);
  }, [errors, name]);
  const debounceChange = useMemo(() => {
    return onWatchChange ? debounce(onWatchChange, watchDelay) : undefined;
  }, [onWatchChange, watchDelay]);
  return useMemo(() => {
    const { ref, ...resField } = res.field;
    return {
      ...res,
      field: {
        ...resField,
        onChange: (...event: any[]) => {
          resField.onChange(...event);
          debounceChange && debounceChange(name);
        },
        error: Boolean(error),
        helperText: error ? t(error.message || '') : undefined,
      },
    };
  }, [res, t, error, debounceChange, name]);
};
