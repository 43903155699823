import React from 'react';
import { createRoute } from 'utils/typed-routes';
import { RouteProps } from 'react-router-dom';

const LANDING_URL = process.env.REACT_APP_URL_WEBSITE;

export interface IRouter extends Pick<RouteProps, 'path' | 'exact'> {
  path: string;
  private?: boolean;
  Layout?: React.ComponentType;
  component: React.LazyExoticComponent<any>;
}

export enum EVENT_PAGE_TABS {
  GENERAL = 'general',
  EXHIBITORS = 'exhibitors',
  CALENDARS = 'calendars',
}

export enum EVENT_BUILDER_STEP {
  START = 'start',
  VIDEOS = 'videos',
  IMAGES = 'images',
  DESCRIPTION = 'description',
  MATERIALS = 'materials',
  REPRESENTATIVES = 'representatives',
  FINISH = 'finish',
}

export enum PREFERENCES_PAGE_TABS {
  DETAILS = 'details',
  COMPANIES = 'companies',
  EVENTS = 'my-events',
}

export const APP_ROUTES = {
  HOME: createRoute<void, void>('/'),
  DEBUG: createRoute<void, { pass: string }>('/debug'),
  LOGIN: createRoute<void, { redirect?: string }>('/login'),
  PREFERENCES: createRoute<void, { tab: PREFERENCES_PAGE_TABS; 'tab-events'?: string }>(
    '/preferences',
  ),
  FULL_EDIT: createRoute<void, void | { redirect: string }>('/complete'),
  EVENT: createRoute<void, { id: string; tab: EVENT_PAGE_TABS }>(`/event`),

  EVENT_BUILDER: createRoute<{ customerNetworkingEventID: string }, { step: EVENT_BUILDER_STEP }>(
    '/event-builder/:customerNetworkingEventID',
  ),
  EVENT_BUILDER_PREVIEW: createRoute<{ customerNetworkingEventID: string }, void>(
    '/event-builder/:customerNetworkingEventID/preview',
  ),
  EVENT_BUILDER_PAYMENT: createRoute<{ networkingEventID: string }, void>(
    '/event/:networkingEventID/payment',
  ),
  EVENT_BUILDER_PAYMENT_SUCCESS: createRoute<{ customerNetworkingEventID: string }, void>(
    '/event/:customerNetworkingEventID/payment/success',
  ),
  EVENT_BUILDER_PAYMENT_ERROR: createRoute<{ customerNetworkingEventID: string }, void>(
    '/event/:customerNetworkingEventID/payment/error',
  ),
  MEMBER: createRoute<void, { member: string }>('/member'),
  COMPANY: createRoute<void, { company: string }>('/company'),
  COMPANY_EDITOR: createRoute<void, void | { companyID: string }>('/company/settings'),
  EXHIBITOR: createRoute<void, { id: string; eventID: string }>('/exhibitor'),
  SEARCH: createRoute<void, { networkItemID: number }>('/search'),
  NOT_FOND: createRoute<void, void>('/404'),

  LANDING_LOGIN: createRoute<void, { redirect: string }>(`${LANDING_URL}/login`),
};
