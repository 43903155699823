import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslate } from 'hooks';
import { SvgSpeaker } from 'components/svg-icon';

import style from './index.module.scss';

interface Props {
  onClick: () => void;
}

export const ButtonEventVideo: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslate();
  return (
    <Button
      onClick={onClick}
      variant={'outlined'}
      size={'large'}
      className={style.wrap}
      fullWidth
      startIcon={<SvgSpeaker fontSize={'inherit'} className={style.icon} />}
    >
      {t('event-stage-videos')}
    </Button>
  );
};
