import VAR from './_variabels.scss';
import { createTheme, ThemeOptions } from '@material-ui/core';

const FontSize = '1.4rem';

const AppThemConfig = {
  props: {
    MuiTextField: {
      fullWidth: true,
    },
    MuiSkeleton: {
      animation: 'wave',
    },
  },
  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    fontWeightRegular: 400,
    fontFamily: 'Roboto, Arial, Tahoma, Geneva, sans-serif',
    h1: {
      fontSize: '3.2rem',
      lineHeight: 1.45,
      fontWeight: 300,
      marginBottom: '1.5rem',
    },
    h2: {
      fontSize: '2.8rem',
      lineHeight: 1.2,
      fontWeight: 'normal',
    },
    h3: {
      fontSize: '2.3rem',
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.9rem',
    },
    h5: {
      fontSize: '1.7rem',
    },
    h6: {
      fontSize: '1.7rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '1.3rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1.5rem',
      lineHeight: 1.45,
    },
    body2: {
      fontSize: '1.2rem',
    },
    button: {},
    caption: {},
    overline: {
      fontSize: '1rem',
    },
  },
  palette: {
    text: {
      primary: '#000',
    },
    primary: {
      main: VAR.colorSecondary,
      contrastText: '#fff',
    },
    secondary: {
      main: VAR.colorPrimary,
      contrastText: '#fff',
    },
  },
  breakpoints: {
    values: {
      xs: Number(VAR.xs),
      sm: Number(VAR.sm),
      md: Number(VAR.md),
      lg: Number(VAR.lg),
      xl: Number(VAR.xl),
    },
  },
  overrides: {
    MuiListItem: {
      root: {
        color: VAR.colorSecondary,
        fontSize: '1.4rem',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '2.4rem',
        minHeight: '3.6rem',
        fontSize: '1.3rem',
        textTransform: 'none',
        fontWeight: 500,
        paddingLeft: '2.5rem',
        paddingRight: '2.5rem',
        lineHeight: '1.2',
        textAlign: 'center',
      },
      sizeLarge: {
        minWidth: '14rem',
        minHeight: '4.8rem',
        fontSize: '1.5rem',
        fontWeight: 400,
        textTransform: 'uppercase',
        paddingLeft: '3rem',
        paddingRight: '3rem',
      },
      sizeSmall: {
        minWidth: '1px',
        minHeight: '2.4rem',
        fontWeight: 400,
        fontSize: '1.2rem',
        padding: '5px 10px',
      },
      textSizeSmall: {
        root: {
          fontSize: '1.4rem',
          lineHeight: '1.5',
        },
      },
      outlined: {
        border: '0.2rem solid rgba(207,220,231, 0.3)',
        color: '#fff',

        '&:hover': {
          borderColor: 'rgba(207,220,231, 0.5)',
        },
      },
      outlinedSecondary: {
        borderWidth: '0.2rem',
        '&:hover': {
          borderWidth: '0.2rem',
        },
      },
    },
    // DIALOG
    MuiDialogTitle: {
      root: {
        textTransform: 'none',
      },
    },
    MuiDialog: {
      paper: {
        overflowY: 'inherit',
      },
    },

    //FORMS
    MuiInputBase: {
      input: {
        height: '2em',
        padding: '0.6rem 0',
        fontSize: '1.5rem',
        '&::placeholder': {
          color: '#6D7278',
          opacity: '1',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#6D7278',
      },
      formControl: {
        top: '0.6rem',
      },
      shrink: {
        fontSize: '1.6rem',
      },
    },

    MuiInput: {
      underline: {
        '&::before': {
          borderColor: '#C0C3C6',
        },
      },
    },

    MuiFormHelperText: {
      root: {
        fontSize: '1.2rem',
        lineHeight: 1,
        color: 'rgba(0,0,0,0.5)',
        marginTop: '1.2rem',
      },
    },

    MuiSnackbarContent: {
      message: {
        fontSize: FontSize,
      },
    },
    MuiIconButton: {
      root: {
        color: VAR.colorSecondary,
      },
    },
    MuiFab: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderWidth: '1px',
        borderColor: 'inherit',
        borderStyle: 'solid',
      },
    },
    MuiTabs: {
      indicator: {
        left: 0,
        top: 0,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        backgroundColor: '#EEF2F4',
        fontSize: '1.7rem',
        fontWeight: 400,
      },
      textColorPrimary: {
        color: 'black',
        '&$selected': {
          backgroundColor: 'white',
        },
      },
    },
  },
} as ThemeOptions;

export default createTheme(AppThemConfig);
