import { useMemo } from 'react';
import clsx from 'clsx';

export type AppButtonSizes = 'extra-large' | 'large' | 'medium' | 'small';
export type AppButtonVariants = 'contained' | 'outlined' | 'text';

interface useAppButtonClassesProps {
  baseName: string;
  variant?: AppButtonVariants;
  size?: AppButtonSizes;
}

export const useAppButtonClasses = ({
  baseName,
  variant = 'contained',
  size = 'medium',
}: useAppButtonClassesProps) => {
  const variant_ = useMemo(() => {
    return `${baseName}_variant_${variant}`;
  }, [baseName, variant]);
  const size_ = useMemo(() => {
    return `${baseName}_size_${size}`;
  }, [baseName, size]);

  return clsx(baseName, variant_, size_);
};
