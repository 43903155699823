import React, { useCallback, useEffect, useMemo } from 'react';
import { useAppSelector, usePageEvent } from 'hooks';
import clsx from 'clsx';

import {
  getEventExhibitorsActions,
  resetEventExhibitorsFilters,
  selectEventExhibitorsData,
  selectEventExhibitorsFilters,
  selectEventExhibitorsPagination,
  setEventExhibitorsFilters,
  setEventExhibitorsPagination,
} from 'store/event';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutNoData } from 'components/layout-no-data';
import { AppPagination } from 'components/app-pagination';

import { Loading } from 'components/loading';

import { LayoutSearch } from '../layout-search';
import { AsideFilters } from '../aside-filters';
import { TabExhibitorsBannerComplete } from '../tab-exhibitors-banner-complete';
import { ExhibitorCard } from '../exhibitor-card';
import { TabExhibitorsBannerBecome } from '../tab-exhibitors-banner-become';
import { TabExhibitorsTools } from '../tab-exhibitors-tools';

import style from './index.module.scss';

const COUNT_RECORDS_TO_SHOW_FILTERS = 1;

interface Props {
  view: 'list' | 'cards';
  onChangeView: (value: this['view']) => void;
}

export const TabExhibitorsList: React.FC<Props> = ({ view, onChangeView }) => {
  const { data, loading } = useSelector(selectEventExhibitorsData);
  const { pages, page, count } = useSelector(selectEventExhibitorsPagination);
  const event = usePageEvent();

  const dispatch = useDispatch();

  useEffect(() => {
    if (data.length === 0) {
      dispatch(getEventExhibitorsActions.request());
    }
  }, [data.length, dispatch]);
  const isShowFilters = event.totalExhibitors >= COUNT_RECORDS_TO_SHOW_FILTERS;

  const cardView = useMemo(() => {
    switch (view) {
      case 'list':
        return 'line';
      default:
        return 'card';
    }
  }, [view]);

  const { filters, hasValue } = useAppSelector(selectEventExhibitorsFilters);
  const onSetFilters = useCallback(
    (data: Partial<typeof filters>) => {
      dispatch(setEventExhibitorsFilters(data));
      dispatch(setEventExhibitorsPagination({ page: 1 }));
      dispatch(getEventExhibitorsActions.request());
    },
    [dispatch],
  );

  const onResetAndRefresh = useCallback(() => {
    dispatch(setEventExhibitorsPagination({ page: 1 }));
    dispatch(resetEventExhibitorsFilters());
    dispatch(getEventExhibitorsActions.request());
  }, [dispatch]);

  const onSetPage = useCallback(
    (e: any, page: number) => {
      dispatch(setEventExhibitorsPagination({ page }));
      dispatch(getEventExhibitorsActions.request());
    },
    [dispatch],
  );

  return (
    <>
      {!event.isExhibitor && (
        <TabExhibitorsBannerBecome
          eventID={event.id}
          className={style.banner}
          earlyStage={event.earlyStage}
          earlyStagePrice={event.earlyStagePrice}
          earlyStageDate={event.earlyStageDate}
          standardPrice={event.standardPrice}
        />
      )}
      {event.isExhibitor && (
        <TabExhibitorsBannerComplete networkingEventID={event.id} className={style.banner} />
      )}
      <LayoutSearch
        Tools={<TabExhibitorsTools value={view} onChange={onChangeView} count={count} />}
        Aside={
          isShowFilters ? (
            <AsideFilters
              eventID={event.id}
              initData={filters}
              isShowClear={hasValue}
              onSetFilters={onSetFilters}
              onReset={onResetAndRefresh}
            />
          ) : null
        }
      >
        <LayoutNoData
          classes={{ root: style.empty, title: style.emptyTitle }}
          isNoData={data.length === 0 && !loading}
        >
          <div
            className={clsx({
              [style.content]: true,
              [style.contentCards]: view === 'cards',
              [style.contentList]: view === 'list',
              [style.contentFull]: !isShowFilters,
            })}
          >
            {data.map((item, i) => {
              return (
                <ExhibitorCard
                  key={i}
                  view={cardView}
                  eventID={event.id}
                  id={item.id}
                  picture={item.logo}
                  title={item.companyName}
                  categoryTitle={item.categoryTitle}
                  countryFlag={item.flag}
                  countryTitle={item.countryTitle}
                  users={item.representatives}
                  isOwn={item.isOwner}
                  isDisabledMeeting={!event.isEventDate}
                />
              );
            })}
            <Loading active={loading} />
          </div>
          <AppPagination count={pages} page={page} onChange={onSetPage} />
        </LayoutNoData>
      </LayoutSearch>
    </>
  );
};
