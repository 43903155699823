import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsDebugMode, setEventsDebugMode } from 'store/events';

export const useDebugMode = () => {
  const dispatch = useDispatch();
  const isDebug = useSelector(selectIsDebugMode);
  const onToggle = useCallback(() => {
    dispatch(setEventsDebugMode(!isDebug));
  }, [dispatch, isDebug]);

  return useMemo(() => {
    return {
      isDebug,
      onToggle,
    };
  }, [onToggle, isDebug]);
};
