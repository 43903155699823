import React, { lazy, Suspense } from 'react';
import { APP_ROUTES, IRouter } from 'configs/routes';
import { Route, Switch } from 'react-router-dom';
import { RoutePrivateWrap, ScrollPageTop } from './components';
import { LayoutContained, LayoutTransparent, LayoutTransparentPrivate } from 'components/layouts';

export const routes: IRouter[] = [
  {
    path: APP_ROUTES.DEBUG.path,
    component: lazy(() => import(/* webpackChunkName: '__debug' */ 'pages/__debug')),
  },
  {
    path: APP_ROUTES.HOME.path,
    component: lazy(() => import(/* webpackChunkName: 'Dashboard' */ 'pages/Dashboard')),
  },
  {
    path: APP_ROUTES.EXHIBITOR.path,
    component: lazy(() => import(/* webpackChunkName: 'Exhibitor' */ 'pages/Exhibitor')),
  },
  {
    path: APP_ROUTES.PREFERENCES.path,
    component: lazy(() => import(/* webpackChunkName: 'Preferences' */ 'pages/Preferences')),
  },
  {
    path: APP_ROUTES.FULL_EDIT.path,
    component: lazy(() => import(/* webpackChunkName: 'FullEdit' */ 'pages/FullEdit')),
  },
  {
    path: APP_ROUTES.SEARCH.path,
    component: lazy(() => import(/* webpackChunkName: 'Search' */ 'pages/Search')),
  },
  {
    path: APP_ROUTES.MEMBER.path,
    exact: false,
    component: lazy(() => import(/* webpackChunkName: 'Member' */ 'pages/Member')),
  },
  {
    path: APP_ROUTES.COMPANY.path,
    exact: true,
    component: lazy(() => import(/* webpackChunkName: 'CompanyView' */ 'pages/CompanyView')),
  },
  {
    path: APP_ROUTES.COMPANY_EDITOR.path,
    exact: true,
    component: lazy(() => import(/* webpackChunkName: 'EditCompany' */ 'pages/EditCompany')),
  },
  {
    path: APP_ROUTES.EVENT.path,
    exact: true,
    component: lazy(() => import(/* webpackChunkName: 'Event' */ 'pages/Event')),
  },
  {
    path: APP_ROUTES.EVENT_BUILDER.path,
    exact: true,
    component: lazy(() => import(/* webpackChunkName: 'EventBuilder' */ 'pages/EventBuilder')),
    Layout: LayoutTransparentPrivate,
  },
  {
    path: APP_ROUTES.EVENT_BUILDER_PREVIEW.path,
    component: lazy(
      () => import(/* webpackChunkName: 'EventBuilderPreview' */ 'pages/EventBuilderPreview'),
    ),
  },
  {
    path: APP_ROUTES.EVENT_BUILDER_PAYMENT.path,
    exact: true,
    component: lazy(
      () => import(/* webpackChunkName: 'EventBuilderPayment' */ 'pages/EventBuilderPayment'),
    ),
    Layout: LayoutTransparentPrivate,
  },
  {
    path: APP_ROUTES.EVENT_BUILDER_PAYMENT_SUCCESS.path,
    exact: true,
    component: lazy(
      () =>
        import(
          /* webpackChunkName: 'EventBuilderPaymentSuccess' */ 'pages/EventBuilderPaymentSuccess'
        ),
    ),
    Layout: LayoutTransparentPrivate,
  },
  {
    path: APP_ROUTES.EVENT_BUILDER_PAYMENT_ERROR.path,
    exact: true,
    component: lazy(
      () =>
        import(/* webpackChunkName: 'EventBuilderPaymentError' */ 'pages/EventBuilderPaymentError'),
    ),
    Layout: LayoutTransparentPrivate,
  },
  {
    path: APP_ROUTES.NOT_FOND.path,
    exact: true,
    component: lazy(() => import(/* webpackChunkName: 'NotFound' */ 'pages/NotFound')),
    private: false,
    Layout: LayoutTransparent,
  },
  {
    path: '*',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: 'NotFound' */ 'pages/NotFound')),
    private: false,
    Layout: LayoutTransparent,
  },
];

export const AppRoutes: React.FC = () => {
  return (
    <ScrollPageTop>
      <Switch>
        {routes.map(
          ({
            component: Component,
            private: privateRoute = true,
            exact = true,
            path,
            Layout = LayoutContained,
            ...props
          }) => (
            <Route key={path} {...props} path={path} exact={exact}>
              <RoutePrivateWrap withPrivate={privateRoute}>
                <Suspense
                  fallback={<div style={{ color: '#5fb800', padding: '1rem' }}>Loading...</div>}
                >
                  <Layout>
                    <Component />
                  </Layout>
                </Suspense>
              </RoutePrivateWrap>
            </Route>
          ),
        )}
      </Switch>
    </ScrollPageTop>
  );
};
