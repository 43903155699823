export const onChangeCapitalize = (e: any) => {
  const value = String(e?.target?.value || '');
  return value.charAt(0).toUpperCase() + value.substring(1);
};

export const prepareRequest = <T extends { [x: string]: any | null } = {}>(data: T) => {
  const keys = Object.keys(data) as (keyof T)[];
  return keys.reduce((acc, key) => {
    // @ts-ignore
    acc[key] = data[key] === '' ? null : data[key];
    return acc;
  }, data);
};
