const prepareValue = (value?: string) => {
  return String(value).trim().replace(/"/gi, '\\"');
};
export const createFilterSmartSearch = <T>({
  name,
  value,
}: {
  name: keyof T | (keyof T)[];
  value: any;
}) => {
  const names = Array.isArray(name) ? name : [name];
  if (!value) return null;

  const field = names.map((_name) => `${_name}.replace(" ",String.Empty)`).join('+');
  return createFilterContains({ name: `(${field})`, value: String(value).replace(/ /g, '') });
};
export const createFilterEquals = <T extends object = {}>({
  name,
  value,
}: {
  name: keyof T | (keyof T)[];
  value: any;
}) => {
  const names = Array.isArray(name) ? name : [name];

  return value ? `(${names.map((_n) => `${_n}=="${prepareValue(value)}"`).join('||')})` : undefined;
};
export const createFilterContains = <T extends object = {}>({
  name,
  value,
}: {
  name: keyof T | (keyof T)[];
  value: any;
}) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? `(${names.map((_n) => `${_n}.contains("${prepareValue(value)}")`).join('||')})`
    : null;
};

export const createFilterArrays = <T>({ name, value }: { name: keyof T; value: string[] }) => {
  return value.length ? `(${value.map((v) => `${name}=="${v}"`).join('||')})` : null;
};
