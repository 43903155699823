import React from 'react';
import './_style.scss';
import { AppFooterLogo } from './logo';
import { AppFooterControls } from './controls';
import { useTranslate } from 'hooks';
import clsx from 'clsx';

interface Props {
  alternativeStyle?: boolean;
}

export const AppFooter: React.FC<Props> = ({ alternativeStyle = false }) => {
  const { t } = useTranslate();

  return (
    <footer className={clsx('app-footer', alternativeStyle && 'app-footer_alternative')}>
      <div className={'container'}>
        <div className={'app-footer__inner'}>
          <div className={'app-footer__item app-footer__logo'}>
            <AppFooterLogo />
          </div>
          <div className={'app-footer__item app-footer__cop'}>
            <span>{t('copyrights')}</span>
          </div>
          <div className={'app-footer__item app-footer__terms'}>
            <AppFooterControls />
          </div>
        </div>
      </div>
    </footer>
  );
};
