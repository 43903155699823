import React from 'react';
import { Divider } from '@material-ui/core';
import './_style.scss';
import clsx from 'clsx';

interface DialogDividerProps {
  className?: string;
}

export const DialogDivider: React.FC<DialogDividerProps> = ({ className }) => {
  const classNames = clsx('dialog-divider', className);
  return <Divider className={classNames} />;
};
