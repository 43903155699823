import React from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import style from './index.module.scss';
import clsx from 'clsx';
import { useOpen } from 'hooks';

interface Props extends ReactPlayerProps {
  previewPicture?: string;
}

export const BackgroundPlayer: React.FC<Props> = ({ previewPicture, ...rest }) => {
  const { isOpen, onOpen } = useOpen();
  return (
    <div className={clsx(style.wrap, isOpen && style.play)}>
      <img className={style.preview} src={previewPicture} alt={''} />
      <ReactPlayer
        muted={true}
        playing={true}
        controls={false}
        loop={true}
        width={'100%'}
        height={'100%'}
        onPlay={onOpen}
        {...rest}
      />
    </div>
  );
};
