import { StoreState } from '../index';
import { createSelector } from '@reduxjs/toolkit';
import { CustomerNetworkingEvent } from 'services/customer-networking-event';
import { EVENT_BUILDER_STEP } from 'configs';
import { EventBuilderItemState } from 'store/event-builder/slice';

const selectState = (state: StoreState) => state.eventBuilder;

export const selectEventBuilderItem = createSelector(
  [selectState, (state, customerNetworkingEventID: string) => customerNetworkingEventID],
  (items, customerNetworkingEventID) => {
    return items[customerNetworkingEventID] || EventBuilderItemState();
  },
);

export const calcCustomerEventSteps = (data?: Partial<CustomerNetworkingEvent> | null) => {
  return {
    [EVENT_BUILDER_STEP.START]: Boolean(data?.paid),
    [EVENT_BUILDER_STEP.VIDEOS]: Boolean(data?.displayVideos),
    [EVENT_BUILDER_STEP.IMAGES]: Boolean(data?.displayImages),
    [EVENT_BUILDER_STEP.DESCRIPTION]: Boolean(data?.displayContent1 || data?.displayContent2),
    [EVENT_BUILDER_STEP.MATERIALS]: Boolean(data?.displayResources),
    [EVENT_BUILDER_STEP.REPRESENTATIVES]: Boolean(true),
    [EVENT_BUILDER_STEP.FINISH]: Boolean(data?.confirmContent),
  };
};
