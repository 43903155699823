import {
  API_NETWORKING_EVENT_CALENDARS,
  CALENDAR_STATUS_ID,
  CalendarAvailableSlot,
  CalendarAvailableSlotPatch,
  CalendarCurrentUser,
  MeetingPayload,
  NewCalendar,
  ServerNewCalendar,
  TimeZone,
} from './models';
import {
  convertToDate,
  convertUTCToZoned,
  convertZonedToUTC,
  getEachDayOfInterval,
  PatchModel,
  prepareRecords,
} from 'utils';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { api, axiosBaseGetQuery, transformResponseSources } from 'services/utils';
import { NetworkingEvent } from 'services/networking-events';
import { set } from 'date-fns';

class Service {
  static prepareCreateCalendar(data: NewCalendar, timeZone: string): ServerNewCalendar {
    return {
      ...data,
      calendarTimeSlots: data.calendarTimeSlots.map(({ start, end }) => {
        return {
          networkingEventSlotFromDateTime: convertZonedToUTC(start, timeZone).toISOString(),
          networkingEventSlotToDateTime: convertZonedToUTC(end, timeZone).toISOString(),
        };
      }),
    };
  }

  static normalizeCalendarData(data: CalendarCurrentUser) {
    return {
      ...data,
      calendarAvailableSlots: data.calendarAvailableSlots.sort((a, b) => {
        return (
          convertToDate(a.slotToDateTime).getTime() - convertToDate(b.slotToDateTime).getTime()
        );
      }),
    };
  }

  async getUserCurrentUserCalendar(networkingEventID: string) {
    const result = await api.get<CalendarCurrentUser>(
      API_NETWORKING_EVENT_CALENDARS.GET_CURRENT_USER_CALENDAR,
      {
        params: { networkingEventID },
      },
    );
    return {
      ...result,
      data: Service.normalizeCalendarData(result.data),
    };
  }

  async getUserCalendar(networkingEventID: string, appUserID: string) {
    const result = await api.get<CalendarCurrentUser>(
      API_NETWORKING_EVENT_CALENDARS.GET_USER_CALENDAR,
      {
        params: { networkingEventID, appUserID },
      },
    );
    return {
      ...result,
      data: Service.normalizeCalendarData(result.data),
    };
  }

  async createCalendar(data: NewCalendar, timeZone: string) {
    const _data = Service.prepareCreateCalendar(data, timeZone);
    return api.post(API_NETWORKING_EVENT_CALENDARS.CREATE_CALENDAR, _data);
  }

  async createCalendarAuto(data: NetworkingEvent) {
    try {
      await ServiceNetworkingEventCalendars.getUserCurrentUserCalendar(data.id);
    } catch (e) {
      const startHours = 8;
      const endHours = startHours + 9;

      await ServiceNetworkingEventCalendars.createCalendar(
        {
          networkingEventID: data.id,
          calendarTimeZoneID: data.calendarTimeZoneID,
          calendarTimeSlots: getEachDayOfInterval(
            convertUTCToZoned(data.fromDate, data.timeZoneID),
            convertUTCToZoned(data.toDate, data.timeZoneID),
          ).map((date) => ({
            start: set(convertToDate(date), {
              hours: startHours,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            }),
            end: set(convertToDate(date), {
              hours: endHours,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            }),
            slotID: undefined,
          })),
        },
        data.timeZoneID,
      );
    }
  }

  async updateCalendar(data: Pick<NewCalendar, 'networkingEventID' | 'calendarTimeZoneID'>) {
    const { networkingEventID, calendarTimeZoneID } = data;
    return api.put(API_NETWORKING_EVENT_CALENDARS.UPDATE_USER_CALENDAR(networkingEventID), {
      calendarTimeZoneID,
    });
  }

  async removeCalendar(networkingEventID: string) {
    return api.delete(API_NETWORKING_EVENT_CALENDARS.REMOVE_CALENDAR(networkingEventID));
  }

  async updateCalendarSlots(
    networkingEventID: string,
    oldSlots: CalendarAvailableSlotPatch[],
    newSlots: CalendarAvailableSlotPatch[],
    timeZone: string,
  ) {
    const { postItems, patchItems, deleteItems } = prepareRecords(
      oldSlots,
      newSlots,
      'calendarAvailableSlotID',
    );

    const promiseDelete = Promise.all(
      deleteItems.map(({ calendarAvailableSlotID }) =>
        ServiceNetworkingEventCalendars.deleteAvailableSlot(calendarAvailableSlotID),
      ),
    );
    const promiseCreate = Promise.all(
      postItems.map(({ slotFromDateTime, slotToDateTime }) =>
        ServiceNetworkingEventCalendars.createAvailableSlot(
          { networkingEventID, slotFromDateTime, slotToDateTime },
          timeZone,
        ),
      ),
    );
    const promisePatch = Promise.all(
      patchItems.map(({ newItem }) =>
        ServiceNetworkingEventCalendars.patchAvailableSlot(newItem, timeZone),
      ),
    );
    return Promise.all([promiseDelete, promiseCreate, promisePatch]);
  }

  async createMeeting(
    data: PatchModel<
      MeetingPayload,
      'networkingEventID' | 'toUserAppID' | 'slotFromDateTime' | 'slotToDateTime'
    >,
  ) {
    return api.post(API_NETWORKING_EVENT_CALENDARS.CREATE_MEETING, {
      ...data,
      slotFromDateTime: convertToDate(data.slotFromDateTime).toISOString(),
      slotToDateTime: convertToDate(data.slotToDateTime).toISOString(),
    });
  }

  async createAvailableSlot(
    data: Omit<CalendarAvailableSlot, 'calendarAvailableSlotID'> & { networkingEventID: string },
    timeZone: string,
  ) {
    const { networkingEventID, slotToDateTime, slotFromDateTime } = data;
    return api.post(API_NETWORKING_EVENT_CALENDARS.CREATE_AVAILABLE_SLOT, {
      networkingEventID,
      slotFromDateTime: convertZonedToUTC(slotFromDateTime, timeZone).toISOString(),
      slotToDateTime: convertZonedToUTC(slotToDateTime, timeZone).toISOString(),
    });
  }

  async patchAvailableSlot(data: CalendarAvailableSlot, timeZone: string) {
    return api.put(API_NETWORKING_EVENT_CALENDARS.UPDATE_AVAILABLE_SLOT, {
      ...data,
      slotFromDateTime: convertZonedToUTC(data.slotFromDateTime, timeZone).toISOString(),
      slotToDateTime: convertZonedToUTC(data.slotToDateTime, timeZone).toISOString(),
    });
  }

  async deleteAvailableSlot(
    calendarAvailableSlotID: CalendarAvailableSlot['calendarAvailableSlotID'],
  ) {
    return api.delete(
      API_NETWORKING_EVENT_CALENDARS.DELETE_AVAILABLE_SLOT(calendarAvailableSlotID),
    );
  }

  async acceptMeeting(calendarMeetingID: string) {
    return api.put(API_NETWORKING_EVENT_CALENDARS.UPDATE_MEETING, {
      calendarMeetingID,
      calendarSlotStatusID: CALENDAR_STATUS_ID.TAKEN,
    });
  }
  async declineMeeting(calendarMeetingID: string) {
    return api.delete(API_NETWORKING_EVENT_CALENDARS.DELETE_DECLINE_MEETING(calendarMeetingID));
  }
}

export const ServiceNetworkingEventCalendars = new Service();

(window as any).ServiceNetworkingEventCalendars = ServiceNetworkingEventCalendars;
const createCompositeID = (networkingEventID: string, appUserID: string) => {
  return `${networkingEventID}__&__${appUserID}`;
};
export const networkingEventCalendarApi = createApi({
  reducerPath: 'networkingEventCalendarApi',
  baseQuery: axiosBaseGetQuery(),
  tagTypes: ['Calendar', 'CurrentUserCalendar'],
  endpoints: (builder) => ({
    getTimezones: builder.query<TimeZone[], void>({
      query: () => ({
        url: API_NETWORKING_EVENT_CALENDARS.GET_TIME_ZONES_DYNAMIC,
      }),
      transformResponse: transformResponseSources,
    }),

    getSlotsInfo: builder.query<{ id: number; title: string }[], void>({
      query: () => ({
        url: API_NETWORKING_EVENT_CALENDARS.GET_SLOT_STATUSES_DYNAMIC,
      }),
      transformResponse: transformResponseSources,
    }),

    getUserCalendar: builder.query<
      CalendarCurrentUser,
      { networkingEventID: string; appUserID: string }
    >({
      queryFn: ({ networkingEventID, appUserID }) => {
        return ServiceNetworkingEventCalendars.getUserCalendar(networkingEventID, appUserID);
      },
      providesTags: (res, err, { networkingEventID, appUserID }) => [
        { type: 'Calendar', id: createCompositeID(networkingEventID, appUserID) },
      ],
    }),

    getCurrentUserCalendar: builder.query<CalendarCurrentUser, { networkingEventID: string }>({
      queryFn: async ({ networkingEventID }) => {
        try {
          const { data } = await ServiceNetworkingEventCalendars.getUserCurrentUserCalendar(
            networkingEventID,
          );
          return { data };
        } catch (e) {
          return { error: e as Error };
        }
      },
      providesTags: (res, err, { networkingEventID }) => [
        { type: 'CurrentUserCalendar', id: networkingEventID },
      ],
    }),
    createCurrentUserCalendar: builder.mutation<
      void,
      { networkingEventID: string; data: NewCalendar; timeZone: string }
    >({
      queryFn: async ({ networkingEventID, data, timeZone }) => {
        try {
          await ServiceNetworkingEventCalendars.createCalendar(
            { ...data, networkingEventID },
            timeZone,
          );
          return { data: undefined };
        } catch (e) {
          return { error: e as Error };
        }
      },
      invalidatesTags: (res, err, { networkingEventID }) => [
        { type: 'CurrentUserCalendar', id: networkingEventID },
      ],
    }),
    updateCurrentUserCalendar: builder.mutation<
      void,
      {
        networkingEventID: string;
        calendarTimeZoneID: string;
        oldSlots: CalendarAvailableSlotPatch[];
        newSlots: CalendarAvailableSlotPatch[];
        eventTimeZone: string;
      }
    >({
      queryFn: async ({
        networkingEventID,
        newSlots,
        oldSlots,
        eventTimeZone,
        calendarTimeZoneID,
      }) => {
        try {
          await Promise.all([
            ServiceNetworkingEventCalendars.updateCalendar({
              networkingEventID,
              calendarTimeZoneID,
            }),
            ServiceNetworkingEventCalendars.updateCalendarSlots(
              networkingEventID,
              oldSlots,
              newSlots,
              eventTimeZone,
            ),
          ]);

          return { data: undefined, error: undefined };
        } catch (e) {
          return { error: e as Error };
        }
      },
      invalidatesTags: (res, err, { networkingEventID }) => [
        { type: 'CurrentUserCalendar', id: networkingEventID },
      ],
    }),

    createMeeting: builder.mutation<
      void,
      {
        networkingEventID: string;
        appUserID: string;
        data: { slotFromDateTime: string; slotToDateTime: string; message: string };
      }
    >({
      queryFn: async ({ networkingEventID, appUserID, data }) => {
        const { slotFromDateTime, slotToDateTime, message } = data;
        try {
          await ServiceNetworkingEventCalendars.createMeeting({
            networkingEventID,
            toUserAppID: appUserID,
            slotFromDateTime,
            slotToDateTime,
            fromUserMessage: message,
          });
          return { data: undefined };
        } catch (e) {
          return { error: e as Error };
        }
      },
      invalidatesTags: (res, err, { networkingEventID, appUserID }) => [
        { type: 'Calendar', id: createCompositeID(networkingEventID, appUserID) },
        { type: 'CurrentUserCalendar', id: networkingEventID },
      ],
    }),
    acceptMeeting: builder.mutation<
      void,
      { networkingEventID: string; appUserID: string; calendarMeetingID: string }
    >({
      queryFn: async ({ calendarMeetingID }) => {
        try {
          await ServiceNetworkingEventCalendars.acceptMeeting(calendarMeetingID);
          return { data: undefined };
        } catch (e) {
          return { error: e as Error };
        }
      },
      invalidatesTags: (res, err, { networkingEventID, appUserID }) => [
        { type: 'Calendar', id: createCompositeID(networkingEventID, appUserID) },
        { type: 'CurrentUserCalendar', id: networkingEventID },
      ],
    }),
    declineMeeting: builder.mutation<
      void,
      { networkingEventID: string; appUserID: string; calendarMeetingID: string }
    >({
      queryFn: async ({ calendarMeetingID }) => {
        try {
          await ServiceNetworkingEventCalendars.declineMeeting(calendarMeetingID);
          return { data: undefined };
        } catch (e: any) {
          return { error: e as Error };
        }
      },
      invalidatesTags: (res, err, { networkingEventID, appUserID }) => [
        { type: 'Calendar', id: createCompositeID(networkingEventID, appUserID) },
        { type: 'CurrentUserCalendar', id: networkingEventID },
      ],
    }),
  }),
});

export * from './models';
