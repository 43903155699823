import { VideoPreview } from 'components/video-preview';
import { AppAvatar } from 'components/app-avatar';
import { Button, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useLoadHtml, useTranslate } from 'hooks';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'configs/routes';
import style from './index.module.scss';
interface Props {
  companyID: string;
  title: string;
  videoURL: string;
  imageURL: string;
  htmlURL: string;
  companyName: string;
  isPlaying?: boolean;
}

export const MainStagePreview: React.FC<Props> = ({
  title,
  isPlaying,
  videoURL,
  imageURL,
  companyName,
  htmlURL,
  companyID,
}) => {
  const { t } = useTranslate();
  const { push } = useHistory();
  const goToCompany = useCallback(() => {
    push(APP_ROUTES.COMPANY.getConfig()({ company: companyID }));
  }, [companyID, push]);

  const { data: htmlContent } = useLoadHtml(htmlURL);

  return (
    <div>
      <VideoPreview
        withLoading={true}
        playing={isPlaying}
        value={videoURL}
        className={style.video}
      />
      <Typography component={'h4'} variant={'h6'} style={{ marginBottom: '1.5rem' }}>
        {title}
      </Typography>

      <div className={style.user}>
        <AppAvatar src={imageURL} isCompany={true} size={'sm'} onClick={goToCompany} />
        <div className={style.company} onClick={goToCompany}>
          {companyName}
        </div>
        {companyID && (
          <Button color={'secondary'} variant={'contained'} fullWidth onClick={goToCompany}>
            {t('contact-presenters')}
          </Button>
        )}
      </div>
      {htmlContent && (
        <div
          dangerouslySetInnerHTML={{ __html: htmlContent || '' }}
          className={style.description}
        />
      )}
    </div>
  );
};
