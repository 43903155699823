import React from 'react';
import { AppHeader } from 'components/app-header';
import { LayoutTransparent } from '../layout-transparent';
import style from './index.module.scss';

interface Props {
  className?: string;
}

export const LayoutTransparentPrivate: React.FC<Props> = ({ children, className }) => {
  return (
    <LayoutTransparent
      className={style.layout}
      Header={
        <div>
          <AppHeader isTransparent={true} />
        </div>
      }
    >
      {children}
    </LayoutTransparent>
  );
};
