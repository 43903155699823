import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface DialogInfoHeaderProps {
  text: string;
  onClose: () => void;
}

export const DialogInfoHeader: React.FC<DialogInfoHeaderProps> = ({ text, onClose }) => {
  return (
    <div className={'info-header'}>
      <Typography color={'primary'} variant={'h1'} className={'info-header__title'}>
        {text}
      </Typography>
      <IconButton onClick={onClose} className={'info-header__close'}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};
