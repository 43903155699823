import React from 'react';
import { Button, ButtonProps, Theme, useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'hooks';

interface Props extends ButtonProps {
  className?: string;
  isActive?: boolean;
}

export const ButtonMeet: React.FC<Props> = ({ className, isActive, ...rest }) => {
  const { t } = useTranslate();
  const isMobile = useMediaQuery<Theme>((theme) => {
    return theme.breakpoints.down('lg');
  });
  const isActiveStyle = isMobile || isActive;

  return (
    <Button
      variant={isActiveStyle ? 'contained' : 'text'}
      color={'primary'}
      className={className}
      {...rest}
    >
      {t('new-request')}
    </Button>
  );
};
