import React, { useCallback } from 'react';
import { CardBlock } from '../exhibitor-card-block';
import { CardLine } from '../exhibitor-card-line';
import { APP_ROUTES, EVENT_BUILDER_STEP } from 'configs';
import { useHistory } from 'react-router-dom';

export interface ExhibitorCardProps {
  eventID: string;
  id: string;
  title: string;
  picture: string;
  countryTitle: string;
  countryFlag: string;
  categoryTitle: string;
  users: { firstName: string; lastName: string; profilePicture?: string }[];
  isOwn: boolean;
  isDisabledMeeting: boolean;
}

interface Props extends ExhibitorCardProps {
  view: 'card' | 'line';
}

export const ExhibitorCard: React.FC<Props> = ({ view, ...rest }) => {
  const { push } = useHistory();
  const { eventID, id, isOwn } = rest;
  const onGoToDetails = useCallback(() => {
    push(APP_ROUTES.EXHIBITOR.getConfig()({ id, eventID }));
  }, [id, push, eventID]);
  const onEdit = useCallback(() => {
    push(
      APP_ROUTES.EVENT_BUILDER.getConfig({ customerNetworkingEventID: id })({
        step: EVENT_BUILDER_STEP.START,
      }),
    );
  }, [id, push]);
  const onClick = isOwn ? onEdit : onGoToDetails;
  return view === 'card' ? (
    <CardBlock {...rest} onClick={onClick} />
  ) : (
    <CardLine {...rest} onClick={onClick} />
  );
};
