import React, { useCallback, useEffect } from 'react';
import { LayoutNoData } from 'components/layout-no-data';
import { AppPagination } from 'components/app-pagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventVisitorsActions,
  resetEventVisitorsFilters,
  selectEventVisitorsFilters,
  selectEventVisitorsHasFilters,
  selectEventVisitorsList,
  selectEventVisitorsLoading,
  selectEventVisitorsPagination,
  setEventVisitorsFilters,
  setEventVisitorsPagination,
} from 'store/event';
import { VisitorItem } from '../tab-visitors-item';
import { LayoutSearch } from 'pages/Event/components/layout-search';
import { useAppSelector, usePageEvent } from 'hooks';
import { AsideFilters } from '../aside-filters';
import { Loading } from 'components/loading';

interface Props {
  isVisible: boolean;
}

const COUNT_RECORDS_TO_SHOW_FILTERS = 5;

export const TabVisitors: React.FC<Props> = ({ isVisible }) => {
  const data = useSelector(selectEventVisitorsList);
  const loading = useSelector(selectEventVisitorsLoading);
  const { pages, page } = useSelector(selectEventVisitorsPagination);
  const dispatch = useDispatch();

  const event = usePageEvent();

  const isShowFilters = event.totalVisitors >= COUNT_RECORDS_TO_SHOW_FILTERS;

  const filters = useAppSelector(selectEventVisitorsFilters);
  const hasFilters = useSelector(selectEventVisitorsHasFilters);

  useEffect(() => {
    if (data.length === 0 && isVisible) {
      dispatch(getEventVisitorsActions.request());
    }
  }, [data.length, isVisible, dispatch]);

  const onSetFilters = useCallback(
    (data: Partial<typeof filters>) => {
      dispatch(setEventVisitorsFilters(data));
      dispatch(setEventVisitorsPagination({ page: 1 }));
      dispatch(getEventVisitorsActions.request());
    },
    [dispatch],
  );

  const onResetAndRefresh = useCallback(() => {
    dispatch(setEventVisitorsPagination({ page: 1 }));
    dispatch(resetEventVisitorsFilters());
    dispatch(getEventVisitorsActions.request());
  }, [dispatch]);

  const onSetPage = useCallback(
    (e: any, page: number) => {
      dispatch(setEventVisitorsPagination({ page }));
      dispatch(getEventVisitorsActions.request());
    },
    [dispatch],
  );

  return (
    <LayoutSearch
      Aside={
        isShowFilters ? (
          <AsideFilters
            eventID={event.id}
            initData={filters}
            onSetFilters={onSetFilters}
            isShowClear={hasFilters}
            onReset={onResetAndRefresh}
          />
        ) : null
      }
    >
      <LayoutNoData isNoData={data.length === 0 && !loading}>
        {data.map((visitor) => {
          return (
            <VisitorItem
              key={visitor.appUserID}
              item={visitor}
              disableRequest={!event.isEventDate}
            />
          );
        })}
        <Loading active={loading} />
      </LayoutNoData>
      <AppPagination count={pages} page={page} onChange={onSetPage} />
    </LayoutSearch>
  );
};
