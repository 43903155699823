import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import theme from 'styles/theme';
import 'styles/index.scss';

import { Provider } from 'react-redux';
import { appStore } from 'store';

import { AppRoutes } from 'router';
import { GAProvider } from 'components/google-analytics';

import {
  AppLoadingProvider,
  AppThemeProvider,
  AuthContextProvider,
  AxiosInterceptors,
  FullStoryProvider,
  AppDictionaryProvider,
  ProviderNotifications,
  AppChatProvider,
  ProviderComplete,
} from 'contexts';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const trackingID = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY as string;

const App: FC = () => {
  return (
    <BrowserRouter>
      <Provider store={appStore}>
        <GAProvider trackingID={trackingID}>
          <AxiosInterceptors>
            <AuthContextProvider>
              <AppDictionaryProvider>
                <FullStoryProvider>
                  <AppThemeProvider theme={theme}>
                    <ProviderNotifications>
                      <AppLoadingProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <AppChatProvider>
                            <ProviderComplete>
                              <AppRoutes />
                            </ProviderComplete>
                          </AppChatProvider>
                        </MuiPickersUtilsProvider>
                      </AppLoadingProvider>
                    </ProviderNotifications>
                  </AppThemeProvider>
                </FullStoryProvider>
              </AppDictionaryProvider>
            </AuthContextProvider>
          </AxiosInterceptors>
        </GAProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
