import React, { memo, useCallback, useMemo } from 'react';
import { FormControl, InputLabel } from '@material-ui/core';
import JoditEditor, { JoditProps } from 'jodit-react';
import { Loading } from 'components/loading';
import style from './index.module.scss';
import clsx from 'clsx';

export interface AppEditorProps {
  label?: string;
  value?: any;
  onChange?: (e: string, value: string) => void;
  onBlur?: (value: string) => void;
  error?: boolean;
  disabled?: boolean;
  loading?: boolean;

  config?: JoditProps['config'];
}

export const AppEditor = memo<AppEditorProps>(
  ({ label, value, onChange, onBlur, error, disabled = false, loading = false, config }) => {
    const value_ = useMemo(() => {
      return typeof value !== 'string' ? '' : value;
    }, [value]);

    const config_ = useMemo(() => {
      return {
        buttons:
          'bold,italic,underline,|,ul,ol,outdent,indent,|,align,|,fontsize,paragraph,video,image,table,link,|,undo,redo,|,fullsize,print',
        toolbarAdaptive: false,
        toolbarSticky: false,
        uploader: { insertImageAsBase64URI: true },
        height: '20rem',
        cleanHTML: { denyTags: 'script iframe' },
        disabled,
        ...(config || {}),
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    const handleChange = useCallback(
      (value: string) => {
        onChange && onChange(value, value);
        onBlur && onBlur(value);
      },
      [onChange, onBlur],
    );

    return (
      <FormControl className={clsx(style.wrap, label && style.wrapLabel)} error={Boolean(error)}>
        {label && (
          <InputLabel className={style.label} shrink={true}>
            {label}
          </InputLabel>
        )}
        <JoditEditor value={value_} config={config_} onBlur={handleChange} />
        <Loading active={loading} />
      </FormControl>
    );
  },
);
