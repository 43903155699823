import React from 'react';
import { Dialog, Theme, useMediaQuery } from '@material-ui/core';
import { IDialogBase } from '../../models';
import { FooterSubmit } from '../footer-submit';
import { HeaderSubmit } from '../header-submit';
import './_style.scss';
import { DialogDivider } from '../dialog-divider';
import clsx from 'clsx';
import { Loading } from 'components/loading';

interface DialogSubmitProps extends IDialogBase {
  title: string;

  onSubmit(): void;
}

export const DialogSubmit: React.FC<DialogSubmitProps> = ({
  title,
  isOpen,
  className,
  onClose,
  onSubmit,
  loading,
  children,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classNames = clsx('dialog-submit', className);
  return (
    <Dialog fullWidth fullScreen={isMobile} maxWidth={'md'} open={isOpen} className={classNames}>
      <HeaderSubmit title={title} onClose={onClose} className={'dialog-submit__header'} />
      <div className={'dialog-submit__body'}>
        <div className={'dialog-submit__body-content'}>{children}</div>
        <DialogDivider />
        <div className={'dialog-submit__body-footer'}>
          <FooterSubmit onClose={onClose} onSubmit={onSubmit} className={'dialog-submit__footer'} />
        </div>
        <Loading active={loading} />
      </div>
    </Dialog>
  );
};
