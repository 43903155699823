const API = 'Conversations';

export const API_CHAT = {
  GET_CONVERSATIONS: `${API}/GetUserConversations`,
  GET_CONVERSATION: (conversationID: string) => `${API}/GetConversation/${conversationID}`,
  START_CONVERSATION: (toAppUserID: string) => `${API}/StartConversation/${toAppUserID}`,
  READ_CONVERSATION: (conversationID: string) =>
    `${API}/UpdateConversationAsRead?conversationID=${conversationID}`,
  DELETE_CONVERSATION: (conversationID: string) => `${API}/DeleteConversation/${conversationID}`,
  GET_MESSAGE: (messageID: string) => `${API}/GetMessage/${messageID}`,
  DELETE_MESSAGE: (messageID: string) => `${API}/DeleteMessage/${messageID}`,
  ADD_MESSAGE: `${API}/AddMessage`,
  GET_BADGE_COUNT: `${API}/GetBadgeCounter`,
};

export class Conversation {
  conversationID!: string;
  appUserID!: string;
  appUserFirstName = '';
  appUserLastName = '';
  appUserProfilePicture: string | null = null;
  appUserCompanyName: string | null = null;
  appUserPosition: string | null = null;
  lastMessageFirstName: string | null = null;
  lastMessage = '';
  lastUpdatedDate = new Date().toISOString();
  notRead = false;
}

export class ConversationMessage {
  conversationID!: string;
  conversationMessageID!: string;
  appUserID!: string;
  appUserFirstName = '';
  appUserLastName = '';
  appUserProfilePicture: string | null = '';
  messageDate = new Date().toISOString();
  message = '';
  isRead = false;
}
