import { ValueFileUploader } from 'utils';

const API = 'CustomerNetworkingEventResources';

export const API_NETWORKING_EVENT_RESOURCES = {
  GET_ALL_DYNAMIC: `${API}/GetCustomerNetworkingEventResourcesDynamic`,
  CREATE: `${API}/AddCustomerNetworkingEventResource`,
  DELETE: (id: string) => `${API}/RemoveCustomerNetworkingEventResource/${id}`,
  PATCH: (id: string) => `${API}/PatchCustomerNetworkingEventResource/${id}`,
};

class Base {
  customerNetworkingEventID!: string;
  title: string = '';
  linkURL: ValueFileUploader = '';
  rank: number = 0;
}

export class NewNetworkingEventResource extends Base {
  id: null = null;
}

export class ModelNetworkingEventResource extends Base {
  id!: string;
}

export class NetworkingEventResource extends ModelNetworkingEventResource {}
