import * as React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { AppButtonSizes, AppButtonVariants, useAppButtonClasses } from './useButtonClasses';
import clsx from 'clsx';

const BUTTON_NAME = 'app-button-primary';

interface AppButtonPrimaryProps extends Omit<ButtonProps, 'size'> {
  variant?: AppButtonVariants;
  size?: AppButtonSizes;
}
export const AppButtonPrimary: React.FC<AppButtonPrimaryProps> = ({
  children,
  variant = 'contained',
  size = 'large',
  className,
  ...rest
}) => {
  const appButtonClasses = useAppButtonClasses({ baseName: BUTTON_NAME, variant, size });
  const classNames = clsx('app-button', appButtonClasses, className);
  return (
    <Button {...rest} variant={variant} color={'secondary'} className={classNames}>
      {children}
    </Button>
  );
};
