import React, { useMemo } from 'react';
import { Button, FormControl, FormHelperText, Grid } from '@material-ui/core';
import { useChatMessageTyping, useChatSendMessage } from 'components/custom-chat/hooks';
import { useDecorator, useFormController, useTranslate } from 'hooks';
import JoditEditor from 'jodit-react';
import style from './index.module.scss';
import DOMPurify from 'dompurify';

const MAX_LENGTH = 2000;

const CONFIG = {
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,

  showPlaceholder: false,
  buttons: 'bold,italic,underline,link',

  defaultActionOnPaste: 'insert_only_text',
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,

  height: 130,
  width: '100%',
  allowResizeX: false,
  allowResizeY: false,

  cleanHTML: {
    cleanOnPaste: true,
    removeEmptyElements: true,
    replaceNBSP: true,
    allowTags: {
      p: true,
      span: true,
      a: true,
      strong: true,
      div: true,
      em: true,
      u: true,
      br: true,
    },
  },

  limitChars: MAX_LENGTH,

  link: {
    noFollowCheckbox: false,
    openInNewTabCheckbox: false,
  },

  toolbarAdaptive: false,
  toolbarSticky: false,
};

interface Props {
  isNew: boolean;
  conversationID: string;
  userID: string;
}

export const MessageInput: React.FC<Props> = ({ conversationID, userID, isNew }) => {
  const { control, errors, onSendMessage, loading } = useChatSendMessage({
    conversationID,
    appUserID: userID,
    isNew,
  });
  const { onTyping } = useChatMessageTyping({ conversationID, appUserID: userID, isNew });
  const { t } = useTranslate();

  const { field } = useFormController({
    control,
    errors,
    name: 'message',
    rules: {
      required: {
        value: true,
        message: t('required'),
      },
    },
  });

  const disableButton = field.value.length === 0 || loading;

  const config = useMemo(() => {
    return {
      ...CONFIG,
      disabled: loading,
    };
  }, [loading]);

  const value = field.value;
  const text = useMemo(() => {
    return DOMPurify.sanitize(value, { KEEP_CONTENT: true, ALLOWED_TAGS: [] });
  }, [value]);

  const charsCount = text.length;

  const helperText = field.helperText || `${charsCount} / ${MAX_LENGTH}`;

  return (
    <FormControl error={Boolean(field.error)} className={style.wrap}>
      <Grid container justifyContent={'space-between'} alignItems={'flex-end'}>
        <Grid item xs={12}>
          <JoditEditor
            {...field}
            onChange={useDecorator(field.onChange, onTyping)}
            config={config}
          />
        </Grid>
        <Grid item>
          <FormHelperText>{helperText}</FormHelperText>
        </Grid>
        <Grid item>
          <Button
            disabled={disableButton}
            variant={'contained'}
            color={'secondary'}
            onClick={onSendMessage}
            fullWidth
            style={{
              textTransform: 'uppercase',
            }}
          >
            {t('send')}
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
};
