import { DialogContactUs, DialogContactUsProps } from 'components/dialogs/contact-us';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectEvent } from 'store/event';
import { SUBJECT_SPONSOR_ID } from 'pages/Event';

interface Props extends Omit<DialogContactUsProps, 'commentPayload' | 'isOpen'> {}

export const BecomeSponsorDialogContactUs: React.FC<Props> = ({
  defaultSubjectID = SUBJECT_SPONSOR_ID,
  ...rest
}) => {
  const event = useSelector(selectEvent);
  return (
    <DialogContactUs
      isOpen={true}
      defaultSubjectID={defaultSubjectID}
      {...rest}
      commentPayload={`Event: ${event?.fullEventTitle}`}
    />
  );
};
