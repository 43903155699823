import { ENDPOINTS_PAGES, Page, PageWithHtml } from './models';
import { axiosBaseGetQuery } from 'services/utils';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ServiceUploads } from '../media-uploads';

export const pagesApi = createApi({
  reducerPath: 'pagesApi',
  baseQuery: axiosBaseGetQuery(),
  endpoints: (builder) => ({
    getPageDashboard: builder.query<Page, void>({
      query: () => ({
        url: ENDPOINTS_PAGES.GET_DASHBOARD,
      }),
    }),
    getPageTerms: builder.query<PageWithHtml, void>({
      query: () => ({
        url: ENDPOINTS_PAGES.GET_TERMS_AND_CONDITIONS,
      }),
      transformResponse: async (page: Page) => {
        const { data: html } = await ServiceUploads.getHtml(page.htmlURL);
        return { ...page, html };
      },
    }),
    getPagePrivacy: builder.query<PageWithHtml, void>({
      query: () => ({
        url: ENDPOINTS_PAGES.GET_PRIVACY_POLICE,
      }),
      transformResponse: async (page: Page) => {
        const { data: html } = await ServiceUploads.getHtml(page.htmlURL);
        return { ...page, html };
      },
    }),
    getPageNetwork: builder.query<Page, void>({
      query: () => ({
        url: ENDPOINTS_PAGES.GET_PACKAGE_NETWORK_STEPS,
      }),
    }),
  }),
});
export * from './models';
