import { UserStatusType } from 'configs';
import { UserProfessionalField } from 'services/user-profiles/models';

export enum ENDPOINTS_ACCOUNTS {
  GENERATE_CODE_VIA_SMS = 'Accounts/GeneratePasswordViaSMS',
  VERIFY_RECAPTCHA = 'Accounts/VerifyReCaptchaForApp',
  REFRESH_TOKEN = 'Accounts/RefreshToken',
  LOGOUT = 'Accounts/Logout',
  GET_CURRENT_USER = 'Accounts/GetCurrentAppUser',
  GENERATE_PASSWORD = 'Accounts/GeneratePassword',
  LOGIN = 'Accounts/LoginWithCode',
}

export interface CurrentUser {
  appUserID: string;
  firstName: string;
  lastName: string;
  fullName: string;
  profilePicture: string;
  email: string;
  alternativeEmail: string;
  phoneNumber: string;
  currentCompanyID: string;
  position: string;
  currentCompanyPosition: string;
  companyName: string;
  currentCompanyName: string;
  companyWebsite: string;
  description: string;
  cvFile: string;
  deckFile: string;
  fieldOfOccupationID: number;
  titleOfNameID: number;
  networkItemID: number;
  countryID: number;
  regionID: number;
  medicalFieldID: number;
  healthCareCategoryID: number;
  fieldOfServiceID: number;
  developmentStageID: number;
  totalUnReadInboxMessages: number;
  profileCompleteness: number;
  isSendMailApproval: boolean;
  isActive: boolean;
  isAllowToGetJobOffer: boolean;
  isAllowToAddReview: boolean;
  hasPendingReview: boolean;
  pendingReviewID: string;

  currentAppUserProfileSummary: SummaryInfo;
  userStatusID: UserStatusType;
}
export interface CurrentUserFullInfo extends CurrentUser {
  _professionalFieldOfInterests: UserProfessionalField[];
}

interface SummaryInfo {
  profileCompleteness: number;
  networkItemTitle: string;
  networkItemIcon: string;
  missingInforamtionList: string[];
}

export class CodeViaSMS {
  email: string = '';
  languageID: number = 1;
  rememberMe: boolean = true;
  smSbyAlias: boolean = false;
  phoneNumber: string = '';
}

export interface JWT {
  token: string;
  refreshToken: string;
}
