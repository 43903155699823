import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectLabels } from 'store/labels';
import { calcTranslate } from 'utils';
import { LabelTranslateProps } from 'configs';

export const useTranslate = () => {
  const { map } = useSelector(selectLabels);
  const t = useCallback(
    (key: string) => {
      const label = map[key];
      if (!label && !localStorage.getItem('hideLabels')) {
        console.groupCollapsed(
          `%c "${key}" %c not found please add it to crm`,
          'color: red;',
          'color: black;',
        );
        console.trace();
        console.groupEnd();
      }
      return label?.title || key;
    },
    [map],
  );
  const tp = useCallback(
    (...props: LabelTranslateProps) => {
      const [key, payload] = props;
      return calcTranslate(t(key), payload);
    },
    [t],
  );
  return { t, tp };
};
