import React, { memo } from 'react';
import { LayoutPage } from 'components/layout-page';
import { Button, Grid } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { APP_ROUTES } from 'configs/routes';
import { useDebugMode, useSearchParams } from 'hooks';

export const DEBUG_PASSWORD = '222222';
export const DEBUG_QUERY_NAME = 'pass';

export default memo(() => {
  const { isDebug, onToggle } = useDebugMode();
  const { query } = useSearchParams();
  const pass = query[DEBUG_QUERY_NAME];

  return (
    <LayoutPage>
      <div className={'container'} style={{ height: '100%' }}>
        {pass === DEBUG_PASSWORD ? (
          <Grid
            container
            justify={'center'}
            alignItems={'center'}
            direction={'column'}
            style={{ height: '100%' }}
          >
            <Grid item>
              <h1>Current Mode: {isDebug ? 'DEBUG' : 'USUALLY'}</h1>
            </Grid>
            <Grid item>
              <Button onClick={onToggle} color={'primary'} variant={'contained'}>
                SWITCH
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Redirect to={APP_ROUTES.HOME.getConfig()()} />
        )}
      </div>
    </LayoutPage>
  );
});
