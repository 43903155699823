import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { AppBadge } from 'components/app-badge';
import SmsIcon from '@material-ui/icons/Sms';

import { useSelector } from 'react-redux';
import { eventChatStar, useGaEvent } from '../google-analytics';
import { selectChatHasNewMessages, useChatOpen } from '../custom-chat';

export const IconMessages: React.FC = () => {
  const gaEvent = useGaEvent();

  const { isOpen, onToggle } = useChatOpen();

  const onClick = useCallback(() => {
    setTimeout(() => {
      onToggle();

      if (!isOpen) {
        gaEvent(eventChatStar());
      }
    }, 100);
  }, [isOpen, onToggle, gaEvent]);
  const hasNewMessages = useSelector(selectChatHasNewMessages);

  return (
    <IconButton className={'nav-icon-message'} onClick={onClick}>
      {hasNewMessages ? (
        <AppBadge variant="dot">
          <SmsIcon />
        </AppBadge>
      ) : (
        <SmsIcon />
      )}
    </IconButton>
  );
};
