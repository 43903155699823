import { useChatConversation } from './use-chat-conversation';
import { useAppDispatch, useEffectDebounce } from 'hooks';
import { actionChatReadConversation } from 'components/custom-chat/store';

export const useChatRead = () => {
  const dispatch = useAppDispatch();
  const { isInit, conversation, isLoading } = useChatConversation();

  useEffectDebounce(
    () => {
      if (isInit && !isLoading && conversation && conversation.notRead) {
        dispatch(actionChatReadConversation({ conversationID: conversation.conversationID }));
      }
    },
    2000,
    [isInit, dispatch, conversation, isLoading],
  );
};
