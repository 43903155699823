import { eachDayOfInterval, format, set, toDate as fnsToDate } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

export const isServerDateString = <T>(value: T) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/gi.test(String(value));
};

export type DateValue = Date | string | number | undefined;

export const getDateKeyDay = (date: DateValue) => {
  return format(convertToDate(date), 'yyyy-MM-dd');
};
export const convertToDate = (value: DateValue) => {
  if (!value) {
    return new Date();
  }
  if (typeof value === 'string') {
    return new Date(value);
  }
  return fnsToDate(value);
};
interface SameDateTimeOptions {
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}
export const setTheSameDateTime = (
  originValue: DateValue,
  secondValue: DateValue,
  options?: Partial<SameDateTimeOptions>,
) => {
  const second = convertToDate(secondValue);
  return set(convertToDate(originValue), {
    hours: second.getHours(),
    minutes: second.getMinutes(),
    seconds: second.getSeconds(),
    milliseconds: second.getMilliseconds(),
    ...options,
  });
};
export const getEachDayOfInterval = (start: DateValue, end: DateValue) => {
  return eachDayOfInterval({ start: convertToDate(start), end: convertToDate(end) }).map((date) =>
    String(date),
  );
};
export const getCurrentUTC = () => {
  return format(new Date(), 'xxx');
};
export const getCurrentDate = (hours = 8, minutes = 0, seconds = 0) => {
  const _date = new Date();
  _date.setHours(hours);
  _date.setMinutes(minutes);
  _date.setSeconds(seconds);
  return _date;
};

export const convertZonedToUTC = (date: DateValue, offset: string) => {
  return zonedTimeToUtc(convertToDate(date), offset);
};
export const convertUTCToZoned = (date: DateValue, offset: string) => {
  return utcToZonedTime(convertToDate(date), offset);
};

export const getMinDate = <T extends DateValue>(dates: T[], checker: (date: T) => number) => {
  let min = { value: dates[0], checkerResult: checker(dates[0]) };

  for (let i = 1; i < dates.length; i++) {
    const value = dates[i];
    const { checkerResult } = min;
    const newCheckerResult = checker(value);
    if (newCheckerResult < checkerResult) {
      min = { value, checkerResult: newCheckerResult };
    }
  }

  return min.value;
};
