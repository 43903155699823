import React, { useCallback, useState } from 'react';

import style from './index.module.scss';

import { EventDates } from 'components/event-dates';
import { Divider, ListItem, useMediaQuery } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import { actionEventAddToCalendar } from 'store/event/actions';
import { useAppDispatch, useMountedRef, useTranslate } from 'hooks';
import { Theme } from '@material-ui/core/styles';

interface Props {
  fromDate: string;
  toDate: string;
  networkingEventID: string;
}

export const EventHeaderDates: React.FC<Props> = ({ fromDate, toDate, networkingEventID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const mountedRef = useMountedRef();
  const dispatch = useAppDispatch();

  const onAddToCalendar = useCallback(async () => {
    setIsLoading(true);
    await dispatch(actionEventAddToCalendar({ networkingEventID }));
    if (mountedRef.current) {
      setIsLoading(false);
    }
  }, [mountedRef, dispatch, networkingEventID]);
  const { t } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  return (
    <div className={style.wrap}>
      <EventDates fromDate={fromDate} toDate={toDate} variant={'light'} />
      {!isMobile && <Divider orientation={'vertical'} className={style.divider} />}
      <div className={style.buttonWrap}>
        <ListItem
          button
          component={'button'}
          disabled={isLoading}
          onClick={onAddToCalendar}
          className={style.addCalendar}
        >
          <EventIcon fontSize={'large'} className={style.addCalendarIcon} />
          {t('add-to-calendar')}
        </ListItem>
        {/*<Button className={style.button}></Button>*/}
      </div>
    </div>
  );
};
