export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};
export type PatchModel<T extends {}, ID extends keyof T> = Pick<T, ID> & Partial<Omit<T, ID>>;
export type ArrayModel<T> = T extends (infer U)[] ? U : never;

export const setToModel = <Model extends { [x: string]: any }>(
  model: Model,
  source?: DeepPartial<Model>,
) => {
  const keys: (keyof Model)[] = Object.keys(model);
  const result = {} as Model;
  keys.forEach((key) => {
    if (source && source[key] !== undefined) {
      result[key] = source[key] as Model[keyof Model];
    } else {
      result[key] = model[key];
    }
  });

  return result;
};

export const sortObject = <T extends Record<string, any>>(obj: T) => {
  return Object.keys(obj)
    .sort()
    .reduce((sortedObj, key: keyof T) => {
      sortedObj[key] = obj[key];
      return sortedObj;
    }, {} as T);
};

export const createObjectMap = <M extends any[]>(
  source: M,
  key: keyof ArrayModel<M>,
): { [x: string]: undefined | ArrayModel<M> } => {
  return source.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {});
};
