import { StoreState } from '../index';
import { createSelector } from 'reselect';
import { UserStatusType } from 'configs';
import { StoreCompanyItem } from 'store/auth/slice';

export const selectStateAuth = (state: StoreState) => state.auth;
export const selectAppUserID = createSelector(selectStateAuth, ({ user }) => {
  return user?.appUserID;
});

export const selectCurrentUser = createSelector(selectStateAuth, ({ user }) => {
  return user;
});
const selectCompaniesMap = createSelector(selectStateAuth, ({ companiesMap }) => {
  return companiesMap;
});
export const selectStoreCurrentUserCompany = createSelector(
  [selectCompaniesMap, (state, companyID: string) => companyID],
  (items, companyID) => items[companyID] || new StoreCompanyItem(),
);

export const selectCurrentCompanyID = createSelector(
  selectCurrentUser,
  (user) => user?.currentCompanyID,
);
export const selectCurrentUserCompanies = createSelector(
  selectStateAuth,
  selectCurrentCompanyID,
  ({ loadingCompanies, companies }, companyID) => {
    return {
      isLoading: loadingCompanies,
      data: companies.map((item) => {
        return { ...item, selected: companyID === item.companyID };
      }),
    };
  },
);

export const selectCurrentUserCompaniesPending = createSelector(
  selectCurrentUserCompanies,
  ({ isLoading, data }) => {
    return {
      isLoading,
      data: data.filter(
        ({ companyUserStatusType }) => companyUserStatusType === UserStatusType.PENDING_INVITATION,
      ),
    };
  },
);
export const selectCurrentUserCompaniesActive = createSelector(
  selectCurrentUserCompanies,
  ({ isLoading, data }) => {
    return {
      isLoading,
      data: data.filter(
        ({ companyUserStatusType }) => companyUserStatusType === UserStatusType.ACTIVE,
      ),
    };
  },
);
