import { StoreState } from '../index';
import { createSelector } from '@reduxjs/toolkit';
import { createObjectMap } from '../../utils';

export const selectLabelsState = (state: StoreState) => state.labels;

export const selectLabels = createSelector(selectLabelsState, ({ loading, labels }) => {
  return {
    loading,
    data: labels,
    map: createObjectMap(labels, 'labelKey'),
  };
});
export const selectMapLabels = createSelector(selectLabels, ({ map }) => {
  return map;
});
