import React from 'react';
import styleMain from '../index.module.scss';
import ImgGroup from 'images/bg-video.svg';
import styleCustom from './index.module.scss';
import { useTranslate } from 'hooks';
import clsx from 'clsx';

interface BannerPromoteCompanyProps {
  size?: 'small' | 'medium';
  onClick: () => void;
}

export const BannerPromoteCompany: React.FC<BannerPromoteCompanyProps> = ({
  size = 'medium',
  onClick,
}) => {
  const { t } = useTranslate();
  return (
    <div className={clsx(styleCustom.wrap, styleCustom[size])} onClick={onClick}>
      <div className={styleCustom.banner}>
        <div>
          <h2 className={styleCustom.title}>{t('banner-promote-title')}</h2>
        </div>
        <div className={styleCustom['right-wrap']}>
          <div className={clsx(styleMain.right, styleMain.ripple, styleCustom.right)}>
            <img src={ImgGroup} alt={''} />
          </div>
        </div>
      </div>
    </div>
  );
};
