import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useScrollTo, useTranslate } from 'hooks';
import { MainStagePreview } from '../main-stage-preview';
import { Loading } from 'components/loading';
import { MainStageVideos } from '../main-stage-videos';
import CloseIcon from '@material-ui/icons/Close';
import { networkingEventApi } from 'services/networking-events';
import style from './index.module.scss';

interface Props {
  networkingEventID: string;
  onClose: () => void;
}

export const MainStageDialog: React.FC<Props> = ({ onClose, networkingEventID }) => {
  const { t } = useTranslate();
  const { isLoading, data = [] } = networkingEventApi.endpoints.getEventVideos.useQuery({
    networkingEventID,
  });

  const [videoID, setVideoID] = useState<string | null>(null);
  const selectedVideo = useMemo(() => {
    return data.find(({ id }) => videoID === id);
  }, [data, videoID]);

  useEffect(() => {
    if (data.length > 0) {
      setVideoID(data[0].id);
    }
  }, [data]);

  const [isPlaying, setIsPlaying] = useState(true);
  const refView = useRef<HTMLDivElement>(null);
  const scrollToElement = useScrollTo();
  const onPlay = useCallback(
    (v: string) => {
      if (refView.current) scrollToElement(refView.current);
      setVideoID(v);
      setIsPlaying(true);
    },
    [scrollToElement],
  );

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Dialog fullWidth={true} open={true} maxWidth={'xl'} fullScreen={isMobile}>
      <DialogTitle disableTypography>
        <div className={style.heading}>
          <Typography variant={'h4'} color={'primary'}>
            {t('main-stages')}
          </Typography>
          <IconButton size={'small'} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {selectedVideo && (
          <div ref={refView} className={style.layout}>
            <div>
              <MainStagePreview
                companyID={selectedVideo?.companyID}
                title={selectedVideo?.title}
                companyName={selectedVideo?.companyName}
                videoURL={selectedVideo?.videoURL}
                imageURL={selectedVideo?.imageURL}
                htmlURL={selectedVideo?.html}
                isPlaying={isPlaying}
              />
            </div>
            <div>
              <MainStageVideos
                isLoading={isLoading}
                videoID={selectedVideo.id}
                list={data}
                onPlay={onPlay}
              />
            </div>
          </div>
        )}
        <Loading active={isLoading} />
      </DialogContent>
      <DialogActions>
        <div className={style.control}>
          <Button color={'secondary'} variant={'contained'} onClick={onClose}>
            {t('close')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
