import React from 'react';
import style from './index.module.scss';
interface Props {
  picture: string;
  title: string;
  description: string;
}

export const GeneralBenefit: React.FC<Props> = ({ picture, title, description }) => {
  return (
    <div className={style.wrap}>
      <div className={style.icon}>
        <img src={picture} alt="" />
      </div>
      <div className={style.title}>{title}</div>
      {description}
    </div>
  );
};
