import { UserStatusType } from 'configs';

const API_ENDPOINT = 'Companies';

export const API_COMPANIES = {
  API_ENDPOINT,
  CREATE_COMPANY: `${API_ENDPOINT}/CreateCompany`,
  CREATE_COMPANY_USER: `${API_ENDPOINT}/CreateUserCompany`,
  GET_COMPANIES: `${API_ENDPOINT}/GetCompaniesDynamic`,

  GET_COMPANY: (data: { companyID: string }) => `${API_ENDPOINT}/GetCompany/${data.companyID}`,

  GET_HTML: (p: { fileName: string }) => `${API_ENDPOINT}/GetHtml/${p.fileName}`,

  PATCH_COMPANY: (data: { companyID: string | number }) =>
    `${API_ENDPOINT}/PatchCompany/${data.companyID}`,
  PATCH_COMPANY_USER: (data: { companyUserID: string | number }) =>
    `${API_ENDPOINT}/PatchUserCompany/${data.companyUserID}`,

  DELETE_COMPANY: (data: { companyID: string | number }) =>
    `${API_ENDPOINT}/DeleteCompany/${data.companyID}`,
  DELETE_COMPANY_USER: (data: { companyUserID: string | number }) =>
    `${API_ENDPOINT}/DeleteUserCompany/${data.companyUserID}`,

  GET_DEVELOPMENT_STAGES: `${API_ENDPOINT}/GetCompanyDevelopmentStagesDynamic`,
  POST_DEVELOPMENT_STAG: `${API_ENDPOINT}/CreateCompanyDevelopmentStage`,
  DELETE_DEVELOPMENT_STAGES: (data: { uid: string | number }) =>
    `${API_ENDPOINT}/DeleteCompanyDevelopmentStage/${data.uid}`,

  GET_BUSINESS_ACTIVITIES: `${API_ENDPOINT}/GetCompanyBusinessActivitiesDynamic`,
  POST_BUSINESS_ACTIVITIES: `${API_ENDPOINT}/CreateCompanyBusinessActivity`,
  DELETE_BUSINESS_ACTIVITIES: (data: { uid: string | number }) =>
    `${API_ENDPOINT}/DeleteCompanyBusinessActivity/${data.uid}`,

  INVITE_APP_MEMBER: `${API_ENDPOINT}/InviteExistingMemberToCompany`,
  INVITE_NEW_MEMBER: `${API_ENDPOINT}/InviteNewMemberToCompany`,
  INVITE_PENDING_MEMBER: `${API_ENDPOINT}/InvitePendingMemberToCompany`,

  INVITE_CONFIRM: (companyID: string) => `${API_ENDPOINT}/ConfirmInviteForCompany/${companyID}`,
  INVITE_REJECT: (companyID: string) => `${API_ENDPOINT}/RejectInviteForCompany/${companyID}`,

  GET_USER_COMPANIES_DYNAMIC: `${API_ENDPOINT}/GetUserCompaniesDynamic`,
  PATCH_USER_COMPANY: (p: { userCompanyID: string }) =>
    `${API_ENDPOINT}/PatchUserCompany/${p.userCompanyID}`,
  DELETE_USER_COMPANY: (p: { userCompanyID: string }) =>
    `${API_ENDPOINT}/DeleteUserCompany/${p.userCompanyID}`,
};

export class CompanyModel {
  companyID!: string;
  networkItemID: number | null = null;
  countryID: number | null = null;
  developmentStageID: number | null = null;
  healthCareCategoryID: number | null = null;
  fieldOfServiceID: number | string | null = null;
  businessFieldOfInterestID: number | null = null;
  ingredientTypeID: number | null = null;
  medicalFieldID: number | null = null;
  productTypeID: number | null = null;
  companyName: string = '';
  companyWebsite: string = '';
  logo: string = '';
  deckFile: string = '';
  numberOfEmployees: string = '';
  numberOfMonitoredStudies: string = '';
  salesTurnover: string = '';
  companyDescription: string = '';

  tto = false;
  numberOfResearchers = '';
  mainResearchField = '';
  numberOfPhysicians = '';
  medicalTourism = false;

  _businessActivities: CompanyBusinessActivity[] = [];
}

export class Company extends CompanyModel {
  businessActivities: string = '';
  businessActivityList: string[] = [];
  businessFieldOfInterestTitle: null | number = null;
  fieldOfServiceTitle: null | string = null;
  countryFlag: string = '';
  countryTitle: string = '';
  descriptionLabel: string = '';
  developmentStageTitle: string = '';
  displayCompanyBusinessActivity: boolean = false;
  displayCompanyBusinessFieldOfInterest: boolean = false;
  displayCompanyDevelopmentStage: boolean = false;
  displayCompanyHealthCareCategory: boolean = false;
  displayCompanyIngredientType: boolean = false;
  displayCompanyMedicalField: boolean = false;
  displayCompanyNumberOfMonitoredStudies: boolean = false;
  displayCompanyProductType: boolean = false;
  displayCompanySalesTurnover: boolean = false;
  displayCompanyFieldOfService: boolean = false;
  fileLabel: string | undefined = '';
  healthCareCategoryTitle: string | undefined = '';
  healthCareSubCategoryTitle: string | null = null;
  ingredientTypeTitle: string | null = null;
  medicalFieldLabel: string | null = null;
  medicalFieldTitle: string | null = null;
  networkItemCompanyNameLabel: string | null = null;
  networkItemDescriptionLabel: string | null = null;
  networkItemFileLabel: string | null = null;
  networkItemImage: string = '';
  networkItemMedicalFieldLabel: string = '';
  networkItemTitle: string = '';
  productTypeTitle: string | null = '';

  displayCompanyNumberOfPhysicians: boolean = false;
  displayCompanyMedicalTourism: boolean = false;
  displayCompanyMainResearchField: boolean = false;
  displayCompanyNumberOfResearchers: boolean = false;
  displayCompanyTTO: boolean = false;
  displayCompanyNumberOfEmployees: boolean = false;
}

export class UserCompany {
  userCompanyID!: string;
  appUserID!: string;
  companyID!: string;
  companyLogo: string = '';
  companyName: string = '';
  companyCountryTitle: string = '';
  companyCountryFlag: string = '';
  userCountryTitle: string = '';
  userCountryFlag: string = '';
  fullName: string = '';
  profilePicture: string = '';
  position: string = '';
  isOwner: boolean = false;

  memberUserStatusType!: UserStatusType;
  companyUserStatusType!: UserStatusType;
}

interface BaseInviteModel {
  companyID: string;
  position?: string;
}

export interface CompanyBusinessActivity {
  uid: string;
  companyID: string;
  businessActivityID: string;
}

export interface InviteAppModel extends BaseInviteModel {
  appUserID: string;
}

export interface InviteNewModel extends BaseInviteModel {
  email: string;
  firstName: string;
  lastName: string;
}
