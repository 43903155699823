import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineActions } from '../_utils';
import { NetworkingEvent } from 'services/networking-events';

export const DEBUG_STORE_KEY = '__DEBUG_MODE__';

export interface StateEvents {
  isDebugMode: boolean;
  events: {
    error: null | Error;
    loading: boolean;
    data: NetworkingEvent[];
    filter: {
      search: string;
      isUpcoming: boolean;
    };
    pagination: {
      take: number;
      count: number;
      page: number;
    };
  };
}

const initState = (): StateEvents => {
  return {
    isDebugMode: Boolean(localStorage.getItem(DEBUG_STORE_KEY)),
    events: {
      error: null,
      loading: false,
      data: [],
      filter: {
        search: '',
        isUpcoming: true,
      },
      pagination: {
        take: 20,
        count: 0,
        page: 1,
      },
    },
  };
};

const slice = createSlice({
  name: 'EVENTS',
  initialState: initState(),
  reducers: {
    requestGetEvents(state) {
      state.events.loading = true;
    },
    successGetEvents(state, action: PayloadAction<{ data: NetworkingEvent[]; count: number }>) {
      const {
        payload: { data, count },
      } = action;
      state.events.loading = false;
      state.events.data = data;
      state.events.pagination.count = count;
    },
    failGetEvents(state, action: PayloadAction<Error>) {
      state.events.loading = false;
      state.events.error = action.payload;
    },

    setEventsDebugMode(state, action: PayloadAction<boolean>) {
      state.isDebugMode = action.payload;
    },
    setEventsFilter(state, action: PayloadAction<Partial<StateEvents['events']['filter']>>) {
      state.events.filter = { ...state.events.filter, ...action.payload };
    },
    resetEventsFilter(state) {
      state.events.filter = initState().events.filter;
    },
    setEventsPagination(
      state,
      action: PayloadAction<Partial<StateEvents['events']['pagination']>>,
    ) {
      state.events.pagination = { ...state.events.pagination, ...action.payload };
    },
  },
});

const { requestGetEvents, successGetEvents, failGetEvents } = slice.actions;

export const getEventsActions = combineActions(requestGetEvents, successGetEvents, failGetEvents);

export const { setEventsDebugMode, setEventsFilter, resetEventsFilter, setEventsPagination } =
  slice.actions;

export const reducerEvents = slice.reducer;
