import { UserStatusType } from 'configs';

const API = 'NetworkingEvents';

export const API_NETWORKING_EVENT = {
  GET_DYNAMIC: `${API}/GetNetworkingEventsDynamic`,
  GET_VISITORS_DYNAMIC: `${API}/GetNetworkingEventVisitorsDynamic`,
  GET_EXHIBITORS_DYNAMIC: `${API}/GetNetworkingEventExhibitorsDynamic`,
  GET_PACKAGES_DYNAMIC: `${API}/GetNetworkingEventPackagesDynamic`,
  GET_CATEGORIES_DYNAMIC: `${API}/GetNetworkingEventCategoriesDynamic`,
  GET_SPONSORS_DYNAMIC: `${API}/GetNetworkingEventSponsorsDynamic`,
  GET_COMPANY_VIDEOS_DYNAMIC: `${API}/GetNetworkingEventCompanyVideosDynamic`,

  JOIN_TO_EVENT: (p: { networkingEventID: string; networkingEventCategoryID: string }) =>
    `${API}/JoinNetworkingEvent/${p.networkingEventID}/${p.networkingEventCategoryID}`,
  UN_JOIN_TO_EVENT: (networkingEventID: string) =>
    `${API}/OptOutNetworkingEvent/${networkingEventID}`,

  ADD_EVENT_TO_CALENDAR: (networkingEventID: string) =>
    `${API}/GetAddEventToCalendarURL/${networkingEventID}`,

  GET_MY_VISITOR_EVENTS: `${API}/GetCurrentUserNetworkingEventsAsVisitorDynamic`,
  GET_MY_EXHIBITOR_EVENTS: `${API}/GetCurrentUserNetworkingEventsAsRepresentativeDynamic`,

  ACCEPT_EVENT_INVITATION: (id: string) =>
    `${API}/NetworkingEventRepresentativeAcceptInvitation/${id}`,
  DECLINE_EVENT_INVITATION: (id: string) =>
    `${API}/NetworkingEventRepresentativeDeclineInvitation/${id}`,
};

export interface NetworkingEvent {
  id: string;
  alias: string;
  title: string;
  titlePrefix: string;
  welcomeHtml: string;
  picture: string;
  fromDate: string;
  toDate: string;
  allowVisitors: boolean;
  isVisitor: boolean;
  isExhibitor: boolean;
  earlyStage: boolean;
  earlyStagePrice: string;
  earlyStageDate: string;
  standardPrice: string;
  totalVisitors: number;
  totalExhibitors: number;
  targetAudiences: TargetAudience[];
  benefits: Benefit[];
  services: Feature[];
  video: string;
  extraPresenterPrice: number;
  maxPresenters: number;
  calendarTimeZoneID: string;
  timeZoneID: string;
  timeZoneUtcDistance: string;
  countryID: string;
  timeZoneName: string;
  defaultCategoryID: string | null;
}
export interface NetworkingEventNormalized extends NetworkingEvent {
  fullEventTitle: string;
  isEventDate: boolean;
}

export interface EventSponsor {
  id: string;
  title: string;
  logo: string;
  linkURL?: string;
  rank: number;
}

interface Benefit {
  title: string;
  description: string;
  picture: string;
  rank: number;
}

interface Feature {
  title: string;
  description: string;
  picture: string;
  rank: number;
}

export interface NetworkingEventCategory {
  id: string;
  title: string;
  isActive: boolean;
}

interface TargetAudience {
  title: string;
  rank: number;
}

export interface NetworkingVisitor {
  appUserID: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
  titleOfNameID: number;
  title: string;
  regionID: number;
  countryID: number;
  medicalFieldID: number;
  networkingEventCategoryID: string;
  networkingEventCategoryTitle: string;
  healthCareCategoryID: number;
  networkItemID: number;
  countryTitle: string;
  countryFlag: string;
  position: string;
  companyName: string;
  networkItemTitle: string;
  networkItemIcon: string;
  healthCareCategoryTitle: string;
  medicalFieldTitle: string;
  regionTitle: string;
}

export interface NetworkingExhibitor {
  id: string;
  isOwner: boolean;
  customerID: string;
  companyName: string;
  companyWebsite: string;
  logo: string;
  countryID: number;
  flag: string;
  countryTitle: string;
  packageTitle: string;
  packageIcon: string;
  networkingEventPackageID: string;
  networkingEventCategoryID: string;
  categoryTitle: string;
  displayVideos: boolean;
  videosTitle: string;
  displayImages: boolean;
  imagesTitle: string;
  displayResources: boolean;
  resourcesTitle: string;
  displayContent1: boolean;
  contentTitle1: string;
  contentHtml1: string;
  displayContent2: boolean;
  contentTitle2: string;
  contentHtml2: string;
  representatives: {
    id: string;
    appUserID: string;
    position: string;
    firstName: string;
    lastName: string;
    profilePicture: string;
    userStatusID: UserStatusType;
  }[];
  exhibitorPageVideos: { title: string; videoURL: string; rank: number }[];
  exhibitorPageImages: { title: string; imageURL: string; rank: number }[];
  exhibitorPageResources: { title: string; linkURL: string; rank: number }[];
}

export interface NetworkingVideo {
  id: string;
  title: string;
  videoURL: string;
  html: string;
  imageURL: string;
  companyID: string;
  companyName: string;
  rank: number;
}

export interface MyVisitorEvent {
  id: string;
  networkingEventID: string;
  networkingEventTitle: string;
  networkingEventFromDate: string;
  networkingEventToDate: string;
  networkingEventAlias: string;
  networkingEventPicture: string;
}

export interface MyExhibitorEvent {
  networkingEventRepresentativeID: string;
  userStatusID: number;
  userStatusType: UserStatusType;
  userStatusTitle: string;
  companyName: string;
  companyLogo: string;
  countryFlag: string;
  countryTitle: string;
  networkingEventPicture: string;
  networkingEventID: string;
  networkingEventTitle: string;
  networkingEventFromDate: string;
  networkingEventToDate: string;
  networkingEventAlias: string;
}
