import { useMemo } from 'react';
import { networkingEventApi } from 'services/networking-events';
import {
  CALENDAR_STATUS_ID,
  currentUserTimeZone,
  networkingEventCalendarApi,
} from 'services/networking-event-calendars';
import { useMap } from './use-map';
import { customerNetworkingEventApi } from 'services/customer-networking-event';
import { membersApi } from 'services/members';
import { parametersApi } from 'services/parameters';
import { pagesApi } from 'services/pages';
import { companiesApi } from 'services/companies';
import { ArrayModel } from 'utils';

import vars from 'styles/_variabels.scss';
import { useCurrentUser } from 'hooks/use-current-user';

type DataType<T> = T extends { data?: infer U } ? U : unknown;

const useSourceData = <T extends { data?: any[] }>(v: T) => {
  const data = useMemo(() => {
    return (v.data || []) as Exclude<DataType<T>, undefined>;
  }, [v.data]);
  return { ...v, data };
};
const useSourceDataMap = <T extends { data: any[] }>(v: T, mapKey: keyof ArrayModel<T['data']>) => {
  const map = useMap(v.data, String(mapKey));
  return { ...v, map };
};

export const useApiGetEventSponsors = networkingEventApi.endpoints.getSponsors.useQuery;
export const useSourceTimeZones = () => {
  const result = useSourceDataMap(
    useSourceData(networkingEventCalendarApi.endpoints.getTimezones.useQuery()),
    'id',
  );
  const defaultID = useMemo(() => {
    const currentTimeZoneID = currentUserTimeZone.timeZoneID;
    return result.data.find(({ timeZoneID }) => currentTimeZoneID === timeZoneID)?.id;
  }, [result.data]);
  return { ...result, defaultID };
};

export const useSourceEventCategories = (networkEventID: string) => {
  const result = useSourceData(networkingEventApi.endpoints.getCategories.useQuery(networkEventID));
  const map = useMap(result.data, 'id');
  return { ...result, map };
};

export const useSourceFieldOfOccupations = () =>
  useSourceData(parametersApi.endpoints.getFieldOfOccupation.useQuery());
export const useSourceRegions = () => useSourceData(parametersApi.endpoints.getRegions.useQuery());
export const useSourceSubject = () => useSourceData(parametersApi.endpoints.getSubjects.useQuery());
export const useSourceMedicalFields = () =>
  useSourceData(parametersApi.endpoints.getMedicalFields.useQuery());
export const useSourceHealthCareCategories = () =>
  useSourceData(parametersApi.endpoints.getHealthCareCategories.useQuery());
export const useSourceFieldOfServices = () =>
  useSourceData(parametersApi.endpoints.getFieldOfServices.useQuery());
export const useSourceCountries = () =>
  useSourceData(parametersApi.endpoints.getCountries.useQuery());
export const useSourceProfessionalFieldOfInterests = () =>
  useSourceData(parametersApi.endpoints.getProfInterests.useQuery());
export const useSourceDevelopmentStages = () =>
  useSourceData(parametersApi.endpoints.getDevelopmentStages.useQuery());
export const useSourceTitleOfNames = () =>
  useSourceData(parametersApi.endpoints.getTitleOfNames.useQuery());
export const useSourceNetworkItems = (forMember: boolean = true, forCompany: boolean = false) => {
  const res = useSourceData(
    parametersApi.endpoints.getNetworkItems.useQuery({ forMember, forCompany }),
  );
  const map = useMap(res.data, 'id');
  return { ...res, map };
};
export const useSourceProductTypes = () =>
  useSourceData(parametersApi.endpoints.getProductTypes.useQuery());
export const useSourceBusinessFieldOfInterests = () =>
  useSourceData(parametersApi.endpoints.getBusInterest.useQuery());
export const useSourceIngredientTypes = () =>
  useSourceData(parametersApi.endpoints.getIngredientTypes.useQuery());
export const useSourceBusinessActivities = () =>
  useSourceData(parametersApi.endpoints.getBusActivities.useQuery());

const calcSlotColor = (id: number) => {
  switch (id) {
    case CALENDAR_STATUS_ID.AVAILABLE:
      return vars.colorWhite;
    case CALENDAR_STATUS_ID.TAKEN:
      return vars.colorSecondary;
    case CALENDAR_STATUS_ID.NOT_AVAILABLE:
      return vars.colorBorder;
    case CALENDAR_STATUS_ID.PENDING:
      return vars.colorOrange;
    case CALENDAR_STATUS_ID.MY_PENDING:
      return vars.colorPur;
    case CALENDAR_STATUS_ID.ARRANGE:
      return vars.colorBlueLight;
    default:
      return vars.colorBorder;
  }
};

const calcSlotIcon = (id: number) => {
  switch (id) {
    case CALENDAR_STATUS_ID.AVAILABLE:
      return false;
    case CALENDAR_STATUS_ID.TAKEN:
      return false;
    case CALENDAR_STATUS_ID.NOT_AVAILABLE:
      return false;
    case CALENDAR_STATUS_ID.PENDING:
      return false;
    case CALENDAR_STATUS_ID.MY_PENDING:
      return false;
    case CALENDAR_STATUS_ID.ARRANGE:
      return true;
    default:
      return false;
  }
};
export const useSourceSlotsInfo = () => {
  const result = useSourceData(networkingEventCalendarApi.endpoints.getSlotsInfo.useQuery());
  const data = useMemo(() => {
    return result.data.map((item) => {
      return {
        ...item,
        color: calcSlotColor(item.id),
        icon: calcSlotIcon(item.id),
      };
    });
  }, [result.data]);
  return { ...result, data };
};

export const useSourcePageDashboard = pagesApi.endpoints.getPageDashboard.useQuery;
export const useSourcePagePrivacy = pagesApi.endpoints.getPagePrivacy.useQuery;
export const useSourcePageTerms = pagesApi.endpoints.getPageTerms.useQuery;
export const useSourcePageNetwork = pagesApi.endpoints.getPageNetwork.useQuery;
export const useSourceCustomerEvent =
  customerNetworkingEventApi.endpoints.getMyCustomerEvent.useQuery;
export const useSourceCustomerEventRepresentatives = (
  networkEventID: string,
  isExhibitor: boolean,
) => {
  const result = useSourceData(
    customerNetworkingEventApi.endpoints.getMyCustomerEventRepresentatives.useQuery(
      networkEventID,
      { skip: !isExhibitor },
    ),
  );
  const user = useCurrentUser();
  const data = useMemo(() => {
    return (isExhibitor ? result.data : [user]) as {
      appUserID: string;
      firstName: string;
      lastName: string;
      profilePicture: string;
    }[];
  }, [result.data, user, isExhibitor]);
  return { ...result, data };
};

export const useSourceMember = (appUserID: string) => {
  return membersApi.endpoints.getMember.useQuery(appUserID);
};
export const useSourceMemberCompanies = (appUserID: string) => {
  const { appUserID: currentAppUserID } = useCurrentUser();
  return companiesApi.endpoints.getMemberCompanies.useQuery(appUserID, {
    refetchOnMountOrArgChange: currentAppUserID === appUserID,
  });
};
export const useSourceCalendarUserCalendar =
  networkingEventCalendarApi.endpoints.getUserCalendar.useQuery;
export const useSourceEvent = networkingEventApi.endpoints.getNetworkingEvent.useQuery;
