import { useCallback } from 'react';

export const useScrollTo = () => {
  return useCallback((value: HTMLElement | string) => {
    let element: HTMLElement | null;
    if (typeof value === 'string') {
      element = document.querySelector(value) || null;
    } else {
      element = value;
    }

    if (!element) {
      return console.warn('Scroll element not found');
    }
    element.scrollIntoView({ behavior: 'smooth' });
  }, []);
};
