import React from 'react';
import style from './index.module.scss';
import { Button, Tooltip, Typography } from '@material-ui/core';

import { AppAvatar } from 'components/app-avatar';
import { AppAvatarGroup } from 'components/app-avatar-group';
import { useTranslate } from 'hooks';
import clsx from 'clsx';
import { ChatTooltipDisabled } from 'components/chat-tooltip-disabled';

interface ExhibitorCardProps {
  eventID: string;
  id: string;
  title: string;
  picture: string;
  countryTitle: string;
  countryFlag: string;
  categoryTitle: string;
  users: { firstName: string; lastName: string; profilePicture?: string }[];
  isOwn: boolean;
  onClick: () => void;
  isDisabledMeeting: boolean;
}

export const CardBlock: React.FC<ExhibitorCardProps> = ({
  title,
  picture,
  countryTitle,
  categoryTitle,
  countryFlag,
  users = [],
  isOwn,
  onClick,
  isDisabledMeeting,
}) => {
  const { t } = useTranslate();

  return (
    <div className={clsx(style.item, isOwn && style.active)} onClick={onClick}>
      {(countryTitle || countryFlag) && (
        <div className={style.country}>
          {countryFlag && <img src={countryFlag} alt={''} />}
          <Typography component={'div'} variant={'body2'} className={style.countryName}>
            {countryTitle || ''}
          </Typography>
        </div>
      )}
      <AppAvatar isCompany={true} src={picture} size={'xm'} className={style.avatar} />
      <Typography component={'div'} variant={'h6'} color={'primary'}>
        {title}
      </Typography>
      <div className={style.info}>
        <Typography component={'div'} variant={'subtitle2'} style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
          {t('category')}
        </Typography>
        <Typography component={'div'}>{categoryTitle}</Typography>
      </div>
      <div className={style.bottom}>
        {isOwn ? (
          <Button variant={'contained'} color={'secondary'}>
            {t('edit-my-booth')}
          </Button>
        ) : (
          <>
            {users.length > 0 && (
              <div className={style.users}>
                <AppAvatarGroup max={5}>
                  {users.map((item, i) => {
                    return (
                      <Tooltip key={i} title={`${item.firstName} ${item.lastName}`}>
                        <div>
                          <AppAvatar
                            src={item.profilePicture}
                            firstName={item.firstName}
                            lastName={item.lastName}
                            size={'xs'}
                          />
                        </div>
                      </Tooltip>
                    );
                  })}
                </AppAvatarGroup>
              </div>
            )}
            <ChatTooltipDisabled disabled={!isDisabledMeeting}>
              <div className={style.control}>
                <Button variant={'contained'} color={'primary'} disabled={isDisabledMeeting}>
                  {t('meet-now')}
                </Button>
              </div>
            </ChatTooltipDisabled>
          </>
        )}
      </div>
    </div>
  );
};
