import React from 'react';
import { Tooltip } from '@material-ui/core';
import { useOpen, useTranslate } from 'hooks';

interface Props {
  children: React.ReactElement<any, any>;
  disabled: boolean;
}

export const ChatTooltipDisabled: React.FC<Props> = ({ children, disabled }) => {
  const { t } = useTranslate();
  const { isOpen, onOpen, onClose } = useOpen();
  return (
    <Tooltip
      open={disabled ? false : isOpen}
      title={t('chat-coming-soon')}
      onOpen={onOpen}
      onClose={onClose}
    >
      {children}
    </Tooltip>
  );
};
