import React, { useCallback, useState } from 'react';
import './_style.scss';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DefaultCompanyIcon from 'images/default-company.svg';
import { ServiceCompanies, UserCompany } from 'services/companies';
import { useDispatch } from 'react-redux';
import { actionAccountSelectCompany, actionGetUserCompanies } from 'store/auth';
import { AppButtonPrimary, AppButtonSecondary } from '../../app-buttons';
import { useGoToCompany, useRequestNotify, useTranslate } from 'hooks';
import { eventCompanyView, useGaEvent } from '../../google-analytics';
import clsx from 'clsx';
import { Loading } from 'components/loading';

interface CompanyItemProps {
  company: UserCompany;
  isPending: boolean;
  isSelected?: boolean;
  onClose: () => void;
}

export const CompanyItem: React.FC<CompanyItemProps> = ({
  isPending,
  company,
  isSelected,
  onClose,
}) => {
  const { t } = useTranslate();
  const classNames = clsx(
    'nav-company-item',
    isSelected && 'nav-company-item_active',
    isPending && 'nav-company-item_pending',
  );

  const { goToCompany, onEditCompany: _onEditCompany } = useGoToCompany({
    companyID: company.companyID,
  });

  const gaEvent = useGaEvent();
  const goToCompanyWrap = useCallback(() => {
    gaEvent(eventCompanyView());
    goToCompany();
  }, [gaEvent, goToCompany]);

  const dispatch = useDispatch();

  const onEditCompany = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      gaEvent(eventCompanyView());
      _onEditCompany();
      onClose();
    },
    [_onEditCompany, onClose, gaEvent],
  );

  const onSelectCompany = useCallback(() => {
    if (isPending) {
      return;
    }
    dispatch(actionAccountSelectCompany.request(company));
    goToCompanyWrap();
    onClose();
  }, [isPending, dispatch, company, onClose, goToCompanyWrap]);

  const [isLoading, setLoading] = useState(false);
  const requestDecline = useCallback(async () => {
    await ServiceCompanies.rejectInvite(company.companyID);
    dispatch(actionGetUserCompanies.request());
  }, [company.companyID, dispatch]);
  const { requestWrap: onDecline } = useRequestNotify({
    request: requestDecline,
    setLoading,
    withSuccess: false,
  });
  const requestAccept = useCallback(async () => {
    await ServiceCompanies.confirmInvite(company.companyID);
    dispatch(actionGetUserCompanies.request());
  }, [dispatch, company.companyID]);

  const { requestWrap: onAccept } = useRequestNotify({
    request: requestAccept,
    setLoading,
    withSuccess: false,
  });

  return (
    <div className={classNames} onClick={onSelectCompany}>
      {isPending && <div className={'nav-company-item__message'}>{t('invitation-to-join')}</div>}
      <div className={'nav-company-item__logo'}>
        <img src={company.companyLogo || DefaultCompanyIcon} alt={''} />
      </div>
      <div className={'nav-company-item__title'}>{company.companyName}</div>
      <div className={'nav-company-item__title-sub'}>{company.position}</div>
      {company.isOwner && !isPending && (
        <div className={'nav-company-item__controls'}>
          <IconButton title={t('edit-company')} color={'primary'} onClick={onEditCompany}>
            <EditIcon />
          </IconButton>
        </div>
      )}
      {isPending && (
        <div className={'nav-company-item__controls'}>
          <AppButtonSecondary size={'small'} variant={'text'} onClick={onDecline}>
            {t('decline')}
          </AppButtonSecondary>
          <AppButtonPrimary size={'small'} onClick={onAccept}>
            {t('accept')}
          </AppButtonPrimary>
        </div>
      )}
      <Loading active={isLoading} />
    </div>
  );
};
