import { calcFilterSelect } from 'components/helpers';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useEffectDebounce } from 'hooks';

const setValueToStorage = (name: string, value: any) => {
  if (value) {
    localStorage.setItem(name, value);
  } else {
    localStorage.removeItem(name);
  }
};

interface UseFilterControllerProps<Model> {
  auto?: boolean;
  name: keyof Model;
  queryName?: any;
  isEnable?: boolean;
  initValue?: string;
  queryConverter?: (v: string | undefined) => any;
  valueTemplate?: (props: {
    name: UseFilterControllerProps<Model>['name'];
    value: string;
  }) => string | undefined;
}

export const useFilterController = <Model>({
  auto = true,
  name,
  queryName = name,
  isEnable = true,
  initValue,
  valueTemplate = calcFilterSelect,
}: UseFilterControllerProps<Model>): UseFilterControllerMethods => {
  const [template, setTemplate] = useState<string | undefined>();
  const [value, setValue] = useState(() => {
    let initV = initValue || localStorage.getItem(queryName) || '';
    setTemplate(valueTemplate({ name, value: initV }));
    setValueToStorage(queryName, initV);
    return initV;
  });

  const onChange = useCallback(
    (e?: any) => {
      const value = e?.target?.value || '';
      setValue(value);
      setValueToStorage(queryName, value);
    },
    [queryName],
  );
  const onReset = useCallback(
    (v: any = '') => {
      setValue(v);
      setTemplate(valueTemplate({ name, value: v }));
      setValueToStorage(queryName, v);
    },
    [valueTemplate, name, queryName],
  );

  const onFilter = useCallback(() => {
    setTemplate(valueTemplate({ name, value: value }));
    setValueToStorage(queryName, value);
  }, [valueTemplate, name, value, queryName]);

  useEffectDebounce(
    () => {
      if (auto) {
        onFilter();
      }
    },
    200,
    [onFilter, auto],
  );

  return useMemo(() => {
    return {
      onFilter,
      isEnable,
      value,
      setValue,
      template,
      setTemplate,
      onChange,
      onReset,
    };
  }, [onReset, isEnable, onFilter, value, setValue, onChange, template, setTemplate]);
};
export const useFilterForm = ({
  filters,
  onChangeTemplate,
}: {
  filters: UseFilterControllerMethods[];
  onChangeTemplate: (template: string, isInit: boolean) => void;
}) => {
  const enabledFilters = useMemo(() => {
    return filters.filter(({ isEnable }) => isEnable);
  }, [filters]);
  const reset = useCallback(() => {
    return enabledFilters.forEach(({ onReset }) => onReset(''));
  }, [enabledFilters]);

  const watchTemplate = useMemo(() => {
    return enabledFilters
      .map(({ template }) => template)
      .filter((v) => !!v)
      .join(' && ');
  }, [enabledFilters]);
  const refInit = useRef<boolean>(true);

  useEffectDebounce(
    () => {
      onChangeTemplate(watchTemplate, refInit.current);
      refInit.current = false;
    },
    500,
    [watchTemplate, onChangeTemplate],
  );

  const hasValues = useMemo(() => {
    return enabledFilters.some(({ value }) => !!value);
  }, [enabledFilters]);

  return useMemo(() => {
    return {
      reset,
      watchTemplate,
      hasValues,
    };
  }, [reset, watchTemplate, hasValues]);
};

interface UseFilterControllerMethods {
  value: string;
  isEnable: boolean;
  setValue: (v: UseFilterControllerMethods['value']) => void;
  template: string | undefined;
  setTemplate: (v: UseFilterControllerMethods['template']) => void;
  onChange: (e: { target: { value: UseFilterControllerMethods['value'] } }) => void;
  onFilter: () => void;
  onReset: (v: any) => void;
}
