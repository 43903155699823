import { convertToDate, getCurrentDate } from 'utils/dates';
import { isWithinInterval } from 'date-fns';
import { NetworkingEvent, NetworkingEventNormalized } from 'services/networking-events';

export const adapterEventPriceDate = (data: {
  earlyStageDate: string;
  earlyStage: boolean;
  standardPrice: string | number;
  earlyStagePrice: string | number;
}) => {
  const { earlyStage, earlyStageDate, earlyStagePrice, standardPrice } = data;
  const isEarlyStage = !earlyStage
    ? false
    : earlyStage || (earlyStageDate && getCurrentDate() <= convertToDate(earlyStageDate));

  return {
    isEarlyStage,
    earlyStageDate,
    price: Number(isEarlyStage ? earlyStagePrice : standardPrice),
    standardPrice: Number(standardPrice),
    earlyStagePrice: Number(earlyStagePrice),
  };
};

const calculateFullEventTitle = (props?: { titlePrefix?: string; title?: string } | null) => {
  return [props?.titlePrefix, props?.title].filter((v) => v).join(' ');
};

export const adapterEvenPageData = <T extends NetworkingEvent>(
  data: T,
): NetworkingEventNormalized => {
  return {
    ...data,
    earlyStageDate: data?.earlyStageDate || String(new Date()),
    fullEventTitle: calculateFullEventTitle(data),
    isEventDate: isWithinInterval(new Date(), {
      start: convertToDate(data.fromDate),
      end: convertToDate(data.toDate),
    }),
  };
};
