import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Paper,
  Popper,
} from '@material-ui/core';
import { ProfileCompleteInfo } from './profile-complete-info';
import {
  KEY_DO_NOT_SHOW_COMPLETENESS_PAGE,
  PERCENT_PROFILE_TO_COMPLETENESS,
} from 'contexts/provider-complete';

import CloseIcon from '@material-ui/icons/Close';
import { APP_ROUTES } from 'configs/routes';
import { CirclePercent } from '../circle-percent';
import VAR from 'styles/_variabels.scss';
import { useCurrentUser, useIsRoutes, useOpen, useTranslate } from 'hooks';

export const ProfileComplete: React.FC = () => {
  const { t } = useTranslate();
  const { profileCompleteness } = useCurrentUser();
  const ref = useRef<HTMLUListElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLUListElement | null>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleToggle = useCallback(
    (e: any) => {
      if (!anchorEl) {
        setAnchorEl(e.currentTarget);
      } else {
        setAnchorEl(null);
      }
    },
    [anchorEl],
  );

  const { isOpen: isShowAgainState, onClose: onCloseShowAgainState } = useOpen(
    !localStorage.getItem(KEY_DO_NOT_SHOW_COMPLETENESS_PAGE),
  );
  const [stateCompleteness, setstateCompleteness] = useState(false);
  const onChangeCompleteness = useCallback((e: any, checked: boolean) => {
    setstateCompleteness(checked);
    localStorage.setItem(KEY_DO_NOT_SHOW_COMPLETENESS_PAGE, String(checked));
  }, []);

  const isFullEditPage = useIsRoutes(APP_ROUTES.FULL_EDIT.path);
  const isOpenDoNotShowAgain = useMemo(() => {
    return isShowAgainState && isFullEditPage;
  }, [isShowAgainState, isFullEditPage]);

  return profileCompleteness !== 100 ? (
    <div className={'profile-complete'}>
      <List ref={ref}>
        <ListItem onClick={handleToggle} button>
          <CirclePercent
            width={40}
            height={40}
            percent={profileCompleteness}
            value={`${profileCompleteness}%`}
            colorLine={
              profileCompleteness < PERCENT_PROFILE_TO_COMPLETENESS
                ? VAR.colorOrange
                : VAR.colorPrimary
            }
          />
          <div className={'profile-complete__title'}>{t('profile-completeness')}</div>
        </ListItem>
      </List>
      <Popper open={!!anchorEl} anchorEl={anchorEl} style={{ zIndex: 99 }}>
        <Paper className={'profile-complete__menu'}>
          <ClickAwayListener onClickAway={handleClose}>
            <div>
              <ProfileCompleteInfo onClose={handleClose} />
            </div>
          </ClickAwayListener>
        </Paper>
      </Popper>
      <Popper open={isOpenDoNotShowAgain} anchorEl={ref.current} style={{ zIndex: 99 }}>
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <div className={'profile-complete__do-not-show'}>
              <FormControlLabel
                label={t('do-not-show-again-this-page')}
                control={
                  <Checkbox
                    color={'primary'}
                    checked={stateCompleteness}
                    onChange={onChangeCompleteness}
                  />
                }
              />
              <IconButton size={'small'} color={'primary'} onClick={onCloseShowAgainState}>
                <CloseIcon fontSize={'small'} />
              </IconButton>
            </div>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  ) : null;
};
