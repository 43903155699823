import { AxiosError, AxiosTransformer } from 'axios';
import { sortObject } from 'utils/model';
import { isServerDateString } from 'utils/dates';

export const calcPaginationSkip = ({ page, take }: { take: number; page: number }) => {
  return take * (page - 1);
};
export const calcPaginationState = ({
  take,
  page,
  count,
}: {
  take: number;
  count: number;
  page: number;
}) => {
  const skip = calcPaginationSkip({ take, page });
  const pages = Math.ceil(count / take);
  const isLastPage = pages === page;
  return {
    take,
    page,
    count,
    pages,
    skip,
    isLastPage,
  };
};

export const parseErrorData = <T = string>(error: { message?: string } | AxiosError<T>) => {
  if (!error) {
    return new Error('error');
  }
  if ('isAxiosError' in error) {
    const errorData = error.response?.data;

    if (!errorData) {
      return new Error('error');
    }

    if (typeof errorData === 'string') {
      return new Error(errorData);
    }
    return { ...new Error('custom-error'), ...errorData };
  }
  return new Error(error.message);
};

export enum ResponseType {
  ERROR = 0,
  WARNING = 1,
  INFO = 2,
  SUCCESS = 3,
}

export interface ServiceError<P = undefined> {
  responseType: ResponseType;
  messageLabel: string;
  additionalParams: P;
}

type NoUndefinedField<T> = {
  [P in keyof T]-?: Exclude<T[P], null | undefined>;
};
export const prepareRecords = <T extends Record<string, any>>(
  oldRecords: T[],
  newRecords: T[],
  mainField: keyof T,
) => {
  const postItems = newRecords.filter(
    (item) => item[mainField] === null || item[mainField] === undefined,
  ) as NoUndefinedField<T>[];
  const deleteItems = [] as NoUndefinedField<T>[];
  const patchItems = [] as { oldItem: NoUndefinedField<T>; newItem: NoUndefinedField<T> }[];

  const mapNewRecordsIds = new Map(
    newRecords.filter((item) => item[mainField]).map((item) => [item[mainField], item]),
  );
  const mapOldRecordsIds = new Map(oldRecords.map((item) => [item[mainField], item]));

  Array.from(mapNewRecordsIds.entries()).forEach(([id, item]) => {
    const isInOld = mapOldRecordsIds.get(id);
    if (!isInOld) {
      // @ts-ignore
      postItems.push(item);
    }
  });

  Array.from(mapOldRecordsIds.entries()).forEach(([id, item]) => {
    const isInNewItem = mapNewRecordsIds.get(id);
    if (!isInNewItem) {
      // @ts-ignore
      deleteItems.push(item);
    }
  });

  Array.from(mapNewRecordsIds.entries()).forEach(([id, newItem]) => {
    const oldItem = mapOldRecordsIds.get(id);
    if (oldItem) {
      if (JSON.stringify(sortObject(oldItem)) !== JSON.stringify(sortObject(newItem))) {
        // @ts-ignore
        patchItems.push({ oldItem, newItem });
      }
    }
  });

  return {
    postItems,
    deleteItems,
    patchItems,
  };
};

export const axiosDateTransformer: AxiosTransformer = (res) => {
  try {
    return JSON.parse(res, (key, value) => {
      if (typeof value === 'string' && isServerDateString(value)) {
        const _date = Date.parse(value);
        if (_date) {
          return `${String(value).split('.')[0]}.000Z`;
        }
      }
      return value;
    });
  } catch (e) {
    return res;
  }
};
export const isRtkMutationRejected = <T>(mutationResult: any): mutationResult is { error: T } => {
  return Boolean(mutationResult && mutationResult.error);
};
export const isRtkMutationFulfilled = <T>(mutationResult: any): mutationResult is { data: T } => {
  return Boolean(
    mutationResult && mutationResult.hasOwnProperty && mutationResult.hasOwnProperty('data'),
  );
};

export interface BaseParams {
  select?: string;
  filter?: string;
  orderBy?: string;
  take?: number;
  skip?: number;
  count?: boolean;
}

type IsTrue<T extends any, S, E> = T extends true ? S : E;

export type DynamicResult<T extends any, P extends BaseParams | undefined = undefined> = P extends {
  count: infer U;
}
  ? IsTrue<U, { value: T[]; count: number }, { value: T[] }>
  : { value: T[] };
