import React from 'react';
import { Pagination, PaginationProps } from '@material-ui/lab';
import './_style.scss';
import { Theme, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

interface AppPaginationProps extends PaginationProps {}

export const AppPagination: React.FC<AppPaginationProps> = ({ className, ...rest }) => {
  const classNames = clsx('app-pagination', className);
  const { count } = rest;

  const isMdLess = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isSMLess = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return count && count > 1 ? (
    <div className={'app-pagination-wrap'}>
      <Pagination
        {...rest}
        color={'primary'}
        siblingCount={1}
        boundaryCount={1}
        size={isSMLess ? 'medium' : isMdLess ? 'medium' : 'large'}
        className={classNames}
        hidePrevButton
        hideNextButton
      />
    </div>
  ) : null;
};
