import { ValueFileUploader } from 'utils';

const API = 'CustomerNetworkingEventVideos';

export const API_NETWORKING_EVENT_VIDEOS = {
  GET_ALL_DYNAMIC: `${API}/GetCustomerNetworkingEventVideosDynamic`,
  CREATE: `${API}/AddCustomerNetworkingEventVideo`,
  DELETE: (id: string) => `${API}/RemoveCustomerNetworkingEventVideo/${id}`,
  PATCH: (id: string) => `${API}/PatchCustomerNetworkingEventVideo/${id}`,
};

export interface NetworkingEventVideo {
  id: string;
  customerNetworkingEventID: string;
  title: string;
  videoURL: ValueFileUploader;
  rank: number;
}
